import { useEffect, useMemo, useState } from "react";
import Box from "@mui/material/Box";
import CssBaseline from "@mui/material/CssBaseline";
import Toolbar from "@mui/material/Toolbar";
import List from "@mui/material/List";
import Typography from "@mui/material/Typography";
import ListItem from "@mui/material/ListItem";
import ListItemButton from "@mui/material/ListItemButton";
import {
	CircularProgress,
	Collapse,
	IconButton,
	useMediaQuery,
} from "@mui/material";
import AccountCircleOutlinedIcon from "@mui/icons-material/AccountCircleOutlined";
import ExpandLess from "@mui/icons-material/ExpandLess";
import ExpandMore from "@mui/icons-material/ExpandMore";
import { useLocation, useNavigate, Outlet } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import { useCallback } from "react";

import logOutimg from "../../assets/logout.svg";
import madconnectLogo from "../../assets/madconnect-logo.svg";
import DashboardLogo from "../../assets/DashboardLogo.svg";
import ConnectionLogo from "../../assets/ConnectionLogo.svg";
import settingsLogo from "../../assets/settingsLogo.svg";
import userLogo from "../../assets/userLogo.svg";
import userLogoSolid from "../../assets/userLogoSolid.svg";
import DashboardLogoOutline from "../../assets/DashboardLogoOutline.svg";
import ConnectionLogoSolid from "../../assets/ConnectionLogoSolid.svg";
import settingsLogoSolid from "../../assets/settingsLogoSolid.svg";
import supportLogo from "../../assets/support.svg";
import supportSolidLogo from "../../assets/supportSolid.svg";

import { color } from "../../utils/Colors";
import {
	useGetUserQuery,
	useLogOutMutation,
	useUpdateUserStatusMutation,
} from "../../services/query";
import {
	useGetAccountMutation,
	useGetAllAccountsQuery,
	useGetBillableAccountMutation,
	useGetBillableAccountQuery,
} from "../../services/accountsQuery";
import {
	madTechState,
	setIsAdminAgencyUser,
	setIsHomeNav,
	setIsMyplatforms,
	setLoggedin,
	setSelectedAccount,
	setUser,
} from "../../features/madTechSlice";
// import LogoutIcon from '@mui/icons-material/Logout';
import { HOST } from "../../utils/Baseurl";
import { ReportLogo, ReportLogoSolid } from "../CommanFields/TextFields";
import { useGetUserActionStatusMutation } from "../../services/proxyServerQuery";
import PendingSnowflakeStep from "../SnowFlakeStep/PendingSnowflake";
import { isSnowFlake } from "../../utils/utils";
import { ErrorBoundary } from "react-error-boundary";
import ErrorHandler from "./ErrorHandler";
import {
	HeaderWrapper,
	SectionHeading,
} from "../../screens/Dashboard/Dashboard.styles";
import DashboardIcon from "../../assets/DashboardIcon";
import ConnectionsIcon from "../../assets/ConnectionsIcon";
import ReportsIcon from "../../assets/ReportsIcon";
import SettingsIcon from "../../assets/SettingsIcon";
import ResourcesIcon from "../../assets/ResourcesIcon";
import SupportIcon from "../../assets/SupportIcon";

// const drawerWidth = window.innerWidth * 0.17;
// docs.madconnect.io

const AppLayout = () => {
	const location = useLocation();
	const queryParams = new URLSearchParams(location?.search);
	const account = queryParams?.get("accountId");
	const matchexl = useMediaQuery("(min-width:1500px)");

	const drawerWidth = 236;

	const { pathname } = useLocation();
	const { isLoggedin, isHomeNav, selectedAccount, user } =
		useSelector(madTechState);
	const dispatch = useDispatch();
	const navigate = useNavigate();
	const [connectionOpen, setconnectionOpen] = useState(
		pathname.startsWith("/platform") ||
			pathname === "/connections" ||
			pathname === "/create-connections"
	);
	const [settingsOpen, setsettingsOpen] = useState(
		pathname === "/myprofile" ||
			pathname === "/profileDetails" ||
			pathname === "/invoice" ||
			pathname.startsWith("/users") ||
			pathname.startsWith("/roles") ||
			pathname.startsWith("/pricing")
	);
	const [hoverTextId, setHoverTextId] = useState(null);
	const [subheadingText, setSubheadingText] = useState(null);
	const [userType, setUserType] = useState("Brand");
	const [loading, setLoading] = useState(account ? true : false);

	// const [verifiedUser, setVerifiedUser] = useState(false)

	const userDetails = JSON.parse(localStorage.getItem("USER"));
	const [logOutUser, { data, error }] = useLogOutMutation();
	const {
		data: userData,
		error: userError,
		refetch,
	} = useGetUserQuery({ userid: userDetails?.userId });
	const [updateUserStatus, { data: updateRes, error: updateError }] =
		useUpdateUserStatusMutation();
	const [
		getAcoountDetails,
		{ data: accountDetailsData, error: accountDetailsError },
	] = useGetAccountMutation();
	const {
		data: accountbillDetailsData,
		error: accountbillDetailsError,
		isLoading,
	} = useGetBillableAccountQuery(account ? account : userDetails?.accountId);

	const [open, setOpen] = useState(false);

	const handleOpen = useCallback(() => {
		setOpen(true);
		if (
			userData?.response?.accountDetails?.firstLogin &&
			pathname === "/dashboard"
		) {
			updateUserStatus(false);
		}
	}, [useUpdateUserStatusMutation, useGetUserQuery, open, userData]);

	const handleCloseSettings = useCallback(() => {
		setsettingsOpen(false);
	}, []);

	const handleCloseConnection = useCallback(() => {
		setconnectionOpen(false);
	}, []);

	const canView = (name) => {
		const permission = user?.permissions?.find((p) => p.name === name);
		if (!permission) return true; // If permission name is not found, visible to all users
		return permission.permissions.view;
	};

	const dashBoardIems = useMemo(
		() =>
			[
				{
					text: "Dashboard",
					permissionName: "Dashboard",
					img: () =>
						pathname === "/dashboard" ? (
							<DashboardIcon variant="solid" color="#538BF4" />
						) : (
							<DashboardIcon variant="outline" color="#929396" />
						),
					navigateUrl: selectedAccount
						? "/dashboard?accountId=" + selectedAccount.id
						: "/dashboard",
					isActive: pathname === "/dashboard",
				},
				{
					text: "Connections",
					permissionName: "Connections",
					img: () =>
						connectionOpen ||
						pathname === "/platform" ||
						pathname === "/connections" ||
						pathname === "/create-connections" ? (
							<ConnectionsIcon variant="solid" color="#538BF4" />
						) : (
							<ConnectionsIcon variant="outline" color="#929396" />
						),
					isActive:
						pathname.startsWith("/platform") ||
						pathname === "/create-connections" ||
						pathname === "/connections",
				},
				{
					text: "Reports",
					permissionName: "Reports",
					img: () =>
						pathname === "/reports" ? (
							<ReportsIcon variant="solid" color="#538BF4" />
						) : (
							<ReportsIcon variant="outline" color="#929396" />
						),
					navigateUrl: selectedAccount
						? "/reports?accountId=" + selectedAccount.id
						: "/reports",
					isActive: pathname === "/reports",
				},
				{
					text: "Settings",
					permissionName: "Settings",
					img: () =>
						settingsOpen ||
						(account
							? pathname === "/profileDetails"
							: pathname === "/myprofile") ||
						pathname === "/invoice" ? (
							<SettingsIcon variant="solid" color="#538BF4" />
						) : (
							<SettingsIcon variant="outline" color="#929396" />
						),
					isActive:
						pathname === "/myprofile" ||
						pathname === "/invoice" ||
						pathname === "/users" ||
						pathname === "/roles" ||
						pathname === "/profileDetails" ||
						pathname === "/pricing",
				},
				{
					text: "Resources",
					permissionName: "Resources",
					img: () => <ResourcesIcon variant="outline" color="#929396" />,
				},
				{
					text: "Support",
					permissionName: "Support",
					img: () => <SupportIcon variant="outline" color="#929396" />,
				},
			].filter((item) => canView(item.permissionName)),
		[hoverTextId, pathname]
	);

	const handleConnectionOpen = () => {
		setconnectionOpen(true);
	};

	const handleSettingsOpen = () => {
		setsettingsOpen(true);
	};

	const handleOpenSettings = () => {
		setsettingsOpen(true);
	};

	useEffect(() => {
		// get account details
		const queryParams = new URLSearchParams(location.search);
		if (
			queryParams !== null &&
			queryParams !== undefined &&
			queryParams.has("accountId")
		) {
			const accountId = queryParams.get("accountId");
			getAcoountDetails(accountId);
		} else {
			dispatch(setSelectedAccount(null));
		}

		if (
			userDetails !== undefined &&
			userDetails !== null &&
			userDetails?.userType !== null &&
			userDetails?.userType !== ""
		) {
			setUserType(userDetails?.userType);
			if (userDetails?.accountDetails?.firstLogin) {
				setOpen(true);
			}
		}
	}, []);

	useEffect(() => {
		if (!isHomeNav) {
			dispatch(setIsHomeNav());
		}
	}, [isHomeNav]);

	useEffect(() => {
		if (data !== undefined) {
			dispatch(setLoggedin("no"));
			dispatch(setIsAdminAgencyUser(false));
			localStorage.clear();
			window.location.href = "/signin";
		}

		if (error !== undefined) {
			console.log("error", error);
		}
	}, [data, error]);

	const [carouselSteps, setCarouselSteps] = useState([]);

	useEffect(() => {
		if (userData !== undefined) {
			if (userData?.statusCode === 200 && userData?.response) {
				localStorage.setItem("USER", JSON.stringify(userData.response));
				dispatch(setUser(userData.response));
				// dispatch(setLoggedin());
				const userStatus = userData?.response?.accountDetails?.status;

				if (userStatus === "CONTRACT_PENDING") {
					setCarouselSteps([
						{
							title: "Sign the Contract",
							label:
								"In order to activate your connections, we need you to sign the agreement, which you will receive via Docusign email.",
							imgPath: require("../../assets/CarouselImage8.png"),
						},
						{
							title: "Activate Connections",
							label:
								"Our technical team is working on your account setup. Check your MadConnect dashboard alerts for an update when your connections are activated.",
							imgPath: require("../../assets/CarouselImage3.png"),
						},
						{
							title: "Add more connections!",
							label:
								"You can add more connections at any time through the dashboard or if you get in contact with your customer success manager. ",
							imgPath: require("../../assets/CarouselImage9.png"),
						},
					]);
				} else if (userStatus === "IN_PROGRESS") {
					setCarouselSteps([
						{
							title: "Activate Connections",
							label:
								"Our technical team is working on your account setup. Check your MadConnect dashboard alerts for an update when your connections are activated.",
							imgPath: require("../../assets/CarouselImage3.png"),
						},
						{
							title: "Add more connections!",
							label:
								"You can add more connections at any time through the dashboard or if you get in contact with your customer success manager. ",
							imgPath: require("../../assets/CarouselImage9.png"),
						},
					]);
				} else if (userStatus === "READY ") {
					setCarouselSteps([
						{
							title: "Add more connections!",
							label:
								"You can add more connections at any time through the dashboard or if you get in contact with your customer success manager. ",
							imgPath: require("../../assets/CarouselImage9.png"),
						},
					]);
				} else {
					setCarouselSteps([
						{
							title: "Congratulations on being part of MadConnect",
							label:
								"There are four critical steps for you to complete next in order to be successful on the MadConnect platform.",
							imgPath: require("../../assets/CarouselImage6.webp"),
						},
						{
							title: "Pricing Details",
							label:
								"Look out for an email from our customer success team about pricing.",
							imgPath: require("../../assets/CarouselImage7.png"),
						},
						{
							title: "Sign the Contract",
							label:
								"In order to activate your connections, we need you to sign the agreement, which you will receive via Docusign email.",
							imgPath: require("../../assets/CarouselImage8.png"),
						},
						{
							title: "Activate Connections",
							label:
								"Our technical team is working on your account setup. Check your MadConnect dashboard alerts for an update when your connections are activated.",
							imgPath: require("../../assets/CarouselImage3.png"),
						},
						{
							title: "Add more connections!",
							label:
								"You can add more connections at any time through the dashboard or if you get in contact with your customer success manager. ",
							imgPath: require("../../assets/CarouselImage9.png"),
						},
					]);
				}

				if (
					userData?.response?.accountDetails?.firstLogin &&
					pathname === "/dashboard"
				) {
					handleOpen();
				}
			} else {
				localStorage.clear();
				// window.location.href = HOST + "/signin";
			}
		}

		// {
		//     "otp": "655966",
		//     "userId": "b481ab12428cab45",
		//     "userType": "Brand",
		//     "token": "40557a81-b5b4-4c9b-bc86-6974c8165cc1",
		//     "firstName": "Subhajit",
		//     "lastName": "Paul",
		//     "email": "subhajit.p@madtechadvisors.com",
		//     "companyName": "MadConnect",
		//     "title": "Developer",
		//     "phoneNumber": "8961912223",
		//     "stepPending": 0,
		//     "accountDetails": {
		//       "status": "WAITING_FOR_REVIEW",
		//       "description": "Account is under review",
		//       "firstLogin": true
		//     }
		//   }

		if (userError !== undefined) {
			console.log("error", userError);
		}
	}, [userData, userError]);

	useEffect(() => {
		if (updateRes !== undefined) {
			if (updateRes?.statusCode === 200) {
				refetch();
			}
		}

		if (updateError !== undefined) {
			console.log("updateError", updateError);
		}
	}, [updateRes, updateError]);

	useEffect(() => {
		if (accountDetailsData !== undefined) {
			if (accountDetailsData?.statusCode === 200) {
				dispatch(setSelectedAccount(accountDetailsData?.response));
			}
		}

		if (accountDetailsError !== undefined) {
			dispatch(setSelectedAccount(null));
		}
	}, [accountDetailsData, accountDetailsError]);

	useEffect(() => {
		if (selectedAccount) {
			setLoading(false);
		}
	}, [selectedAccount]);

	const settingNavigateURL = useMemo(() => {
		if (account) return "/profileDetails";
		if (canView("Profile")) return "/myprofile";
		else if (canView("Profile")) return "/profileDetails";
		else if (canView("Users")) {
			return "/users";
		} else if (canView("Roles")) {
			return "/roles";
		} else if (canView("Pricing")) {
			return "/pricing";
		}
		return "/myprofile";
	}, [user, account]);

	if (isLoggedin === "no") {
		localStorage.clear();
		navigate("/signin");
		return;
	}
	return (
		<Box
			sx={{
				display: "flex",
				flex: "1 1 0%",
				height: "100vh",
			}}
		>
			{loading ? (
				<CircularProgress
					variant="indeterminate"
					disableShrink
					sx={{ margin: "auto", color: color.loadingColor }}
					size={45}
					thickness={8}
				/>
			) : (
				<>
					<CssBaseline />
					{/* SIDE BAR */}
					<Box
						sx={{
							width: drawerWidth,
							flexShrink: 0,
						}}
						PaperProps={{
							style: {
								width: drawerWidth,
								// marginTop: appbarHeight,
								boxSizing: "border-box",
								backgroundColor: color.whiteColor,
							},
						}}
						variant="permanent"
						anchor="left"
					>
						<Box
							sx={{
								borderRight: "1px solid rgba(0, 0, 0, 0.12)",
								height: "100%",
								display: "flex",
								flexDirection: "column",
								justifyContent: "space-between",
							}}
						>
							<Box sx={{ margin: "15px auto 25px 35px" }}>
								<img
									style={{
										height: "40px",
										width: "100px",
										cursor: "pointer",
									}}
									alt={"MADCONNECT"}
									onClick={() => {
										handleCloseConnection();
										handleCloseSettings();
										navigate(
											selectedAccount
												? "/dashboard?accountId=" + selectedAccount?.id
												: "/dashboard"
										);
										window.scrollTo({ behavior: "auto", top: 0 });
									}}
									src={madconnectLogo}
								/>
							</Box>
							<List
								sx={{
									py: 0,
									flex: "1 1 0",
									overflowY: "auto",
									overflowX: "hidden",
								}}
							>
								{dashBoardIems?.map((item, index) => (
									<ListItem
										key={index}
										disablePadding
										sx={{
											width: "100%",
											mb: "0rem",
											flexDirection: "column",
											alignItems: "center",
											height:
												(connectionOpen && item.text === "Connections") ||
												(item.text === "Settings" && settingsOpen)
													? undefined
													: 64,
										}}
									>
										<ListItemButton
											onMouseOver={() => setHoverTextId(index)}
											onMouseLeave={() => setHoverTextId(null)}
											onClick={() => {
												if (item.text === "Resources") {
													window.open("https://docs.madconnect.io/");
													return;
													// window.location.href = 'https://docs.madconnect.io/'
												}
												
												if (item.text === "Connections") {
													handleConnectionOpen();
													dispatch(setIsMyplatforms("My Platform"));
													navigate(
														selectedAccount
															? "/platform?accountId=" + selectedAccount.id
															: "/platform"
													);
													window.scrollTo({ behavior: "auto", top: 0 });
													if (settingsOpen) {
														handleCloseSettings();
													}
													return;
												} else if (connectionOpen) {
													handleCloseConnection();
												}

												if (item.text === "Settings") {
													handleSettingsOpen();
													navigate(
														selectedAccount
															? `${settingNavigateURL}?accountId=` +
																	selectedAccount.id
															: `${settingNavigateURL}`
													);
													window.scrollTo({ behavior: "auto", top: 0 });
													return;
												} else if (settingsOpen) {
													handleCloseSettings();
												}

												if (item.text === "Support") {
													// window.location.href = "mailto:support@madconnect.io"
													navigate(
														selectedAccount
															? "/support?accountId=" + selectedAccount.id
															: "/support"
													);
													return;
												}

												navigate(item.navigateUrl);
												window.scrollTo({ behavior: "auto", top: 0 });
											}}
											sx={{
												position: "relative",
												padding: "0px",
												display: "flex",
												flexDirection: "row",
												justifyContent: "center",
												width: "100%",
												height: "3.5rem",
												background: "none !important",
											}}
										>
											<Box
												sx={{
													display: "flex",
													flexDirection: "row",
													alignItems: "center",
													gap: "10px",
													width: "206px",
													height: "51px",
													borderRadius: "7px",
													padding: "15px 20px",
													backgroundColor:
														item.isActive ||
														(item.text === "Connections" && connectionOpen) ||
														(item.text === "Settings" && settingsOpen)
															? color.sidebarItemSelected
															: "transparent",
													// opacity: (item.text === 'Connections' && connectionOpen) ||
													//     (item.text === 'Settings' && settingsOpen) ? 0.8 : 1,
													"&:hover": {
														backgroundColor: color.sidebarItemHover,
														// opacity: 0.8,
													},
												}}
											>
												{item.img()}
												<Typography
													sx={{
														color:
															item.isActive ||
															(item.text === "Connections" && connectionOpen) ||
															(item.text === "Settings" && settingsOpen)
																? color.themeColor
																: color.themeDarkGray,
														fontSize: "1.125rem",
														fontWeight: 400,
														letterSpacing: "0.04em",
														fontFamily: "Helvetica Neue",
													}}
												>
													{item.text}
												</Typography>
											</Box>
											{/* <ListItemText primary={item.text} sx={{ color: color.themeColor, fontWeight: 900 }} /> */}
										</ListItemButton>
										{item.text === "Connections" && (
											<Collapse
												in={connectionOpen}
												timeout="auto"
												unmountOnExit
											>
												<ListItemButton
													onMouseOver={() => setSubheadingText("My platforms")}
													onMouseLeave={() => setSubheadingText(null)}
													onClick={() => {
														dispatch(setIsMyplatforms("My Platform"));
														navigate(
															selectedAccount
																? "/platform?accountId=" + selectedAccount.id
																: "/platform"
														);
														window.scrollTo({ behavior: "auto", top: 0 });
													}}
													sx={{
														pr: 0,
														width: "12.5rem",
														backgroundColor: "#fff",
														borderLeft: `2px solid ${
															pathname.startsWith("/platform")
																? color.themeColor
																: "rgba(25, 50, 65, 0.12)"
														}`,
														ml: "3rem",
														"&:hover": {
															backgroundColor: "#fff",
															// opacity: 0.6
														},
													}}
												>
													<Typography
														sx={{
															fontSize: "1rem",
															fontWeight: 500,
															mt: "0.5rem",
															fontFamily: "Helvetica Neue",
															color:
																subheadingText === "My platforms" ||
																pathname.startsWith("/platform")
																	? color.themeColor
																	: color.themeDarkGray,
														}}
													>
														My Platforms
													</Typography>
												</ListItemButton>
												{canView("Connections") && (
													<ListItemButton
														onMouseOver={() => setSubheadingText("Connection")}
														onMouseLeave={() => setSubheadingText(null)}
														onClick={() => {
															navigate(
																selectedAccount
																	? "/connections?accountId=" +
																			selectedAccount.id
																	: "/connections"
															);
															window.scrollTo({ behavior: "auto", top: 0 });
														}}
														sx={{
															pr: 0,
															width: "12.5rem",
															backgroundColor: "#fff",
															ml: "3rem",
															borderLeft: `2px solid ${
																pathname === "/connections"
																	? color.themeColor
																	: "rgba(25, 50, 65, 0.12)"
															}`,
															"&:hover": {
																backgroundColor: "#fff",
																// opacity: 0.6
															},
														}}
													>
														<Typography
															sx={{
																fontSize: "1rem",
																fontWeight: 500,
																mt: "0.5rem",
																fontFamily: "Helvetica Neue",
																color:
																	subheadingText === "Connection" ||
																	pathname === "/connections"
																		? color.themeColor
																		: color.themeDarkGray,
															}}
														>
															My Connections
														</Typography>
													</ListItemButton>
												)}
												{canView("Create Connection") && (
													<ListItemButton
														onMouseOver={() =>
															setSubheadingText("Create Connection")
														}
														onMouseLeave={() => setSubheadingText(null)}
														onClick={() => {
															navigate(
																selectedAccount
																	? "/create-connections?accountId=" +
																			selectedAccount.id
																	: "/create-connections"
															);
															window.scrollTo({ behavior: "auto", top: 0 });
														}}
														sx={{
															pr: 0,
															width: "12.5rem",
															backgroundColor: "#fff",
															ml: "3rem",
															borderLeft: `2px solid ${
																pathname === "/create-connections"
																	? color.themeColor
																	: "rgba(25, 50, 65, 0.12)"
															}`,
															"&:hover": {
																backgroundColor: "#fff",
																// opacity: 0.6
															},
														}}
													>
														<Typography
															sx={{
																fontSize: "1rem",
																fontWeight: 500,
																mt: "0.5rem",
																fontFamily: "Helvetica Neue",
																color:
																	subheadingText === "Create Connection" ||
																	pathname === "/create-connections"
																		? color.themeColor
																		: color.themeDarkGray,
															}}
														>
															Create Connection
														</Typography>
													</ListItemButton>
												)}
											</Collapse>
										)}
										{item.text === "Settings" && (
											<Collapse in={settingsOpen} timeout="auto" unmountOnExit>
												<ListItemButton
													onMouseOver={() => setSubheadingText("My Profile")}
													onMouseLeave={() => setSubheadingText(null)}
													onClick={() => {
														navigate(
															selectedAccount
																? "/myprofile?accountId=" + selectedAccount.id
																: "/myprofile"
														);
														window.scrollTo({ behavior: "auto", top: 0 });
														// setsettingsOpen(true)
													}}
													sx={{
														pr: 0,
														width: "12.5rem",
														display: selectedAccount ? "none" : "",
														ml: "3rem",
														borderLeft: `2px solid ${
															pathname === "/myprofile"
																? color.themeColor
																: "rgba(25, 50, 65, 0.12)"
														}`,
														"&:hover": {
															backgroundColor: "#fff",
															// opacity: 0.6
														},
													}}
												>
													<Typography
														sx={{
															fontSize: "1rem",
															fontWeight: 500,
															mt: "0.5rem",
															fontFamily: "Helvetica Neue",
															display: selectedAccount ? "none" : "",
															color:
																subheadingText === " My Profile" ||
																pathname === "/myprofile"
																	? color.themeColor
																	: color.themeDarkGray,
														}}
													>
														My Profile
													</Typography>
												</ListItemButton>
												{canView("Profile") && (
													<ListItemButton
														onMouseOver={() =>
															setSubheadingText("Profile Details")
														}
														onMouseLeave={() => setSubheadingText(null)}
														onClick={() => {
															navigate(
																selectedAccount
																	? "/profileDetails?accountId=" +
																			selectedAccount.id
																	: "/profileDetails"
															);
															window.scrollTo({ behavior: "auto", top: 0 });
															// setsettingsOpen(true)
														}}
														sx={{
															pr: 0,
															width: "12.5rem",
															ml: "3rem",
															borderLeft: `2px solid ${
																pathname === "/profileDetails"
																	? color.themeColor
																	: "rgba(25, 50, 65, 0.12)"
															}`,
															"&:hover": {
																backgroundColor: "#fff",
																// opacity: 0.6
															},
														}}
													>
														<Typography
															sx={{
																fontSize: "1rem",
																fontWeight: 500,
																mt: "0.5rem",
																fontFamily: "Helvetica Neue",
																color:
																	subheadingText === "Profile Details" ||
																	pathname === "/profileDetails"
																		? color.themeColor
																		: color.themeDarkGray,
															}}
														>
															Account Profile
														</Typography>
													</ListItemButton>
												)}

												{canView("Users") && (
													<ListItemButton
														onMouseOver={() => setSubheadingText("Users")}
														onMouseLeave={() => setSubheadingText(null)}
														onClick={() => {
															navigate(
																selectedAccount
																	? "/users?accountId=" + selectedAccount.id
																	: "/users"
															);
															window.scrollTo({ behavior: "auto", top: 0 });
															// setsettingsOpen(true)
														}}
														sx={{
															pr: 0,
															width: "12.5rem",
															// mr: 4,
															ml: "3rem",
															borderLeft: `2px solid ${
																pathname.startsWith("/users")
																	? color.themeColor
																	: "rgba(25, 50, 65, 0.12)"
															}`,
															"&:hover": {
																backgroundColor: "#fff",
																// opacity: 0.6
															},
														}}
													>
														<Typography
															sx={{
																fontSize: "1rem",
																fontWeight: 500,
																mt: "0.5rem",
																fontFamily: "Helvetica Neue",
																color:
																	subheadingText === "Users" ||
																	pathname.startsWith("/users")
																		? color.themeColor
																		: color.themeDarkGray,
															}}
														>
															Users
														</Typography>
													</ListItemButton>
												)}
												{canView("Roles") && (
													<ListItemButton
														onMouseOver={() => setSubheadingText("Roles")}
														onMouseLeave={() => setSubheadingText(null)}
														onClick={() => {
															navigate(
																selectedAccount
																	? "/roles?accountId=" + selectedAccount.id
																	: "/roles"
															);
															window.scrollTo({ behavior: "auto", top: 0 });
															// setsettingsOpen(true)
														}}
														sx={{
															pr: 0,
															width: "12.5rem",
															ml: "3rem",
															// mr: 4,
															borderLeft: `2px solid ${
																pathname.startsWith("/roles")
																	? color.themeColor
																	: "rgba(25, 50, 65, 0.12)"
															}`,
															"&:hover": {
																backgroundColor: "#fff",
																// opacity: 0.6
															},
														}}
													>
														<Typography
															sx={{
																fontSize: "1rem",
																fontWeight: 500,
																mt: "0.5rem",
																fontFamily: "Helvetica Neue",
																color:
																	subheadingText === "Roles" ||
																	pathname.startsWith("/roles")
																		? color.themeColor
																		: color.themeDarkGray,
															}}
														>
															Roles
														</Typography>
													</ListItemButton>
												)}

												{accountbillDetailsData?.response?.billable === true &&
													!isSnowFlake() && (
														<ListItemButton
															onMouseOver={() =>
																setSubheadingText("Contract & Invoice")
															}
															onMouseLeave={() => setSubheadingText(null)}
															onClick={() => {
																navigate(
																	selectedAccount
																		? "/invoice?accountId=" + selectedAccount.id
																		: "/invoice"
																);
																window.scrollTo({ behavior: "auto", top: 0 });
																// setsettingsOpen(true)
															}}
															sx={{
																pr: 0,
																width: "12.5rem",
																ml: "3rem",
																borderLeft: `2px solid ${
																	pathname === "/invoice"
																		? color.themeColor
																		: "rgba(25, 50, 65, 0.12)"
																}`,
																"&:hover": {
																	backgroundColor: "#fff",
																	// opacity: 0.6
																},
															}}
														>
															<Typography
																sx={{
																	fontSize: "1rem",
																	fontWeight: 500,
																	mt: "0.5rem",
																	fontFamily: "Helvetica Neue",
																	color:
																		subheadingText === "Contract & Invoice" ||
																		pathname === "/invoice"
																			? color.themeColor
																			: color.themeDarkGray,
																}}
															>
																Invoices
															</Typography>
														</ListItemButton>
													)}

												{accountbillDetailsData?.response?.billable === true &&
													!isSnowFlake() && (
														<ListItemButton
															onMouseOver={() => setSubheadingText("Pricing")}
															onMouseLeave={() => setSubheadingText(null)}
															onClick={() => {
																navigate(
																	selectedAccount
																		? "/pricing?accountId=" + selectedAccount.id
																		: "/pricing"
																);
																window.scrollTo({ behavior: "auto", top: 0 });
																// setsettingsOpen(true)
															}}
															sx={{
																pr: 0,
																width: "12.5rem",
																ml: "3rem",
																// mr: 4,
																display: "",
																borderLeft: `2px solid ${
																	pathname === "/pricing"
																		? color.themeColor
																		: "rgba(25, 50, 65, 0.12)"
																}`,
																"&:hover": {
																	backgroundColor: "#fff",
																	// opacity: 0.6
																},
															}}
														>
															<Typography
																sx={{
																	fontSize: "1rem",
																	fontWeight: 500,
																	mt: "0.5rem",
																	fontFamily: "Helvetica Neue",
																	color:
																		subheadingText === "pricing" ||
																		pathname === "/pricing"
																			? color.themeColor
																			: color.themeDarkGray,
																}}
															>
																Pricing
															</Typography>
														</ListItemButton>
													)}
											</Collapse>
										)}
									</ListItem>
								))}
							</List>
							{carouselSteps.length > 0 && (
								<Box
									sx={{
										border: "1px solid #FFE6E6",
										ml: "1rem",
										visibility: "hidden",
										p: "0.5rem",
										height: "3rem",
										width: 232,
										backgroundColor: "#FFF7F7",
										display: "flex",
										borderRadius: 2,
										flexDirection: "row",
										alignItems: "center",
										justifyContent: "center",
									}}
								>
									<img
										alt=""
										src={carouselSteps[0].imgPath}
										style={{ maxWidth: "100%", maxHeight: "100%" }}
									></img>
								</Box>
							)}
							<Box
								sx={{
									height: selectedAccount ? 193 : 130,
									display: "flex",
									flexDirection: "column",
									justifyContent: "center",
								}}
							>
								{account && (
									<Box
										sx={{
											display: "flex",
											flexDirection: "row",
											cursor: "pointer",
											mb: "1rem",
											justifyContent: "center",
										}}
										onClick={() => {
											dispatch(setSelectedAccount(null));
											window.location.replace("/admin/dashboard");
										}}
									>
										<Box
											sx={{
												border: "1px solid rgba(56, 67, 172, 0.07)",
												p: "0.5rem",
												height: 51,
												width: 206,
												backgroundColor: color.sidebarItemSelected,
												display: "flex",
												borderRadius: 2,
												flexDirection: "row",
												alignItems: "center",
												justifyContent: "center",
											}}
										>
											<Typography
												sx={{
													fontSize: "1rem",
													fontWeight: 500,
													// ml: "1rem",
													color: color.themeColor,
													fontFamily: "Helvetica Neue",
												}}
											>
												Switch to Admin Portal
											</Typography>
										</Box>
									</Box>
								)}
								<Box
									sx={{
										display: "flex",
										flexDirection: "row",
										cursor: "pointer",
										mb: 1,
										justifyContent: "center",
									}}
									onClick={() => {
										logOutUser();
									}}
								>
									<Box
										sx={{
											padding: "15px 20px",
											height: 51,
											width: 206,
											display: "flex",
											borderRadius: 7,
											flexDirection: "row",
											alignItems: "center",
											gap: "10px",
										}}
									>
										<img
											alt="logout"
											src={logOutimg}
											width={21}
											height={21}
										></img>
										<Typography
											sx={{
												fontSize: "1.125rem",
												fontWeight: 400,
												color: "#FF5A5A",
												fontFamily: "Helvetica Neue",
												margin: "0px",
											}}
										>
											Log out
										</Typography>
									</Box>
								</Box>
								<Box
									sx={{
										display: "flex",
										borderRadius: 2,
										flexDirection: "row",
										mb: "5px",
										ml: "37px",
									}}
								>
									<Typography
										sx={{
											fontSize: "0.875rem",
											fontWeight: "400",
											color: color.themeDarkGray,
											fontFamily: "Helvetica Neue",
											textDecoration: "underline",
											cursor: "pointer",
											margin: "0",
										}}
										onClick={() => {
											window.open("/terms");
										}}
									>
										Terms of Service
									</Typography>
								</Box>
								<Box
									sx={{
										display: "flex",
										borderRadius: 2,
										flexDirection: "row",
										ml: "37px",
									}}
								>
									<Typography
										sx={{
											fontSize: "0.875rem",
											fontWeight: "400",
											color: color.themeDarkGray,
											fontFamily: "Helvetica Neue",
											textDecoration: "underline",
											cursor: "pointer",
											margin: "0",
										}}
										onClick={() => {
											window.open("/data-processing-addendum");
										}}
									>
										Data Processing Addendum
									</Typography>
								</Box>
							</Box>
						</Box>
					</Box>
					{/* COMPONENT Render  */}
					<Box
						// component="main"
						sx={{
							width: `calc(100% - ${drawerWidth}px)`,
							// margin: "auto",
							bgcolor: color.layoutBgColor,
							height: "100%",
							// overflowY: "scroll",
							display: "flex",
							flexDirection: "column",
						}}
					>
						<Box
							sx={{
								// mx: "auto",
								// mt: !userDetails?.accountDetails?.description ? "4.8rem" : "0.5rem",
								flex: "1 1 0",
								display: "flex",
								flexDirection: "column",
								paddingBottom: "8px",
								paddingTop: "8px",
								overflowY: "scroll",
							}}
						>
							<ErrorBoundary FallbackComponent={ErrorHandler}>
								<HeaderWrapper>
									<SectionHeading>
										{account ? selectedAccount?.name : user?.accountName}
									</SectionHeading>
									<Box
										sx={{
											display: "flex",
											flexDirection: "row",
											gap: "12px",
											alignItems: "center",
											cursor: selectedAccount ? "default" : "pointer",
										}}
										onClick={() => {
											if (selectedAccount) return;
											// if (account === null || account === undefined) {
											handleOpenSettings();
											navigate("/myprofile");
											window.scrollTo({ behavior: "auto", top: 0 });
											// }
										}}
									>
										{userDetails && (
											<Typography
												style={{
													fontSize: "1rem",
													fontWeight: 500,
													fontFamily: "Helvetica Neue",
													color: "#000",
												}}
											>
												{userDetails?.firstName} {userDetails?.lastName}
											</Typography>
										)}
										<IconButton
											size="large"
											aria-label="account of current user"
											aria-controls="menu-appbar"
											aria-haspopup="true"
											color="inherit"
											style={{
												cursor: selectedAccount ? "default" : "pointer",
												padding: 0,
											}}
										>
											<AccountCircleOutlinedIcon
												sx={{
													padding: 0,
													color: "#000",
													width: "2rem",
													height: "2rem",
												}}
											/>
										</IconButton>
									</Box>
								</HeaderWrapper>
								<Outlet />
							</ErrorBoundary>
						</Box>
					</Box>
				</>
			)}
		</Box>
	);
};

export default AppLayout;

// paddingVertical in blue bar under 1080px