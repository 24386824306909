import React from "react";
import {
	AccountsCard,
	AverageMonthlyCard,
	AveragedailyCard,
	AveragedailyCardHeading,
	CardHeading,
	ConnectionCard,
	Container,
	CurrentMonthCard,
	Heading,
	NoOfValueOfCard,
	TotalVolumeCard,
	VolumnContainer,
	VolumnHeading,
	Wrapper,
} from "./Dashboard.styles";
import { Box, Typography } from "@mui/material";

import analytics5Img from "../../assets/AnalyticsTab5.svg";
import analytics4Img from "../../assets/AnalyticsTab4.svg";
import analytics3Img from "../../assets/AnalyticsTab3.svg";
import analytics2Img from "../../assets/AnalyticsTab2.svg";
import activeConnections from "../../assets/activeConnections.svg";
import dataTransfers from "../../assets/dataTransfers.svg";
import { formatRows } from "../../utils/utils";

const DashboardCard = ({
	analyticsData,
	timeLineTxt,
	upArrowImg,
	downArrowImg,
	hideAvg,
}) => {
	return (
		<div>
			<Container>
				{/* connection */}
				<ConnectionCard>
					<Box height="40px" width="40px">
						<img
							src={analytics3Img}
							style={{ height: "40px", width: "40px" }}
						/>
					</Box>
					<Box
						sx={{
							display: "flex",
							flexDirection: "column",
							justifyContent: "space-between",
							height: "100%",
						}}
					>
						<CardHeading>Connections</CardHeading>
						<Box>
							<NoOfValueOfCard>
								{formatRows(analyticsData?.connections)}
							</NoOfValueOfCard>
						</Box>
					</Box>
				</ConnectionCard>

				{/* active connection */}
				<ConnectionCard>
					<Box height="40px" width="40px">
						<img
							src={activeConnections}
							style={{ height: "40px", width: "40px" }}
						/>
					</Box>
					<Box
						sx={{
							display: "flex",
							flexDirection: "column",
							justifyContent: "space-between",
							height: "100%",
						}}
					>
						<CardHeading>Active Connections</CardHeading>
						<Box>
							<NoOfValueOfCard>
								{formatRows(analyticsData?.activeConnections)}
							</NoOfValueOfCard>
						</Box>
					</Box>
				</ConnectionCard>

				{/* Data Transfers */}
				<ConnectionCard>
					<Box height="40px" width="40px">
						<img
							src={dataTransfers}
							style={{ height: "40px", width: "40px" }}
						/>
					</Box>
					<Box
						sx={{
							display: "flex",
							flexDirection: "column",
							justifyContent: "space-between",
							height: "100%",
						}}
					>
						<CardHeading>Data Transfers</CardHeading>
						<Box>
							<NoOfValueOfCard>
								{formatRows(analyticsData?.dataSyncs)}
							</NoOfValueOfCard>
						</Box>
					</Box>
				</ConnectionCard>

				{/* Average monthly volume */}
				<AverageMonthlyCard>
					<Box sx={{ height: "40px", width: "40px" }}>
						<img
							src={analytics4Img}
							style={{ height: "40px", width: "40px" }}
						/>
					</Box>
					<Box
						sx={{
							display: "flex",
							flexDirection: "column",
							justifyContent: "space-between",
							height: "100%",
						}}
					>
						<CardHeading>Average Monthly Volume</CardHeading>
						{hideAvg ? (
							<Box display="flex" flexDirection="row" alignItems="center">
								<NoOfValueOfCard>NA</NoOfValueOfCard>
							</Box>
						) : (
							<Box
								sx={{
									display: "flex",
									flexDirection: "row",
									alignItems: "center",
									gap: "10px",
								}}
							>
								<NoOfValueOfCard>
									{formatRows(Math.round(analyticsData?.averageMonthlyVolume))}
								</NoOfValueOfCard>
							</Box>
						)}
					</Box>
				</AverageMonthlyCard>

				{/* Current month volume
				<CurrentMonthCard>
					<Box height="40px" width="40px">
						<img
							src={analytics4Img}
							style={{ height: "40px", width: "40px" }}
						/>
					</Box>
					<Box display="flex" flexDirection="column" ml="1rem">
						<Box display="flex" alignItems="center">
							<NoOfValueOfCard>
								{new Intl.NumberFormat("en-US").format(
									Math.round(analyticsData?.currentMonthlyVolume)
								)}
							</NoOfValueOfCard>
						</Box>

						<CardHeading>Current month volume </CardHeading>
					</Box>
				</CurrentMonthCard> */}

				{/* Total volume */}
				<TotalVolumeCard>
					<Box height="40px" width="40px">
						<img
							src={analytics5Img}
							style={{ height: "40px", width: "40px" }}
						/>
					</Box>
					<Box
						sx={{
							display: "flex",
							flexDirection: "column",
							justifyContent: "space-between",
							height: "100%",
						}}
					>
						<CardHeading>Total Volume (rows)</CardHeading>
						<Box>
							<NoOfValueOfCard>
								{formatRows(analyticsData?.totalVolume)}
							</NoOfValueOfCard>
						</Box>
					</Box>
				</TotalVolumeCard>
			</Container>
		</div>
	);
};

export default DashboardCard;
