import { Box, TableCell, Typography, TableHead } from "@mui/material";
import { styled as MuiStyled } from "@mui/material/styles";
import styled from "styled-components";
import { color } from "../../utils/Colors";

export const StyledTableCell = styled(TableCell)({
	borderBottom: "none !important",
});

export const StickyTableHead = MuiStyled(TableHead)({
	position: "sticky",
	top: 0,
	background: "#fff",
	zIndex: 1,
});

export const TableHeaderTypography = styled(Typography)((attr) => ({
	fontWeight: "500 !important",
	fontSize: "1rem !important",
	color: `${
		attr.nestedHeader ? "#454545" : color.tableHeaderTextColor
	} !important`,
	fontFamily: "Helvetica Neue !important",
}));

export const TableCellTypography = styled(Typography)((attr) => ({
	fontWeight: "500 !important",
	fontSize: "0.875rem !important",
	color: `${
		attr.color || attr.nested ? "#66696A" : color.tableCellTextColor
	} !important`,
	fontFamily: "Helvetica Neue !important",
}));

export const ConnectionIdTypography = styled(Typography)({
	fontWeight: "500 !important",
	fontSize: "0.875rem !important",
	color: `${color.tableCellSecondaryTextColor} !important`,
	fontFamily: "Helvetica Neue !important",
});

export const TableWrapper = styled(Box)({
	display: "flex",
	flexDirection: "column",
	margin: "20px",
	borderRadius: "12px 0px 12px 12px",
	height: "100%",
	overflow: "hidden",
	boxShadow: "0px 0px 13px 0px #3A3A3A0F",
	border: "1px solid #EEF3F8",
});

export const TableHeaderWrapper = styled(Box)({
	display: "flex",
	flexDirection: "row",
	justifyContent: "flex-end",
	alignItems: "center",
});

// Styled components for the Date Range Picker
export const DatePickerWrapper = styled.div`
  display: flex;
  align-items: center;
  gap: 10px;
  height: 40px;
  padding: 10px;
  border: 1px solid #EAEDF6;
  border-radius: 10px;
  background-color: #ffffff;
`;

export const DateRangeText = styled.span`
  font-size: 16px;
  font-weight: 400;
  color: #25262D;
`;
