import React, { useEffect, useState, useMemo } from "react";
import {
	Box,
	OutlinedInput,
	Paper,
	Table,
	TableBody,
	TableCell,
	TableContainer,
	TableHead,
	TablePagination,
	TableRow,
	Tooltip,
} from "@mui/material";
import AddIcon from "@mui/icons-material/Add";
import { useNavigate } from "react-router-dom";
import { useSelector } from "react-redux";
import moment from "moment";

import searchImg from "../../assets/search.svg";
import noConfigImg from "../../assets/Illustrations.svg";
import trash from "../../assets/trash-2.svg";
import editImg from "../../assets/edit.svg";
import searchIcon from "../../assets/searchIcon.svg";
import { color } from "../../utils/Colors";
import { useGetSUserMutation } from "../../services/query";
import { madTechState } from "../../features/madTechSlice";
import CommonButton from "../../components/CommonButton/CommonButton";
import SBreadCrumb from "../../components/Setting/SBreadCrumb";
import {
	BodyWrapper,
	DeleteImg,
	EditImg,
	HeadingWrapper,
	NoUserAvailable,
	SUserTableCell,
	SUserTableHeader,
	TableBodyRowStyle,
	TableUserId,
	TableUserName,
} from "./SUers.styles";
import Loader from "../../components/Setting/Loader";
import { tableColumn } from "./constants";
import Delete from "./Modal/Delete";
import {
	SearchBarWrapper,
	SearchInput,
} from "../../components/CommonSearch/CommonSearch.styles";

const SUsers = () => {
	const { selectedAccount, user } = useSelector(madTechState);

	const navigate = useNavigate();

	const [users, setUsers] = useState(null);
	const [page, setPage] = React.useState(0);
	const [rowsPerPage, setRowsPerPage] = React.useState(50);
	const [searchValue, setSearchValue] = useState("");
	const [rows, setRows] = useState(null);
	const [selectedItem, setSelectedItem] = useState(null);
	const [isDeleteOpen, setIsDeleteOpen] = useState(false);

	const [
		getSUser,
		{ data: usersData, error: usersError, isLoading: usersLoading },
	] = useGetSUserMutation();

	useEffect(() => {
		getSUser();
	}, []);

	useEffect(() => {
		if (
			usersData !== undefined &&
			usersData.statusCode === 200 &&
			usersData.response
		) {
			setUsers(usersData.response);
			setRows(usersData.response);
		}

		if (usersError !== undefined) {
			setUsers([]);
			setRows([]);
			console.log("usersError", usersError);
		}
	}, [usersData, usersError]);

	const requestSearch = (searchedVal) => {
		const filteredRows = users?.filter((row) => {
			return (
				row?.userId?.toString().includes(searchedVal) ||
				row?.name?.toLowerCase().includes(searchedVal.toLowerCase())
			);
		});
		setRows(filteredRows);
		setPage(0);
		setSearchValue(searchedVal);
	};

	const handleChangePage = (event, newPage) => {
		setPage(newPage);
	};

	const handleChangeRowsPerPage = (event) => {
		setRowsPerPage(parseInt(event.target.value, 10));
		setPage(0);
	};

	const findPermissionByName = (name) => {
		const foundPermission = user?.permissions.find((p) => p.name === name);
		return foundPermission ? foundPermission.permissions : null;
	};

	const usersPermissions = useMemo(
		() => findPermissionByName("Users"),
		[user?.permissions]
	);

	const handleAddUser = () => {
		navigate(
			selectedAccount
				? "/users/add?accountId=" + selectedAccount.id
				: "/users/add"
		);
	};

	const handleUserName = (row) => {
		setSelectedItem(row);
		navigate(
			selectedAccount
				? "/users/" +
						row.id +
						"?accountId=" +
						selectedAccount.id +
						"&userId=" +
						row.userId
				: "/users/" + row.id + "?userId=" + row.userId
		);
	};

	const handleNavigateRole = () => {
		navigate(
			selectedAccount ? "/roles?accountId=" + selectedAccount.id : "/roles"
		);
	};

	const handleEdit = (row) => {
		setSelectedItem(row);
		navigate(
			selectedAccount
				? "/users/" +
						row.id +
						"?accountId=" +
						selectedAccount.id +
						"&userId=" +
						row.userId
				: "/users/" + row.id + "?userId=" + row.userId
		);
	};

	const handleDeleteModalOpen = (row) => {
		setSelectedItem(row);
		setIsDeleteOpen(true);
	};

	return (
		<>
			<Box sx={{ px: 3 }}>
				<HeadingWrapper>
					<Box sx={{ display: "flex", gap: "0.5rem" }}>
						<SBreadCrumb
							mainPathName="Settings"
							mainPath="myprofile"
							pathName="Users"
						/>
					</Box>
					<Box sx={{ display: "flex", gap: "0.5rem" }}>
						{usersPermissions?.create && (
							<CommonButton
								width={140}
								height={40}
								textStyle={{
									fontSize: "1rem",
									fontWeight: 400,
									letterSpacing: "normal",
									color: color.whiteColor,
								}}
								customStyle={{
									marginLeft: "auto",
									marginRight: "10px",
								}}
								borderRadius="10px"
								color={color.buttonColor}
								onClick={handleAddUser}
								title={"Add User"}
								startIcon={
									<AddIcon
										startIconStyle={{ height: 24, width: 24 }}
										sx={{ marginRight: "-8px", marginLeft: "4px" }}
									/>
								}
							/>
						)}
						<SearchBarWrapper>
							<img src={searchIcon} width={20} height={20} alt="search" />
							<SearchInput
								placeholder="Search"
								value={searchValue}
								onChange={(e) => requestSearch(e.target.value)}
							/>
						</SearchBarWrapper>
					</Box>
				</HeadingWrapper>
				<BodyWrapper>
					{usersLoading || rows === null ? (
						<Loader />
					) : (
						<Paper
							sx={{
								width: "100%",
								height: "100%",
								overflow: "hidden",
								borderRadius: "12px 0px 12px 12px",
							}}
						>
							<TableContainer
								sx={{
									height: "100%",
									maxHeight: "calc(100vh - 190px)",
									overflowY: "auto",
								}}
							>
								<Table stickyHeader>
									<TableHead
										sx={{
											"& .MuiTableCell-root:first-of-type": {
												borderTopLeftRadius: "12px",
												borderBottomLeftRadius: "12px",
											},
											"& .MuiTableCell-root:last-child": {
												borderTopRightRadius: "12px",
												borderBottomRightRadius: "12px",
											},
										}}
									>
										<TableRow>
											<TablePagination
												rowsPerPageOptions={[5, 10, 25, 50]}
												count={rows.length}
												rowsPerPage={rowsPerPage}
												page={page}
												SelectProps={{
													inputProps: {
														"aria-label": "accounts per page",
													},
													native: true,
												}}
												onPageChange={handleChangePage}
												onRowsPerPageChange={handleChangeRowsPerPage}
											/>
										</TableRow>
										<TableRow>
											{tableColumn?.map((cloumnName, i) => (
												<SUserTableHeader
													sx={{
														textAlign:
															cloumnName === "Created Date" ||
															cloumnName === "Last Login"
																? "right"
																: "left",
													}}
													key={i}
												>
													{cloumnName}
												</SUserTableHeader>
											))}
											<SUserTableHeader sx={{ textAlign: "left" }}>
												{(usersPermissions?.modify ||
													usersPermissions?.delete) && <>Action</>}
											</SUserTableHeader>
										</TableRow>
									</TableHead>
									<TableBody>
										{(rowsPerPage > 0
											? rows.slice(
													page * rowsPerPage,
													page * rowsPerPage + rowsPerPage
											  )
											: rows
										).map((row) => (
											<TableBodyRowStyle key={row.userId}>
												<TableCell>
													<TableUserName onClick={() => handleUserName(row)}>
														{row.name}
													</TableUserName>
													<TableUserId>{row.userId}</TableUserId>
												</TableCell>
												<TableCell>
													<TableUserName onClick={handleNavigateRole}>
														{row.roleName ? row.roleName : "Admin"}
													</TableUserName>
													<TableUserId>{row.roleId}</TableUserId>
												</TableCell>
												<SUserTableCell>{row.email}</SUserTableCell>
												<SUserTableCell sx={{ maxWidth: "200px" }}>
													<Tooltip title={row.accounts} placeholder="top">
														<div
															style={{
																font: "inherit",
																whiteSpace: "nowrap",
																overflow: "hidden",
																textOverflow: "ellipsis",
															}}
														>
															{row.accounts ? row.accounts : "All"}
														</div>
													</Tooltip>
												</SUserTableCell>
												<SUserTableCell sx={{ textAlign: "right" }}>
													{moment(row.createdTime * 1000).format("MM-DD-YYYY")}
												</SUserTableCell>
												<SUserTableCell sx={{ textAlign: "right" }}>
													{moment(row.lastLoginTime * 1000).format(
														"MM-DD-YYYY"
													)}
												</SUserTableCell>
												{(usersPermissions?.modify ||
													usersPermissions?.delete) && (
													<SUserTableCell>
														<Box display="flex">
															{usersPermissions?.modify && (
																<Tooltip title="Edit" placement="top">
																	<EditImg onClick={() => handleEdit(row)}>
																		<img
																			src={editImg}
																			style={{
																				maxWidth: "100%",
																				maxHeight: "100%",
																			}}
																		/>
																	</EditImg>
																</Tooltip>
															)}
															{usersPermissions?.delete && (
																<Tooltip title="Delete" placement="top">
																	<DeleteImg
																		onClick={() => handleDeleteModalOpen(row)}
																	>
																		<img
																			src={trash}
																			style={{
																				maxWidth: "100%",
																				maxHeight: "100%",
																			}}
																		/>
																	</DeleteImg>
																</Tooltip>
															)}
														</Box>
													</SUserTableCell>
												)}
											</TableBodyRowStyle>
										))}
										{rows.length == 0 && (
											<TableRow>
												<TableCell sx={{ textAlign: "center" }} colSpan={8}>
													<Box
														display="flex"
														justifyContent="center"
														alignItems="center"
														paddingBottom={4}
													>
														<Box
															sx={{
																display: "flex",
																flexDirection: "column",
																justifyContent: "center",
																alignItems: "center",
																marginRight: 4,
															}}
														>
															<Box
																sx={{ width: "11.875rem", height: "11.875rem" }}
															>
																<img
																	src={noConfigImg}
																	style={{
																		maxWidth: "100%",
																		maxHeight: "100%",
																	}}
																/>
															</Box>
															<NoUserAvailable>
																No users available!
															</NoUserAvailable>
														</Box>
													</Box>
												</TableCell>
											</TableRow>
										)}
									</TableBody>
								</Table>
							</TableContainer>
						</Paper>
					)}
				</BodyWrapper>
			</Box>

			{/* DELETE ACCOUNT MODAL */}
			<Delete
				getSUser={getSUser}
				isDeleteOpen={isDeleteOpen}
				selectedItem={selectedItem}
				setIsDeleteOpen={setIsDeleteOpen}
				setSelectedItem={setSelectedItem}
			/>
		</>
	);
};

export default SUsers;
