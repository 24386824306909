import { Box, TableCell, Typography } from "@mui/material";
import styled from "styled-components";

import { color } from "../../utils/Colors";

export const BoxWrapper = styled(Box)({
	position: "absolute !important",
	top: "50% !important",
	left: "50% !important",
	transform: "translate(-50%, -50%) !important",
	borderRadius: "8px 8px 8px 8px !important",
	backgroundColor: "#F8F8FE !important",
	padding: "32px !important",
	display: "flex",
	flexDirection: "column",
	justifyContent: "center",
	alignItems: "center",
});

export const LoaderWrapper = styled(Box)({
	display: "flex",
	justifyContent: "center",
	alignItems: "center",
	width: "100%",
	marginBottom: "100px",
	marginTop: "50px",
	flex: 5.6,
});

export const Wrapper = styled(Box)({
	display: "flex",
	flexDirection: "row",
	backgroundColor: color.layoutBgColor,
	// marginTop: "8px",
});

export const SubWrapper = styled(Box)({
	width: "100%",
	marginRight: "1.5rem",
	marginLeft: "1.5rem",
	flex: 5.4,
});

export const HeadingWrapper = styled(Box)({
	position: "sticky",
	top: "-8px",
	paddingTop: "8px",
	background: "#FAFCFF",
	width: "100%",
	zIndex: 99,
	// paddingTop: "10px",
});

export const HeadingSubWrapper = styled(Box)({
	display: "flex",
	flexDirection: (props) => (props.matchesxss ? "row" : "column"),
	alignItems: "flex-start",
	justifyContent: "space-between",
	backgroundColor: "#FAFCFF",
	height: "40px",
});

export const Heading = styled(Typography)({
	fontSize: "1.25rem !important",
	fontWeight: "700 !important",
	fontFamily: "Helvetica Neue !important",
	letterSpacing: "0.2px !important",
});

export const NoDataWrapper = styled(Box)({
	display: "flex",
	flexDirection: "column",
	justifyContent: "center",
	alignItems: "center",
	height: "800px",
});

export const NoDataHeading = styled(Typography)({
	marginTop: "32px !important",
	color: "#999999 !important",
	textAlign: "center !important",
	fontSize: "1.125rem !important",
	fontWeight: "400 !important",
	fontFamily: "Helvetica Neue !important",
});

export const ColumnHeading = styled(Typography)(
	({ isSmallForTableHeader, isMyPaltformForTableHeader, colName }) => ({
		width:
			colName === "Platform"
				? "20% !important"
				: colName === "Data type"
				? "18% !important"
				: colName === "Configuration"
				? "17% !important"
				: colName === "Status"
				? "15% !important"
				: colName === "Action"
				? "7% !important"
				: "8% !important",
		position: "relative !important",
		paddingLeft: "0.33rem !important",
		display: "flex",
		alignItems: "center",
		fontFamily: "Helvetica Neue !important",
		fontWeight: "500 !important",
		fontSize: isSmallForTableHeader
			? isMyPaltformForTableHeader
				? "7.7px !important"
				: "11px !important"
			: "0.875rem !important",
		color: "#666666 !important",
		justifyContent:
			colName === "Platform"
				? "start !important"
				: colName === "Source/Destination" || colName === "#Connections"
				? "end !important"
				: (colName === "Status" || colName === "Action") && "center !important",

		textAlign:
			(colName === "Source/Destination" || colName === "#Connections") &&
			"center !important",
	})
);

export const PlatformText = styled("span")(
	({ isMyPaltformForTableHeader }) => ({
		color: color.tableCellTextColor,
		fontWeight: 500,
		fontFamily: "Helvetica Neue",
		textTransform: "capitalize",
		cursor: "default",
		fontSize: "0.875rem",
		whiteSpace: "nowrap",
		overflow: "hidden",
		textOverflow: "ellipsis",
	})
);

export const ConfigureText = styled(Typography)(
	({ isMyPaltformForTableHeader }) => ({
		fontFamily: "Helvetica Neue !important",
		fontWeight: "500 !important",
		fontSize: "0.875rem !important",
		// color: color.buttonColor,
		// color:item ? color.buttonColor : 'rgba(0, 0, 0, 0.38)' ,
	})
);

export const ConnectionsText = styled(Typography)(
	({ isMyPaltformForTableHeader }) => ({
		fontFamily: "Helvetica Neue !important",
		fontWeight: "500 !important",
		fontSize: "0.875rem !important",
		color: color.tableCellTextColor,
		whiteSpace: "nowrap !important",
	})
);

export const StatusText = styled(Typography)(
	({ isMyPaltformForTableHeader }) => ({
		fontFamily: "Helvetica Neue !important",
		fontWeight: "500 !important",
		fontSize: "0.875rem !important",
		color: color.tableCellTextColor,
	})
);

export const TableHeaderCell = styled(TableCell)(({ theme }) => {
	return {
		fontWeight: "500 !important",
		fontSize: "1rem !important",
		fontFamily: "Helvetica Neue !important",
		color: `${color.tableHeaderTextColor} !important`,
		whiteSpace: "nowrap !important",
	};
});
