import { Box, Typography } from "@mui/material";
import styled from "styled-components";

import { color } from "../../utils/Colors";

export const Wrapper = styled(Box)({
  padding: "48px 0px",
  display: "flex",
  flexDirection: "column",
  width: "100%",
  backgroundColor: "#193241",
  justifyContent: "center",
  alignItems: "center",
});

export const Heading = styled(Typography)({
  color: "#fff",
  fontWeight: "400 !important",
  fontFamily: "Phosphate !important",
  letterSpacing: "0.2px !important",
});

export const SubHeading = styled(Typography)({
  width: "60% !important",
  textAlign: "left",
  marginTop: "32px !important",
  color: "#fff",
  fontWeight: "500 !important",
  fontFamily: "Helvetica Neue !important",
  letterSpacing: "0.2px !important",
});

export const QuestionWrapper = styled(Box)({
  padding: "80px 0px",
  display: "flex",
  flexDirection: "column",
  alignItems: "center",
  backgroundColor: "#eeeeee",
});

export const QuestionListContainer = styled(Box)({
  width: "91%",
  maxWidth: 1350,
  marginBottom: "1rem",
  backgroundColor: "#fff",
  padding: "24px",
  borderRadius: "8px",
  border: "1px solid rgba(54, 70, 172, 0.24)",
});

export const QuestionTitle = styled(Typography)({
  fontSize: "1.25rem !important",
  fontWeight: "700 !important",
  fontFamily: "Helvetica Neue !important",
});

export const QuestionDescription = styled(Typography)({
  fontSize: "1rem !important",
  fontWeight: 400,
  marginTop: "0.5rem !important",
  fontFamily: "Inter !important",
});

export const QuestionInnerTitle = styled(Typography)({
  fontSize: "1rem !important",
  fontWeight: "700 !important",
  marginTop: "0.5rem !important",
  fontFamily: "Helvetica Neue !important",
});

export const BtnClick = styled("span")({
  textDecorationLine: "underline !important",
  cursor: "pointer",
  fontSize: "1rem",
  fontWeight: 400,
  fontFamily: "Inter",
});

export const ListMain = styled("ul")({
    marginTop: "10px", 
    listStyleType: "none"
})
