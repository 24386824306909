import { createSlice } from "@reduxjs/toolkit";

export const PANEL = Object.freeze({
	PLATFORM_USER: 0,
	MADCONNECT_USER: 1,
	ADMIN: 2,
	INVALID: 3,
});

// const initialState = {
//   user: {
//     "userId": "f1d9dcfdd9644dbf",
//     "accountId": "2b1443dd8eff9ddf",
//     "userType": "madconnect",
//     "firstName": "Harshad R",
//     "lastName": "Kardile",
//     "email": "harshad@devicemotion.in",
//     "companyName": "MadConnect",
//     "title": "Engineer",
//     "phoneNumber": "919876543216",
//     "stepPending": 0,
//     "accountDetails": {
//       "status": "READY",
//       "description": "Our technical team is working on your account setup. Check your MadConnect dashboard alerts for an update when your connections are activated.",
//       "firstLogin": false
//     },
//     "permissions": [
//       {
//         "permissions": {
//           "modify": true,
//           "view": true,
//           "create": false,
//           "delete": false
//         },
//         "name": "Account Profile"
//       },
//       {
//         "permissions": {
//           "modify": true,
//           "view": true,
//           "create": true,
//           "delete": true
//         },
//         "name": "Accounts"
//       },
//       {
//         "permissions": {
//           "modify": true,
//           "view": true,
//           "create": true,
//           "delete": true
//         },
//         "name": "Connections"
//       },
//       {
//         "permissions": {
//           "modify": true,
//           "view": true,
//           "create": true,
//           "delete": true
//         },
//         "name": "Contracts"
//       },
//       {
//         "permissions": {
//           "modify": false,
//           "view": true,
//           "create": false,
//           "delete": false
//         },
//         "name": "Dashboard"
//       },
//       {
//         "permissions": {
//           "modify": true,
//           "view": true,
//           "create": true,
//           "delete": true
//         },
//         "name": "Invoices"
//       },
//       {
//         "permissions": {
//           "modify": true,
//           "view": true,
//           "create": false,
//           "delete": false
//         },
//         "name": "My Profile"
//       },
//       {
//         "permissions": {
//           "modify": true,
//           "view": true,
//           "create": true,
//           "delete": false
//         },
//         "name": "Pricing"
//       },
//       {
//         "permissions": {
//           "modify": false,
//           "view": true,
//           "create": false,
//           "delete": false
//         },
//         "name": "Reports"
//       },
//       {
//         "permissions": {
//           "modify": true,
//           "view": true,
//           "create": true,
//           "delete": true
//         },
//         "name": "Roles"
//       },
//       {
//         "permissions": {
//           "modify": true,
//           "view": true,
//           "create": true,
//           "delete": true
//         },
//         "name": "Users"
//       }
//     ]
//   },
//   isLoggedin: true,
//   token: null,
//   userId: null,
//   platfomrs: [
//     {
//       "categories": [
//         5,
//         7
//       ],
//       "id": "17cb9338984dd58d",
//       "name": "Meta Ads",
//       "dataType": "Audiences",
//       "dataTypeId": 4,
//       "logo": "http://ec2-44-213-15-199.compute-1.amazonaws.com/platforms/Meta.png",
//       "overviewUrl": "http://ec2-44-213-15-199.compute-1.amazonaws.com/v1/document?path=meta-facebook-ads-audience",
//       "docUrl": "https://docs.madconnect.io/supported-connectors/facebook-ads",
//       "status": 1,
//       "supportedType": "BOTH",
//       "integrated": true
//     },
//     {
//       "categories": [],
//       "id": "17cb9338984dd58d",
//       "name": "Meta Ads",
//       "dataType": "conversions",
//       "dataTypeId": 42,
//       "logo": "http://ec2-44-213-15-199.compute-1.amazonaws.com/platforms/Meta.png",
//       "overviewUrl": "http://ec2-44-213-15-199.compute-1.amazonaws.com/v1/document?path=meta-facebook-ads-conversions-api",
//       "docUrl": "https://docs.madconnect.io/supported-connectors/facebook-ads",
//       "status": 1,
//       "supportedType": "BOTH",
//       "integrated": true
//     },
//     {
//       "categories": [],
//       "id": "17cb9338984dd58d",
//       "name": "Meta Ads",
//       "dataType": "Insights",
//       "dataTypeId": 43,
//       "logo": "http://ec2-44-213-15-199.compute-1.amazonaws.com/platforms/Meta.png",
//       "overviewUrl": "http://ec2-44-213-15-199.compute-1.amazonaws.com/v1/document?path=meta-facebook-ads-insights-api",
//       "docUrl": "https://docs.madconnect.io/supported-connectors/facebook-ads",
//       "status": 1,
//       "supportedType": "BOTH",
//       "integrated": true
//     },
//     {
//       "categories": [
//         5
//       ],
//       "id": "a7442ba436c87857",
//       "name": "Google Ads",
//       "dataType": "customer match",
//       "dataTypeId": 28,
//       "logo": "http://ec2-44-213-15-199.compute-1.amazonaws.com/platforms/GoogleAds.png",
//       "overviewUrl": "http://ec2-44-213-15-199.compute-1.amazonaws.com/v1/document?path=google-ads-customer-match",
//       "docUrl": "https://docs.madconnect.io/supported-connectors/google-ads",
//       "status": 1,
//       "supportedType": "BOTH",
//       "integrated": true
//     },
//     {
//       "categories": [],
//       "id": "a7442ba436c87857",
//       "name": "Google Ads",
//       "dataType": "offline conversions",
//       "dataTypeId": 44,
//       "logo": "http://ec2-44-213-15-199.compute-1.amazonaws.com/platforms/GoogleAds.png",
//       "overviewUrl": "http://ec2-44-213-15-199.compute-1.amazonaws.com/v1/document?path=google-ads-offline-conversions",
//       "docUrl": "https://docs.madconnect.io/supported-connectors/google-ads",
//       "status": 1,
//       "supportedType": "BOTH",
//       "integrated": true
//     },
//     {
//       "categories": [],
//       "id": "a7442ba436c87857",
//       "name": "Google Ads",
//       "dataType": "reporting",
//       "dataTypeId": 45,
//       "logo": "http://ec2-44-213-15-199.compute-1.amazonaws.com/platforms/GoogleAds.png",
//       "overviewUrl": "http://ec2-44-213-15-199.compute-1.amazonaws.com/v1/document?path=google-ads-api-reporting",
//       "docUrl": "https://docs.madconnect.io/supported-connectors/google-ads",
//       "status": 1,
//       "supportedType": "BOTH",
//       "integrated": true
//     },
//     {
//       "categories": [],
//       "id": "a7442ba436c87857",
//       "name": "Google Ads",
//       "dataType": "click conversions",
//       "dataTypeId": 46,
//       "logo": "http://ec2-44-213-15-199.compute-1.amazonaws.com/platforms/GoogleAds.png",
//       "overviewUrl": "http://ec2-44-213-15-199.compute-1.amazonaws.com/v1/document?path=google-ads-click-conversions",
//       "docUrl": "https://docs.madconnect.io/supported-connectors/google-ads",
//       "status": 1,
//       "supportedType": "BOTH",
//       "integrated": true
//     },
//     {
//       "categories": [
//         7
//       ],
//       "id": "69b7318a943118d3",
//       "name": "The Trade Desk",
//       "dataType": "1P audience activation",
//       "dataTypeId": 16,
//       "logo": "http://ec2-44-213-15-199.compute-1.amazonaws.com/platforms/TheTradeDesk.svg",
//       "overviewUrl": "http://ec2-44-213-15-199.compute-1.amazonaws.com/v1/document?path=the-trade-desk-audience-activation",
//       "docUrl": "https://docs.madconnect.io/supported-connectors/thetradedesk",
//       "status": 1,
//       "supportedType": "BOTH",
//       "integrated": true
//     },
//     {
//       "categories": [],
//       "id": "69b7318a943118d3",
//       "name": "The Trade Desk",
//       "dataType": "raw events data stream",
//       "dataTypeId": 51,
//       "logo": "http://ec2-44-213-15-199.compute-1.amazonaws.com/platforms/TheTradeDesk.svg",
//       "overviewUrl": "http://ec2-44-213-15-199.compute-1.amazonaws.com/v1/document?path=the-trade-desk-raw-event-data-stream-reds",
//       "docUrl": "https://docs.madconnect.io/supported-connectors/thetradedesk",
//       "status": 1,
//       "supportedType": "BOTH",
//       "integrated": true
//     },
//     {
//       "categories": [],
//       "id": "69b7318a943118d3",
//       "name": "The Trade Desk",
//       "dataType": "raw offline measurement events",
//       "dataTypeId": 52,
//       "logo": "http://ec2-44-213-15-199.compute-1.amazonaws.com/platforms/TheTradeDesk.svg",
//       "overviewUrl": "http://ec2-44-213-15-199.compute-1.amazonaws.com/v1/document?path=the-trade-desk-raw-offline-measurement-conversion",
//       "docUrl": "https://docs.madconnect.io/supported-connectors/thetradedesk",
//       "status": 1,
//       "supportedType": "BOTH",
//       "integrated": true
//     },
//     {
//       "categories": [],
//       "id": "69b7318a943118d3",
//       "name": "The Trade Desk",
//       "dataType": "raw real time conversion events",
//       "dataTypeId": 53,
//       "logo": "http://ec2-44-213-15-199.compute-1.amazonaws.com/platforms/TheTradeDesk.svg",
//       "overviewUrl": "http://ec2-44-213-15-199.compute-1.amazonaws.com/v1/document?path=the-trade-desk-raw-real-time-conversion-events",
//       "docUrl": "https://docs.madconnect.io/supported-connectors/thetradedesk",
//       "status": 1,
//       "supportedType": "BOTH",
//       "integrated": true
//     },
//     {
//       "categories": [
//         8,
//         13
//       ],
//       "id": "24abf7f8fc33403f",
//       "name": "Comscore",
//       "dataType": "rapid analytics",
//       "dataTypeId": 5,
//       "logo": "http://ec2-44-213-15-199.compute-1.amazonaws.com/platforms/Comscore.svg",
//       "overviewUrl": "http://ec2-44-213-15-199.compute-1.amazonaws.com/v1/document?path=comscore",
//       "docUrl": "https://docs.madconnect.io/supported-connectors/comscore",
//       "status": 1,
//       "supportedType": "SOURCE",
//       "integrated": true
//     },
//     {
//       "categories": [
//         16,
//         8
//       ],
//       "id": "a7f76a3dafe44d0b",
//       "name": "Kochava",
//       "dataType": "geo events",
//       "dataTypeId": 29,
//       "logo": "http://ec2-44-213-15-199.compute-1.amazonaws.com/platforms/Kochava.svg",
//       "overviewUrl": "http://ec2-44-213-15-199.compute-1.amazonaws.com/v1/document?path=kochava",
//       "docUrl": "https://docs.madconnect.io/supported-connectors/kochava",
//       "status": 1,
//       "supportedType": "BOTH",
//       "integrated": true
//     },
//     {
//       "categories": [
//         7
//       ],
//       "id": "938d5675a5cae5e2",
//       "name": "Bing Ads",
//       "dataType": "Audiences",
//       "dataTypeId": 23,
//       "logo": "http://ec2-44-213-15-199.compute-1.amazonaws.com/platforms/MicrosoftBingAds.svg",
//       "overviewUrl": "http://ec2-44-213-15-199.compute-1.amazonaws.com/v1/document?path=bing-ads-audiences",
//       "docUrl": "https://docs.madconnect.io/supported-connectors/bing-ads",
//       "status": 1,
//       "supportedType": "BOTH",
//       "integrated": true
//     },
//     {
//       "categories": [],
//       "id": "938d5675a5cae5e2",
//       "name": "Bing Ads",
//       "dataType": "events",
//       "dataTypeId": 49,
//       "logo": "http://ec2-44-213-15-199.compute-1.amazonaws.com/platforms/MicrosoftBingAds.svg",
//       "overviewUrl": "http://ec2-44-213-15-199.compute-1.amazonaws.com/v1/document?path=bing-ads-events",
//       "docUrl": "https://docs.madconnect.io/supported-connectors/bing-ads",
//       "status": 1,
//       "supportedType": "BOTH",
//       "integrated": true
//     },
//     {
//       "categories": [
//         16,
//         18
//       ],
//       "id": "5589ef726dc3fb94",
//       "name": "Tapad",
//       "dataType": "",
//       "dataTypeId": 13,
//       "logo": "http://ec2-44-213-15-199.compute-1.amazonaws.com/platforms/Tapad.svg",
//       "overviewUrl": "http://ec2-44-213-15-199.compute-1.amazonaws.com/v1/document?path=tapad",
//       "docUrl": "https://docs.madconnect.io/supported-connectors/tapad",
//       "status": 1,
//       "supportedType": "BOTH",
//       "integrated": true
//     },
//     {
//       "categories": [
//         16,
//         9
//       ],
//       "id": "a04fb499f1acfa2e",
//       "name": "Gravy",
//       "dataType": "",
//       "dataTypeId": 26,
//       "logo": "http://ec2-44-213-15-199.compute-1.amazonaws.com/platforms/Gravy.png",
//       "overviewUrl": "http://ec2-44-213-15-199.compute-1.amazonaws.com/v1/document?path=gravy-analytics",
//       "docUrl": "https://docs.madconnect.io/supported-connectors/tapad",
//       "status": 1,
//       "supportedType": "BOTH",
//       "integrated": true
//     },
//     {
//       "categories": [
//         16
//       ],
//       "id": "1306bfc45a892b6a",
//       "name": "Veraset",
//       "dataType": "",
//       "dataTypeId": 2,
//       "logo": "http://ec2-44-213-15-199.compute-1.amazonaws.com/platforms/Veraset.png",
//       "overviewUrl": "http://ec2-44-213-15-199.compute-1.amazonaws.com/v1/document?path=veraset",
//       "docUrl": "https://docs.madconnect.io/supported-connectors/veraset",
//       "status": 1,
//       "supportedType": "BOTH",
//       "integrated": true
//     },
//     {
//       "categories": [
//         5
//       ],
//       "id": "3f78a3d42bfa5902",
//       "name": "Freewheel",
//       "dataType": "events",
//       "dataTypeId": 9,
//       "logo": "http://ec2-44-213-15-199.compute-1.amazonaws.com/platforms/Freewheel.svg",
//       "overviewUrl": "http://ec2-44-213-15-199.compute-1.amazonaws.com/v1/document?path=freewheel-events-rest-api-source",
//       "docUrl": "https://docs.madconnect.io/supported-connectors/freewheel",
//       "status": 1,
//       "supportedType": "BOTH",
//       "integrated": true
//     },
//     {
//       "categories": [],
//       "id": "3f78a3d42bfa5902",
//       "name": "Freewheel",
//       "dataType": "campaign posting",
//       "dataTypeId": 60,
//       "logo": "http://ec2-44-213-15-199.compute-1.amazonaws.com/platforms/Freewheel.svg",
//       "overviewUrl": "http://ec2-44-213-15-199.compute-1.amazonaws.com/v1/document?path=freewheel-campaign-posting-rest-api-destination",
//       "docUrl": "https://docs.madconnect.io/supported-connectors/freewheel",
//       "status": 1,
//       "supportedType": "BOTH",
//       "integrated": true
//     },
//     {
//       "categories": [
//         7,
//         5
//       ],
//       "id": "a224e0c684fd4b3e",
//       "name": "Google DV360",
//       "dataType": "audience segments",
//       "dataTypeId": 27,
//       "logo": "http://ec2-44-213-15-199.compute-1.amazonaws.com/platforms/GoogleDV360.png",
//       "overviewUrl": "http://ec2-44-213-15-199.compute-1.amazonaws.com/v1/document?path=google-dv360",
//       "docUrl": "https://docs.madconnect.io/supported-connectors/google-dv-360",
//       "status": 1,
//       "supportedType": "BOTH",
//       "integrated": true
//     },
//     {
//       "categories": [
//         15
//       ],
//       "id": "3c327fdd94db28e9",
//       "name": "mParticle",
//       "dataType": "Audiences",
//       "dataTypeId": 8,
//       "logo": "http://ec2-44-213-15-199.compute-1.amazonaws.com/platforms/MParticle.png",
//       "overviewUrl": "http://ec2-44-213-15-199.compute-1.amazonaws.com/v1/document?path=mparticle-audience",
//       "docUrl": "https://docs.madconnect.io/supported-connectors/mparticle",
//       "status": 1,
//       "supportedType": "BOTH",
//       "integrated": true
//     },
//     {
//       "categories": [],
//       "id": "3c327fdd94db28e9",
//       "name": "mParticle",
//       "dataType": "events",
//       "dataTypeId": 50,
//       "logo": "http://ec2-44-213-15-199.compute-1.amazonaws.com/platforms/MParticle.png",
//       "overviewUrl": "http://ec2-44-213-15-199.compute-1.amazonaws.com/v1/document?path=mparticle-events",
//       "docUrl": "https://docs.madconnect.io/supported-connectors/mparticle",
//       "status": 1,
//       "supportedType": "BOTH",
//       "integrated": true
//     },
//     {
//       "categories": [
//         16,
//         15
//       ],
//       "id": "b49afb32f7352822",
//       "name": "Lotame",
//       "dataType": "Audiences",
//       "dataTypeId": 30,
//       "logo": "http://ec2-44-213-15-199.compute-1.amazonaws.com/platforms/Lotame.png",
//       "overviewUrl": "http://ec2-44-213-15-199.compute-1.amazonaws.com/v1/document?path=lotame",
//       "docUrl": "https://docs.madconnect.io/supported-connectors/lotame",
//       "status": 1,
//       "supportedType": "BOTH",
//       "integrated": true
//     },
//     {
//       "categories": [
//         16
//       ],
//       "id": "3b89d7637ad48f19",
//       "name": "Experian",
//       "dataType": "consumer credit profile",
//       "dataTypeId": 7,
//       "logo": "http://ec2-44-213-15-199.compute-1.amazonaws.com/platforms/Experian.png",
//       "overviewUrl": "http://ec2-44-213-15-199.compute-1.amazonaws.com/v1/document?path=experian-audiences-ccp-api",
//       "docUrl": "",
//       "status": 1,
//       "supportedType": "BOTH",
//       "integrated": true
//     },
//     {
//       "categories": [],
//       "id": "3b89d7637ad48f19",
//       "name": "Experian",
//       "dataType": "audiences u-enrichment",
//       "dataTypeId": 40,
//       "logo": "http://ec2-44-213-15-199.compute-1.amazonaws.com/platforms/Experian.png",
//       "overviewUrl": "http://ec2-44-213-15-199.compute-1.amazonaws.com/v1/document?path=experian-audiences-u-enrichment",
//       "docUrl": "",
//       "status": 1,
//       "supportedType": "BOTH",
//       "integrated": true
//     },
//     {
//       "categories": [],
//       "id": "3b89d7637ad48f19",
//       "name": "Experian",
//       "dataType": "Omniview",
//       "dataTypeId": 41,
//       "logo": "http://ec2-44-213-15-199.compute-1.amazonaws.com/platforms/Experian.png",
//       "overviewUrl": "http://ec2-44-213-15-199.compute-1.amazonaws.com/v1/document?path=experian-omniview-api",
//       "docUrl": "",
//       "status": 1,
//       "supportedType": "BOTH",
//       "integrated": true
//     },
//     {
//       "categories": [
//         5
//       ],
//       "id": "b8f92b3360f60ea3",
//       "name": "Google Ads Manager",
//       "dataType": "reporting",
//       "dataTypeId": 31,
//       "logo": "http://ec2-44-213-15-199.compute-1.amazonaws.com/platforms/GoogleAdManager.png",
//       "overviewUrl": "http://ec2-44-213-15-199.compute-1.amazonaws.com/v1/document?path=google-ads-manager",
//       "docUrl": "",
//       "status": 1,
//       "supportedType": "BOTH",
//       "integrated": true
//     },
//     {
//       "categories": [
//         5,
//         7
//       ],
//       "id": "65j8b5tt6rtki3a4",
//       "name": "Yahoo",
//       "dataType": "reporting",
//       "dataTypeId": 38,
//       "logo": "http://ec2-44-213-15-199.compute-1.amazonaws.com/platforms/Yahoo.png",
//       "overviewUrl": "http://ec2-44-213-15-199.compute-1.amazonaws.com/v1/document?path=yahoo-dsp-api",
//       "docUrl": null,
//       "status": 1,
//       "supportedType": "BOTH",
//       "integrated": true
//     },
//     {
//       "categories": [],
//       "id": "65j8b5tt6rtki3a4",
//       "name": "Yahoo",
//       "dataType": "Audiences",
//       "dataTypeId": 58,
//       "logo": "http://ec2-44-213-15-199.compute-1.amazonaws.com/platforms/Yahoo.png",
//       "overviewUrl": "http://ec2-44-213-15-199.compute-1.amazonaws.com/v1/document?path=yahoo-traffic-api-audiences",
//       "docUrl": null,
//       "status": 1,
//       "supportedType": "BOTH",
//       "integrated": true
//     },
//     {
//       "categories": [
//         15
//       ],
//       "id": "ic1nosf03ic4st9o",
//       "name": "ActionIQ",
//       "dataType": "Events",
//       "dataTypeId": 39,
//       "logo": "http://ec2-44-213-15-199.compute-1.amazonaws.com/platforms/ActionIQ.png",
//       "overviewUrl": "http://ec2-44-213-15-199.compute-1.amazonaws.com/v1/document?path=actioniq",
//       "docUrl": null,
//       "status": 1,
//       "supportedType": "BOTH",
//       "integrated": true
//     },
//     {
//       "categories": [
//         5,
//         6,
//         7,
//         8,
//         9,
//         10,
//         11,
//         12,
//         13,
//         14,
//         15,
//         16,
//         17,
//         18,
//         19
//       ],
//       "id": "799bed47649a0c89",
//       "name": "AWS",
//       "dataType": "",
//       "dataTypeId": 20,
//       "logo": "http://ec2-44-213-15-199.compute-1.amazonaws.com/platforms/AWS.png",
//       "overviewUrl": "http://ec2-44-213-15-199.compute-1.amazonaws.com/v1/document?path=aws-api-data",
//       "docUrl": "",
//       "status": 1,
//       "supportedType": "BOTH",
//       "integrated": true
//     },
//     {
//       "categories": [
//         13
//       ],
//       "id": "5602z73la2s5m2zi",
//       "name": "AppsFlyer Privacy Cloud",
//       "dataType": "events",
//       "dataTypeId": 37,
//       "logo": "http://ec2-44-213-15-199.compute-1.amazonaws.com/platforms/AppsFlyer.png",
//       "overviewUrl": "http://ec2-44-213-15-199.compute-1.amazonaws.com/v1/document?path=appsflyer-events",
//       "docUrl": null,
//       "status": 1,
//       "supportedType": "BOTH",
//       "integrated": true
//     },
//     {
//       "categories": [],
//       "id": "5602z73la2s5m2zi",
//       "name": "AppsFlyer Privacy Cloud",
//       "dataType": "Audiences",
//       "dataTypeId": 56,
//       "logo": "http://ec2-44-213-15-199.compute-1.amazonaws.com/platforms/AppsFlyer.png",
//       "overviewUrl": "http://ec2-44-213-15-199.compute-1.amazonaws.com/v1/document?path=apps-flyer-audiences",
//       "docUrl": null,
//       "status": 1,
//       "supportedType": "BOTH",
//       "integrated": true
//     },
//     {
//       "categories": [
//         5,
//         6,
//         7,
//         8,
//         9,
//         10,
//         11,
//         12,
//         13,
//         14,
//         15,
//         16,
//         17,
//         18,
//         19
//       ],
//       "id": "d0f5134058bb4433",
//       "name": "Nielsen",
//       "dataType": "reporting",
//       "dataTypeId": 34,
//       "logo": "http://ec2-44-213-15-199.compute-1.amazonaws.com/platforms/Neilsen.png",
//       "overviewUrl": "http://ec2-44-213-15-199.compute-1.amazonaws.com/v1/document?path=nielsen",
//       "docUrl": "",
//       "status": 1,
//       "supportedType": "BOTH",
//       "integrated": true
//     },
//     {
//       "categories": [],
//       "id": "d0f5134058bb4433",
//       "name": "Nielsen",
//       "dataType": "analytics",
//       "dataTypeId": 59,
//       "logo": "http://ec2-44-213-15-199.compute-1.amazonaws.com/platforms/Neilsen.png",
//       "overviewUrl": "http://ec2-44-213-15-199.compute-1.amazonaws.com/v1/document?path=nielsen",
//       "docUrl": "",
//       "status": 1,
//       "supportedType": "BOTH",
//       "integrated": true
//     },
//     {
//       "categories": [
//         5
//       ],
//       "id": "288d632456a978f6",
//       "name": "Adform",
//       "dataType": "Campaign",
//       "dataTypeId": 6,
//       "logo": "http://ec2-44-213-15-199.compute-1.amazonaws.com/platforms/Adform.png",
//       "overviewUrl": "http://ec2-44-213-15-199.compute-1.amazonaws.com/v1/document?path=adform-campaign-api-source",
//       "docUrl": "",
//       "status": 1,
//       "supportedType": "BOTH",
//       "integrated": true
//     },
//     {
//       "categories": [
//         6,
//         16
//       ],
//       "id": "e4d4a83899616b37",
//       "name": "LiveRamp",
//       "dataType": "ID match",
//       "dataTypeId": 35,
//       "logo": "http://ec2-44-213-15-199.compute-1.amazonaws.com/platforms/LiveRamp.png",
//       "overviewUrl": "http://ec2-44-213-15-199.compute-1.amazonaws.com/v1/document?path=liveramp-id-match-and-retrieval-api",
//       "docUrl": "",
//       "status": 1,
//       "supportedType": "BOTH",
//       "integrated": true
//     },
//     {
//       "categories": [],
//       "id": "e4d4a83899616b37",
//       "name": "LiveRamp",
//       "dataType": "transcoding",
//       "dataTypeId": 57,
//       "logo": "http://ec2-44-213-15-199.compute-1.amazonaws.com/platforms/LiveRamp.png",
//       "overviewUrl": "http://ec2-44-213-15-199.compute-1.amazonaws.com/v1/document?path=liveramp-transcoding-api",
//       "docUrl": "",
//       "status": 1,
//       "supportedType": "BOTH",
//       "integrated": true
//     },
//     {
//       "categories": [
//         19
//       ],
//       "id": "441eaaae7996a311",
//       "name": "HubSpot",
//       "dataType": "",
//       "dataTypeId": 10,
//       "logo": "http://ec2-44-213-15-199.compute-1.amazonaws.com/platforms/Hubspot.png",
//       "overviewUrl": "http://ec2-44-213-15-199.compute-1.amazonaws.com/v1/document?path=hubspot-api-data",
//       "docUrl": "",
//       "status": 1,
//       "supportedType": "BOTH",
//       "integrated": true
//     },
//     {
//       "categories": [
//         7,
//         16
//       ],
//       "id": "79d45448056052b5",
//       "name": "LinkedIn",
//       "dataType": "analytics",
//       "dataTypeId": 21,
//       "logo": "http://ec2-44-213-15-199.compute-1.amazonaws.com/platforms/LinkedIn.png",
//       "overviewUrl": "http://ec2-44-213-15-199.compute-1.amazonaws.com/v1/document?path=linkedin-analytics-api",
//       "docUrl": "",
//       "status": 1,
//       "supportedType": "BOTH",
//       "integrated": true
//     },
//     {
//       "categories": [],
//       "id": "79d45448056052b5",
//       "name": "LinkedIn",
//       "dataType": "audience dmp segments",
//       "dataTypeId": 47,
//       "logo": "http://ec2-44-213-15-199.compute-1.amazonaws.com/platforms/LinkedIn.png",
//       "overviewUrl": "http://ec2-44-213-15-199.compute-1.amazonaws.com/v1/document?path=linkedin-audiences-dmp-segments",
//       "docUrl": "",
//       "status": 1,
//       "supportedType": "BOTH",
//       "integrated": true
//     },
//     {
//       "categories": [],
//       "id": "79d45448056052b5",
//       "name": "LinkedIn",
//       "dataType": "offline conversions",
//       "dataTypeId": 48,
//       "logo": "http://ec2-44-213-15-199.compute-1.amazonaws.com/platforms/LinkedIn.png",
//       "overviewUrl": "http://ec2-44-213-15-199.compute-1.amazonaws.com/v1/document?path=linkedin-offline-conversions-api",
//       "docUrl": "",
//       "status": 1,
//       "supportedType": "BOTH",
//       "integrated": true
//     },
//     {
//       "categories": [
//         19,
//         18
//       ],
//       "id": "70b0eadc4458a5bf",
//       "name": "Adobe",
//       "dataType": "audience segments",
//       "dataTypeId": 18,
//       "logo": "http://ec2-44-213-15-199.compute-1.amazonaws.com/platforms/Adobe.png",
//       "overviewUrl": "http://ec2-44-213-15-199.compute-1.amazonaws.com/v1/document?path=adobe",
//       "docUrl": "",
//       "status": 1,
//       "supportedType": "BOTH",
//       "integrated": true
//     },
//     {
//       "categories": [
//         16
//       ],
//       "id": "f099927d9b545a8b",
//       "name": "Narrative",
//       "dataType": "",
//       "dataTypeId": 36,
//       "logo": "http://ec2-44-213-15-199.compute-1.amazonaws.com/platforms/Narrative.png",
//       "overviewUrl": "http://ec2-44-213-15-199.compute-1.amazonaws.com/v1/document?path=narrative-data-collaboration-platform-api",
//       "docUrl": "",
//       "status": 1,
//       "supportedType": "BOTH",
//       "integrated": true
//     },
//     {
//       "categories": [
//         7
//       ],
//       "id": "b98264606a152eea",
//       "name": "Spotify",
//       "dataType": "",
//       "dataTypeId": 32,
//       "logo": "http://ec2-44-213-15-199.compute-1.amazonaws.com/platforms/Spotify.png",
//       "overviewUrl": "http://ec2-44-213-15-199.compute-1.amazonaws.com/v1/document?path=spotify-web-api",
//       "docUrl": "",
//       "status": 1,
//       "supportedType": "BOTH",
//       "integrated": true
//     },
//     {
//       "categories": [
//         7
//       ],
//       "id": "c4d8ab0898ac1a33",
//       "name": "Pandora",
//       "dataType": "",
//       "dataTypeId": 33,
//       "logo": "http://ec2-44-213-15-199.compute-1.amazonaws.com/platforms/Pandora.png",
//       "overviewUrl": "http://ec2-44-213-15-199.compute-1.amazonaws.com/v1/document?path=pandora-data",
//       "docUrl": "",
//       "status": 1,
//       "supportedType": "BOTH",
//       "integrated": true
//     },
//     {
//       "categories": [
//         15
//       ],
//       "id": "5cfe846889792abf",
//       "name": "Salesforce",
//       "dataType": "",
//       "dataTypeId": 15,
//       "logo": "http://ec2-44-213-15-199.compute-1.amazonaws.com/platforms/SalesforceDMP.png",
//       "overviewUrl": "http://ec2-44-213-15-199.compute-1.amazonaws.com/v1/document?path=salesforce",
//       "docUrl": "",
//       "status": 1,
//       "supportedType": "BOTH",
//       "integrated": true
//     },
//     {
//       "categories": [
//         7
//       ],
//       "id": "4a0abc7c35fb5fe0",
//       "name": "Snap",
//       "dataType": "Audiences",
//       "dataTypeId": 11,
//       "logo": "http://ec2-44-213-15-199.compute-1.amazonaws.com/platforms/Snap.png",
//       "overviewUrl": "http://ec2-44-213-15-199.compute-1.amazonaws.com/v1/document?path=mparticle-audience",
//       "docUrl": "",
//       "status": 1,
//       "supportedType": "BOTH",
//       "integrated": true
//     },
//     {
//       "categories": [
//         7
//       ],
//       "id": "6b86e12b4f48a69b",
//       "name": "Tik Tok",
//       "dataType": "ad measurement",
//       "dataTypeId": 17,
//       "logo": "http://ec2-44-213-15-199.compute-1.amazonaws.com/platforms/TikTok.png",
//       "overviewUrl": "http://ec2-44-213-15-199.compute-1.amazonaws.com/v1/document?path=tiktok-marketing-ad-measurement",
//       "docUrl": "",
//       "status": 1,
//       "supportedType": "BOTH",
//       "integrated": true
//     },
//     {
//       "categories": [],
//       "id": "6b86e12b4f48a69b",
//       "name": "Tik Tok",
//       "dataType": "Audiences",
//       "dataTypeId": 54,
//       "logo": "http://ec2-44-213-15-199.compute-1.amazonaws.com/platforms/TikTok.png",
//       "overviewUrl": "http://ec2-44-213-15-199.compute-1.amazonaws.com/v1/document?path=tiktok-marketing-audience-management-api",
//       "docUrl": "",
//       "status": 1,
//       "supportedType": "BOTH",
//       "integrated": true
//     },
//     {
//       "categories": [
//         7
//       ],
//       "id": "144dda24a5c2396c",
//       "name": "Pinterest",
//       "dataType": "ads management",
//       "dataTypeId": 3,
//       "logo": "http://ec2-44-213-15-199.compute-1.amazonaws.com/platforms/Pinterest.png",
//       "overviewUrl": "http://ec2-44-213-15-199.compute-1.amazonaws.com/v1/document?path=pinterest-ads-management-api",
//       "docUrl": "",
//       "status": 1,
//       "supportedType": "BOTH",
//       "integrated": true
//     },
//     {
//       "categories": [],
//       "id": "144dda24a5c2396c",
//       "name": "Pinterest",
//       "dataType": "ads analytics",
//       "dataTypeId": 55,
//       "logo": "http://ec2-44-213-15-199.compute-1.amazonaws.com/platforms/Pinterest.png",
//       "overviewUrl": "http://ec2-44-213-15-199.compute-1.amazonaws.com/v1/document?path=pinterest-ads-analytics-api",
//       "docUrl": "",
//       "status": 1,
//       "supportedType": "BOTH",
//       "integrated": true
//     },
//     {
//       "categories": [
//         7
//       ],
//       "id": "9fb4f1caa7464e18",
//       "name": "MediaMath",
//       "dataType": "",
//       "dataTypeId": 25,
//       "logo": "http://ec2-44-213-15-199.compute-1.amazonaws.com/platforms/MediaMath.png",
//       "overviewUrl": "http://ec2-44-213-15-199.compute-1.amazonaws.com/v1/document?path=mediamath-by-infillion",
//       "docUrl": "",
//       "status": 1,
//       "supportedType": "BOTH",
//       "integrated": true
//     },
//     {
//       "categories": [
//         5,
//         6,
//         7,
//         8,
//         9,
//         10,
//         11,
//         12,
//         13,
//         14,
//         15,
//         16,
//         17,
//         18,
//         19
//       ],
//       "id": "779a074a52a59696",
//       "name": "Neustar",
//       "dataType": "",
//       "dataTypeId": 19,
//       "logo": "http://ec2-44-213-15-199.compute-1.amazonaws.com/platforms/Neustar.png",
//       "overviewUrl": "http://ec2-44-213-15-199.compute-1.amazonaws.com/v1/document?path=neustar",
//       "docUrl": "",
//       "status": 1,
//       "supportedType": "BOTH",
//       "integrated": true
//     },
//     {
//       "categories": [
//         5,
//         6,
//         7,
//         8,
//         9,
//         10,
//         11,
//         12,
//         13,
//         14,
//         15,
//         16,
//         17,
//         18,
//         19
//       ],
//       "id": "984e22ad746ba4cf",
//       "name": "OneTrust",
//       "dataType": "",
//       "dataTypeId": 24,
//       "logo": "http://ec2-44-213-15-199.compute-1.amazonaws.com/platforms/OneTrust.png",
//       "overviewUrl": "http://ec2-44-213-15-199.compute-1.amazonaws.com/v1/document?path=onetrust",
//       "docUrl": "",
//       "status": 1,
//       "supportedType": "BOTH",
//       "integrated": true
//     },
//     {
//       "categories": [
//         15
//       ],
//       "id": "4dd49d0df6ef27c3",
//       "name": "Permutive",
//       "dataType": "",
//       "dataTypeId": 12,
//       "logo": "http://ec2-44-213-15-199.compute-1.amazonaws.com/platforms/Permutive.png",
//       "overviewUrl": "http://ec2-44-213-15-199.compute-1.amazonaws.com/v1/document?path=permutive",
//       "docUrl": "",
//       "status": 1,
//       "supportedType": "BOTH",
//       "integrated": true
//     },
//     {
//       "categories": [
//         7
//       ],
//       "id": "01b4b39a5b62fde7",
//       "name": "Roku",
//       "dataType": "analytics",
//       "dataTypeId": 1,
//       "logo": "http://ec2-44-213-15-199.compute-1.amazonaws.com/platforms/Roku.png",
//       "overviewUrl": "http://ec2-44-213-15-199.compute-1.amazonaws.com/v1/document?path=the-roku-analytics-component-library-racl",
//       "docUrl": "",
//       "status": 1,
//       "supportedType": "BOTH",
//       "integrated": true
//     },
//     {
//       "categories": [
//         5,
//         6,
//         7,
//         8,
//         9,
//         10,
//         11,
//         12,
//         13,
//         14,
//         15,
//         16,
//         17,
//         18,
//         19
//       ],
//       "id": "5ab29046d5342659",
//       "name": "Roqad",
//       "dataType": "",
//       "dataTypeId": 14,
//       "logo": "http://ec2-44-213-15-199.compute-1.amazonaws.com/platforms/Roqad.png",
//       "overviewUrl": "http://ec2-44-213-15-199.compute-1.amazonaws.com/v1/document?path=roq.ad-identity-graph",
//       "docUrl": "",
//       "status": 1,
//       "supportedType": "BOTH",
//       "integrated": true
//     },
//     {
//       "categories": [
//         5,
//         6,
//         7,
//         8,
//         9,
//         10,
//         11,
//         12,
//         13,
//         14,
//         15,
//         16,
//         17,
//         18,
//         19
//       ],
//       "id": "7f78a3d4trfaq9q2",
//       "name": "Shopify",
//       "dataType": "",
//       "dataTypeId": 22,
//       "logo": "http://ec2-44-213-15-199.compute-1.amazonaws.com/platforms/Shopify.png",
//       "overviewUrl": "http://ec2-44-213-15-199.compute-1.amazonaws.com/v1/document?path=shopify-admin-api",
//       "docUrl": "",
//       "status": 1,
//       "supportedType": "BOTH",
//       "integrated": true
//     }
//   ],
//   categories: [
//     {
//       id: 1,
//       name: 'Advertising',
//       subCategories: [
//         {
//           id: 5,
//           name: 'Re-Targeting'
//         },
//         {
//           id: 6,
//           name: 'Data Onboarding'
//         },
//         {
//           id: 7,
//           name: 'User Acquisition'
//         }
//       ]
//     },
//     {
//       id: 2,
//       name: 'Analytics',
//       subCategories: [
//         {
//           id: 8,
//           name: 'Attribution'
//         },
//         {
//           id: 9,
//           name: 'Conversion Tracking'
//         },
//         {
//           id: 10,
//           name: 'Monitoring'
//         },
//         {
//           id: 11,
//           name: 'Predictive Analytics'
//         },
//         {
//           id: 12,
//           name: 'Custom Feeds'
//         },
//         {
//           id: 13,
//           name: 'User Analytics'
//         }
//       ]
//     },
//     {
//       id: 3,
//       name: 'Data Warehousing',
//       subCategories: [
//         {
//           id: 14,
//           name: 'Row Data Export'
//         },
//         {
//           id: 15,
//           name: 'DMP'
//         }
//       ]
//     },
//     {
//       id: 4,
//       name: 'Marketing',
//       subCategories: [
//         {
//           id: 16,
//           name: 'Data Enrichment'
//         },
//         {
//           id: 17,
//           name: 'Customer Engagement'
//         },
//         {
//           id: 18,
//           name: 'Personalization'
//         },
//         {
//           id: 19,
//           name: 'CRM'
//         }
//       ]
//     }
//   ],
//   allSelectedPlatforms: [
//     {
//       categories: [
//         16
//       ],
//       dataTypes: [
//         'audience'
//       ],
//       id: '1306bfc45a892b6a',
//       name: 'Veraset',
//       "dataType": "Audiences",
//       logo: 'platforms/Veraset.png',
//       overviewUrl: 'http://ec2-44-213-15-199.compute-1.amazonaws.com/e6d1adf4-c955-11ed-8cb2-f7ad6676aa63/Default/Default.html',
//       description: 'Data input platforms',
//       status: 1,
//       srcConnections: 0,
//       destConnections: 0,
//       configured: false,
//       inUse: false
//     },
//     {
//       categories: [
//         8,
//         13
//       ],
//       dataTypes: [
//         'audience'
//       ],
//       id: '24abf7f8fc33403f',
//       name: 'Comscore',
//       logo: 'platforms/Comscore.svg',
//       overviewUrl: 'http://ec2-44-213-15-199.compute-1.amazonaws.com/e6d1adf4-c955-11ed-8cb2-f7ad6676aa63/Default/Default.html',
//       description: 'Data input platforms',
//       status: 1,
//       srcConnections: 1,
//       destConnections: 1,
//       configured: false,
//       "dataType": "Audiences",
//       inUse: true
//     },
//     {
//       categories: [
//         5
//       ],
//       dataTypes: [
//         'audience'
//       ],
//       id: '288d632456a978f6',
//       name: 'Adform',
//       logo: 'platforms/Adform.png',
//       overviewUrl: 'http://ec2-44-213-15-199.compute-1.amazonaws.com/e6d1adf4-c955-11ed-8cb2-f7ad6676aa63/Default/Default.html',
//       description: 'Data input platforms',
//       status: 2,
//       srcConnections: 0,
//       destConnections: 1,
//       "dataType": "Audiences",
//       configured: false,
//       inUse: true
//     },
//     {
//       categories: [
//         6,
//         16
//       ],
//       dataTypes: [
//         'audience'
//       ],
//       id: 'e4d4a83899616b37',
//       name: 'LiveRamp',
//       logo: 'platforms/LiveRamp.png',
//       overviewUrl: 'http://ec2-44-213-15-199.compute-1.amazonaws.com/e6d1adf4-c955-11ed-8cb2-f7ad6676aa63/Default/Default.html',
//       description: 'Data input platforms',
//       status: 2,
//       srcConnections: 2,
//       "dataType": "Audiences",
//       destConnections: 0,
//       configured: false,
//       inUse: true
//     },
//     {
//       categories: [
//         5,
//         6,
//         7,
//         8,
//         9,
//         10,
//         11,
//         12,
//         13,
//         14,
//         15,
//         16,
//         17,
//         18,
//         19
//       ],
//       dataTypes: [
//         'audience'
//       ],
//       id: '799bed47649a0c89',
//       name: 'AWS',
//       logo: 'platforms/AWS.png',
//       overviewUrl: 'http://ec2-44-213-15-199.compute-1.amazonaws.com/e6d1adf4-c955-11ed-8cb2-f7ad6676aa63/Default/Default.html',
//       description: 'Data input platforms',
//       status: 2,
//       srcConnections: 0,
//       "dataType": "Audiences",
//       destConnections: 0,
//       configured: false,
//       inUse: false
//     },
//     {
//       categories: [
//         5
//       ],
//       dataTypes: [
//         'audience'
//       ],
//       id: 'b8f92b3360f60ea3',
//       name: 'Google Ads Manager',
//       logo: 'platforms/GoogleAdManager.png',
//       overviewUrl: 'http://ec2-44-213-15-199.compute-1.amazonaws.com/e6d1adf4-c955-11ed-8cb2-f7ad6676aa63/Google-Ads-Manager/GoogleAdsManager.html',
//       description: 'Data input platforms',
//       status: 2,
//       srcConnections: 0,
//       destConnections: 0,
//       "dataType": "Audiences",
//       configured: false,
//       inUse: false
//     },
//     {
//       categories: [
//         16
//       ],
//       dataTypes: [
//         'audience'
//       ],
//       id: '3b89d7637ad48f19',
//       name: 'Experian',
//       logo: 'platforms/Experian.png',
//       overviewUrl: 'http://ec2-44-213-15-199.compute-1.amazonaws.com/e6d1adf4-c955-11ed-8cb2-f7ad6676aa63/Experian/Experian.html',
//       description: 'Data input platforms',
//       status: 1,
//       srcConnections: 0,
//       destConnections: 0,
//       "dataType": "Audiences",
//       configured: false,
//       inUse: false
//     },
//     {
//       categories: [
//         16,
//         8
//       ],
//       dataTypes: [
//         'audience'
//       ],
//       id: 'a7f76a3dafe44d0b',
//       name: 'Kochava',
//       logo: 'platforms/Kochava.svg',
//       overviewUrl: 'http://ec2-44-213-15-199.compute-1.amazonaws.com/e6d1adf4-c955-11ed-8cb2-f7ad6676aa63/Kochava/Kochava.html',
//       description: 'Data input platforms',
//       status: 1,
//       srcConnections: 0,
//       destConnections: 1,
//       "dataType": "Audiences",
//       configured: false,
//       inUse: true
//     },
//     {
//       categories: [
//         7
//       ],
//       dataTypes: [
//         'audience'
//       ],
//       id: '69b7318a943118d3',
//       name: 'The Trade Desk',
//       logo: 'platforms/TheTradeDesk.svg',
//       overviewUrl: 'http://ec2-44-213-15-199.compute-1.amazonaws.com/e6d1adf4-c955-11ed-8cb2-f7ad6676aa63/Default/Default.html',
//       description: 'Data input platforms',
//       status: 1,
//       srcConnections: 0,
//       destConnections: 0,
//       "dataType": "Audiences",
//       configured: false,
//       inUse: false
//     },
//     {
//       categories: [
//         5
//       ],
//       dataTypes: [
//         'audience'
//       ],
//       id: 'a7442ba436c87857',
//       name: 'Google Ads',
//       logo: 'platforms/GoogleAds.png',
//       overviewUrl: 'http://ec2-44-213-15-199.compute-1.amazonaws.com/e6d1adf4-c955-11ed-8cb2-f7ad6676aa63/Default/Default.html',
//       description: 'Data input platforms',
//       status: 1,
//       srcConnections: 0,
//       destConnections: 0,
//       "dataType": "Audiences",
//       configured: false,
//       inUse: false
//     },
//     {
//       categories: [
//         16,
//         15
//       ],
//       dataTypes: [
//         'audience'
//       ],
//       id: 'b49afb32f7352822',
//       name: 'Lotame',
//       logo: 'platforms/Lotame.png',
//       overviewUrl: 'http://ec2-44-213-15-199.compute-1.amazonaws.com/e6d1adf4-c955-11ed-8cb2-f7ad6676aa63/Lotame/Lotame.html',
//       description: 'Data input platforms',
//       status: 1,
//       srcConnections: 0,
//       "dataType": "Audiences",
//       destConnections: 0,
//       configured: false,
//       inUse: false
//     },
//     {
//       categories: [
//         15
//       ],
//       dataTypes: [
//         'audience'
//       ],
//       id: '3c327fdd94db28e9',
//       name: 'mParticle',
//       logo: 'platforms/MParticle.png',
//       overviewUrl: 'http://ec2-44-213-15-199.compute-1.amazonaws.com/e6d1adf4-c955-11ed-8cb2-f7ad6676aa63/mParticle/mParticle.html',
//       description: 'Data input platforms',
//       status: 1,
//       srcConnections: 0,
//       destConnections: 0,
//       "dataType": "Audiences",
//       configured: true,
//       inUse: false
//     },
//     {
//       categories: [
//         5,
//         7
//       ],
//       dataTypes: [
//         'audience'
//       ],
//       id: '17cb9338984dd58d',
//       name: 'Facebook Ads',
//       logo: 'platforms/FacebookAdvertising.svg',
//       overviewUrl: 'http://ec2-44-213-15-199.compute-1.amazonaws.com/e6d1adf4-c955-11ed-8cb2-f7ad6676aa63/Default/Default.html',
//       description: 'Data input platforms',
//       status: 1,
//       srcConnections: 0,
//       destConnections: 0,
//       "dataType": "Audiences",
//       configured: false,
//       inUse: false
//     }
//   ],
//   allConnectors: [],
//   isHomeNav: true,
//   isGetStarted: false,
//   isPlatformContact: true,
//   imageLoaded: false,
//   userChoice: 'Brand',
//   userProfile: null,
//   invoices: [],
//   platformId: {
//     id: 'e4d4a83899616b37',
//     logo: 'platforms/LiveRamp.png',
//     name: 'LiveRamp',
//     overviewUrl: 'http://ec2-44-213-15-199.compute-1.amazonaws.com/e6d1adf4-c955-11ed-8cb2-f7ad6676aa63/Default/Default.html'
//   },
//   platformByIdConfigurations: {
//     "categories": [
//       16,
//       18
//     ],
//     "id": "5589ef726dc3fb94",
//     "name": "Tapad",
//     "logo": "http://ec2-44-213-15-199.compute-1.amazonaws.com/platforms/Tapad.svg",
//     "overviewUrl": "http://ec2-44-213-15-199.compute-1.amazonaws.com/e6d1adf4-c955-11ed-8cb2-f7ad6676aa63/Default/Default.html",
//     "docUrl": "https://docs.madconnect.io/supported-connectors/tapad",
//     "status": 1,
//     "supportedType": "BOTH",
//     "integrated": true
//   },
//   platformConfigurations: {
//     dataTypes: [
//       'audience'
//     ],
//     audience: {
//       destPlatformConfigurations: [
//         {
//             "lastUpdated": 0,
//             "configuration": [
//                 {
//                     "module": null,
//                     "description": "",
//                     "label": "Sign in with Meta",
//                     "type": "button",
//                     "list": null,
//                     "required": true,
//                     "listValue": null,
//                     "readonly": false,
//                     "copyText": false,
//                     "name": "OAuth",
//                     "limit": 0,
//                     "id": "oauth",
//                     "value": ""
//                 },
//                 {
//                   listValue: null,
//                   readonly: false,
//                   copyText: false,
//                   name: 'Password',
//                   description: '',
//                   id: 'password',
//                   label: 'Password',
//                   type: 'text',
//                   list: null,
//                   value: '3',
//                   required: true
//                 },
//                 {
//                   listValue: null,
//                   readonly: false,
//                   copyText: false,
//                   name: 'Client Id',
//                   description: '',
//                   id: 'clientId',
//                   label: 'Client Id',
//                   type: 'text',
//                   list: null,
//                   value: '3',
//                   required: true
//                 }
//             ],
//             "name": "New Configuration",
//             "inUse": false,
//             "count": 0,
//             "id": ""
//         }
//     ],
//       storeInDB: false,
//       showOtherSrcFields: true,
//       srcPlatformConfigurations: [
//         {
//             "lastUpdated": 0,
//             "configuration": [
//                 {
//                     "module": null,
//                     "description": "",
//                     "label": "Sign in with Meta",
//                     "type": "button",
//                     "list": null,
//                     "required": true,
//                     "listValue": null,
//                     "readonly": false,
//                     "copyText": false,
//                     "name": "OAuth",
//                     "limit": 0,
//                     "id": "oauth",
//                     "value": ""
//                 }
//             ],
//             "name": "New Configuration",
//             "inUse": false,
//             "count": 0,
//             "id": ""
//         }
//     ],
//       destConfigured: true,
//       showDest: true,
//       showOtherDestFields: true,
//       srcConfigured: false,
//       showSrc: true
//     }
//   },
//   isMyplatforms: 'Configuration',
//   selectedAccount: {
//     id: 'a18a458fbacce906',
//     userId: '410e97d7365801f6',
//     name: 'Adidas',
//     type: 'Publisher',
//     status: 'Completed',
//     source: 'Madconnect',
//     owner: 'johnsmith@adidas.com',
//     volume: 0,
//     connections: '3',
//     createdTime: 1692346702,
//     updatedTime: 1692681358,
//     enabled: true
//   }
// }

const initialState = {
	user: JSON?.parse(localStorage.getItem("USER")),
	isLoggedin: "none",
	token: null,
	userId: null,
	platfomrs: [],
	categories: [],
	allSelectedPlatforms: null,
	allConnectors: [],
	isHomeNav: true,
	isGetStarted: false,
	isPlatformContact: true,
	imageLoaded: false,
	userChoice: "Brand",
	userProfile: null,
	invoices: [],
	platformId: null,
	platformByIdConfigurations: null,
	platformConfigurations: null,
	isMyplatforms: "My Platform",
	selectedAccount: null,
	accountDetails: null,
	isAdminAgency: false,
};

export const madTechSlice = createSlice({
	name: "madTech",
	initialState,
	reducers: {
		setIsMyplatforms: (state, { payload }) => {
			state.isMyplatforms = payload;
		},
		setClearplatformConfigurations: (state, { payload }) => {
			state.platformConfigurations[payload.name] = state.platformConfigurations[
				payload.name
			].map((item) => ({ ...item, value: "" }));
			// state.platformConfigurations[payload.name][payload.id].value = ''
		},
		setisConfigurationFieldChange: (state, { payload }) => {
			state.platformConfigurations[payload.name][payload.id].value =
				payload.value;
		},
		setisConfigurationFieldChangeV1: (state, { payload }) => {
			state.platformConfigurations[payload.name][payload.id].listValue =
				payload.value;
		},
		setPlatformId: (state, { payload }) => {
			state.platformId = payload;
		},
		setPlatformConfigurations: (state, { payload }) => {
			state.platformConfigurations = payload;
		},
		resetPlatformConfigurations: (state) => {
			state.platformConfigurations = null;
		},
		setPlatformByIdConfigurations: (state, { payload }) => {
			state.platformByIdConfigurations = payload;
		},
		setInvoices: (state, { payload }) => {
			state.invoices = payload;
		},
		setImageLoaded: (state, { payload }) => {
			state.imageLoaded = payload;
		},
		setIsHomeNav: (state, { payload }) => {
			state.isHomeNav = !state.isHomeNav;
		},
		setUserProfile: (state, { payload }) => {
			state.userProfile = payload;
		},
		setUser: (state, { payload }) => {
			state.user = payload;
		},
		setToken: (state, { payload }) => {
			state.token = payload.token;
			state.userId = payload.userId;
		},
		setAllPlatfomrs: (state, { payload }) => {
			state.platfomrs = payload.platforms;
			state.categories = payload.categories;
		},
		setLoggedin: (state, { payload }) => {
			state.isLoggedin = payload;
		},
		setIsAdminAgencyUser: (state, { payload }) => {
			state.isAdminAgency = payload;
		},
		setAllConnectors: (state, { payload }) => {
			state.allConnectors = payload;
		},
		setSelectedPlatforms: (state, { payload }) => {
			state.allSelectedPlatforms = payload;
		},
		setIsGetStarted: (state, { payload }) => {
			state.isGetStarted = !state.isGetStarted;
		},
		setIsPlatformContact: (state, { payload }) => {
			state.isPlatformContact = payload;
		},
		setUserChoice: (state, { payload }) => {
			state.userChoice = payload;
		},
		setSelectedAccount: (state, { payload }) => {
			state.selectedAccount = payload;
		},
		setAccountDetails: (state, { payload }) => {
			state.accountDetails = payload;
		},
		addRequestedPlatform: (state, { payload }) => {
			if (
				state.allSelectedPlatforms?.some(
					(obj) => obj.id === payload?.id && obj.dataType !== payload?.dataType
				)
			) {
				state.allSelectedPlatforms = [
					...state.allSelectedPlatforms,
					...payload,
				];
			}
		},
		deleteRequestPlatform: (state, { payload }) => {
			state.allSelectedPlatforms = state.allSelectedPlatforms.filter(
				(el) => el.id !== payload
			);
		},
		deleteSelectedPlatform: (state, { payload }) => {
			state.allSelectedPlatforms = state.allSelectedPlatforms.filter(
				(el) => !(el.id === payload.id && el.dataTypeId === payload.dataTypeId)
			);
		},
		addConfigurationAction: (state, { payload }) => {
			// Assuming 'Event' is the dataType you want to target
			const { dataType, platformType } = payload;
			// You may adjust this according to the payload structure you receive
			if (platformType === "SOURCE") {
				state.platformConfigurations[dataType].srcPlatformConfigurations.push({
					id: null,
					name: "New Configuration",
					lastUpdated: 0,
					configuration: payload.srcPlatformConfigurations,
				});
			} else if (platformType === "DESTINATION") {
				state.platformConfigurations[dataType].destPlatformConfigurations.push({
					id: null,
					name: "New Configuration",
					lastUpdated: 0,
					configuration: payload.destPlatformConfigurations,
				});
			}

			// state.platformConfigurations[dataType].destPlatformConfigurations.configuration.push(payload.destPlatformConfigurations);
		},
		updateConfiguration: (state, { payload }) => {
			const { configuration } = payload;
			state.platformConfigurations = configuration;
		},
		deleteConfigurationAction: (state, { payload }) => {
			// Assuming 'Event' is the dataType you want to target
			const { dataType, platformType, index } = payload;
			// You may adjust this according to the payload structure you receive
			// const dataType = "event";
			if (platformType === "SOURCE") {
				state.platformConfigurations[dataType].srcPlatformConfigurations =
					state.platformConfigurations[
						dataType
					].srcPlatformConfigurations.filter((_, indexT) => indexT !== index);
			} else if (platformType === "DESTINATION") {
				state.platformConfigurations[dataType].destPlatformConfigurations =
					state.platformConfigurations[
						dataType
					].destPlatformConfigurations.filter((_, indexT) => indexT !== index);
			}

			// state.platformConfigurations[dataType].destPlatformConfigurations.configuration.push(payload.destPlatformConfigurations);
		},
	},
});

// Action creators are generated for each case reducer function
export const {
	setUser,
	setToken,
	setAllPlatfomrs,
	setLoggedin,
	setAllConnectors,
	setSelectedPlatforms,
	setIsHomeNav,
	setIsGetStarted,
	setIsPlatformContact,
	setImageLoaded,
	setUserChoice,
	setUserProfile,
	setInvoices,
	setPlatformConfigurations,
	resetPlatformConfigurations,
	setPlatformByIdConfigurations,
	setPlatformId,
	setisConfigurationFieldChange,
	setisConfigurationFieldChangeV1,
	setClearplatformConfigurations,
	setIsMyplatforms,
	isMyplatforms,
	platformId,
	setSelectedAccount,
	setAccountDetails,
	addRequestedPlatform,
	deleteRequestPlatform,
	deleteSelectedPlatform,
	addConfigurationAction,
	deleteConfigurationAction,
	updateConfiguration,
	setIsAdminAgencyUser,
} = madTechSlice.actions;
export const madTechState = (store) => store.madTechReducer;
export const getSrcPlatformConfigurations = (store) =>
	store.madTechReducer?.platformConfigurations?.srcPlatformConfigurations;
export const getDestPlatformConfigurations = (store) =>
	store.madTechReducer?.platformConfigurations?.destPlatformConfigurations;

export const getPanelType = (store) => {
	const userType = store.madTechReducer?.user?.userType;
	if (!userType) return PANEL.INVALID;
	if (userType === "platform" || userType === "cloud-provider")
		return PANEL.PLATFORM_USER;
	else if (userType === "madconnect") return PANEL.MADCONNECT_USER;
	else return PANEL.ADMIN;
};
export default madTechSlice.reducer;
