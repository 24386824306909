import { Box, useMediaQuery } from "@mui/material";
import React from "react";
import EastIcon from "@mui/icons-material/East";
import "react-lazy-load-image-component/src/effects/blur.css";
import { LazyLoadImage } from "react-lazy-load-image-component";

import madTechImg from "../../assets/MadTechLogo.webp";
import { color } from "../../utils/Colors";
import {
	AboutSuperContainer,
	MainContainer,
	Container,
	Heading,
	SubHeading,
	LearnMoreBox,
} from "./AboutMad.styles";
import { getFontSize } from "../../utils/utils";

const AboutMadTech = () => {
	const matches = {
		lg: useMediaQuery("(min-width:1100px)"),
		md: useMediaQuery("(min-width:980px)"),
		sm: useMediaQuery("(min-width:880px)"),
		xs: useMediaQuery("(min-width:760px)"),
		xss: useMediaQuery("(min-width:650px)"),
	};

	return (
		<AboutSuperContainer>
			<MainContainer>
				<Box>
					<LazyLoadImage
						alt={"MADCONNECT"}
						effect="blur"
						width={matches.xss ? "70%" : "100%"}
						height={"100%"}
						src={madTechImg}
					/>
				</Box>
				<Container
					matchesxss={matches.xss}
					px={
						matches.lg
							? 6
							: matches.md
							? 5
							: matches.sm
							? 4
							: matches.xs
							? 3
							: 2
					}
					py={
						matches.lg
							? 3
							: matches.md
							? 2
							: matches.sm
							? 2
							: matches.xs
							? 1
							: 1
					}
					borderRadius={2}
				>
					<Box display="flex" flexDirection="column">
						<Heading
							fontSize={getFontSize(matches, {
								lg: "2.2rem",
								md: "1.8rem",
								sm: "1.6rem",
								xs: "1.5rem",
								xss: "1.5rem",
							})}
						>
							About MadTech
						</Heading>
						{matches.lg && (
							<Box
								sx={{
									width: "20%",
									height: 3,
									backgroundColor: "#3843AC",
									mt: 1,
								}}
							/>
						)}
						<SubHeading
							fontSize={getFontSize(matches, {
								lg: "1rem",
								md: "1re8m",
								sm: "1rem",
								xs: "0.8rem",
								xss: "0.8rem",
							})}
							mt={matches.xs ? 1 : matches.xss ? 1 : 1}
						>
							MadTech is a product and data-focused consultancy specializing in
							AdTech and MarTech. Our team of business strategists, data
							specialists, product managers, and engineers combines strategy
							with hands-on development to help brands, agencies, publishers,
							and platforms maximize the value of their technology and data
							investments.
						</SubHeading>
						<Box
							sx={{
								cursor: "pointer",
								display: "flex",
								flexDirection: "row",
								mt: matches.lg ? 1 : 1,
								alignItems: "center",
							}}
							onClick={() => {
								// dispatch(setIsPlatformContact(true))
								// navigate('/learnmore')
								window.open("https://www.madtech.io");
								window.scrollTo({ behavior: "auto", top: 0 });
							}}
						>
							<LearnMoreBox>Learn more</LearnMoreBox>
							<EastIcon
								color={color.themeColor}
								sx={{ width: "1.5rem", height: "1.5rem", ml: "0.5rem" }}
							/>
						</Box>
					</Box>
				</Container>
			</MainContainer>
		</AboutSuperContainer>
	);
};

export default AboutMadTech;
