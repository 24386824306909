const SupportIcon = ({ variant, color }) => {
	return variant === "solid" ? (
		<svg
			width="21"
			height="21"
			viewBox="0 0 21 21"
			fill="none"
			xmlns="http://www.w3.org/2000/svg"
		>
			<path
				fill-rule="evenodd"
				clip-rule="evenodd"
				d="M1.96875 10.4027C1.97082 10.0205 2.12359 9.65444 2.3939 9.38413C2.66421 9.11383 3.03023 8.96105 3.4125 8.95898H5.33767C5.72041 8.95954 6.08731 9.11183 6.35795 9.38246C6.62858 9.6531 6.78087 10.02 6.78142 10.4027V14.0777C6.78184 14.2674 6.74478 14.4554 6.67237 14.6307C6.59996 14.8061 6.49363 14.9654 6.35949 15.0995C6.22534 15.2337 6.06602 15.34 5.89067 15.4124C5.71531 15.4848 5.52739 15.5219 5.33767 15.5215H4.2C2.9715 15.5215 1.96875 14.5187 1.96875 13.2902V10.4027Z"
				fill={color || "#25262D"}
			/>
			<path
				fill-rule="evenodd"
				clip-rule="evenodd"
				d="M10.5002 3.27148C9.78181 3.27086 9.07037 3.4119 8.40657 3.68652C7.74277 3.96114 7.13964 4.36395 6.63168 4.87191C6.12371 5.37987 5.7209 5.98301 5.44628 6.64681C5.17166 7.31061 5.03063 8.02205 5.03125 8.74041V9.62399C5.03125 9.79803 4.96211 9.96495 4.83904 10.088C4.71597 10.2111 4.54905 10.2802 4.375 10.2802C4.20095 10.2802 4.03403 10.2111 3.91096 10.088C3.78789 9.96495 3.71875 9.79803 3.71875 9.62399V8.74041C3.71875 4.99191 6.75168 1.95898 10.5002 1.95898C14.2487 1.95898 17.2816 4.99191 17.2816 8.74041V9.61559C17.2816 9.78963 17.2125 9.95655 17.0894 10.0796C16.9663 10.2027 16.7994 10.2718 16.6254 10.2718C16.4513 10.2718 16.2844 10.2027 16.1613 10.0796C16.0382 9.95655 15.9691 9.78963 15.9691 9.61559V8.74041C15.9691 5.71641 13.5242 3.27148 10.5002 3.27148ZM16.6668 14.2219C16.7514 14.2389 16.8317 14.2723 16.9034 14.3203C16.975 14.3683 17.0364 14.43 17.0842 14.5017C17.132 14.5735 17.1652 14.654 17.1818 14.7386C17.1985 14.8232 17.1983 14.9102 17.1813 14.9947C16.7183 17.299 14.6865 19.0304 12.25 19.0304H11.3754C11.2013 19.0304 11.0344 18.9613 10.9113 18.8382C10.7882 18.7151 10.7191 18.5482 10.7191 18.3742C10.7191 18.2001 10.7882 18.0332 10.9113 17.9101C11.0344 17.7871 11.2013 17.7179 11.3754 17.7179H12.25C13.1083 17.7179 13.9402 17.421 14.6045 16.8774C15.2688 16.3339 15.7246 15.5773 15.8946 14.7359C15.9289 14.5654 16.0295 14.4155 16.1743 14.3191C16.3191 14.2227 16.4963 14.1878 16.6668 14.2219Z"
				fill={color || "#25262D"}
			/>
			<path
				fill-rule="evenodd"
				clip-rule="evenodd"
				d="M14.2188 10.4027C14.2208 10.0205 14.3736 9.65444 14.6439 9.38414C14.9142 9.11383 15.2802 8.96105 15.6625 8.95898H17.5877C17.9704 8.95954 18.3373 9.11183 18.6079 9.38246C18.8786 9.6531 19.0309 10.02 19.0314 10.4027V13.2461C19.031 13.8494 18.7912 14.4278 18.3647 14.8544C17.9382 15.281 17.3598 15.5209 16.7566 15.5215H15.6625C15.2798 15.5209 14.9129 15.3686 14.6422 15.098C14.3716 14.8274 14.2193 14.4605 14.2188 14.0777V10.4027Z"
				fill={color || "#25262D"}
			/>
		</svg>
	) : (
		<svg
			width="21"
			height="21"
			viewBox="0 0 21 21"
			fill="none"
			xmlns="http://www.w3.org/2000/svg"
		>
			<path
				fill-rule="evenodd"
				clip-rule="evenodd"
				d="M3.3222 10.3119C3.30972 10.3236 3.29968 10.3376 3.29265 10.3532C3.28563 10.3688 3.28175 10.3856 3.28125 10.4027V13.2902C3.28125 13.7942 3.696 14.209 4.2 14.209H5.33767C5.41275 14.209 5.46892 14.1528 5.46892 14.0777V10.4027C5.46825 10.3681 5.45421 10.3351 5.42974 10.3107C5.40527 10.2862 5.37227 10.2722 5.33767 10.2715H3.4125C3.37824 10.2725 3.34577 10.287 3.3222 10.3119ZM1.96875 10.4027C1.97082 10.0205 2.12359 9.65444 2.3939 9.38413C2.66421 9.11383 3.03023 8.96105 3.4125 8.95898H5.33767C5.72041 8.95954 6.08731 9.11183 6.35795 9.38246C6.62858 9.6531 6.78087 10.02 6.78142 10.4027V14.0777C6.78184 14.2674 6.74478 14.4554 6.67237 14.6307C6.59996 14.8061 6.49363 14.9654 6.35949 15.0995C6.22534 15.2337 6.06602 15.34 5.89067 15.4124C5.71531 15.4848 5.52739 15.5219 5.33767 15.5215H4.2C2.9715 15.5215 1.96875 14.5187 1.96875 13.2902V10.4027Z"
				fill={color || "#25262D"}
			/>
			<path
				fill-rule="evenodd"
				clip-rule="evenodd"
				d="M10.5002 3.27148C9.78181 3.27086 9.07037 3.4119 8.40657 3.68652C7.74277 3.96114 7.13964 4.36395 6.63168 4.87191C6.12371 5.37987 5.7209 5.98301 5.44628 6.64681C5.17166 7.31061 5.03063 8.02205 5.03125 8.74041V9.62399C5.03125 9.79803 4.96211 9.96495 4.83904 10.088C4.71597 10.2111 4.54905 10.2802 4.375 10.2802C4.20095 10.2802 4.03403 10.2111 3.91096 10.088C3.78789 9.96495 3.71875 9.79803 3.71875 9.62399V8.74041C3.71875 4.99191 6.75168 1.95898 10.5002 1.95898C14.2487 1.95898 17.2816 4.99191 17.2816 8.74041V9.61559C17.2816 9.78963 17.2125 9.95655 17.0894 10.0796C16.9663 10.2027 16.7994 10.2718 16.6254 10.2718C16.4513 10.2718 16.2844 10.2027 16.1613 10.0796C16.0382 9.95655 15.9691 9.78963 15.9691 9.61559V8.74041C15.9691 5.71641 13.5242 3.27148 10.5002 3.27148ZM16.6668 14.2219C16.7514 14.2389 16.8317 14.2723 16.9034 14.3203C16.975 14.3683 17.0364 14.43 17.0842 14.5017C17.132 14.5735 17.1652 14.654 17.1818 14.7386C17.1985 14.8232 17.1983 14.9102 17.1813 14.9947C16.7183 17.299 14.6865 19.0304 12.25 19.0304H11.3754C11.2013 19.0304 11.0344 18.9613 10.9113 18.8382C10.7882 18.7151 10.7191 18.5482 10.7191 18.3742C10.7191 18.2001 10.7882 18.0332 10.9113 17.9101C11.0344 17.7871 11.2013 17.7179 11.3754 17.7179H12.25C13.1083 17.7179 13.9402 17.421 14.6045 16.8774C15.2688 16.3339 15.7246 15.5773 15.8946 14.7359C15.9289 14.5654 16.0295 14.4155 16.1743 14.3191C16.3191 14.2227 16.4963 14.1878 16.6668 14.2219Z"
				fill={color || "#25262D"}
			/>
			<path
				fill-rule="evenodd"
				clip-rule="evenodd"
				d="M15.5722 10.3119C15.5597 10.3236 15.5497 10.3376 15.5427 10.3532C15.5356 10.3688 15.5318 10.3856 15.5313 10.4027V14.0777C15.5313 14.1486 15.5922 14.209 15.6625 14.209H16.7566C17.2868 14.209 17.7189 13.7764 17.7189 13.2461V10.4027C17.7183 10.3681 17.7042 10.3351 17.6797 10.3107C17.6553 10.2862 17.6223 10.2722 17.5877 10.2715H15.6625C15.6282 10.2725 15.5958 10.287 15.5722 10.3119ZM14.2188 10.4027C14.2208 10.0205 14.3736 9.65444 14.6439 9.38413C14.9142 9.11383 15.2802 8.96105 15.6625 8.95898H17.5877C17.9704 8.95954 18.3373 9.11183 18.6079 9.38246C18.8786 9.6531 19.0309 10.02 19.0314 10.4027V13.2461C19.031 13.8494 18.7912 14.4278 18.3647 14.8544C17.9382 15.281 17.3598 15.5209 16.7566 15.5215H15.6625C15.2798 15.5209 14.9129 15.3686 14.6422 15.098C14.3716 14.8274 14.2193 14.4605 14.2188 14.0777V10.4027Z"
				fill={color || "#25262D"}
			/>
		</svg>
	);
};

export default SupportIcon;
