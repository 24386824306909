const SettingsIcon = ({ variant, color }) => {
	return variant === "solid" ? (
		<svg
			width="21"
			height="21"
			viewBox="0 0 21 21"
			fill="none"
			xmlns="http://www.w3.org/2000/svg"
		>
			<path
				d="M17.5836 8.06677C15.9998 8.06677 15.3523 6.94677 16.1398 5.57302C16.5948 4.77677 16.3236 3.76177 15.5273 3.30677L14.0136 2.44052C13.3223 2.02927 12.4298 2.27427 12.0186 2.96552L11.9223 3.13177C11.1348 4.50552 9.83984 4.50552 9.04359 3.13177L8.94734 2.96552C8.55359 2.27427 7.66109 2.02927 6.96984 2.44052L5.45609 3.30677C4.65984 3.76177 4.38859 4.78552 4.84359 5.58177C5.63984 6.94677 4.99234 8.06677 3.40859 8.06677C2.49859 8.06677 1.74609 8.81052 1.74609 9.72927V11.2693C1.74609 12.1793 2.48984 12.9318 3.40859 12.9318C4.99234 12.9318 5.63984 14.0518 4.84359 15.4255C4.38859 16.2218 4.65984 17.2368 5.45609 17.6918L6.96984 18.558C7.66109 18.9693 8.55359 18.7243 8.96484 18.033L9.06109 17.8668C9.84859 16.493 11.1436 16.493 11.9398 17.8668L12.0361 18.033C12.4473 18.7243 13.3398 18.9693 14.0311 18.558L15.5448 17.6918C16.3411 17.2368 16.6123 16.213 16.1573 15.4255C15.3611 14.0518 16.0086 12.9318 17.5923 12.9318C18.5023 12.9318 19.2548 12.188 19.2548 11.2693V9.72927C19.2461 8.81927 18.5023 8.06677 17.5836 8.06677ZM10.4961 13.343C8.92984 13.343 7.65234 12.0655 7.65234 10.4993C7.65234 8.93302 8.92984 7.65552 10.4961 7.65552C12.0623 7.65552 13.3398 8.93302 13.3398 10.4993C13.3398 12.0655 12.0623 13.343 10.4961 13.343Z"
				fill={color || "#25262D"}
			/>
		</svg>
	) : (
		<svg
			width="21"
			height="21"
			viewBox="0 0 21 21"
			fill="none"
			xmlns="http://www.w3.org/2000/svg"
		>
			<path
				d="M10.4961 13.125C11.9459 13.125 13.1211 11.9498 13.1211 10.5C13.1211 9.05021 11.9459 7.875 10.4961 7.875C9.04631 7.875 7.87109 9.05021 7.87109 10.5C7.87109 11.9498 9.04631 13.125 10.4961 13.125Z"
				stroke={color || "#25262D"}
				stroke-width="1.5"
				stroke-miterlimit="10"
				stroke-linecap="round"
				stroke-linejoin="round"
			/>
			<path
				d="M1.74609 11.2712V9.73119C1.74609 8.82119 2.48984 8.06873 3.40859 8.06873C4.99234 8.06873 5.63984 6.94873 4.84359 5.57498C4.38859 4.78748 4.65984 3.76373 5.45609 3.30873L6.96984 2.44248C7.66109 2.03123 8.55359 2.27623 8.96484 2.96748L9.06109 3.13373C9.84859 4.50748 11.1436 4.50748 11.9398 3.13373L12.0361 2.96748C12.4473 2.27623 13.3398 2.03123 14.0311 2.44248L15.5448 3.30873C16.3411 3.76373 16.6123 4.78748 16.1573 5.57498C15.3611 6.94873 16.0086 8.06873 17.5923 8.06873C18.5023 8.06873 19.2548 8.81244 19.2548 9.73119V11.2712C19.2548 12.1812 18.5111 12.9337 17.5923 12.9337C16.0086 12.9337 15.3611 14.0537 16.1573 15.4274C16.6123 16.2237 16.3411 17.2387 15.5448 17.6937L14.0311 18.5599C13.3398 18.9712 12.4473 18.7262 12.0361 18.0349L11.9398 17.8687C11.1523 16.4949 9.85734 16.4949 9.06109 17.8687L8.96484 18.0349C8.55359 18.7262 7.66109 18.9712 6.96984 18.5599L5.45609 17.6937C4.65984 17.2387 4.38859 16.2149 4.84359 15.4274C5.63984 14.0537 4.99234 12.9337 3.40859 12.9337C2.48984 12.9337 1.74609 12.1812 1.74609 11.2712Z"
				stroke={color || "#25262D"}
				stroke-width="1.5"
				stroke-miterlimit="10"
				stroke-linecap="round"
				stroke-linejoin="round"
			/>
		</svg>
	);
};

export default SettingsIcon;
