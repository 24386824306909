import React, { useCallback, useEffect, useMemo, useState } from "react";
import {
	Box,
	CircularProgress,
	Table,
	TableBody,
	TableContainer,
	TableRow,
	Typography,
	Paper,
} from "@mui/material";
import { useNavigate } from "react-router-dom";
import { DateRangePicker } from "react-dates";
import "react-dates/initialize";
import moment from "moment";
import momentTz from "moment-timezone";

import noConfigImg from "../../assets/Illustrations.svg";
import {
	useAnalyticsConnectionsMutation,
	useAnalyticsDataMutation,
	useAnalyticsDataSyncsMutation,
	useAnalyticsDataVolumeMutation,
	useDeleteConnectionMutation,
	useFilterAnalyticsMutation,
	useGetConnectionsQuery,
	useGetDashboardforParamsConnectionsMutation,
} from "../../services/connectionsQuery";
import upArrowImg from "../../assets/GreenArrowUp.svg";
import downArrowImg from "../../assets/RedArrowDown.svg";
import CommonDatePicker from "../../components/CommonDatePicker/CommonDatePicker";
import "./Dashboard.css";
import "react-dates/lib/css/_datepicker.css";
import "./react_dates_overrides.css";
import {
	ConnecionWrapper,
	NoDataAailable,
	NoDataAailableHeading,
	VolumnContainer,
	SectionHeading,
	Wrapper,
	LoaderWrapper,
	StyledTableCell,
	StickyTableHead,
	TableHeaderTypography,
	LinkTypograpy,
	TabStyled,
	TabHeading,
	TabsWrapper,
	LoaderWrapperIndividual,
} from "./Dashboard.styles";
import DashboardCard from "./DashboardCard";
import SBreadCrumb from "../../components/Setting/SBreadCrumb";
import {
	Chart as ChartJS,
	LineElement,
	BarElement,
	PointElement,
	LinearScale,
	CategoryScale,
	Tooltip,
	Legend,
	Filler,
	Title,
} from "chart.js";
import ChartDataLabels from "chartjs-plugin-datalabels";
import { Line, Bar } from "react-chartjs-2";
import { color } from "../../utils/Colors";
import ConnectionDataRow from "./ConnectionDataRow";
import { madTechState } from "../../features/madTechSlice";
import { useSelector } from "react-redux";
import { DashboardTabsHeading } from "../Connections/MyConnections/Constant";

const DashBoard = ({ setStep }) => {
	const navigate = useNavigate();
	const [connectors, setConnectors] = useState(null);
	const [timeLineTxt, setTimeLineTxt] = useState("Last 6 months");
	// individual loader added
	const [showIndividualLoader, setShowIndividualLoader] = useState(false)
	const [timemilliseconds, setTimeMilliseconds] = useState(180 * 24 * 60 * 60);
	const [hideAvg, setHideAvg] = useState(false);
	const [islogout, setIslogout] = useState(true);
	const [connections, setConnections] = useState(null);
	const [metricsData, setMetricsData] = useState(null);
	const [connectionsData, setConnectionsData] = useState(null);
	const [dataSyncsData, setDataSyncsData] = useState(null);
	const [dataVolumeData, setDataVolumeData] = useState(null);
	const [graphMaxValue, setGraphMaxValue] = useState(null);

	const [isCustom, setIsCustom] = useState(false);
	const [startDate, setStartDate] = useState(null);
	const [endDate, setEndDate] = useState(null);
	const [focusedInput, setFocusInput] = useState(null);
	const [activeTab, setActiveTab] = useState("Connections");
	const [visibleMonth, setVisibleMonth] = useState(moment());

	const [
		getAnalyticsData,
		{
			data: analyticsData,
			isLoading: analyticsDataLoading,
			error: analyticsDataError,
		},
	] = useAnalyticsDataMutation();
	const [
		getAnalyticsConnections,
		{
			data: analyticsConnections,
			isLoading: analyticsConnectionsLoading,
			error: analyticsConnectionsError,
		},
	] = useAnalyticsConnectionsMutation();
	const [
		getAnalyticsDataSyncs,
		{
			data: analyticsDataSyncs,
			isLoading: analyticsDataSyncsLoading,
			error: analyticsDataSyncsError,
		},
	] = useAnalyticsDataSyncsMutation();
	const [
		getAnalyticsDataVolume,
		{
			data: analyticsDataVolume,
			isLoading: analyticsDataVolumeLoading,
			error: analyticsDataVolumeError,
		},
	] = useAnalyticsDataVolumeMutation();
	const { selectedAccount } = useSelector(madTechState);

	const [
		getconnection,
		{
			data: connectionData,
			error: connectionError,
			isLoading: connectionLoading,
		},
	] = useGetDashboardforParamsConnectionsMutation();

	useEffect(() => {
		getconnection();
	}, []);

	useEffect(() => {
		if (dataVolumeData) {
			const connections = dataVolumeData?.flatMap((item) =>
				item?.connections?.map((connection) => connection?.name)
			);
			const uniqueConnections = [...new Set(connections)];
			setConnections(uniqueConnections);
		}
	}, [dataVolumeData]);

	const [
		deleteConnection,
		{ data: deleteConnectionData, error: deleteConnectionError },
	] = useDeleteConnectionMutation();

	const deleteConnector = useCallback(
		(id, idx) => {
			deleteConnection({
				id,
			});
			let newConnectors = [...connectors];
			newConnectors.splice(idx, 1);
			setConnectors(newConnectors);
			// setConnectors(connectors?.filter(item => item.id !== id))
		},
		[useDeleteConnectionMutation, connectors]
	);

	ChartJS.register(
		LineElement,
		PointElement,
		LinearScale,
		CategoryScale,
		Tooltip,
		Legend,
		Filler,
		BarElement,
		Title,
		ChartDataLabels
	);

	const lineColors = useMemo(
		() => [
			"#0E8929",
			"#800707",
			"#F96483",
			"#73BF16",
			"#538BF4",
			"#59C2D1",
			"#F1CB43",
			"#CB59D1",
			"#F1CB43",
			"#F9955F",
			"#3843AC",
		],
		[]
	);

	// Data for Data Volume Line Chart
	const dataVolumeChartData = useMemo(
		() => ({
			labels: dataVolumeData?.map((item) => item?.label), // X-axis labels
			datasets: connections?.slice(0, 10)?.map((conItem, index) => ({
				label: conItem,
				data: dataVolumeData?.map((item) => {
					const connection = item?.connections?.find(
						(con) => con?.name === conItem
					);
					return connection ? Math.round(connection?.volume) : 0;
				}),
				borderColor: lineColors[index],
				pointRadius: 4, // Reduced dot size
				pointHoverRadius: 6, // Slightly larger on hover
				pointBackgroundColor: "white", // White fill inside
				tension: 0.3, // Smooth curve
				borderWidth: 1,
			})),
		}),
		[dataVolumeData, connections, lineColors]
	);

	// Options for Data Volume Line Chart
	const dataVolumeChartOptions = useMemo(
		() => ({
			showScale: false,
			scaleShowLabels: false,
			scaleShowGridLines: false,
			responsive: true,
			datasetFill: true,
			maintainAspectRatio: false,
			showScale: false,
			plugins: {
				legend: {
					display: false,
				},
				datalabels: {
					display: false,
				},
				tooltip: {
					bodyFont: {
						size: 12,
						weight: 400,
						family: "Helvetica Neue",
					},
					titleFont: {
						size: 14,
						weight: 400,
						family: "Helvetica Neue",
					},
				},
			},
			scales: {
				x: {
					grid: {
						drawOnChartArea: false,
					},
					ticks: {
						font: {
							size: 14,
							weight: 400,
							family: "Helvetica Neue",
						},
					},
					border: {
						display: false,
					},
				},
				y: {
					beginAtZero: true,
					ticks: {
						precision: 0,
						stepSize: 1,
						callback: (value) => {
							return value.toLocaleString("en-US");
						},
						font: {
							size: 14,
							weight: 400,
							family: "Helvetica Neue",
						},
					},
					grid: {
						drawOnChartArea: true,
						color: "rgba(0, 0, 0, 0.1)",
						borderDash: [3, 3],
					},
					border: {
						display: false,
					},
					max: graphMaxValue?.volume,
				},
			},
		}),
		[graphMaxValue]
	);

	// Options for Data Transfers Bar Chart
	const dataTransfersChartOptions = useMemo(
		() => ({
			showScale: false,
			scaleShowLabels: false,
			scaleShowGridLines: false,
			responsive: true,
			datasetFill: true,
			maintainAspectRatio: false,
			showScale: false,
			plugins: {
				legend: {
					display: false,
				},
				datalabels: {
					color: "white",
					font: {
						size: 12,
						weight: 400,
						family: "Helvetica Neue",
					},
					formatter: (value) => (isNaN(value) || value === null ? "" : value),
					anchor: "center",
					align: "center",
				},
				tooltip: {
					bodyFont: {
						size: 12,
						weight: 400,
						family: "Helvetica Neue",
					},
					titleFont: {
						size: 14,
						weight: 400,
						family: "Helvetica Neue",
					},
				},
			},
			scales: {
				x: {
					grid: {
						drawOnChartArea: false,
					},
					ticks: {
						font: {
							size: 14,
							weight: 400,
							family: "Helvetica Neue",
						},
					},
					border: {
						display: false,
					},
				},
				y: {
					beginAtZero: true,
					ticks: {
						precision: 0,
						stepSize: 1,
						callback: function (value) {
							return value.toLocaleString("en-US");
						},
						font: {
							size: 14,
							weight: 400,
							family: "Helvetica Neue",
						},
					},
					grid: {
						drawOnChartArea: true,
						color: "rgba(0, 0, 0, 0.1)",
						borderDash: [3, 3],
					},
					border: {
						display: false,
					},
					max: graphMaxValue?.syncs,
				},
			},
		}),
		[graphMaxValue]
	);

	// Data for Data Transfers Bar Chart
	const dataTransfersChartData = useMemo(
		() => ({
			labels: dataSyncsData?.map((item) => item.label),
			datasets: [
				{
					label: "Data Transfers",
					data: dataSyncsData?.map((item) => Math.round(item.syncsVolume)),
					backgroundColor: color.themeColor,
					barPercentage:
						dataSyncsData?.length < 3
							? 0.2
							: dataSyncsData?.length < 5
							? 0.3
							: 0.5,
				},
			],
		}),
		[dataSyncsData]
	);
	// Options for Data Connections Bar Chart
	const dataConnectionsChartOptions = useMemo(
		() => ({
			showScale: false,
			scaleShowLabels: false,
			scaleShowGridLines: false,
			responsive: true,
			datasetFill: true,
			maintainAspectRatio: false,
			showScale: false,
			plugins: {
				legend: {
					display: false,
				},
				datalabels: {
					color: "white",
					font: {
						size: 12,
						weight: 400,
						family: "Helvetica Neue",
					},
					formatter: (value) => (isNaN(value) || value === null ? "" : value),
					anchor: "center",
					align: "center",
				},
				tooltip: {
					bodyFont: {
						size: 12,
						weight: 400,
						family: "Helvetica Neue",
					},
					titleFont: {
						size: 14,
						weight: 400,
						family: "Helvetica Neue",
					},
				},
			},
			scales: {
				y: {
					beginAtZero: true,
					ticks: {
						precision: 0,
						stepSize: 1,
						callback: function (value) {
							return value.toLocaleString("en-US");
						},
						font: {
							size: 14,
							weight: 400,
							family: "Helvetica Neue",
						},
					},
					stacked: true,
					grid: {
						drawOnChartArea: true,
						color: "rgba(0, 0, 0, 0.1)",
						borderDash: [3, 3],
					},
					border: {
						display: false,
					},
					max: graphMaxValue?.connections,
				},
				x: {
					stacked: true,
					grid: {
						drawOnChartArea: false,
					},
					ticks: {
						font: {
							size: 14,
							weight: 400,
							family: "Helvetica Neue",
						},
					},
					border: {
						display: false,
					},
				},
			},
		}),
		[graphMaxValue]
	);

	// Data for Data Connections Bar Chart
	const dataConnectionsChartData = useMemo(
		() => ({
			labels: connectionsData?.map((item) => item.label),
			datasets: [
				{
					label: "Active",
					data: connectionsData?.map((item) =>
						Math.round(item.activeConnections)
					),
					backgroundColor: color.barActiveColor,
					barPercentage:
						connectionsData?.length < 3
							? 0.2
							: connectionsData?.length < 5
							? 0.3
							: 0.5,
				},
				{
					label: "Idle",
					data: connectionsData?.map((item) =>
						Math.round(item.idelConnections)
					),
					backgroundColor: color.barIdleColor,
					barPercentage:
						connectionsData?.length < 3
							? 0.2
							: connectionsData?.length < 5
							? 0.3
							: 0.5,
				},
			],
		}),
		[connectionsData]
	);

	useEffect(() => {
		if (connectionData !== undefined && connectionData?.response) {
			// console.log('loogedin user connections', connectionData);
			const allConnections = connectionData?.response?.map((item) => {
				const source = {
					value: item?.src?.name,
					label: item?.src?.name,
					img: item?.src?.logo,
					id: item?.src?.id,
					description: item?.src?.description,
					dataType: item?.src?.dataType,
				};
				const destination = {
					value: item?.dest?.name,
					label: item?.dest?.name,
					img: item?.dest?.logo,
					id: item?.dest?.id,
					description: item?.dest?.description,
					dataType: item?.dest?.dataType,
				};
				return {
					id: item?.id,
					source,
					destination,
					name: item.name,
					conId: item.conId,
					usedVolume: item.usedVolume,
					enabled: item.enabled,
					status: item.status,
					deleted: item.deleted,
					dataType: item.dataType,
					monthlyVolume: item.monthlyVolume,
					totalVolume: item.totalVolume,
					syncs: item.syncs,
				};
			});
			setConnectors(allConnections);
		}

		if (connectionError !== undefined) {
			console.log("connectionError", connectionError);
			if (connectionError?.statusCode === 403) {
				navigate("signin");
			}
		}
	}, [connectionData, connectionError]);

	const getAnalytics = useCallback(() => {
		let sDate = parseInt(Date.now() / 1000 - timemilliseconds);
		let now = new Date();
		now.setDate(now.getDate() - 1); // Go to previous day
		now.setHours(23, 59, 59, 999); // Set time to 23:59:59

		let eDate = Math.floor(now.getTime() / 1000); // Convert to Unix timestamp in seconds
		const timezoneOffset =
			momentTz.tz.zone(momentTz.tz.guess()).utcOffset(new Date()) * 60;

		getAnalyticsData({
			endDate: eDate,
			startDate: sDate,
			status: timeLineTxt,
			offset: timezoneOffset,
		});
		getAnalyticsConnections({
			endDate: eDate,
			startDate: sDate,
			status: timeLineTxt,
			offset: timezoneOffset,
		});
		getAnalyticsDataSyncs({
			endDate: eDate,
			startDate: sDate,
			status: timeLineTxt,
			offset: timezoneOffset,
		});
		getAnalyticsDataVolume({
			endDate: eDate,
			startDate: sDate,
			status: timeLineTxt,
			offset: timezoneOffset,
		});
	}, [
		getAnalyticsData,
		getAnalyticsConnections,
		getAnalyticsDataSyncs,
		getAnalyticsDataVolume,
		timemilliseconds,
		timeLineTxt,
	]);

	const getAnalytics1 = useCallback(
		(startDate, endDate) => {
			const timezoneOffset =
				momentTz.tz.zone(momentTz.tz.guess()).utcOffset(new Date()) * 60;

			getAnalyticsData({
				endDate: parseInt(endDate / 1000),
				startDate: parseInt(startDate / 1000),
				status: timeLineTxt,
				offset: timezoneOffset,
			});
			getAnalyticsConnections({
				endDate: parseInt(endDate / 1000),
				startDate: parseInt(startDate / 1000),
				status: timeLineTxt,
				offset: timezoneOffset,
			});
			getAnalyticsDataSyncs({
				endDate: parseInt(endDate / 1000),
				startDate: parseInt(startDate / 1000),
				status: timeLineTxt,
				offset: timezoneOffset,
			});
			getAnalyticsDataVolume({
				endDate: parseInt(endDate / 1000),
				startDate: parseInt(startDate / 1000),
				status: timeLineTxt,
				offset: timezoneOffset,
			});
		},
		[
			getAnalyticsData,
			getAnalyticsConnections,
			getAnalyticsDataSyncs,
			getAnalyticsDataVolume,
			timemilliseconds,
			timeLineTxt,
		]
	);

	const handleChangeTab = (event, newValue) => {
		setActiveTab(newValue);
	};

	useEffect(() => {
		if (timemilliseconds) {
			getAnalytics();
		}
	}, [timemilliseconds]);

	useEffect(() => {
		if (!analyticsDataLoading && !analyticsDataVolumeLoading && !analyticsDataSyncsLoading && !analyticsConnectionsLoading) {
			setShowIndividualLoader(false)
		}
	}, [analyticsDataVolumeLoading, analyticsDataSyncsLoading, analyticsConnectionsLoading, analyticsDataLoading])

	useEffect(() => {
		if (
			analyticsData !== undefined &&
			analyticsData?.statusCode === 200 &&
			analyticsData?.response
		) {
			setMetricsData(analyticsData.response);
			setHideAvg(analyticsData.response.hideAvg);
		}
		if (analyticsDataError !== undefined) {
			console.log("analyticsDataError", analyticsDataError);
		}
	}, [analyticsData, analyticsDataError]);

	useEffect(() => {
		if (
			analyticsConnections !== undefined &&
			analyticsConnections?.statusCode === 200 &&
			analyticsConnections?.response
		) {
			setConnectionsData(analyticsConnections.response.graph);
			setGraphMaxValue((value) => ({
				...value,
				connections: analyticsConnections.response.max,
			}));
		}
		if (analyticsConnectionsError !== undefined) {
			console.log("analyticsConnectionsError", analyticsConnectionsError);
		}
	}, [analyticsConnections, analyticsConnectionsError]);

	useEffect(() => {
		if (
			analyticsDataSyncs !== undefined &&
			analyticsDataSyncs?.statusCode === 200 &&
			analyticsDataSyncs?.response
		) {
			setDataSyncsData(analyticsDataSyncs.response.graph);
			setGraphMaxValue((value) => ({
				...value,
				syncs: analyticsDataSyncs.response.max,
			}));
		}
		if (analyticsDataSyncsError !== undefined) {
			console.log("analyticsDataSyncsError", analyticsDataSyncsError);
		}
	}, [analyticsDataSyncs, analyticsDataSyncsError]);

	useEffect(() => {
		if (
			analyticsDataVolume !== undefined &&
			analyticsDataVolume?.statusCode === 200 &&
			analyticsDataVolume?.response
		) {
			setDataVolumeData(analyticsDataVolume?.response?.graph);
			setGraphMaxValue((value) => ({
				...value,
				volume: analyticsDataVolume?.response?.max,
			}));
		}
		if (analyticsDataVolumeError !== undefined) {
			console.log("analyticsDataVolumeError", analyticsDataVolumeError);
		}
	}, [analyticsDataVolume, analyticsDataVolumeError]);

	const showLoader = () => {
		return (
			<LoaderWrapper>
				<CircularProgress
					variant="indeterminate"
					disableShrink
					sx={{ color: color.loadingColor }}
					size={45}
					thickness={8}
				></CircularProgress>
			</LoaderWrapper>
		);
	};

	const showLoaderIndividual = () => {
		return (
			<LoaderWrapperIndividual>
				<CircularProgress
					variant="indeterminate"
					disableShrink
					sx={{ color: color.loadingColor }}
					size={45}
					thickness={8}
				></CircularProgress>
			</LoaderWrapperIndividual>
		);
	};

	return (connectors === null ||
		metricsData === null ||
		connectionsData === null ||
		dataSyncsData === null ||
		dataVolumeData === null ||
		analyticsDataLoading ||
		analyticsConnectionsLoading ||
		analyticsDataSyncsLoading ||
		analyticsDataVolumeLoading || connectionLoading) &&
		!showIndividualLoader
		? (
			showLoader()
		) : (
			<Box sx={{ px: "1.5rem" }}>
				{/* heading and date */}
				<Wrapper>
					<SBreadCrumb mainPathName="Dashboard" mainPath="dashboard" />
					{isCustom && (
						<Box
							sx={{
								display: "flex",
								flexDirection: "column",
								alignItems: "end",
								flex: "auto",
								mr: "1rem",
							}}
						>
							<DateRangePicker
								startDate={startDate} // momentPropTypes.momentObj or null,
								startDateId="startDate" // PropTypes.string.isRequired,
								endDate={endDate} // momentPropTypes.momentObj or null,
								endDateId="endDate" // PropTypes.string.isRequired,
								numberOfMonths={1}
								// isOutsideRange={() => false}
								isOutsideRange={(day) => {
									return moment()?.diff(day) < 0;
								}}
								onDatesChange={({ startDate, endDate }) => {
									startDate?.set({
										hour: 0,
										minute: 0,
										second: 0,
										millisecond: 0,
									});
									endDate?.set({
										hour: 0,
										minute: 0,
										second: 0,
										millisecond: 0,
									});
									setStartDate(startDate);
									setEndDate(endDate);
									if (startDate !== null && endDate !== null) {
										getAnalytics1(startDate, endDate);
									}
								}} // PropTypes.func.isRequired,
								focusedInput={focusedInput} // PropTypes.oneOf([START_DATE, END_DATE]) or null,
								onFocusChange={(focusedInput) => {
									setFocusInput(focusedInput);
	
									// Dynamically update visible month when switching focus
									if (focusedInput === "startDate" && startDate) {
										setVisibleMonth(startDate);
									} else if (focusedInput === "endDate" && endDate) {
										setVisibleMonth(endDate);
									}
								}}
								initialVisibleMonth={() => visibleMonth}
							/>
						</Box>
					)}

					<CommonDatePicker
						timeLineTxt={timeLineTxt}
						setTimeLineTxt={setTimeLineTxt}
						setTimeMilliseconds={setTimeMilliseconds}
						getAnalytics={getAnalytics}
						setIsCustom={setIsCustom}
						setShowIndividualLoader={setShowIndividualLoader}
					/>
				</Wrapper>
				{/* connection,Average daily volume ..... container  */}
				{showIndividualLoader && (analyticsDataLoading ||
					analyticsConnectionsLoading ||
					analyticsDataSyncsLoading ||
					analyticsDataVolumeLoading) ? showLoaderIndividual() :
					<>
						<DashboardCard
							analyticsData={metricsData}
							timeLinTxt={timeLineTxt}
							upArrowImg={upArrowImg}
							downArrowImg={downArrowImg}
							hideAvg={hideAvg}
						/>
						{/* Volumn container */}
						<VolumnContainer>
							<TabsWrapper
								value={activeTab}
								onChange={handleChangeTab}
								sx={{
									border: "1px solid rgba(234, 237, 246, 1)",
									borderRadius: "8px",
									marginBottom: "0.5rem",
									width: "fit-content",
								}}
							>
								{DashboardTabsHeading?.map((heading, index) => (
									<TabStyled
										key={heading}
										value={heading}
										label={
											<div style={{ display: "flex", gap: "10px" }}>
												<TabHeading>{heading}</TabHeading>
											</div>
										}
										condition={index < DashboardTabsHeading?.length - 1}
									/>
								))}
							</TabsWrapper>

							{activeTab === "Connections" ? (
								connectionsData?.length === 0 ? (
									<NoDataAailable>
										<Box
											sx={{
												display: "flex",
												flexDirection: "column",
												justifyContent: "center",
												alignItems: "center",
											}}
										>
											<Box sx={{ width: "11.875rem", height: "11.875rem" }}>
												<img
													src={noConfigImg}
													style={{ maxWidth: "100%", maxHeight: "100%" }}
												/>
											</Box>
											<NoDataAailableHeading>
												No data available!
											</NoDataAailableHeading>
										</Box>
									</NoDataAailable>
								) : (
									<Box width="100%" height="100%">
										<div style={{ width: "100%", height: "100%" }}>
											<Bar
												options={dataConnectionsChartOptions}
												data={dataConnectionsChartData}
											/>
										</div>
									</Box>
								)
							) : null}
							{activeTab === "Data Transfers" ? (
								dataSyncsData?.length === 0 ? (
									<NoDataAailable>
										<Box
											sx={{
												display: "flex",
												flexDirection: "column",
												justifyContent: "center",
												alignItems: "center",
											}}
										>
											<Box sx={{ width: "11.875rem", height: "11.875rem" }}>
												<img
													src={noConfigImg}
													style={{ maxWidth: "100%", maxHeight: "100%" }}
												/>
											</Box>
											<NoDataAailableHeading>
												No data available!
											</NoDataAailableHeading>
										</Box>
									</NoDataAailable>
								) : (
									<Box width="100%" height="100%">
										<div style={{ width: "100%", height: "100%" }}>
											<Bar
												options={dataTransfersChartOptions}
												data={dataTransfersChartData}
											/>
										</div>
									</Box>
								)
							) : null}
							{activeTab === "Data Volume" ? (
								dataVolumeData?.length === 0 ? (
									<NoDataAailable>
										<Box
											sx={{
												display: "flex",
												flexDirection: "column",
												justifyContent: "center",
												alignItems: "center",
											}}
										>
											<Box sx={{ width: "11.875rem", height: "11.875rem" }}>
												<img
													src={noConfigImg}
													style={{ maxWidth: "100%", maxHeight: "100%" }}
												/>
											</Box>
											<NoDataAailableHeading>
												No data available!
											</NoDataAailableHeading>
										</Box>
									</NoDataAailable>
								) : (
									<Box width="100%" height="100%">
										<div style={{ width: "100%", height: "100%" }}>
											<Line
												data={dataVolumeChartData}
												options={dataVolumeChartOptions}
											/>
										</div>
									</Box>
								)
							) : null}
						</VolumnContainer>
					</>
				}
				{/* Connecion container */}
				<ConnecionWrapper setStep={setStep}>
					<Box
						sx={{
							display: "flex",
							flexDirection: "row",
							justifyContent: "space-between",
							width: "100%",
							alignItems: "center",
							mx: "auto",
							mt: "0.5rem",
							mb: "1.5rem",
						}}
					>
						<SectionHeading>Connections</SectionHeading>
					</Box>
					{
						connectors?.length > 0 ? (
							<>
								<Paper
									sx={{
										width: "100%",
										height: "100%",
										display: "flex",
										flexDirection: "column",
										overflow: "hidden",
										marginTop: "1rem",
										borderRadius: "12px",
									}}
								>
									<TableContainer sx={{ overflowY: "auto" }}>
										<Table>
											<StickyTableHead
												sx={{
													"& .MuiTableCell-root:first-of-type": {
														borderTopLeftRadius: "12px",
														borderBottomLeftRadius: "12px",
													},
													"& .MuiTableCell-root:last-child": {
														borderTopRightRadius: "12px",
														borderBottomRightRadius: "12px",
													},
												}}
											>
												<TableRow>
													<StyledTableCell>
														<TableHeaderTypography>Name</TableHeaderTypography>
													</StyledTableCell>
													<StyledTableCell>
														<TableHeaderTypography>Source</TableHeaderTypography>
													</StyledTableCell>
													<StyledTableCell>
														<TableHeaderTypography>
															Destination
														</TableHeaderTypography>
													</StyledTableCell>
													<StyledTableCell>
														<TableHeaderTypography>
															Data Type
														</TableHeaderTypography>
													</StyledTableCell>
													<StyledTableCell sx={{ textAlign: "right" }}>
														<TableHeaderTypography>
															Data Transfers
														</TableHeaderTypography>
													</StyledTableCell>
													<StyledTableCell sx={{ textAlign: "right" }}>
														<TableHeaderTypography>
															Avg. Monthly Volume
														</TableHeaderTypography>
													</StyledTableCell>
													<StyledTableCell sx={{ textAlign: "right" }}>
														<TableHeaderTypography>
															Total Volume
														</TableHeaderTypography>
													</StyledTableCell>
													<StyledTableCell>
														<TableHeaderTypography>Status</TableHeaderTypography>
													</StyledTableCell>
												</TableRow>
											</StickyTableHead>
											<TableBody>
												{connectors.slice(0, 10)?.map((item, idx) => (
													<ConnectionDataRow
														key={idx}
														data={item}
														deleteConnector={deleteConnector}
													/>
												))}
											</TableBody>
										</Table>
									</TableContainer>
								</Paper>
								{connectors?.length > 0 && (
									<LinkTypograpy
										onClick={() => {
											navigate(
												selectedAccount
													? "/reports?accountId=" + selectedAccount.id
													: "/reports"
											);
										}}
									>
										Show all connections
									</LinkTypograpy>
								)}
							</>
						) : (
							<Box
								display={"flex"}
								justifyContent={"center"}
								alignItems={"center"}
								paddingBottom={4}
							>
								<Box
									sx={{
										display: "flex",
										flexDirection: "column",
										justifyContent: "center",
										alignItems: "center",
										marginRight: "2rem",
									}}
								>
									<Box sx={{ width: "11.875rem", height: "11.875rem" }}>
										<img
											src={noConfigImg}
											style={{ maxWidth: "100%", maxHeight: "100%" }}
										/>
									</Box>
									<Typography
										sx={{
											color: "#25262D",
											textAlign: "center",
											fontSize: "1.125rem",
											fontWeight: 700,
											fontFamily: "Helvetica Neue",
										}}
									>
										No connections available!
									</Typography>
								</Box>
							</Box>
						)

						// <Box sx={{
						//   display: "flex", flexDirection: "column", justifyContent: "center",
						//   alignItems: "center", px: "1.5rem", width: setStep !== undefined ? '85%' : "100%",
						//   height: 130, mx: "auto",
						//   // border: '1px solid black'
						// }}>
						//   <img alt='' src={noConnections} style={{ width: 250, height: 106 }}></img>
						// </Box>
					}
				</ConnecionWrapper>
			</Box>
		);
};

export default DashBoard;
