import { Box, TableRow, Tooltip, Typography } from "@mui/material";
import { StyledTableCell, TableCellTypography } from "./index.styles";
import { useCallback, useMemo, useState } from "react";
import SmallDot from "../../assets/SmallDot";
import {
	capitalizeWords,
	formatRows,
	formatTimestamp,
	formatWithCommas,
} from "../../utils/utils";
import InfoJsonModal from "./InfoJsonModal";

const ReportDataRow = ({ row }) => {
	const [infoOpen, setInfoOpen] = useState(false);

	const statusColorMap = useMemo(
		() => ({
			success: "#21D457",
			done: "#21D457",
			completed: "#21D457",
			failure: "#DF1907",
			failed: "#DF1907",
			in_progress: "#FA9F0E",
			"in-progress": "#FA9F0E",
			initialization: "#538BF4",
		}),
		[]
	);

	const getStatus = useCallback(
		(status) => {
			const color = statusColorMap?.[status];
			return (
				<Box
					sx={{
						color,
						width: "fit-content",
						display: "flex",
						alignItems: "center",
						gap: "8px",
					}}
				>
					<SmallDot color={color} />
					<Typography
						sx={{
							fontWeight: "500",
							fontSize: "0.875rem",
							color,
							fontFamily: "Helvetica Neue",
						}}
					>
						{capitalizeWords(status)}
					</Typography>
				</Box>
			);
		},
		[statusColorMap]
	);

	return (
		<TableRow key={row.batchId}>
			<StyledTableCell sx={{ textAlign: "left" }}>
				<TableCellTypography nested>
					{formatTimestamp(row.startTime)}
				</TableCellTypography>
			</StyledTableCell>
			{/* <StyledTableCell>
				<TableCellTypography nested>Sync</TableCellTypography>
			</StyledTableCell> */}
			<StyledTableCell sx={{ textAlign: "left" }}>
				<TableCellTypography nested>{row.batchId}</TableCellTypography>
			</StyledTableCell>
			<StyledTableCell sx={{ textAlign: "left" }}>
				{getStatus(row.status)}
			</StyledTableCell>
			<StyledTableCell sx={{ textAlign: "left" }}>
				<TableCellTypography nested>
					{formatTimestamp(row.endTime)}
				</TableCellTypography>
			</StyledTableCell>
			<StyledTableCell sx={{ textAlign: "right" }}>
				<Tooltip
					title={formatWithCommas(row.totalRowsProcessed)}
					placement="bottom"
				>
					<span
						style={{
							fontWeight: "500",
							fontSize: "0.875rem",
							color: "#66696A",
							fontFamily: "Helvetica Neue",
						}}
					>
						{formatRows(row.totalRowsProcessed)}
					</span>
				</Tooltip>
			</StyledTableCell>
			<StyledTableCell sx={{ textAlign: "right" }}>
				<Tooltip title={formatWithCommas(row.deliveredRows)} placement="bottom">
					<span
						style={{
							fontWeight: "500",
							fontSize: "0.875rem",
							color: "#66696A",
							fontFamily: "Helvetica Neue",
						}}
					>
						{formatRows(row.deliveredRows)}
					</span>
				</Tooltip>
			</StyledTableCell>
			<StyledTableCell sx={{ textAlign: "right" }}>
				<Tooltip title={formatWithCommas(row.failedRows)} placement="bottom">
					<span
						style={{
							fontWeight: "500",
							fontSize: "0.875rem",
							color: "#66696A",
							fontFamily: "Helvetica Neue",
						}}
					>
						{formatRows(row.failedRows)}
					</span>
				</Tooltip>
			</StyledTableCell>
			<StyledTableCell sx={{ textAlign: "center" }}>
				{row.info ? (
					<Typography
						component="span"
						onClick={(e) => {
							setInfoOpen(true);
							e.stopPropagation();
						}}
						sx={{
							cursor: "pointer",
							color: "#538BF4",
							fontSize: "14px",
							"&:hover": {
								textDecoration: "underline"
							},
							display: "inline-flex",
							alignItems: "center",
							gap: "4px"
						}}
					>
						view
					</Typography>
				) : (
					"-"
				)}
			</StyledTableCell>

			{row.info && (
				<InfoJsonModal infoOpen={infoOpen} setInfoOpen={setInfoOpen} infoData={row.info} />
			)}
		</TableRow>
	);
};

export default ReportDataRow;
