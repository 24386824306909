const DashboardIcon = ({ variant, color }) => {
	return variant === "solid" ? (
		<svg
			width="21"
			height="21"
			viewBox="0 0 21 21"
			fill="none"
			xmlns="http://www.w3.org/2000/svg"
		>
			<path
				d="M18.8125 9.5375V3.5875C18.8125 2.275 18.2525 1.75 16.8613 1.75H13.3262C11.935 1.75 11.375 2.275 11.375 3.5875V9.5375C11.375 10.85 11.935 11.375 13.3262 11.375H16.8613C18.2525 11.375 18.8125 10.85 18.8125 9.5375Z"
				fill={color || "#25262D"}
			/>
			<path
				d="M9.625 11.4625V17.4125C9.625 18.725 9.065 19.25 7.67375 19.25H4.13875C2.7475 19.25 2.1875 18.725 2.1875 17.4125V11.4625C2.1875 10.15 2.7475 9.625 4.13875 9.625H7.67375C9.065 9.625 9.625 10.15 9.625 11.4625Z"
				fill={color || "#25262D"}
			/>
			<path
				d="M18.8125 17.4125V14.9625C18.8125 13.65 18.2525 13.125 16.8613 13.125H13.3262C11.935 13.125 11.375 13.65 11.375 14.9625V17.4125C11.375 18.725 11.935 19.25 13.3262 19.25H16.8613C18.2525 19.25 18.8125 18.725 18.8125 17.4125Z"
				fill={color || "#25262D"}
			/>
			<path
				d="M9.625 6.0375V3.5875C9.625 2.275 9.065 1.75 7.67375 1.75H4.13875C2.7475 1.75 2.1875 2.275 2.1875 3.5875V6.0375C2.1875 7.35 2.7475 7.875 4.13875 7.875H7.67375C9.065 7.875 9.625 7.35 9.625 6.0375Z"
				fill={color || "#25262D"}
			/>
		</svg>
	) : (
		<svg
			width="21"
			height="21"
			viewBox="0 0 21 21"
			fill="none"
			xmlns="http://www.w3.org/2000/svg"
		>
			<path
				d="M19.25 9.5375V3.5875C19.25 2.275 18.69 1.75 17.2988 1.75H13.7637C12.3725 1.75 11.8125 2.275 11.8125 3.5875V9.5375C11.8125 10.85 12.3725 11.375 13.7637 11.375H17.2988C18.69 11.375 19.25 10.85 19.25 9.5375Z"
				stroke={color || "#25262D"}
				stroke-width="1.5"
				stroke-linecap="round"
				stroke-linejoin="round"
			/>
			<path
				d="M19.25 17.4125V15.8375C19.25 14.525 18.69 14 17.2988 14H13.7637C12.3725 14 11.8125 14.525 11.8125 15.8375V17.4125C11.8125 18.725 12.3725 19.25 13.7637 19.25H17.2988C18.69 19.25 19.25 18.725 19.25 17.4125Z"
				stroke={color || "#25262D"}
				stroke-width="1.5"
				stroke-linecap="round"
				stroke-linejoin="round"
			/>
			<path
				d="M9.1875 11.4625V17.4125C9.1875 18.725 8.6275 19.25 7.23625 19.25H3.70125C2.31 19.25 1.75 18.725 1.75 17.4125V11.4625C1.75 10.15 2.31 9.625 3.70125 9.625H7.23625C8.6275 9.625 9.1875 10.15 9.1875 11.4625Z"
				stroke={color || "#25262D"}
				stroke-width="1.5"
				stroke-linecap="round"
				stroke-linejoin="round"
			/>
			<path
				d="M9.1875 3.5875V5.1625C9.1875 6.475 8.6275 7 7.23625 7H3.70125C2.31 7 1.75 6.475 1.75 5.1625V3.5875C1.75 2.275 2.31 1.75 3.70125 1.75H7.23625C8.6275 1.75 9.1875 2.275 9.1875 3.5875Z"
				stroke={color || "#25262D"}
				stroke-width="1.5"
				stroke-linecap="round"
				stroke-linejoin="round"
			/>
		</svg>
	);
};

export default DashboardIcon;
