import { Box, Typography } from "@mui/material";
import styled from "styled-components";

import { color } from "../../utils/Colors";

export const LeftImage = styled("img")({
	position: "absolute",
	top: 32,
	width: "13.44rem",
	height: "2.81rem",
	left: "15%",
	cursor: "pointer",
});

export const SignInContainer = styled("div")({
	backgroundColor: "#fff",
	borderRadius: 8,
	boxShadow: "0px 8px 20px rgba(0, 0, 0, 0.06)",
	minWidth: (props) => (props.matchesxsss ? "400" : "200"),
	padding: (props) => (props.matchesxsss ? "50" : "30"),
});

export const ErrorContainer = styled(Box)({
	display: "flex",
	textAlign: "center",
	justifyContent: "center",
});

export const LogInTextBox = styled(Typography)({
	color: "red",
	textAlign: "center",
	height: "1.25rem",
});

export const LoginHeading = styled("p")({
	fontSize: (props) => `${props.TextFontSize} !important`,
	fontWeight: "700 !important",
});

export const LoginPara = styled("p")({
	fontSize: (props) => `${props.ContentFontSize} !important`,
});

export const IsRememberLabel = styled("label")({
	marginTop: "0.125rem !important",
	color: "#666666 !important",
	fontSize: "0.875rem !important",
	fontFamily: "Helvetica Neue",
	fontWeight: 400,
});

export const ForgetPassword = styled("a")({
	cursor: "pointer",
	color: "#193241",
	fontWeight: 400,
	fontSize: "0.875rem",
	fontFamily: "Helvetica Neue  !important",
	textDecoration: "none",
});

export const ContactUsLink = styled("a")({
	cursor: "pointer",
	color: "#193241",
	fontWeight: "500 !important",
	marginLeft: "0.25rem",
	fontFamily: "Helvetica Neue !important",
	textDecoration: "none",
});

export const LoginSigninContainer = styled("div")({
	display: "flex",
	flex: 1,
	background: "#fff",
	flexDirection: "column",
	justifyContent: "center",
	minHeight: "100%",
	minHeight: "100vh",
	background: "#193241",
});

export const LeftContainer = styled("div")({
	color: "#fff",
	padding: (props) => (props.matchesxsss ? "2rem 4rem" : "0px 0px"),
});

export const WelcomeToHeading = styled("p")({
	textAlign: "center",
	fontFamily: "Phosphate",
	fontSize: "4.38rem",
	fontWeight: 400,
	letterSpacing: "0.3rem",
});

export const DontHaveAcc = styled("p")({
	color: "#999999",
	fontFamily: "Helvetica Neue !important",
});

export const FAverificationWrapper = styled("div")({
	backgroundColor: "#fff",
	borderRadius: "8px",
	boxShadow: "0px 8px 20px rgba(0, 0, 0, 0.06)",
});

export const LoginErrorMsg = styled(Typography)({
	color: "red",
	textAlign: "center",
	height: "1.25rem",
});

export const FAverificationReceive = styled("p")({
	fontSize: "0.875rem!important ",
	fontFamily: "Helvetica Neue !important",
	color: "#666666",
});

export const FAverificationResend = styled("a")({
	cursor: "pointer",
	fontWeight: "500 !important",
	marginLeft: "0.25rem !important",
	fontSize: "0.875rem !important",
	fontFamily: "Helvetica Neue !important",
	color: color.themeColor,
	textDecoration: "none",
});
