import { Box, Card, CardContent, Grid, Typography } from '@mui/material'
import React from 'react'
import NaLayout from '../../components/NavLayout/NaLayout'
import madConnectLogo from "../../assets/madconnectdark.png";
import DangerousIcon from '@mui/icons-material/Dangerous';
import { Navigate } from 'react-router-dom';

const FailCard = () => { 
  return (
    <Box>
      <NaLayout />
      <Grid
        container
        justifyContent="center"
        alignItems="center"
        sx={{ minHeight: '60vh', bgcolor: '' }}
      >
        <Grid
          item
          md={5}
          lg={5}
          sm={12}
          xs={12}
          sx={{ display: 'flex', justifyContent: 'center', alignItems: 'center' }}
        >
          <Card
            sx={{
              maxWidth: '100%',
              boxShadow: 'lg',
              borderRadius: "10px",
              padding: "10px",
              width: "500px"
            }}
          >
            <div style={{ textAlign: "center", padding: "20px" }}>
              <img
                style={{ width: "auto", height: "5rem", justifyContent: 'center' }}
                alt={"MADCONNECT LOGO"}
                src={madConnectLogo}
              ></img>

            </div>
            <div style={{ textAlign: "center", padding: "5px" }}>
              <DangerousIcon sx={{ color: "red", justifyContent: "center", width: "50px", height: "50px" }} />
            </div>
            <CardContent sx={{ alignItems: 'center', textAlign: 'center', justifyContent: "center" }}>
              <Typography level="body-sm" sx={{ fontSize: "1rem", fontFamily: "Helvetica Neue", color: "#999999" }}>
                Authorization failed.
              </Typography>
            </CardContent>
          </Card>
        </Grid>
      </Grid>
    </Box>
  )
}

export default FailCard
