const ResourcesIcon = ({ variant, color }) => {
	return variant === "solid" ? (
		<svg
			width="21"
			height="21"
			viewBox="0 0 21 21"
			fill="none"
			xmlns="http://www.w3.org/2000/svg"
		>
			<path
				d="M13.1257 19.9052C12.8894 19.9052 12.6707 19.774 12.5569 19.5727C12.4432 19.3627 12.4432 19.1177 12.5657 18.9077L13.4844 17.3765C13.6682 17.0615 14.0794 16.9652 14.3857 17.149C14.7007 17.3327 14.7969 17.7352 14.6132 18.0502L14.3769 18.444C16.7919 17.8752 18.6032 15.7052 18.6032 13.1152C18.6032 12.7565 18.9007 12.459 19.2594 12.459C19.6182 12.459 19.9157 12.7565 19.9157 13.1152C19.9069 16.8602 16.8619 19.9052 13.1257 19.9052Z"
				fill={color || "#25262D"}
			/>
			<path
				d="M1.75 8.53125C1.39125 8.53125 1.09375 8.23375 1.09375 7.875C1.09375 4.13875 4.13875 1.09375 7.875 1.09375C8.11125 1.09375 8.33 1.225 8.44375 1.42625C8.5575 1.63625 8.5575 1.88125 8.435 2.09125L7.51625 3.6225C7.3325 3.92875 6.93 4.03375 6.615 3.84125C6.30875 3.6575 6.20375 3.255 6.39625 2.94L6.6325 2.54625C4.20875 3.115 2.40625 5.285 2.40625 7.875C2.40625 8.23375 2.10875 8.53125 1.75 8.53125Z"
				fill={color || "#25262D"}
			/>
			<path
				d="M12.95 11.0501V13.6226C12.95 15.7663 12.0925 16.6238 9.94875 16.6238H7.37625C5.2325 16.6238 4.375 15.7663 4.375 13.6226V11.0501C4.375 8.90631 5.2325 8.04883 7.37625 8.04883H9.94875C12.0925 8.04883 12.95 8.90631 12.95 11.0501Z"
				fill={color || "#25262D"}
			/>
			<path
				d="M13.6245 4.375H11.052C8.94329 4.375 8.08582 5.215 8.05957 7.28H9.94954C12.522 7.28 13.712 8.47875 13.712 11.0425V12.9325C15.7858 12.9062 16.617 12.0487 16.617 9.94V7.37625C16.6258 5.2325 15.7683 4.375 13.6245 4.375Z"
				fill={color || "#25262D"}
			/>
		</svg>
	) : (
		<svg
			width="21"
			height="21"
			viewBox="0 0 21 21"
			fill="none"
			xmlns="http://www.w3.org/2000/svg"
		>
			<g clip-path="url(#clip0_6145_3138)">
				<path
					d="M12.9823 11.053V13.6589C12.9823 15.8353 12.1136 16.7039 9.93726 16.7039H7.3409C5.17408 16.7039 4.2959 15.8353 4.2959 13.6589V11.053C4.2959 8.88621 5.16453 8.01758 7.3409 8.01758H9.94681C12.1136 8.01758 12.9823 8.88621 12.9823 11.053Z"
					stroke={color || "#25262D"}
					stroke-width="1.5"
					stroke-linecap="round"
					stroke-linejoin="round"
				/>
				<path
					d="M16.7049 7.33038V9.93629C16.7049 12.1126 15.8363 12.9813 13.6599 12.9813H12.9822V11.0531C12.9822 8.88629 12.1136 8.01765 9.9372 8.01765H8.01855V7.33038C8.01855 5.15401 8.88719 4.29492 11.0636 4.29492H13.6695C15.8363 4.29492 16.7049 5.16356 16.7049 7.33038Z"
					stroke={color || "#25262D"}
					stroke-width="1.5"
					stroke-linecap="round"
					stroke-linejoin="round"
				/>
				<path
					d="M20.0451 13.3633C20.0451 17.0574 17.0574 20.0451 13.3633 20.0451L14.3656 18.3746"
					stroke={color || "#25262D"}
					stroke-width="1.5"
					stroke-linecap="round"
					stroke-linejoin="round"
				/>
				<path
					d="M0.954102 7.6369C0.954102 3.94281 3.94183 0.955078 7.63592 0.955078L6.63365 2.62553"
					stroke={color || "#25262D"}
					stroke-width="1.5"
					stroke-linecap="round"
					stroke-linejoin="round"
				/>
			</g>
			<defs>
				<clipPath id="clip0_6145_3138">
					<rect width="21" height="21" fill="white" />
				</clipPath>
			</defs>
		</svg>
	);
};

export default ResourcesIcon;
