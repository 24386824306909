import React from "react";
import { useMediaQuery } from "@mui/material";

import {
	BenefitsBoxConatiner,
	DeliversEfficiencyConatinerHeading,
	BenefitsBoxConatinerSubHeading,
	BenifitLowerContainer,
	BenifitSuperContainer,
	BenifitUpperContainer,
	BenifitsHeading,
} from "./Home.styles";
import { getFontSize } from "../../utils/utils";

const upperObj = [
	{
		heading: "Supports Key Use Cases",
		description:
			"Bi-directional connectivity for audience activation, measurement, and reporting, facilitates UID 2.0s",
	},
	{
		heading: "Built for Scale",
		description: "Rapid integration of new connectors (within 1-2 weeks)",
	},
	{
		heading: "Faster, Up-to-Date Integrations",
		description:
			"No engineering required, we manage maintenance so integrations never go stale",
	},
];

const lowerObj = [
	{
		heading: "Privacy-First Design",
		description:
			"Ensures data safety with end-to-end encryption, SOC-2 compliance and ephemeral data storage practices",
	},
	{
		heading: "Neutral",
		description: "Works with entire ecosystem without competing with platforms",
	},
	{
		heading: "Flexible Deployment",
		description: "Snowflake native app, embedded, self-serve",
	},
];

const DeliversEfficiency = () => {
	const matches = {
		lg: useMediaQuery("(min-width:1100px)"),
		md: useMediaQuery("(min-width:980px)"),
		sm: useMediaQuery("(min-width:880px)"),
		xs: useMediaQuery("(min-width:760px)"),
		xss: useMediaQuery("(min-width:650px)"),
	};

	return (
		<BenifitSuperContainer width="100%" mb={10}>
			<BenifitSuperContainer flexDirection="column" width="91%" maxWidth={1350}>
				<BenifitsHeading
					fontSize={getFontSize(matches, {
						lg: "2.5rem",
						md: "2.2rem",
						sm: "1.9rem",
						xs: "1.7rem",
						xss: "1.7rem",
					})}
				>
					MadConnect Delivers Efficiency
				</BenifitsHeading>
				<BenifitUpperContainer matchesxss={matches.xss}>
					{upperObj?.map((details, index) => {
						return (
							<BenefitsBoxConatiner
								matchesxss={matches.xss}
								width={matches.xss ? "25%" : "100%"}
								key={index}
							>
								<DeliversEfficiencyConatinerHeading
									fontSize={getFontSize(matches, {
										lg: "1.5rem",
										md: "1.3rem",
										sm: "1rem",
										xs: "1rem",
										xss: "1rem",
									})}
								>
									{details.heading}
								</DeliversEfficiencyConatinerHeading>
								<BenefitsBoxConatinerSubHeading
									width={index >= 1 ? "95%" : "85%"}
								>
									{details.description}
								</BenefitsBoxConatinerSubHeading>
							</BenefitsBoxConatiner>
						);
					})}
				</BenifitUpperContainer>
				<BenifitLowerContainer matchesxss={matches.xss}>
					{lowerObj?.map((details, index) => {
						return (
							<BenefitsBoxConatiner
								matchesxss={matches.xss}
								width={matches.xss ? "25%" : "100%"}
								key={index}
							>
								<DeliversEfficiencyConatinerHeading
									fontSize={getFontSize(matches, {
										lg: "1.5rem",
										md: "1.3rem",
										sm: "1rem",
										xs: "1rem",
										xss: "1rem",
									})}
								>
									{details.heading}
								</DeliversEfficiencyConatinerHeading>
								<BenefitsBoxConatinerSubHeading
									width={index >= 1 ? "95%" : "85%"}
								>
									{details.description}
								</BenefitsBoxConatinerSubHeading>
							</BenefitsBoxConatiner>
						);
					})}
				</BenifitLowerContainer>
			</BenifitSuperContainer>
		</BenifitSuperContainer>
	);
};

export default DeliversEfficiency;
