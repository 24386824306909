import {
	Box,
	FormControl,
	InputLabel,
	OutlinedInput,
	TableCell,
	TableRow,
	Typography,
} from "@mui/material";
import styled from "styled-components";

import { color } from "../../utils/Colors";
import PhoneInput from "react-phone-input-2";

export const HeadingWrapper = styled(Box)({
	display: "flex",
	justifyContent: "space-between",
	marginBottom: "16px",
	alignItems: "flex-start",
});

export const BodyWrapper = styled(Box)({
	display: "flex",
	flexDirection: "column",
	justifyContent: "space-between",
	width: "100%",
	margin: "0px auto",
});

export const SUserTableHeader = styled(TableCell)(({ theme }) => {
	return {
		fontWeight: "500 !important",
		fontSize: "1rem !important",
		fontFamily: "Helvetica Neue !important",
		color: `${color.tableHeaderTextColor} !important`,
		background: `${color.tableHeaderColor} !important`,
	};
});

export const SUserTableCell = styled(TableCell)(({ theme }) => {
	return {
		fontSize: "0.875rem !important",
		fontWeight: "500 !important",
		fontFamily: "Helvetica Neue !important",
		color: `${color.tableCellTextColor} !important`,
		padding: "0.8125rem 1rem 0.8125rem 1rem !important",
	};
});

export const TableBodyRowStyle = styled(TableRow)({
	"&:last-child td, &:last-child th": { border: "0 !important" },
	"& .MuiTableCell-root": {
		borderBottom: "1px solid #EEF3F8",
	},
});

export const TableUserName = styled(Typography)({
	fontSize: "0.875rem !important",
	fontWeight: "500 !important",
	fontFamily: "Helvetica Neue !important",
	textDecoration: "underline !important",
	color: `${color.tableCellTextColor} !important`,
	cursor: "pointer !important",
	textAlign: "left",
});

export const TableUserId = styled(Typography)({
	fontSize: "0.875rem !important",
	fontWeight: "400 !important",
	fontFamily: "Helvetica Neue !important",
	color: "#989CAA !important",
	textAlign: "left",
});

export const EditImg = styled(Box)({
	width: 17,
	height: 17,
	marginBottom: "5px",
	marginRight: "20px",
	cursor: "pointer",
});

export const DeleteImg = styled(Box)({
	width: "1.25rem",
	height: "1.25rem",
	marginBottom: "5px",
	cursor: "pointer",
});

export const NoUserAvailable = styled(Typography)({
	color: "#25262D !important",
	textAlign: "center !important",
	fontSize: "1rem !important",
	fontWeight: "700 !important",
	fontFamily: "Helvetica Neue !important",
});

export const AddUserFromWrapper = styled(Box)({
	width: "100%",
	marginTop: "16px",
	border: "1px solid #E4E4E4",
	padding: "24px",
	display: "flex",
	flexDirection: "column",
	backgroundColor: "#fff",
});

export const AddUserFromHeading = styled(Typography)({
	fontSize: "1.125rem !important",
	fontFamily: "Helvetica Neue !important",
	fontWeight: "500 !important",
	letterSpacing: "0.2px !important",
	marginBottom: "16px !important",
});

export const AddUserFieldWrapper = styled(Box)({
	display: "flex",
	marginTop: 0,
	width: "100%",
	justifyContent: "space-between",
});

export const InputsWrapper = styled(FormControl)({
	width: (props) => (props?.width ? props.width : "45%"),
	height: "2.75rem",
	fontSize: "1rem",
	color: "#666666",
	backgroundColor: "#FFFFFF",
	fontFamily: "Helvetica Neue",
	marginTop: (props) => props.mt && `${props.mt} !important`,
});

export const InputLabelWrapper = styled(InputLabel)({
	fontSize: "0.875rem !important",
	marginTop: "0.25rem !important",
	opacity: 0.5,
	backgroundColor: "#FFFFFF !important",
	fontFamily: "Helvetica Neue !important",
});

export const InputFieldWrapper = styled(OutlinedInput)({
	width: "100% !important",
	height: "2.75rem !important",
	fontSize: "1rem !important",
	color: "#666666 !important",
	backgroundColor: "#FFFFFF !important",
	fontFamily: "Helvetica Neue !important",
});

export const PhoneInputWrapper = styled(PhoneInput)({
	width: "100% !important",
	height: "2.75rem !important",
	fontSize: "0.875rem !important",
	color: "#666666 !important",
	backgroundColor: "#FFFFFF !important",
	fontFamily: "Helvetica Neue !important",
});
