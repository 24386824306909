import React from 'react';
import PropTypes from 'prop-types';
import { Button, CircularProgress, Tooltip } from '@mui/material';

import { color } from '../../utils/Colors';

function CommonButton(props) {
  return (
    <div style={{ position: 'relative' }}>
      {props.showTooltip ? (
        <Tooltip title={props.tooltipTitle} placement={props.tooltipPosition}>
          <Button
            type={props.type}
            disabled={props.disabled || props.isLoading}
            onClick={props.onClick}
            style={{
              border: props.hasBorder || "",
              backgroundColor: props.color,
              width: props.width || "",
              height: props.height || "38px",
              color: props.invert ? color.buttonColor : "white",
              textTransform: 'none',
              alignItems: "center",
              padding: "0",
              margin: "0",
              gap: "5px",
              borderRadius: props.borderRadius,
            }}
            variant="contained"
            startIcon={props.startIcon && <>{props.startIcon}</>}
          >
            {props.icon ? props.icon : null}
            <label
              style={{
                fontSize: "1rem",
                cursor: "pointer",
                marginLeft: props.icon ? "8px" : "0",
                letterSpacing: "normal",
                ...props.textStyle,
              }}
            >
              {props.title || 'Title'}
            </label>
          </Button>
        </Tooltip>
      ) : (
        <Button
          type={props.type}
          disabled={props.disabled || props.isLoading}
          onClick={props.onClick}
          style={{
            border: props.hasBorder || "",
            backgroundColor: props.disabled ? "grey" : props.color,
            width: props.width || "",
            height: props.height || "38px",
            color: props.invert ? color.buttonColor : "white",
            textTransform: 'none',
            alignItems: "center",
            padding: "0",
            margin: "0",
            gap: "5px",
            borderRadius: props.borderRadius,
          }}
          variant="contained"
          startIcon={props.startIcon && <>{props.startIcon}</>}
        >
          {props.icon ? props.icon : null}
          <label
            style={{
              fontSize: "1rem",
              cursor: "pointer",
              marginLeft: props.icon ? "8px" : "0",
              letterSpacing: "normal",
              ...props.textStyle,
            }}
          >
            {props.title || 'Title'}
          </label>
        </Button>
      )}
      {props.isLoading && (
        <div
          style={{
            background: '#E2E4F3',
            display: 'flex',
            height: props.height ? props.height : '38px',
            justifyContent: 'center',
            alignItems: 'center',
            position: 'absolute',
            top: 0,
            width: props.width ? props.width : '',
          }}
        >
          <CircularProgress variant="indeterminate" disableShrink sx={{ color: color.loadingColor }} size={25} thickness={8}></CircularProgress>
        </div>
      )}
    </div>
  );
}

CommonButton.propTypes = {
  disabled: PropTypes.bool,
  title: PropTypes.string.isRequired,
  onClick: PropTypes.func.isRequired,
  color: PropTypes.string.isRequired,
  height: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
  width: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
  invert: PropTypes.bool,
  hasBorder: PropTypes.bool,
  isLoading: PropTypes.bool,
  icon: PropTypes.element,
  textStyle: PropTypes.object,
  showTooltip: PropTypes.bool,
  tooltipTitle: PropTypes.string,
  tooltipPosition: PropTypes.string,
  type: PropTypes.string, 
};

export default CommonButton;