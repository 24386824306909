import React from "react";
import { Box } from "@mui/material";

import {
	ConnectorFilterData,
	ConnectorFilterListContainer,
	ConnectorFilterListSubContainer,
	ConnectorFilterListSubContainerData,
	ConnectorFilterListSubContainerHeading,
	Container,
	Divider,
	FilterBy,
	Heading,
	Resetfilters,
} from "./Connectors.styles";
import { useNavigate } from "react-router-dom";

const ConnectoreFilter = ({
	allCategories,
	setSelectedCategories,
	selectedCategories,
	setShowDoc,
	setLoader,
	setFilteredPlatforms,
	setSearchValue,
}) => {
	const navigate = useNavigate();
	const handledResetFilter = () => {
		setShowDoc(false);
		setLoader(false);
		navigate("/connector");
		setSelectedCategories([]);
		setFilteredPlatforms(null);
		setSearchValue("");
	};

	const handleSelectFilter = (catItem) => {
		setShowDoc(false);
		setLoader(false);
		navigate("/connector");
		if (selectedCategories?.length === 0) {
			setSelectedCategories(catItem?.subCategories?.map((item) => item.name));
		} else if (selectedCategories?.length > 0) {
			setSelectedCategories(catItem?.subCategories?.map((item) => item.name));
		} else {
			setSelectedCategories([]);
		}
	};

	const handleSelectDetailFilter = (subCat) => {
		setShowDoc(false);
		setLoader(false);
		navigate("/connector");
		if (!selectedCategories?.includes(subCat.id)) {
			setSelectedCategories((prev) => [...prev, subCat.id]);
			// setSelectedCategories([...selectedCategories, subCat.id])
		} else {
			setSelectedCategories((prev) =>
				prev?.filter((scItem) => scItem !== subCat.id)
			);
		}
	};

	return (
		<Container>
			<Heading>
				<FilterBy className="heading">Filter by</FilterBy>
				<Resetfilters className="heading" onClick={handledResetFilter}>
					Reset filters
				</Resetfilters>
			</Heading>
			<Box px={2}>
				<Divider />
			</Box>
			<ConnectorFilterListContainer
				overflowY={allCategories?.length > 4 ? "scroll" : "none"}
			>
				{allCategories?.map((catItem, idx) => (
					<ConnectorFilterListSubContainer key={idx}>
						<ConnectorFilterListSubContainerHeading
							className="heading"
							fontWeight={500}
							color={`rgba(18, 18, 18, 0.9`}
						>
							{catItem.name}
						</ConnectorFilterListSubContainerHeading>
						<ConnectorFilterListSubContainerData>
							{catItem.subCategories.map((subCat, index) => (
								<ConnectorFilterData
									key={index}
									fontWeight={
										selectedCategories?.includes(subCat.id) ? 700 : 400
									}
									onClick={() => handleSelectDetailFilter(subCat)}
								>
									{subCat.name}
								</ConnectorFilterData>
							))}
						</ConnectorFilterListSubContainerData>
					</ConnectorFilterListSubContainer>
				))}
			</ConnectorFilterListContainer>
		</Container>
	);
};

export default ConnectoreFilter;
