import React, { useEffect, useState } from "react";
import { Box, CircularProgress } from "@mui/material";
import { useNavigate } from "react-router-dom";

import {
	Conatiner,
	ConnectionContainer,
	LoaderWrapper,
	Wrapper,
} from "./Styles/MyConnection.styles";
import { useGetConnectionsQuery } from "../../../services/connectionsQuery";
import Header from "./Header";
import CreateConnection from "./CreateConnection";
import ConnectList from "./ConnectionList/ConnectList";
import { filteredData } from "./Constant";
import useDebounceValue from "../../../hook/useDebounceValue";
import { color } from "../../../utils/Colors";
import SBreadCrumb from "../../../components/Setting/SBreadCrumb";

const MyConnections = ({ connectors, setConnectors }) => {
	const navigate = useNavigate();

	const {
		data: connectionData,
		isLoading: connectionLoading,
		error: connectionError,
		isFetching,
		refetch,
	} = useGetConnectionsQuery();
	const [activeTab, setActiveTab] = useState("In-progress");
	const [inputValue, setInputValue] = useState("");
	const [finalData, setFinalData] = useState(null);

	const debounceSearchValue = useDebounceValue(inputValue, 1000);

	useEffect(() => {
		refetch();
	}, []);

	useEffect(() => {
		if (connectionLoading || isFetching) {
			setFinalData(null);
			setConnectors(null);
		} else if (
			connectionData !== undefined &&
			connectionData.response.length !== 0
		) {
			const allConnections = connectionData?.response?.map((item) => ({
				id: item?.id,
				src: item?.src,
				dest: item?.dest,
				usedVolume: item?.usedVolume,
				enabled: item?.enabled,
				status: item?.status,
				deleted: item?.deleted,
				conId: item?.conId,
				name: item?.name,
				editable: item?.editable,
				env: item?.env,
				transferButton: item?.transferButton,
				transferUrl: item?.transferUrl,
			}));
			setFinalData(allConnections);
		} else {
			setFinalData([]);
			console.log("No connection data available to process");
		}

		if (connectionError && connectionError?.statusCode === 403) {
			navigate("/signin");
		}
	}, [connectionData, connectionError, connectionLoading, isFetching]);

	useEffect(() => {
		if (debounceSearchValue === "") {
			setConnectors(finalData);
		} else {
			const filteredItems = filteredData(finalData, debounceSearchValue);
			setConnectors(filteredItems);
		}
	}, [debounceSearchValue, finalData]);

	const showLoader = () => {
		return (
			<LoaderWrapper>
				<CircularProgress
					variant="indeterminate"
					disableShrink
					sx={{ color: color.loadingColor }}
					size={45}
					thickness={8}
				></CircularProgress>
			</LoaderWrapper>
		);
	};

	return connectionLoading || isFetching || connectors === null ? (
		showLoader()
	) : (
		<Wrapper>
			<Box
				sx={{
					display: "flex",
					justifyContent: "space-between",
					alignItems: "flex-start",
				}}
			>
				<SBreadCrumb mainPathName="My Connections" mainPath="connections" />
				<Header
					data={connectionData?.response}
					inputValue={inputValue}
					setInputValue={setInputValue}
				/>
			</Box>
			<ConnectionContainer>
				{connectors?.length === 0 ? (
					<CreateConnection />
				) : (
					<ConnectList
						connectors={connectors}
						connectionLoading={connectionLoading}
						refetch={refetch}
						activeTab={activeTab}
						setActiveTab={setActiveTab}
						connectionLoader={connectionLoading || isFetching}
					/>
				)}
			</ConnectionContainer>
		</Wrapper>
	);
};

export default MyConnections;
