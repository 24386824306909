import React, { useEffect, useMemo, useState } from "react";
import {
	Box,
	CircularProgress,
	Skeleton,
	FormControlLabel,
	Checkbox,
	Typography,
} from "@mui/material";

import {
	ConnectionListRightActivateBtn,
	ConnectionListRightHeading,
	ConnectionListRightTestBtn,
	ConnectionListRightTestingtHeading,
	Heading,
	RightContainer,
	RightContainerHeader,
	RightContainerHeaderTitle,
} from "../Styles/MyConnection.styles";
import {
	usePostConditionConfirmationMutation,
	usePostTestConnectionMutation,
	usePutActivateConnectionMutation,
} from "../../../../services/query";
import { useMyContext } from "../../../../components/CommanToasterContext/toast";
import { color } from "../../../../utils/Colors";

const mapCheckboxStateToData = (checkboxState) => {
	return Object.keys(checkboxState)?.map((key) => ({
		key: key,
		value: checkboxState[key],
	}));
};

const ConnectionListRight = ({
	activeTab,
	conditionLoading,
	selectedConnection,
	checkBoxLists,
	connectionLoading,
	refetch,
	connections,
	destinationCheckbox,
	setDestinationCheckbox,
	sourceCheckbox,
	setSourceCheckbox,
	setActiveTab,
}) => {
	const { setOpen2, setMessage2, setOpen, setMessage } = useMyContext();

	const [
		addConditionCheck,
		{ data: addconditionData, error: addconditionError },
	] = usePostConditionConfirmationMutation();

	const [
		testNowfunction,
		{ data: testNowData, isLoading: testLoading, error: testNowError },
	] = usePostTestConnectionMutation();

	const [
		activatefunction,
		{ data: activateData, isLoading: activateLoading, error: activateError },
	] = usePutActivateConnectionMutation();

	const [isDisabledOffline, setIsDisabledOffline] = useState(true);
	const [isDisabled, setIsDisabled] = useState(true);
	const [isTestCompleted, setIsTestCompleted] = useState(false);
	const loadingCondition = useMemo(
		() =>
			checkBoxLists === null ||
			selectedConnection === null ||
			conditionLoading ||
			sourceCheckbox === null ||
			destinationCheckbox === null,
		[
			checkBoxLists,
			selectedConnection,
			conditionLoading,
			sourceCheckbox,
			destinationCheckbox,
		]
	);

	useEffect(() => {
		if (checkBoxLists?.destination || checkBoxLists?.source) {
			checkBoxLists?.destination.length === 0
				? setDestinationCheckbox([])
				: checkBoxLists?.destination?.map((ele) => {
						setDestinationCheckbox((prev) => ({
							...prev,
							[ele.key]: ele.value,
						}));
				  });
			checkBoxLists?.source.length === 0
				? setSourceCheckbox([])
				: checkBoxLists?.source?.map((ele) => {
						setSourceCheckbox((prev) => ({ ...prev, [ele.key]: ele.value }));
				  });
		}
	}, [checkBoxLists]);

	useEffect(() => {
		if (addconditionData !== undefined) {
			if (addconditionData?.statusCode === 200) {
				// setMessage(addconditionData.statusMessage);
				// setOpen(true);
			} else {
				setMessage2(addconditionData?.statusMessage);
				setOpen2(true);
			}
		}

		if (addconditionError !== undefined) {
			setMessage2(addconditionData?.statusMessage);
			setOpen2(true);
			console.log("Add Connection Error", addconditionError);
		}
	}, [addconditionData, addconditionError]);

	useEffect(() => {
		if (testNowData !== undefined) {
			if (testNowData?.statusCode === 200) {
				setIsTestCompleted(true);
				setMessage(testNowData?.statusMessage);
				setOpen(true);
			} else {
				setMessage2(testNowData?.statusMessage);
				setOpen2(true);
			}
		}
		if (testNowError !== undefined) {
			console.log("Test Connection Error", testNowError);
		}
	}, [testNowData, testNowError]);

	useEffect(() => {
		if (activateData !== undefined) {
			if (activateData?.statusCode === 200) {
				setMessage(activateData?.statusMessage);
				setOpen(true);
				refetch();
				setIsDisabled(true);
				setActiveTab("Active");
			} else {
				setMessage2(activateData?.statusMessage);
				setOpen2(true);
			}
		}
		if (activateError !== undefined) {
			console.log("Activate Connection Error", activateError);
		}
	}, [activateData, activateError]);

	const handleSourceCheckboxChange = (event) => {
		const updatedCheckItems = {
			...sourceCheckbox,
			[event.target.name]: event.target.checked,
		};

		setSourceCheckbox(updatedCheckItems);
		const data = {
			destination: mapCheckboxStateToData(destinationCheckbox),
			source: mapCheckboxStateToData(updatedCheckItems),
		};
		addConditionCheck({
			connectionId: selectedConnection.conId,
			connectionPayload: data,
		});
	};

	const handleDestinationCheckboxChange = (event) => {
		const updatedCheckItems = {
			...destinationCheckbox,
			[event.target.name]: event.target.checked,
		};

		setDestinationCheckbox(updatedCheckItems);

		const data = {
			destination: mapCheckboxStateToData(updatedCheckItems),
			source: mapCheckboxStateToData(sourceCheckbox),
		};
		addConditionCheck({
			connectionId: selectedConnection.conId,
			connectionPayload: data,
		});
	};

	const HandleActivate = () => {
		const data = {
			id: selectedConnection.conId,
		};
		activatefunction({ data: data });
	};

	const HandleTestNow = () => {
		if (testLoading) {
		}
		testNowfunction({ connectionId: selectedConnection.conId });
	};

	const allChecked = (checkItems) => Object.values(checkItems).every(Boolean);
	const allCheckedOverall = useMemo(
		() =>
			sourceCheckbox && destinationCheckbox
				? allChecked(sourceCheckbox) && allChecked(destinationCheckbox)
				: null,
		[sourceCheckbox, destinationCheckbox]
	);

	return (
		<>
			{activeTab === "In-progress" && (
				<Box
					width="42.2%"
					display="flex"
					borderLeft="1px solid rgba(234, 237, 246, 1)"
				>
					{activeTab === "In-progress" &&
						connections?.["In-progress"]?.length !== 0 && (
							<Box padding="1rem" width="100%">
								<RightContainer>
									{loadingCondition ? (
										<Skeleton
											variant="rounded"
											width={150}
											height={35}
											sx={{ mb: -1 }}
										/>
									) : selectedConnection ? (
										<Box>
											Activation Prerequisites for {selectedConnection.name}
										</Box>
									) : null}
								</RightContainer>
								<Box display="flex">
									<Box width="50%" display="block">
										{loadingCondition ? (
											<Skeleton variant="rounded" width={"50%"} height={25} />
										) : (
											<Heading>
												{selectedConnection?.src?.name}
											</Heading>
										)}

										{loadingCondition ? (
											<Skeleton
												variant="rounded"
												sx={{ mt: 1 }}
												width={"85%"}
												height={190}
											/>
										) : (
											<Box>
												{checkBoxLists?.source?.length !== 0 ? checkBoxLists?.source?.map((item) => (
													<FormControlLabel
														key={item.key}
														disabled={testLoading}
														control={
															<Checkbox
																checked={sourceCheckbox[item.key] || false}
																onChange={handleSourceCheckboxChange}
																name={item.key}
																sx={{ paddingTop: "0px" }}
															/>
														}
														className="CheckboxStype"
														label={item.key}
														sx={{
															width: "100%",
															display: "flex",
															flexDirection: "row",
															alignItems: "flex-start",
															paddingTop: "9px",
														}}
													/>
												)) : <Typography variant="body2" sx={{ color: "#646566", fontSize: "12px", paddingTop: "9px" }}>
													None
												</Typography>}
											</Box>
										)}
									</Box>

									<Box width="50%">
										{loadingCondition ? (
											<Skeleton variant="rounded" width={"50%"} height={25} />
										) : (
											<Heading>
												{selectedConnection?.dest?.name}
											</Heading>
										)}

										{loadingCondition ? (
											<Skeleton
												variant="rounded"
												sx={{ mt: 1 }}
												width={"85%"}
												height={190}
											/>
										) : (
											<Box>
												{checkBoxLists?.destination?.length !== 0 ? checkBoxLists?.destination?.map((item) => (
													<FormControlLabel
														key={item.key}
														disabled={testLoading}
														control={
															<Checkbox
																checked={destinationCheckbox[item.key] || false}
																onChange={handleDestinationCheckboxChange}
																name={item.key}
																sx={{ paddingTop: "0px" }}
															/>
														}
														className="CheckboxStype"
														label={item.key}
														sx={{
															width: "100%",
															display: "flex",
															flexDirection: "row",
															alignItems: "flex-start",
															paddingTop: "9px",
														}}
													/>
												)) : <Typography variant="body2" sx={{ color: "#646566", fontSize: "12px", paddingTop: "9px" }}>
													None
												</Typography>}
											</Box>
										)}
									</Box>
								</Box>
								{/* <Box display="flex" mt={2}>
                <Box width="90%" display="block">
                  {!conditionLoading ? (
                    <ConnectionListRightTestingtHeading width="90%">
                      {testLoading ? "Testing steps" : "Testing Procedure"}
                    </ConnectionListRightTestingtHeading>
                  ) : (
                    <Skeleton
                      variant="rounded"
                      width={"30%"}
                      height={25}
                      sx={{ mt: 1 }}
                    />
                  )}

                  {!conditionLoading ? (
                    <Typography className="TestText" sx={{ width: "90%" }}>
                      For push based connectors, typically audience connectors,
                      initiate test by transfering a small audiece file from the
                      source platform. In case of pull based connectors that are
                      scheduled, typically events and report connectors,
                      MadConnect will issue a pull request and make a sample
                      data available in the destination location. Please contact
                      your MadConnect account manager if you have any queries.
                    </Typography>
                  ) : (
                    <Skeleton
                      variant="rounded"
                      width="100%"
                      height={45}
                      sx={{ mt: 1 }}
                    />
                  )}

                  {!conditionLoading ? (
                    <>
                      {testLoading && (
                        <ConnectionListRightTestingtHeading width="90%">
                          Conection is in test mode
                        </ConnectionListRightTestingtHeading>
                      )}
                      <ConnectionListRightTestBtn
                        loading={testLoading}
                        variant="contained"
                        size="large"
                        disabled={!allCheckedOverall}
                        sx={{
                          mt: 2,
                        }}
                        onClick={HandleTestNow}
                        isDisable={!allCheckedOverall}
                      >
                        {!testLoading ? "Test Now" : "Cancel Test"}
                      </ConnectionListRightTestBtn>
                    </>
                  ) : (
                    <Skeleton
                      variant="rounded"
                      width={112}
                      height={40}
                      sx={{ mt: 2 }}
                    />
                  )}
                </Box>
              </Box> */}
								<Box>
									{/* <Box width="100%">
                  <ConnectionListRightTestingtHeading
                    sx={{
                      marginLeft: 0,
                    }}
                  >
                    {!conditionLoading ? (
                      "Test Result"
                    ) : (
                      <Skeleton variant="rounded" width={100} height={25} />
                    )}
                  </ConnectionListRightTestingtHeading>

                  {!conditionLoading ? (
                    <Typography className="TestText" sx={{ width: "90%" }}>
                      {testNowData?.statusMessage
                        ? testNowData?.statusMessage
                        : "No testing result found"}
                    </Typography>
                  ) : (
                    <Skeleton
                      variant="rounded"
                      width={"90%"}
                      height={35}
                      sx={{ mt: 1 }}
                    />
                  )}
                </Box> */}
									{/* <Box width="100%">
                  {!conditionLoading ? (
                    <FormControlLabel
                      control={
                        <Checkbox
                          onChange={(e) => setIsDisabled(!e.target.checked)}
                        />
                      }
                      className="CheckboxStype"
                      label="Data received at destination verified and found to be satisfactory. "
                      sx={{ color: "rgba(102, 105, 106, 1)s" }}
                      disabled={!isTestCompleted}
                    />
                  ) : (
                    <Skeleton
                      variant="rounded"
                      width={160}
                      height={25}
                      sx={{ mt: 1 }}
                    />
                  )}
                </Box> */}
									<Box width="100%" mt={1}>
										{loadingCondition ? (
											<Skeleton
												variant="rounded"
												width={112}
												height={40}
												sx={{ mt: 1 }}
											/>
										) : (
											<ConnectionListRightActivateBtn
												variant="outlined"
												size="large"
												disabled={!allCheckedOverall}
												onClick={HandleActivate}
											>
												{activateLoading ? (
													<CircularProgress
														variant="indeterminate"
														disableShrink
														sx={{ color: color.loadingColor }}
														size={25}
														thickness={8}
													/>
												) : (
													"Activate"
												)}
											</ConnectionListRightActivateBtn>
										)}
									</Box>
								</Box>
							</Box>
						)}
				</Box>
			)}
			{activeTab === "Offline" &&
				Object.hasOwn(connections, "Offline") &&
				connections["Offline"].length !== 0 && (
					<Box
						width="42.2%"
						display="flex"
						borderLeft="1px solid rgba(234, 237, 246, 1)"
					>
						<Box padding="1rem" width="100%">
							<ConnectionListRightHeading>
								Error Details
							</ConnectionListRightHeading>
							<Box width="100%" ml={0} className="TestText">
								An exception has occurred on the connection. The connection has
								been put in offline mode. The support team is troubleshooting
								the incident. Please contact MadConnect for support updates
							</Box>
							{/* <ConnectionListRightHeading mt={2}>
								How To Resolve
							</ConnectionListRightHeading>
							<Box width="100%" ml={0} className="TestText">
								Figma ipsum component variant main layer. Layer outline move
								style content. Polygon style flatten bold fill pen library.
								Duplicate plugin vertical comment auto italic create. Inspect
								follower component link.
							</Box>

							<Box width="100%" mt={2}>
								<ConnectionListRightTestBtn
									isDisable={!selectedConnection?.conId}
									variant="contained"
									size="large"
									onClick={HandleTestNow}
									disabled={!selectedConnection?.conId}
								>
									{!testLoading ? "Test Now" : "Cancel Test"}
								</ConnectionListRightTestBtn>
							</Box>
							<Box width="100%">
								<ConnectionListRightTestingtHeading
									sx={{
										marginLeft: 0,
									}}
								>
									Test Result
								</ConnectionListRightTestingtHeading>
								<Typography className="TestText">
									{testNowData?.statusMessage
										? testNowData?.statusMessage
										: "No testing result found"}
								</Typography>
							</Box>
							<Box width="100%">
								<FormControlLabel
									control={
										<Checkbox
											onChange={(event) =>
												setIsDisabledOffline(!event.target.checked)
											}
										/>
									}
									className="CheckboxStype"
									label="Data received at destination verified and found to be satisfactory. "
									sx={{ color: "rgba(102, 105, 106, 1)s" }}
									disabled={!isTestCompleted}
								/>
							</Box> */}
							<Box width="100%" mt={1}>
								<ConnectionListRightActivateBtn
									variant="outlined"
									size="large"
									// disabled={isDisabledOffline}
									onClick={HandleActivate}
								>
									{!activateLoading ? (
										"Reactivate"
									) : (
										<CircularProgress
											variant="indeterminate"
											disableShrink
											sx={{ color: color.loadingColor }}
											size={25}
											thickness={8}
										/>
									)}
								</ConnectionListRightActivateBtn>
							</Box>
						</Box>
					</Box>
				)}
		</>
	);
};

export default ConnectionListRight;
