import { Box, InputAdornment } from "@mui/material";
import React from "react";
import { useNavigate } from "react-router-dom";
import { useSelector } from "react-redux";

import {
	CreateBtnContainer,
} from "./Styles/Header.styles";
import searchIcon from "../../../assets/searchIcon.svg";
import resetClose from "../../../assets/cancel.svg";
import { madTechState } from "../../../features/madTechSlice";
import {
	SearchBarWrapper,
	SearchInput,
} from "../../../components/CommonSearch/CommonSearch.styles";

const Header = ({ data, inputValue, setInputValue }) => {
	const navigate = useNavigate();
	const { selectedAccount } = useSelector(madTechState);

	const handleCreateBtn = () => {
		navigate(
			selectedAccount !== null && selectedAccount !== undefined
				? `/create-connections?accountId=${selectedAccount.id}`
				: "/create-connections"
		);
	};

	const endIcon = () => {
		return (
			<InputAdornment position="start">
				<img
					src={resetClose}
					alt="Search icon"
					style={{
						width: 14,
						height: 14,
						cursor: "pointer",
						display: !inputValue && "none",
					}}
					onClick={() => setInputValue("")}
				/>
			</InputAdornment>
		);
	};

	return (
		<Box display="flex" justifyContent="flex-end">
			{data?.length > 0 && (
				<Box display="flex" alignItems="center" gap="10px">
					<CreateBtnContainer variant="outlined" onClick={handleCreateBtn}>
						Create Connection
					</CreateBtnContainer>
					<SearchBarWrapper>
						<img src={searchIcon} width={20} height={20} alt="search" />
						<SearchInput
							placeholder="Search"
							value={inputValue}
							onChange={(e) => {
								setInputValue(e.target.value);
							}}
						/>
					</SearchBarWrapper>
				</Box>
			)}
		</Box>
	);
};

export default Header;
