import React, { useEffect, useMemo, useState } from "react";
import {
	Box,
	CircularProgress,
	Grid,
	Tooltip,
	Typography,
} from "@mui/material";

import leftArrow from "../../assets/left_arrow.svg";
import searchImg from "../../assets/search.svg";
import slash from "../../assets/slash.svg";
import {
	CommingSoonText,
	DetailContainer,
	FilterSearch,
	ListHeader,
	ListHeader2,
	ListHeaderImg,
	ListHeaderSpan,
	ListHeading,
	LoaderContainer,
	LoaderWrapper,
	ToolTipComingSoonContainer,
	ToolTipContainer,
	ToolTipTitle,
	TooltipSpan,
} from "./Connectors.styles";
import { Item } from "./ConnectorsList";
import OverviewPlatform from "../../components/Configuration/OverviewPlatform";
import { color } from "../../utils/Colors";

const ConnectorHeading = ({
	matchesxss,
	handleBack,
	showDoc,
	handleSearch,
	searchValue,
  loadingCondition,
	id,
}) => {
	return (
		<ListHeading matchesxss={matchesxss}>
			{showDoc && id !== undefined ? (
				<ListHeader className="heading" onClick={handleBack}>
					<ListHeaderImg alt="<" src={leftArrow}></ListHeaderImg>
					<ListHeaderSpan className="heading">Back</ListHeaderSpan>
				</ListHeader>
			) : (
				<ListHeader2 className="heading">Connectors</ListHeader2>
			)}

			{!showDoc && (
				<FilterSearch
					value={searchValue}
					onChange={(e) => handleSearch(e)}
					placeholder="Search Here"
          disabled={loadingCondition}
					inputProps={{
						style: { paddingLeft: 15 },
					}}
					matchesxss={matchesxss}
					startAdornment={
						<img
							alt=""
							src={searchImg}
							style={{ width: "1.25rem", height: "1.25rem" }}
						></img>
					}
				/>
			)}
		</ListHeading>
	);
};

const ConnectorData = (props) => {
	const {
		handleBack,
		matchesxss,
		showDoc,
		handleSearch,
		searchValue,
		loader,
		selectedItem,
		selectedDoc,
		data,
		handleShowConnectoreData,
		allplatfomrs,
		ref,
		isLoading,
		id,
	} = props;

	const [markdownContent, setMarkdownContent] = useState("");
	const [loaderOverview, setLoaderOverview] = useState(false);
	const loadingCondition = useMemo(() => allplatfomrs === null || isLoading, [allplatfomrs, isLoading]);

	useEffect(() => {
		const path = selectedDoc?.split("=")?.[1];
		if (path?.length > 0) {
			setLoaderOverview(true);
			fetch(`https://madconnect-docs.s3.amazonaws.com/${path}.md`)
				.then((response) => {
					if (!response.ok) {
						setLoaderOverview(false);
						throw new Error("Network response was not ok");
					}
					setLoaderOverview(false);
					return response.text();
				})
				.then((text) => setMarkdownContent(text))
				.catch((error) => {
					setLoaderOverview(false);
					console.error("Error fetching markdown file:", error);
				});
		}
	}, [selectedDoc]);

	const showLoader = () => {
		return (
			<LoaderContainer p={2}>
				<LoaderWrapper>
					<CircularProgress
						variant="indeterminate"
						disableShrink
						size={45}
						thickness={8}
						sx={{ color: color.loadingColor }}
					></CircularProgress>
				</LoaderWrapper>
			</LoaderContainer>
		);
	};

	return (
		<>
			<ConnectorHeading
				handleBack={handleBack}
				matchesxss={matchesxss}
				showDoc={showDoc}
				handleSearch={handleSearch}
				searchValue={searchValue}
				id={id}
        loadingCondition={loadingCondition}
			/>

			<Box display="flex" flexDirection="column" my="10px">
				<>
					{loader && showLoader()}
					{showDoc && id !== undefined && (
						<>
							<DetailContainer id="logo-container">
								<img
									alt="logo"
									// src={selectedItem?.logo}
									src={`/platforms/${selectedItem.id}.png`}
									style={{ height: "70px", maxWidth: "300px" }}
								/>
							</DetailContainer>
							{/* <iframe
                src={selectedDoc}
                id="doc-src"
                style={{ opacity: 0 }}
                title="description"
                className="frame"
                width={"100%"}
                ref={ref}
                height={800}
              ></iframe> */}
							{loaderOverview && showLoader()}
							<OverviewPlatform markdownContent={markdownContent} />
						</>
					)}
				</>
			</Box>

			<Box my={2} display={showDoc && id !== undefined ? "none" : ""}>
				{loadingCondition ? (
					// Show only loader when displayLoader is true
					<Box
						display="flex"
						justifyContent="center"
						alignItems="center"
						sx={{
							width: "100%",
							height: "55vh",
						}}
					>
						<CircularProgress
							variant="indeterminate"
              disableShrink
              size={45}
              thickness={8}
              sx={{ color: color.loadingColor }}
						/>
					</Box>
				) : (
					// Only show content when loader is not displaying
					<>
						{allplatfomrs.length === 0 ? (
							<Box
								sx={{
									display: "flex",
									justifyContent: "center",
									alignItems: "center",
									width: "100%",
									marginBottom: "50px",
									marginTop: "50px",
									height: "66vh",
								}}
							>
								<Box
									sx={{
										height: "100%",
										display: "flex",
										flexDirection: "column",
										justifyContent: "center",
										alignItems: "center",
									}}
								>
									<img
										alt=""
										src={slash}
										style={{
											width: "4rem",
											height: "4rem",
											marginBottom: 10,
										}}
									></img>
									<Typography
										sx={{
											fontSize: "1rem",
											letterSpacing: 1,
											fontWeight: 500,
										}}
									>
										No connectors found
									</Typography>
								</Box>
							</Box>
						) : (
							<Grid container spacing={{ xs: 4, sm: 4, md: 4 }}>
								{allplatfomrs.map((item, index) => (
									<Grid item xs={12} sm={6} md={4} lg={3} key={index}>
										<Item
											cursor={item.status == 2 ? "default" : "pointer"}
											onClick={() => handleShowConnectoreData(item)}
										>
											<img
												alt=""
												src={`/platforms/${item?.id}.png`}
												style={{ maxWidth: "100%", maxHeight: "65%" }}
											></img>
											<ToolTipContainer>
												<Tooltip
													title={
														<TooltipSpan>
															{item.dataType ? item.dataType : ""}
														</TooltipSpan>
													}
													style={{ textTransform: "capitalize" }}
													placeholder="top"
												>
													<ToolTipTitle>
														{item.dataType ? item.dataType : ""}
													</ToolTipTitle>
												</Tooltip>
											</ToolTipContainer>
											<Box
												sx={{
													display: "flex",
													justifyContent: "center",
													alignItems: "center",
													position: "absolute",
													maxWidth: "70%",
													top: 5,
													left: 4,
													flexDirection: "row",
													paddingTop: "2px",
													paddingLeft: "6px",
												}}
											>
												<span
													style={{
														color: "#000000c2",
														fontWeight: 600,
														fontFamily: "Helvetica Neue",
														textTransform: "capitalize",
														cursor: "default",
														fontSize: "10px",
														whiteSpace: "nowrap",
														overflow: "hidden",
														textOverflow: "ellipsis",
													}}
												>
													{item?.supportedType
														? item?.supportedType?.toLowerCase()
														: ""}
												</span>
											</Box>
											{item.status === 2 && (
												<ToolTipComingSoonContainer>
													<CommingSoonText>Coming Soon</CommingSoonText>
												</ToolTipComingSoonContainer>
											)}
										</Item>
									</Grid>
								))}
							</Grid>
						)}
					</>
				)}
			</Box>
		</>
	);
};

export default ConnectorData;
