import { Box } from "@mui/material";
import React, { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import {
	useGetAuthorizationListMutation,
	useGetContractDetailsQuery,
	useGetInvoiceQuery,
} from "../../services/query";
import { useMyContext } from "../../components/CommanToasterContext/toast";
import Header from "./Header";
import TabsData from "./TabsData";

// const authorizationListData = {
//   "statusCode": 200,
//   "statusMessage": "Authorization list getting successfully",
//   "response": {
//     "isReadonly": true,
//     "isSelected": true,
//     "message": "DeviceMotion authorizes MadConnect to transfer data on DeviceMotion's behalf."
//   }
// }

// const authorizationLoading = false

const Invoice = () => {
	const navigate = useNavigate();
	const { setOpen2, setMessage2 } = useMyContext();
	const [statusCode, setStatusCode] = useState(null);
	const [invoiceList, setInvoiceList] = useState(null);
	const [selectedTab, setSelectedTab] = useState(0);
	const [authorizationData, setAuthorizationListData] = useState(null);
	const [date, setDate] = useState("03/04/2023");
	const [contract, setContract] = useState({});

	const { data, error, refetch } = useGetInvoiceQuery();

	const {
		data: contractRes,
		isLoading: contractLoading,
		error: contractError,
		refetch: refetchContract,
	} = useGetContractDetailsQuery();

	const [
		getAuthorization,
		{
			data: authorizationListData,
			isLoading: authorizationLoading,
			error: authorizationError,
		},
	] = useGetAuthorizationListMutation();

	useEffect(() => {
		if (selectedTab === 2) {
			getAuthorization();
		}
	}, [selectedTab]);

	useEffect(() => {
		refetch();
		refetchContract();
	}, []);

	useEffect(() => {
		let today = new Date();
		let date1 = today.getDate();
		if (date1 < 10) {
			date1 = "0" + date1;
		}
		let month = today.getMonth() + 1;
		if (month < 10) {
			month = "0" + month;
		}
		let dt = date1 + "/" + month + "/" + today.getFullYear();
		setDate(dt);
	}, []);

	useEffect(() => {
		if (data !== undefined) {
			if (data?.statusCode === 200 && data?.response) {
				setInvoiceList(data?.response);
			} else {
				setStatusCode(data?.statusCode);
			}
		}

		if (error !== undefined) {
			console.log("error", error);
			if (error.statusCode === 403) {
				navigate("signin");
			}
		}
	}, [data, error]);

	useEffect(() => {
		if (authorizationListData !== undefined) {
			if (
				authorizationListData?.statusCode === 200 &&
				authorizationListData?.response
			) {
				setAuthorizationListData(authorizationListData?.response);
				return;
			} else {
				setMessage2(authorizationListData?.statusMessage);
				setOpen2(true);
			}
		}

		if (authorizationError !== undefined) {
			console.log("authorizationError", authorizationError);
		}
	}, [authorizationListData, authorizationError]);

	useEffect(() => {
		if (contractRes !== undefined) {
			if (contractRes?.statusCode === 200) {
				console.log(contractRes?.response, "jjjjjjjj");
				setContract(contractRes?.response);
			}
		}

		if (contractError !== undefined) {
			console.log("contractError", contractError);
			if (contractError?.statusCode === 403) {
				window.location.replace("signin");
			}
		}
	}, [contractRes, contractError]);

	return (
		<Box sx={{ px: "1.5rem" }}>
			{/* header */}
			<Header />
			{/* tabs */}
			<TabsData
				selectedTab={selectedTab}
				setSelectedTab={setSelectedTab}
				statusCode={statusCode}
				invoiceList={invoiceList}
				contract={contract}
				date={date}
				authorizationLoading={authorizationLoading}
				authorizationData={authorizationData}
				contractLoading={contractLoading}
			/>
		</Box>
	);
};

export default Invoice;
