import {
	Box,
	Tab,
	TableCell,
	TableHead,
	Tabs,
	Typography,
} from "@mui/material";
import styled from "styled-components";
import { color } from "../../utils/Colors";

export const Wrapper = styled(Box)({
	display: "flex",
	flexDirection: "row",
	justifyContent: "space-between",
	marginBottom: "1rem !important",
	alignItems: "flex-start",
});

export const Heading = styled(Typography)({
	fontSize: "1.25rem !important",
	fontWeight: "700 !important",
	fontFamily: "Helvetica Neue !important",
	marginTop: "6px !important",
});

export const Container = styled(Box)({
	display: "flex",
	flexDirection: "row",
	justifyContent: "space-between",
	height: "101px",
	width: "100%",
	gap: "25px",
	marginTop: "0.5rem !important",
	marginBottom: "0.5rem !important",
});

export const AccountsCard = styled(Box)({
	flex: 1,
	height: "100%",
	borderRadius: "10px",
	background: "rgba(115, 133, 229, 0.1)",
	padding: "15px !important",
	display: "flex",
	alignItems: "flex-start",
	flexDirection: "row",
	gap: "10px",
});

export const ConnectionCard = styled(Box)({
	flex: 1,
	height: "100%",
	borderRadius: "10px",
	background: "rgba(83, 139, 244, 0.1)",
	padding: "15px !important",
	display: "flex",
	alignItems: "flex-start",
	flexDirection: "row",
	gap: "10px",
});

export const NoOfValueOfCard = styled(Typography)({
	fontWeight: "500 !important",
	fontFamily: "Helvetica Neue !important",
	fontSize: "1.875rem !important ",
	color: "#25262D",
});

export const CardHeading = styled(Typography)`
	font-weight: 400 !important;
	font-family: Helvetica Neue !important;
	font-size: 0.875rem !important;
	color: #66696A;
	line-height: 16.8px !important;
	display: flex;
	align-items: center;
	-webkit-line-clamp: 2;
	-webkit-box-orient: vertical;
	overflow: hidden;
	white-space: normal;
	min-height: 33.6px;
`;

export const AveragedailyCard = styled(Box)({
	flex: 1,
	height: "100%",
	borderRadius: "10px",
	backgroundColor: "rgba(83, 139, 244, 0.1)",
	padding: "15px",
	display: "flex",
	alignItems: "flex-start",
	flexDirection: "row",
	gap: "10px",
});

export const AveragedailyCardHeading = styled(Typography)({
	fontWeight: "500 !important",
	fontFamily: "Helvetica Neue !important",
	fontSize: "30px !important",
	color: "#25262D",
});

export const AverageMonthlyCard = styled(Box)({
	flex: 1,
	height: "100%",
	borderRadius: "10px",
	backgroundColor: "rgba(83, 139, 244, 0.1)",
	padding: "15px !important",
	display: "flex",
	alignItems: "flex-start",
	flexDirection: "row",
	gap: "10px",
});

export const CurrentMonthCard = styled(Box)({
	flex: 1,
	height: "100%",
	borderRadius: 1,
	boxShadow:
		"0px 0px 1px rgba(37, 38, 45, 0.25), 0px 2px 4px rgba(37, 38, 45, 0.15)",
	backgroundColor: "#fff",
	marginRight: 0,
	paddingLeft: "1rem",
	paddingTop: "1rem",
	paddingBottom: "1rem",
	display: "flex",
	flexDirection: "row",
});

export const TotalVolumeCard = styled(Box)({
	flex: 1,
	height: "100%",
	borderRadius: "10px",
	backgroundColor: "rgba(83, 139, 244, 0.1)",
	padding: "15px !important",
	display: "flex",
	alignItems: "flex-start",
	flexDirection: "row",
	gap: "10px",
});

export const VolumnContainer = styled("Box")({
	display: "flex",
	flexDirection: "column",
	position: "relative",
	backgroundColor: "#fff",
	borderRadius: "8px",
	marginTop: "1rem",
	padding: "1rem",
	boxShadow:
		"0px 0px 1px rgba(37, 38, 45, 0.25), 0px 2px 4px rgba(37, 38, 45, 0.15)",
	width: "1200",
	height: "25rem",
});

export const SectionHeading = styled("Typography")({
	color: "#000000",
	fontSize: "1.25rem !important",
	fontWeight: "500 !important",
	fontFamily: "Helvetica Neue !important",
});

export const NoDataAailable = styled(Box)({
	position: "absolute",
	top: "40%",
	left: "40%",
	display: "flex",
	justifyContent: "center",
	alignItems: "center",
	height: "5rem",
});

export const NoDataAailableHeading = styled("Typography")({
	color: "#25262D",
	textAlign: "center",
	fontSize: "1.125rem !important",
	fontWeight: "700 !important",
	fontFamily: "Helvetica Neue !important",
});

export const ConnecionWrapper = styled(Box)({
	backgroundColor: "#fff",
	paddingLeft: (props) => (props.setStep !== undefined ? 0 : "16px"),
	paddingRight: (props) => (props.setStep !== undefined ? 0 : "16px"),
	paddingTop: (props) => (props.setStep !== undefined ? 0 : "8px"),
	paddingBottom: "8px",
	borderRadius: "8px",
	marginTop: "1rem",
	boxShadow:
		"0px 0px 1px rgba(37, 38, 45, 0.25), 0px 2px 4px rgba(37, 38, 45, 0.15)",
});

export const HeaderWrapper = styled(Box)({
	display: "flex",
	justifyContent: "space-between",
	backgroundColor: "#fff",
	padding: "12px 24px 20px 24px",
	marginBottom: "25px",
	borderBottom: "1px solid #EEF3F8",
});

export const LoaderWrapper = styled(Box)({
	display: "flex",
	justifyContent: "center",
	alignItems: "center",
	height: "100%",
});
export const LoaderWrapperIndividual = styled(Box)({
	display: "flex",
	justifyContent: "center",
	alignItems: "center",
	height: "70vh",
});

export const StyledTableCell = styled(TableCell)({
	borderBottom: "1px solid #EEF3F8 !important",
	padding: "16px !important",
	fontFamily: "Helvetica Neue !important",
	color: color.tableCellTextColor,
});

export const StickyTableHead = styled(TableHead)({
	position: "sticky",
	top: 0,
	zIndex: 1,
	background: color.tableHeaderColor,
});

export const TableHeaderTypography = styled(Typography)({
	color: `${color.tableHeaderTextColor} !important`,
	fontSize: "1rem !important",
	fontWeight: "500 !important",
	fontFamily: "Helvetica Neue !important",
});

export const TableNameTypography = styled(Typography)({
	color: color.tableCellTextColor,
	fontWeight: "500 !important",
	fontSize: "0.875rem !important",
	fontFamily: "Helvetica Neue !important",
});

export const LinkTypograpy = styled(Typography)({
	fontSize: "0.875rem !important",
	fontFamily: "Helvetica Neue !important",
	textDecoration: "underline",
	color: color.tableCellTextColor,
	cursor: "pointer !important",
	marginTop: "1rem !important",
	marginBottom: "1rem !important",
	textAlign: "center !important",
});

export const TabsWrapper = styled(Tabs)({
	border: "1px solid rgba(234, 237, 246, 1) !important",
	borderRadius: "8px !important",
});

export const TabStyled = styled(Tab)({
	fontSize: "16px !important",
	fontFamily: "Helvetica Neue !important",
	padding: "14px 25px !important",
	borderRight: (props) =>
		props.condition ? "1px solid rgba(234, 237, 246, 1) !important" : "none",
	textTransform: "capitalize",
});

export const TabHeading = styled("span")({
	fontSize: "1rem",
	fontFamily: "Helvetica Neue",
	padding: "1px",
	marginTop: "2px",
	textTransform: "capitalize",
});
