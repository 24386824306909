import { Box, TableCell, TableRow, Tooltip, Typography } from "@mui/material";
import { color } from "../../utils/Colors";
import { StyledTableCell, TableNameTypography } from "./Dashboard.styles";
import connectionLogo from "../../assets/Connector.svg";
import { useMemo } from "react";
import { favIconMapping } from "../../components/FavIconMaping/favicon";

const ConnectionDataRow = ({ data, deleteConnector }) => {
	const StatusChip = ({ status }) => {
		const colors = useMemo(
			() => ({
				"In-progress": {
					background: "rgba(83, 139, 244, 0.08)",
					text: "#538BF4",
				},
				Disabled: {
					background: "rgba(255, 79, 79, 0.08)",
					text: "#FF4F4F",
				},
				Offline: {
					background: "rgba(255, 79, 79, 0.08)",
					text: "#FF4F4F",
				},
				Broken: {
					background: "rgba(255, 79, 79, 0.08)",
					text: "#FF4F4F",
				},
				Deleted: {
					background: "rgba(255, 79, 79, 0.08)",
					text: "#FF4F4F",
				},
				Active: {
					background: "rgba(42, 144, 73, 0.08)",
					text: "#2A9049",
				},
			}),
			[]
		);

		return (
			<Box
				sx={{
					background: colors[status].background,
					borderRadius: "50px",
					padding: "8px 13px",
					width: "fit-content",
				}}
			>
				<Typography
					sx={{
						fontSize: "14px",
						fontWeight: 500,
						fontFamily: "Helvetica Neue",
						color: colors[status].text,
						whiteSpace: "nowrap",
					}}
				>
					{status}
				</Typography>
			</Box>
		);
	};

	const truncateString = (input, textLength) => {
		if (input && input.length > textLength) {
			return input.slice(0, textLength - 3) + "...";
		}
		return input;
	};

	function getFavIconImagePath(key) {
		if (favIconMapping.hasOwnProperty(key)) {
			return favIconMapping[key];
		} else {
			return "platforms2/favicon.png";
		}
	}

	return (
		<TableRow
			sx={{
				"&:last-child td, &:last-child th": { border: 0 },
			}}
		>
			<StyledTableCell>
				<Box
					display="flex"
					flexDirection="row"
					alignItems="center"
					width="100%"
					position="relative"
				>
					<Box
						display="flex"
						flexDirection="column"
						justifyContent="space-between"
					>
						<TableNameTypography>
							{truncateString(data.name, 16)}
						</TableNameTypography>
						<Typography
							sx={{
								fontSize: "0.875rem",
								fontWeight: 500,
								fontFamily: "Helvetica Neue",
								color: "#989CAA",
								textAlign: "left",
							}}
						>
							{data.conId}
						</Typography>
					</Box>
				</Box>
			</StyledTableCell>
			<StyledTableCell>
				<Box
					display="flex"
					flexDirection="row"
					alignItems="center"
					width="100%"
					position="relative"
				>
					<Box
						display="flex"
						justifyContent="start"
						alignItems="center"
						height="2.5rem"
						borderRadius={1}
						gap="5px"
						width="100%"
					>
						<img
							alt=""
							src={`/logos/favicons/${data?.source.id}.svg`}
							style={{
								width: "2.5rem",
								height: "2.5rem",
								objectFit: "contain",
							}}
						></img>
						<Tooltip
							title={
								<span
									style={{
										textTransform: "capitalize",
										fontSize: "1rem",
									}}
								>
									{data?.source.label}
								</span>
							}
							placeholder="top"
						>
							<Typography
								sx={{
									fontSize: "0.875rem",
									fontWeight: 500,
									fontFamily: "Helvetica Neue",
									color: color.tableCellTextColor,
									width: "fit-content",
								}}
							>
								{data?.source.label}
							</Typography>
						</Tooltip>
					</Box>
				</Box>
			</StyledTableCell>
			<StyledTableCell>
				<Box
					display="flex"
					flexDirection="row"
					alignItems="center"
					width="100%"
					position="relative"
				>
					<Box
						display="flex"
						justifyContent="start"
						alignItems="center"
						height="2.5rem"
						borderRadius={1}
						gap="5px"
						width="100%"
					>
						<img
							alt=""
							src={`/logos/favicons/${data?.destination.id}.svg`}
							style={{
								width: "2.5rem",
								height: "2.5rem",
								objectFit: "contain",
							}}
						></img>
						<Tooltip
							title={
								<span
									style={{
										textTransform: "capitalize",
										fontSize: "1rem",
									}}
								>
									{data?.destination.label}
								</span>
							}
							placeholder="top"
						>
							<Typography
								sx={{
									fontSize: "0.875rem",
									fontWeight: 500,
									fontFamily: "Helvetica Neue",
									color: color.tableCellTextColor,
									width: "fit-content",
								}}
							>
								{data?.destination.label}
							</Typography>
						</Tooltip>
					</Box>
				</Box>
			</StyledTableCell>
			<StyledTableCell sx={{ textAlign: "left" }}>
				<Tooltip
					title={
						<span
							style={{
								textTransform: "capitalize",
								fontSize: "12px",
							}}
						>
							{data?.source?.dataType?.trim() !== ""
								? data.source.dataType
								: data?.destination?.dataType || ""}
						</span>
					}
					placeholder="top"
				>
					<span
						style={{
							color: color.tableCellTextColor,
							fontWeight: 500,
							fontSize: "0.875rem",
							fontFamily: "Helvetica Neue",
							textTransform: "capitalize",
							cursor: "default",
							whiteSpace: "nowrap",
							overflow: "hidden",
							textOverflow: "ellipsis",
						}}
					>
						{data?.source?.dataType?.trim() !== ""
							? data.source.dataType
							: data?.destination?.dataType || ""}
					</span>
				</Tooltip>
			</StyledTableCell>
			<StyledTableCell sx={{ textAlign: "right" }}>
				<TableNameTypography>
					{new Intl.NumberFormat("en-US")
						.format(Math.round(data?.syncs))
						.toString()}
				</TableNameTypography>
			</StyledTableCell>
			<StyledTableCell sx={{ textAlign: "right" }}>
				<TableNameTypography>
					{data?.usedVolume !== undefined
						? new Intl.NumberFormat("en-US")
								.format(Math.round(data?.monthlyVolume))
								.toString()
						: "1000"}
				</TableNameTypography>
			</StyledTableCell>
			<StyledTableCell sx={{ textAlign: "right" }}>
				<TableNameTypography>
					{new Intl.NumberFormat("en-US")
						.format(Math.round(data?.totalVolume))
						.toString()}
				</TableNameTypography>
			</StyledTableCell>
			<StyledTableCell sx={{ textAlign: "left" }}>
				<StatusChip status={data?.deleted ? "Deleted" : data?.status} />
			</StyledTableCell>
		</TableRow>
	);
};

export default ConnectionDataRow;
