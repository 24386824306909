import React, { useEffect, useState, useMemo } from "react";
import {
	Backdrop,
	Box,
	Button,
	Fade,
	Modal,
	OutlinedInput,
	Paper,
	Table,
	TableBody,
	TableCell,
	TableContainer,
	TableHead,
	TableRow,
	Tooltip,
	Typography,
} from "@mui/material";
import AddIcon from "@mui/icons-material/Add";
import { useNavigate } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import moment from "moment";

import searchImg from "../../assets/search.svg";
import noConfigImg from "../../assets/Illustrations.svg";
import trash from "../../assets/trash-2.svg";
import editImg from "../../assets/edit.svg";
import cancel from "../../assets/cancel.svg";
import searchIcon from "../../assets/searchIcon.svg";
import { color } from "../../utils/Colors";
import "./SRoles.css";
import { PANEL, getPanelType, madTechState } from "../../features/madTechSlice";
import CommonButton from "../../components/CommonButton/CommonButton";
import {
	useDeleteRoleMutation,
	useGetSRoleMutation,
} from "../../services/query";
import { useMyContext } from "../../components/CommanToasterContext/toast";
import SBreadCrumb from "../../components/Setting/SBreadCrumb";
import Loader from "../../components/Setting/Loader";
import {
	CenteredBox,
	ConfirmationTextBox,
	FormNameInputWrapper,
	FormNameLabelWrapper,
	FormNameWrapper,
	SRoleDescription,
	SRoleTableCell,
	SRoleTableCellStyle,
	SRoleTableHeader,
	TableRowId,
	TableRowName,
} from "./Srole.styles";
import {
	SearchBarWrapper,
	SearchInput,
} from "../../components/CommonSearch/CommonSearch.styles";

const SRoles = () => {
	const panelType = useSelector(getPanelType);
	const { selectedAccount, user } = useSelector(madTechState);

	const [
		getData,
		{ data: roleData, isLoading: roleLoading, error: roleError },
	] = useGetSRoleMutation();
	const [
		deleteRole,
		{
			data: deleteRoleData,
			isLoading: deleteRoleLoading,
			error: deleteRoleError,
		},
	] = useDeleteRoleMutation();

	const navigate = useNavigate();
	const dispatch = useDispatch();
	const userDetail = JSON.parse(localStorage.getItem("USER"));

	const [roles, setRoles] = useState(null);

	const [page, setPage] = React.useState(0);
	const [rowsPerPage, setRowsPerPage] = React.useState(50);
	const [searchValue, setSearchValue] = useState("");
	const [rows, setRows] = useState(null);
	const [selectedItem, setSelectedItem] = useState(null);
	const [isDeleteOpen, setIsDeleteOpen] = useState(false);
	const { setOpen, setMessage, setOpen2, setMessage2 } = useMyContext();
	const [isRoleEdit, setIsRoleEdit] = useState(false);

	useEffect(() => {
		getData();
	}, []);

	useEffect(() => {
		if (roleData !== undefined) {
			if (roleData?.statusCode === 200 && roleData?.response) {
				setRoles(roleData?.response);
				setRows(roleData?.response);
			} else {
				setRoles([]);
			}
		}

		if (roleError !== undefined) {
			setRoles([]);
			setRows([]);
		}
	}, [roleData, roleError]);

	// useEffect(() => {
	//   const allRoles = [
	//     {
	//       id: "b4a61ab003597f80",
	//       name: "User",
	//       description:
	//         "User has administrative capabilities with access to all features",
	//       user: 0,
	//       createdTime: 1674813285,
	//       updatedTime: 1674813285,
	//       dashboard: {
	//         view: true,
	//         create: false,
	//         modify: false,
	//         delete: false,
	//       },
	//       accounts: {
	//         view: true,
	//         create: false,
	//         modify: false,
	//         delete: false,
	//       },
	//       connections: {
	//         view: true,
	//         create: false,
	//         modify: false,
	//         delete: false,
	//       },
	//       users: {
	//         view: true,
	//         create: false,
	//         modify: false,
	//         delete: false,
	//       },
	//       reports: {
	//         view: false,
	//         create: false,
	//         modify: false,
	//         delete: false,
	//       },
	//       contracts: {
	//         view: true,
	//         create: false,
	//         modify: false,
	//         delete: false,
	//       },
	//       invoices: {
	//         view: true,
	//         create: false,
	//         modify: false,
	//         delete: false,
	//       },
	//       profile: {
	//         view: true,
	//         create: false,
	//         modify: false,
	//         delete: false,
	//       },
	//       roles: {
	//         view: true,
	//         create: false,
	//         modify: true,
	//         delete: false,
	//       },
	//     },
	//     {
	//       id: "9d919e9bc11c4b94",
	//       name: "Admin",
	//       description: "User has restricted access",
	//       user: 1,
	//       createdTime: 1675229965,
	//       updatedTime: 1681119748,
	//       dashboard: {
	//         view: true,
	//         create: false,
	//         modify: false,
	//         delete: false,
	//       },
	//       accounts: {
	//         view: true,
	//         create: true,
	//         modify: true,
	//         delete: true,
	//       },
	//       connections: {
	//         view: true,
	//         create: true,
	//         modify: true,
	//         delete: true,
	//       },
	//       users: {
	//         view: true,
	//         create: true,
	//         modify: true,
	//         delete: true,
	//       },
	//       reports: {
	//         view: true,
	//         create: false,
	//         modify: false,
	//         delete: false,
	//       },
	//       contracts: {
	//         view: true,
	//         create: false,
	//         modify: false,
	//         delete: false,
	//       },
	//       invoices: {
	//         view: true,
	//         create: false,
	//         modify: true,
	//         delete: false,
	//       },
	//       profile: {
	//         view: true,
	//         create: false,
	//         modify: true,
	//         delete: false,
	//       },
	//       roles: {
	//         view: true,
	//         create: true,
	//         modify: true,
	//         delete: true,
	//       },
	//     },
	//     // {
	//     //   "id": "a4585fa2c4cfb92a",
	//     //   "name": "Role 3",
	//     //   "description": "Test description of role 3",
	//     //   "user": 0,
	//     //   "createdTime": 1676884120,
	//     //   "updatedTime": 1676884120,
	//     //   "dashboard": {
	//     //     "view": true,
	//     //     "create": false,
	//     //     "modify": false,
	//     //     "delete": false
	//     //   },
	//     //   "accounts": {
	//     //     "view": true,
	//     //     "create": false,
	//     //     "modify": true,
	//     //     "delete": false
	//     //   },
	//     //   "connections": {
	//     //     "view": true,
	//     //     "create": true,
	//     //     "modify": true,
	//     //     "delete": false
	//     //   },
	//     //   "users": {
	//     //     "view": true,
	//     //     "create": true,
	//     //     "modify": true,
	//     //     "delete": true
	//     //   },
	//     //   "reports": {
	//     //     "view": true,
	//     //     "create": false,
	//     //     "modify": false,
	//     //     "delete": false
	//     //   },
	//     //   "contracts": {
	//     //     "view": true,
	//     //     "create": false,
	//     //     "modify": true,
	//     //     "delete": false
	//     //   },
	//     //   "invoices": {
	//     //     "view": true,
	//     //     "create": false,
	//     //     "modify": false,
	//     //     "delete": false
	//     //   },
	//     //   "profile": {
	//     //     "view": true,
	//     //     "create": false,
	//     //     "modify": true,
	//     //     "delete": false
	//     //   },
	//     //   "roles": {
	//     //     "view": false,
	//     //     "create": false,
	//     //     "modify": false,
	//     //     "delete": false
	//     //   },
	//     // }
	//   ];
	//   setRoles(allRoles);
	//   setRows(allRoles);
	// }, []);

	const requestSearch = (searchedVal) => {
		const filteredRows = roles.filter((row) => {
			return (
				row.id.toString().includes(searchedVal) ||
				row.name.toLowerCase().includes(searchedVal.toLowerCase())
			);
		});
		setRows(filteredRows);
		setPage(0);
		setSearchValue(searchedVal);
	};

	const handleChangePage = (event, newPage) => {
		setPage(newPage);
	};

	const handleChangeRowsPerPage = (event) => {
		setRowsPerPage(parseInt(event.target.value, 10));
		setPage(0);
	};

	useEffect(() => {
		if (deleteRoleData !== undefined) {
			if (deleteRoleData?.statusCode === 200) {
				setIsDeleteOpen(false);
				setSelectedItem(null);
				setIsRoleEdit(false);
				setMessage(deleteRoleData?.statusMessage);
				setOpen(true);
				getData();
				return;
			} else {
				setMessage2(deleteRoleData?.statusMessage);
				setOpen2(true);
			}
		}
		if (deleteRoleError !== undefined) {
			console.log("deleteRoleError", deleteRoleError);
		}
	}, [deleteRoleData, deleteRoleError]);

	const _handleDeleteRole = (event) => {
		event.preventDefault();
		var payload = {
			id: selectedItem.id,
		};

		deleteRole(payload);
		// temp
		// deleteRoleClose();
	};

	useEffect(() => {
		setInputText("");
	}, [isDeleteOpen]);

	const deleteRoleClose = () => {
		resetForm();
		setIsDeleteOpen(false);
		setSelectedItem(null);
		setInputText("");
	};

	const _handleAddRole = (event) => {
		event.preventDefault();
		resetForm();
	};

	const _handleUpdateRole = (event) => {
		event.preventDefault();
		resetForm();
	};

	const resetForm = () => {
		setSelectedItem(null);
		// setRoleDetails({
		//   name: "",
		//   description: "",
		//   dashboard: {
		//     view: false,
		//     create: false,
		//     modify: false,
		//     delete: false,
		//   },
		//   accounts: {
		//     view: false,
		//     create: false,
		//     modify: false,
		//     delete: false,
		//   },
		//   connections: {
		//     view: false,
		//     create: false,
		//     modify: false,
		//     delete: false,
		//   },
		//   users: {
		//     view: false,
		//     create: false,
		//     modify: false,
		//     delete: false,
		//   },
		//   reports: {
		//     view: false,
		//     create: false,
		//     modify: false,
		//     delete: false,
		//   },
		//   contracts: {
		//     view: false,
		//     create: false,
		//     modify: false,
		//     delete: false,
		//   },
		//   invoices: {
		//     view: false,
		//     create: false,
		//     modify: false,
		//     delete: false,
		//   },
		//   profile: {
		//     view: false,
		//     create: false,
		//     modify: false,
		//     delete: false,
		//   },
		//   roles: {
		//     view: false,
		//     create: false,
		//     modify: false,
		//     delete: false,
		//   },
		// });
	};

	const [inputText, setInputText] = useState("");
	const [isDeleteButtonEnabled, setIsDeleteButtonEnabled] = useState(false);

	const handleInputChange = (event) => {
		const inputValue = event.target.value.toUpperCase(); // Convert input to uppercase
		setInputText(inputValue);
	};

	useEffect(() => {
		if (inputText === "DELETE") {
			setIsDeleteButtonEnabled(true);
		} else {
			setIsDeleteButtonEnabled(false);
		}
	}, [inputText]);

	const findPermissionByName = (name) => {
		// const foundPermission = Permission.find((p) => p.name === name);
		const foundPermission = user?.permissions.find((p) => p.name === name);
		return foundPermission ? foundPermission.permissions : null;
	};

	const rolesPermissions = useMemo(
		() => findPermissionByName("Roles"),
		[user?.permissions]
	);

	return (
		<>
			<Box sx={{ px: "1.5rem" }}>
				<Box
					display="flex"
					flexDirection="row"
					justifyContent="space-between"
					mb={2}
					alignItems="flex-start"
				>
					<SBreadCrumb
						mainPathName="Settings"
						mainPath="myprofile"
						pathName="Roles"
					/>
					<Box display="flex" gap="0.5rem">
						{rolesPermissions?.create && (
							<CommonButton
								width={140}
								height={40}
								textStyle={{
									fontSize: "1rem",
									fontWeight: 400,
									letterSpacing: "normal",
									color: color.whiteColor,
								}}
								customStyle={{ marginLeft: "auto", marginRight: "10px" }}
								onClick={() => {
									navigate(
										selectedAccount
											? "/roles/add?accountId=" + selectedAccount.id
											: "/roles/add"
									);
								}}
								borderRadius="10px"
								title={"Add Role"}
								color={color.buttonColor}
								startIcon={
									<AddIcon
										startIconStyle={{ height: 24, width: 24 }}
										sx={{ marginRight: "-8px", marginLeft: "4px" }}
									/>
								}
							></CommonButton>
						)}
						<SearchBarWrapper>
							<img src={searchIcon} width={20} height={20} alt="search" />
							<SearchInput
								placeholder="Search"
								value={searchValue}
								onChange={(e) => requestSearch(e.target.value)}
							/>
						</SearchBarWrapper>
					</Box>
				</Box>
				<Box
					display="flex"
					flexDirection="column"
					justifyContent="space-between"
					width="100%"
					mx="auto"
				>
					{roleLoading || rows === null ? (
						<Loader />
					) : (
						<Paper
							sx={{
								width: "100%",
								height: "100%",
								overflow: "hidden",
								borderRadius: "12px 0px 12px 12px",
							}}
						>
							<TableContainer
								sx={{
									height: "100%",
									maxHeight: "calc(100vh - 190px)",
									overflowY: "auto",
								}}
							>
								<Table stickyHeader>
									<TableHead
										sx={{
											"& .MuiTableCell-root:first-of-type": {
												borderTopLeftRadius: "12px",
												borderBottomLeftRadius: "12px",
											},
											"& .MuiTableCell-root:last-child": {
												borderTopRightRadius: "12px",
												borderBottomRightRadius: "12px",
											},
										}}
									>
										<TableRow>
											<SRoleTableHeader>Role</SRoleTableHeader>
											<SRoleTableHeader>Description</SRoleTableHeader>
											<SRoleTableHeader sx={{ textAlign: "right" }}>
												Users
											</SRoleTableHeader>
											<SRoleTableHeader sx={{ textAlign: "right" }}>
												Created Date
											</SRoleTableHeader>
											<SRoleTableHeader sx={{ textAlign: "right" }}>
												Last Updated Date
											</SRoleTableHeader>

											<SRoleTableHeader>
												{(rolesPermissions?.modify ||
													rolesPermissions?.delete) && <>Action</>}
											</SRoleTableHeader>
										</TableRow>
									</TableHead>
									<TableBody>
										{(rowsPerPage > 0
											? rows.slice(
													page * rowsPerPage,
													page * rowsPerPage + rowsPerPage
											  )
											: rows
										).map((row) => (
											<TableRow
												key={row.userId}
												sx={{
													"&:last-child td, &:last-child th": { border: 0 },
													"& .MuiTableCell-root": {
														borderBottom: "1px solid #EEF3F8",
													},
												}}
											>
												<TableCell sx={{ p: "0.8125rem 1rem 0.8125rem 1rem" }}>
													<Box
														display="flex"
														flexDirection="column"
														justifyContent="space-between"
													>
														<TableRowName
															onClick={() => {
																navigate(
																	selectedAccount
																		? "/roles/" +
																				row.id +
																				"?accountId=" +
																				selectedAccount.id
																		: "/roles/" + row.id
																);
																setSelectedItem(row);
															}}
														>
															{row.name}
														</TableRowName>
														<TableRowId>{row.id}</TableRowId>
													</Box>
												</TableCell>
												<SRoleTableCell>
													<SRoleDescription>{row.description}</SRoleDescription>
												</SRoleTableCell>
												<SRoleTableCell sx={{ textAlign: "right" }}>
													{row.user}
												</SRoleTableCell>
												<SRoleTableCell sx={{ textAlign: "right" }}>
													{moment(row.createdTime * 1000).format("MM-DD-YYYY")}
												</SRoleTableCell>
												<SRoleTableCell sx={{ textAlign: "right" }}>
													{moment(row.updatedTime * 1000).format("MM-DD-YYYY")}
												</SRoleTableCell>
												{(rolesPermissions?.modify ||
													rolesPermissions?.delete) && (
													<TableCell>
														<Box display="flex" flexDirection="row">
															{rolesPermissions?.modify && (
																<Tooltip title="Edit" placement="top">
																	<Box
																		width={17}
																		height={17}
																		marginBottom="5px"
																		marginRight="20px"
																		// cursor="pointer"
																		onClick={() => {
																			navigate(
																				selectedAccount
																					? "/roles/" +
																							row.id +
																							"?accountId=" +
																							selectedAccount.id
																					: "/roles/" + row.id
																			);
																		}}
																		sx={{ cursor: "pointer" }}
																	>
																		<img
																			src={editImg}
																			style={{
																				maxWidth: "100%",
																				maxHeight: "100%",
																			}}
																		/>
																	</Box>
																</Tooltip>
															)}
															{rolesPermissions?.delete && (
																<Tooltip title="Delete" placement="top">
																	<Box
																		width="1.25rem"
																		height="1.25rem"
																		marginBottom="5px"
																		// cursor="pointer"
																		onClick={() => {
																			setSelectedItem(row);
																			setIsDeleteOpen(true);
																		}}
																		sx={{ cursor: "pointer" }}
																	>
																		<img
																			src={trash}
																			style={{
																				maxWidth: "100%",
																				maxHeight: "100%",
																			}}
																		/>
																	</Box>
																</Tooltip>
															)}
														</Box>
													</TableCell>
												)}
											</TableRow>
										))}
										{rows.length == 0 && (
											<TableRow>
												<TableCell sx={{ textAlign: "center" }} colSpan={8}>
													<Box
														display={"flex"}
														justifyContent={"center"}
														alignItems={"center"}
														paddingBottom={4}
													>
														<Box
															display="flex"
															flexDirection="column"
															justifyContent="center"
															alignItems="center"
															marginRight={4}
														>
															<Box width="11.875rem" height="11.875rem">
																<img
																	src={noConfigImg}
																	style={{
																		maxWidth: "100%",
																		maxHeight: "100%",
																	}}
																/>
															</Box>
															<Typography
																sx={{
																	color: "#25262D",
																	textAlign: "center",
																	fontSize: "0.875rem",
																	fontWeight: 500,
																	fontFamily: "Helvetica Neue",
																}}
															>
																No roles available!
															</Typography>
														</Box>
													</Box>
												</TableCell>
											</TableRow>
										)}
									</TableBody>
								</Table>
							</TableContainer>
						</Paper>
					)}
				</Box>
			</Box>

			{/* DELETE ACCOUNT MODAL START */}
			{rolesPermissions?.delete && (
				<Modal
					aria-labelledby="transition-modal-title"
					aria-describedby="transition-modal-description"
					open={isDeleteOpen}
					onClose={deleteRoleClose}
					closeAfterTransition
					slots={{ backdrop: Backdrop }}
					slotProps={{ backdrop: { timeout: 500 } }}
				>
					<Fade in={isDeleteOpen}>
						<CenteredBox>
							<Typography
								style={{
									fontWeight: 700,
									fontFamily: "Helvetica Neue",
									fontSize: "1rem",
									textAlign: "center",
								}}
							>
								{selectedItem !== null && (
									<Box
										display="flex"
										flexDirection="row"
										justifyContent="space-between"
										mb={2}
										alignItems="center"
									>
										<Box
											display="flex"
											flexDirection="row"
											alignItems="center"
											width="100%"
											justifyContent="space-between"
										>
											<Box
												display="flex"
												flexDirection="column"
												justifyContent="space-between"
											>
												<Typography
													sx={{
														fontSize: 22,
														fontFamily: "Helvetica Neue",
														color: color.buttonColor,
													}}
												>
													{selectedItem.name}
												</Typography>
												<Typography
													sx={{
														fontSize: "1rem",
														fontFamily: "Helvetica Neue",
														color: "#656C7B",
													}}
												>
													{selectedItem.id}
												</Typography>
											</Box>
										</Box>
									</Box>
								)}
							</Typography>
							<ConfirmationTextBox>
								Are you sure you want delete this role?
							</ConfirmationTextBox>
							<Box
								width="100%"
								display="flex"
								justifyContent="center"
								flexDirection="column"
							>
								<FormNameWrapper
									sx={{
										width: "70%",
										display: "flex",
										justifyContent: "center",
										marginRight: "auto",
										marginLeft: "auto",
									}}
									size="small"
								>
									<FormNameLabelWrapper id="delete">
										Type DELETE to confirm
									</FormNameLabelWrapper>
									<FormNameInputWrapper
										placeholder={""}
										labelid="delete"
										id="delete"
										label="Type DELETE to confirm"
										onChange={handleInputChange}
										value={inputText}
										autoComplete="off"
									/>
								</FormNameWrapper>
							</Box>
							<Box
								mt={3}
								display="flex"
								flexDirection="row"
								justifyContent="center"
								alignItems="center"
								gap="1rem"
							>
								<Button
									onClick={(event) => {
										_handleDeleteRole(event);
									}}
									disabled={deleteRoleLoading || !isDeleteButtonEnabled}
									style={{
										cursor: "pointer",
										backgroundColor: color.whiteColor,
										width: "6.625rem",
										height: "2.5rem",
										color: color.themeRed,
										textTransform: "none",
										border: "1.5px solid",
										borderColor: color.themeRed,
										cursor: isDeleteButtonEnabled ? "pointer" : "not-allowed",
									}}
									variant="contained"
								>
									<Box display="flex" gap="0.5rem" alignItems="center">
										<img
											alt=""
											src={trash}
											style={{
												width: "1.3rem",
												display: "flex",
												alignItems: "center",
											}}
										></img>
										<Typography className="labelStyle">Delete</Typography>
									</Box>
								</Button>

								<Button
									onClick={deleteRoleClose}
									style={{
										cursor: "pointer",
										backgroundColor: color.whiteColor,
										width: "6.625rem",
										height: "2.5rem",
										color: color.themeBlack,
										textTransform: "none",
									}}
									variant="contained"
								>
									<Box
										sx={{
											display: "flex",
											gap: "0.5rem",
											alignItems: "center",
										}}
									>
										<img
											alt=""
											src={cancel}
											style={{
												width: "0.8rem",
												display: "flex",
												alignItems: "center",
											}}
										></img>
										<Typography className="labelStyle">Cancel</Typography>
									</Box>
								</Button>
							</Box>
						</CenteredBox>
					</Fade>
				</Modal>
			)}
			{/* DELETE ACCOUNT MODAL END */}
		</>
	);
};

export default SRoles;
