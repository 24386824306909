const ReportsIcon = ({ variant, color }) => {
	return variant === "solid" ? (
		<svg
			width="21"
			height="21"
			viewBox="0 0 21 21"
			fill="none"
			xmlns="http://www.w3.org/2000/svg"
		>
			<path
				d="M14 1.75H7C3.9375 1.75 2.625 3.5 2.625 6.125V14.875C2.625 17.5 3.9375 19.25 7 19.25H14C17.0625 19.25 18.375 17.5 18.375 14.875V6.125C18.375 3.5 17.0625 1.75 14 1.75ZM7 10.7188H10.5C10.8588 10.7188 11.1562 11.0162 11.1562 11.375C11.1562 11.7338 10.8588 12.0312 10.5 12.0312H7C6.64125 12.0312 6.34375 11.7338 6.34375 11.375C6.34375 11.0162 6.64125 10.7188 7 10.7188ZM14 15.5312H7C6.64125 15.5312 6.34375 15.2338 6.34375 14.875C6.34375 14.5162 6.64125 14.2188 7 14.2188H14C14.3588 14.2188 14.6562 14.5162 14.6562 14.875C14.6562 15.2338 14.3588 15.5312 14 15.5312ZM16.1875 8.09375H14.4375C13.1075 8.09375 12.0312 7.0175 12.0312 5.6875V3.9375C12.0312 3.57875 12.3287 3.28125 12.6875 3.28125C13.0463 3.28125 13.3438 3.57875 13.3438 3.9375V5.6875C13.3438 6.29125 13.8337 6.78125 14.4375 6.78125H16.1875C16.5463 6.78125 16.8438 7.07875 16.8438 7.4375C16.8438 7.79625 16.5463 8.09375 16.1875 8.09375Z"
				fill={color || "#25262D"}
			/>
		</svg>
	) : (
		<svg
			width="21"
			height="21"
			viewBox="0 0 21 21"
			fill="none"
			xmlns="http://www.w3.org/2000/svg"
		>
			<path
				d="M18.375 6.125V14.875C18.375 17.5 17.0625 19.25 14 19.25H7C3.9375 19.25 2.625 17.5 2.625 14.875V6.125C2.625 3.5 3.9375 1.75 7 1.75H14C17.0625 1.75 18.375 3.5 18.375 6.125Z"
				stroke={color || "#25262D"}
				stroke-width="1.5"
				stroke-miterlimit="10"
				stroke-linecap="round"
				stroke-linejoin="round"
			/>
			<path
				d="M12.6875 3.9375V5.6875C12.6875 6.65 13.475 7.4375 14.4375 7.4375H16.1875"
				stroke={color || "#25262D"}
				stroke-width="1.5"
				stroke-miterlimit="10"
				stroke-linecap="round"
				stroke-linejoin="round"
			/>
			<path
				d="M7 11.375H10.5"
				stroke={color || "#25262D"}
				stroke-width="1.5"
				stroke-miterlimit="10"
				stroke-linecap="round"
				stroke-linejoin="round"
			/>
			<path
				d="M7 14.875H14"
				stroke={color || "#25262D"}
				stroke-width="1.5"
				stroke-miterlimit="10"
				stroke-linecap="round"
				stroke-linejoin="round"
			/>
		</svg>
	);
};

export default ReportsIcon;
