import React from "react";
import { useEffect } from "react";
import { useState } from "react";
import { useLocation, useNavigate, useParams } from "react-router-dom";
import { Box, Typography, createTheme, CircularProgress } from "@mui/material";
import { useSelector } from "react-redux";

import Configuration from "../../components/Configuration/Configuration";
import { ThemeProvider } from "styled-components";
import { color } from "../../utils/Colors";
import { madTechState } from "../../features/madTechSlice";
import { useGetPlatformByIdConfigurationMutation } from "../../services/platformQuery";
import SBreadCrumb from "../../components/Setting/SBreadCrumb";

const Configurations = () => {
	const { id } = useParams();
	const { platfomrs, platformId, selectedAccount, user } =
		useSelector(madTechState);

	// eslint-disable-next-line no-restricted-globals
	const queryParams = new URLSearchParams(location?.search);

	const dataType = queryParams.get("dataType");
	const dataTypeId = queryParams.get("dataTypeId");
	const { state } = useLocation();

	const [platformByIdDatas, setPlatformByIdDatas] = useState([]);

	const [
		getPlatformByIdConfiguration,
		{
			data: platformByIdData,
			isLoading: platformByIdLoading,
			error: platformByIdError,
		},
	] = useGetPlatformByIdConfigurationMutation();

	useEffect(() => {
		if (platformId === null)
			getPlatformByIdConfiguration({ platformId: id, dataTypeId: dataTypeId });
	}, [id, platformId]);

	useEffect(() => {
		if (platformByIdData !== undefined) {
			setPlatformByIdDatas(platformByIdData?.response);
		} else if (platformId !== null) {
			setPlatformByIdDatas(platformId);
		} else {
			setPlatformByIdDatas(platfomrs);
		}
	}, [platformByIdData, platformId]);

	const theme = createTheme({
		palette: {
			primary: {
				// Purple and green play nicely together.
				main: color.buttonColor,
			},
		},
	});
	const navigate = useNavigate();
	// console.log('first', data)
	return (
		<ThemeProvider theme={theme}>
			<Box
				sx={{
					pl: "1.5rem",
					width: "100%",
					backgroundColor: color.layoutBgColor,
					height: "92vh",
				}}
			>
				{!platformByIdLoading ? (
					<>
						<SBreadCrumb
							mainPathName="My Platforms"
							mainPath="platform"
							pathName={`${
								platformId !== null
									? platformId?.name
									: platformByIdData?.response?.name
							} ${dataType && dataType != "" ? `(${dataType})` : ""}`}
						/>
						<Configuration
							platformByIdDatas={platformByIdDatas}
							setPlatformByIdDatas={setPlatformByIdDatas}
							dataType={dataType}
							dataTypeId={dataTypeId}
							connectorId={state?.connectorId}
						/>
					</>
				) : (
					<>
						<Box
							sx={{
								display: "flex",
								justifyContent: "center",
								alignItems: "center",
								width: "100%",
								marginBottom: "100px",
								height: "80vh",
								// marginTop: "350px",
								flex: 5.6,
							}}
						>
							<CircularProgress
								variant="indeterminate"
								disableShrink
								sx={{ color: color.loadingColor }}
								size={45}
								thickness={8}
							></CircularProgress>
						</Box>
					</>
				)}
			</Box>
		</ThemeProvider>
	);
};

export default Configurations;
