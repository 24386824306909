import { Box, Tab, Typography } from "@mui/material";
import ArrowForwardIcon from "@mui/icons-material/ArrowForward";
import styled from "styled-components";
import { color } from "../../utils/Colors";

// Header page
export const HeaderWrapper = styled(Box)({
	display: "flex",
	flexDirection: "row",
	marginBottom: "16px !important",
	alignItems: "center",
});

export const Heading = styled(Typography)({
	fontSize: "1.25rem !important",
	fontWeight: "700 !important",
	fontFamily: "Helvetica Neue !important",
	cursor: "pointer",
	// textDecoration: "underline",
});
export const SubheadingBox = styled(Box)({
	display: "flex",
	flexDirection: "row",
	backgroundColor: "#fff",
});

export const Subheading = styled(Typography)({
	fontSize: "1.25rem !important",
	fontFamily: "Helvetica Neue !important",
	fontWeight: "500 !important",
	letterSpacing: "0.2px !important",
	marginLeft: "10px !important",
});

export const ArrowForwardIconStyled = styled(ArrowForwardIcon)({
	height: "1.25rem !important",
	width: "1.25rem !important",
	marginBottom: "3px !important",
});

// InvoiceTabs Page

export const InvoiceTabsWrapper = styled(Box)({
	display: "flex",
	flexDirection: "column",
	backgroundColor: "#fff",
	padding: "16px !important",
	boxShadow:
		"0px 0px 1px rgba(37, 38, 45, 0.25), 0px 2px 4px rgba(37, 38, 45, 0.15)",
	background: "#fff",
	borderRadius: "8px !important",
});

export const InvoiceTabsContainer = styled(Box)({
	width: "100%",
	marginTop: "16px !important",
});

export const NoinvoicehistoryWrapper = styled(Box)({
	display: "flex",
	flexDirection: "column",
	justifyContent: "center",
	alignItems: "center",
});

export const NoinvoiceImgBox = styled(Box)({
	width: "11.875rem",
	height: "11.875rem",
});

export const NoinvoiceHeading = styled(Typography)({
	color: "#25262D",
	textAlign: "center",
	fontSize: "1.125rem !important",
	fontWeight: "500  !important",
	fontFamily: "Helvetica Neue  !important",
});

export const AuthorizationDataWrapper = styled(Box)({
	height: "500px !important",
	display: "flex",
	flexDirection: "column",
	marginTop: "40px !important",
});

export const ContractDetailsWrapper = styled(Box)({
	display: "flex",
	flexDirection: "row",
	justifyContent: "space-between",
	alignItems: "center",
});

export const ContractDetailsHeading = styled(Typography)({
	fontFamily: "Helvetica Neue !important",
	fontSize: "1.25rem !important",
	fontWeight: "700 !important",
});

export const UnsignedWrapper = styled(Box)({
	width: "5.75rem",
	height: "2.0625rem",
	backgroundColor: "rgba(54, 70, 172, 0.1)",
	borderRadius: "16px !important",
	display: "flex",
	justifyContent: "center",
	alignItems: "center",
});

export const UnsignedName = styled(Typography)({
	fontFamily: "Helvetica Neue  !important",
	fontSize: "0.875rem  !important",
	fontWeight: "400  !important",
	color: "#3646AC",
});
export const ListHeading = styled(Typography)({
	fontFamily: "Helvetica Neue !important",
	fontSize: "1.125rem !important",
	fontWeight: "400 !important",
	color: "#000000",
});

export const ContractFooterBox = styled(Box)({
	marginTop: "1.5rem !important ",
	display: "flex",
	flexDirection: "row",
	justifyContent: "space-between",
	alignItems: "center",
});

export const LastUpdated = styled(Typography)({
	fontFamily: "Inter !important",
	fontWeight: "400 !important",
	fontSize: "1rem !important",
	color: "#666666",
});

export const InvoiceListWrapper = styled(Box)({
	display: "flex",
	flexDirection: "column",
	marginTop: "3.5rem !important",
});

export const InvoiceListContainer = styled(Box)({
	height: "3.5rem",
	borderRadius: "8px !important",
	border: "1px solid rgba(25, 50, 65, 0.15)",
	backgroundColor: "#fff",
	marginBottom: "0.75rem !important",
	padding: "0.5rem !important",
	display: "flex",
	flexDirection: "row",
	//  justifyContent: 'space-between'
});

export const InvoiceListHeading = styled(Typography)({
	fontFamily: "Helvetica Neue  !important",
	fontWeight: "400  !important",
	fontSize: "1rem  !important",
	marginLeft: "0.5rem  !important",
});

export const ContactListHeading = styled(Typography)({
	fontFamily: "Helvetica Neue  !important",
	fontWeight: "400  !important",
	fontSize: "1rem  !important",
	marginLeft: "0.5rem  !important",
});

export const InvoiceListSubHeading = styled(Typography)({
	position: "absolute",
	top: -40,
	left: 5,
	fontFamily: "Helvetica Neue !important",
	fontWeight: "500 !important",
	fontSize: (props) =>
		props.isSmallForTableHeader ? "12px !important" : "0.875rem !important",
	color: "#666666",
	width: "2.8125rem",
});

export const SerialNoColumn = styled(Box)({
	width: "12%",
	position: "relative",
	paddingLeft: "0.33rem !important",
	display: "flex",
	alignItems: "center",
});

export const InvoiceNameColumn = styled(Box)({
	display: "flex",
	alignItems: "center",
	width: (props) => (props.isSmallForTableHeader ? "35%" : "30%"),
	position: "relative",
	// paddingLeft: "0.33rem !important",
});

export const ContractNameColumn = styled(Box)({
	display: "flex",
	alignItems: "center",
	width: (props) => (props.isSmallForTableHeader ? "35%" : "30%"),
	position: "relative",
	// paddingLeft: "0.33rem !important",
});

export const ImageBox = styled(Box)({
	width: "2.5rem",
	height: "2.5rem",
});

export const InvoiceImg = styled("img")({
	maxWidth: "100%",
	maxHeight: "100%",
});

export const InvoiceName = styled(Typography)({
	fontFamily: "Helvetica Neue !important",
	fontWeight: "400 !important",
	fontSize: (props) =>
		props.isSmallForTableHeader ? "12px !important" : "1rem !important",
	marginLeft: "1rem !important",
	color: "#121212",
});

export const ContractName = styled(Typography)({
	fontFamily: "Helvetica Neue !important",
	fontWeight: "400 !important",
	fontSize: (props) =>
		props.isSmallForTableHeader ? "12px !important" : "1rem !important",
	marginLeft: "1rem !important",
	color: "#121212",
});

export const InvoiceNameHeading = styled(Typography)({
	position: "absolute",
	top: -40,
	left: 0,
	fontFamily: "Helvetica Neue !important",
	fontWeight: "500 !important",
	fontSize: (props) =>
		props.isSmallForTableHeader ? "12px !important" : "0.875rem !important",
	color: "#666666",
	width: "6.25rem",
});

export const ContractNameHeading = styled(Typography)({
	position: "absolute",
	top: -40,
	left: 0,
	fontFamily: "Helvetica Neue !important",
	fontWeight: "500 !important",
	fontSize: (props) =>
		props.isSmallForTableHeader ? "12px !important" : "0.875rem !important",
	color: "#666666",
	width: "6.25rem",
});

export const DateColumn = styled(Box)({
	width: (props) =>
		props.isSmallForTableHeader ? "20% !important" : "20% !important",
	position: "relative",
	// paddingLeft: "0.33rem !important",
	display: "flex",
	alignItems: "center",
});

export const columnHeading = styled(Typography)({
	fontFamily: "Helvetica Neue  !important",
	fontWeight: "500  !important",
	fontSize: (props) =>
		props.isSmallForTableHeader ? "12px !important" : "1rem  !important",
	color: "#121212",
});

export const DateSubHeading = styled(Typography)({
	position: "absolute",
	top: -40,
	left: 0,
	fontFamily: "Helvetica Neue  !important",
	fontWeight: "500  !important",
	fontSize: (props) =>
		props.isSmallForTableHeader ? "12px  !important" : "0.875rem  !important",
	color: "#666666",
	width: "6.25rem",
});

export const AmountColumn = styled(Box)({
	width: "18%",
	position: "relative",
	// paddingLeft: "0.33rem !important",
	display: "flex",
	alignItems: "center",
	// border: '1px solid black'
});

export const LastUpdatedColumn = styled(Box)({
	width: "18%",
	position: "relative",
	// paddingLeft: "0.33rem !important",
	display: "flex",
	alignItems: "center",
	// border: '1px solid black'
});

export const AmountHeading = styled(Typography)({
	position: "absolute",
	top: -40,
	left: 0,
	fontFamily: "Helvetica Neue !important",
	fontWeight: "500 !important",
	fontSize: (props) =>
		props.isSmallForTableHeader ? "12px !important" : "0.875rem !important",
	color: "#666666",
	width: "6.25rem",
});

export const LastUpdatedHeading = styled(Typography)({
	position: "absolute",
	top: -40,
	left: 0,
	fontFamily: "Helvetica Neue !important",
	fontWeight: "500 !important",
	fontSize: (props) =>
		props.isSmallForTableHeader ? "12px !important" : "0.875rem !important",
	color: "#666666",
	width: "6.25rem",
});

export const StatusColumn = styled(Box)({
	width: (props) =>
		props.isSmallForTableHeader ? "15%  !important" : "10%  !important",
	position: "relative",
	// paddingLeft: "0.33rem !important",
	display: "flex",
	alignItems: "center",
});

export const StatusHeading = styled(Typography)({
	fontFamily: "Helvetica Neue !important",
	fontWeight: "400 !important",
	fontSize: (props) =>
		props.isSmallForTableHeader ? "12px !important" : "1rem !important",
	color: (props) => (props.item === "Unpaid" ? "#FF6B6B" : "#02A93B"),
});

export const StatusSubHeading = styled(Typography)({
	position: "absolute",
	top: -40,
	left: 0,
	fontFamily: "Helvetica Neue !important",
	fontWeight: "500 !important",
	fontSize: (props) =>
		props.isSmallForTableHeader ? "12px !important" : "0.875rem !important",
	color: "#666666",
	width: "6.25rem",
});

export const DownloadColumn = styled(Box)({
	display: "flex",
	alignItems: "center",
});

export const DownloadInner = styled(Box)({
	width: "1.5rem",
	height: "1.5rem",
	cursor: "pointer",
});
