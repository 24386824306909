import {
  Box,
  FormControl,
  FormHelperText,
  InputLabel,
  OutlinedInput,
  Select,
  Typography,
} from "@mui/material";
import PhoneInput from "react-phone-input-2";
import styled from "styled-components";
import { color } from "../../utils/Colors";

export const Wrapper = styled(Box)({
  display: "flex",
  flexDirection: "column",
  backgroundColor: "#fff",
  padding: "16px",
  marginTop: "20px",
  boxShadow:
    "0px 0px 1px rgba(37, 38, 45, 0.25), 0px 2px 4px rgba(37, 38, 45, 0.15)",
  borderRadius: "16px",
});

export const SubWrapper = styled(Box)({
  display: "flex",
  marginTop: 0,
  width: "100%",
  justifyContent: "space-between",
});

export const FormNameWrapper = styled(FormControl)(({ width }) => ({
  width: width || "45%", // Use the width prop if provided, otherwise default to "45%"
  height: "2.75rem",
  fontSize: "1rem",
  color: "#666666",
  backgroundColor: "#FFFFFF",
  fontFamily: "Helvetica Neue",
}));

export const FormNameLabelWrapper = styled(InputLabel)({
  fontSize: "0.875rem !important",
  marginTop: "0.25rem !important",
  opacity: 0.5,
  backgroundColor: "#FFFFFF !important",
  fontFamily: "Helvetica Neue !important",
});

export const FormNameInputWrapper = styled(OutlinedInput)({
  width: "100% !important",
  height: "2.75rem !important",
  fontSize: "1rem !important",
  color: "#666666 !important",
  backgroundColor: "#FFFFFF !important",
  fontFamily: "Helvetica Neue !important",
});

export const ErrorMessageText = styled(FormHelperText)({
  color: "red !important"
});

export const PhoneInputWrapper = styled(PhoneInput)({
  width: "100%",
  height: "2.75rem",
  fontSize: "0.875rem",
  color: "#666666",
  backgroundColor: "#FFFFFF",
  fontFamily: "Helvetica Neue",
  "&.Mui-focused .MuiOutlinedInput-notchedOutline": {
    // borderColor: Theme.FieldBorderColor,
  },
});
export const StyledPhoneInput = styled(PhoneInput)(
  ({ theme }) => ({
    width: "100%",
    height: "2.75rem",
    fontSize: "0.875rem",
    color: "#666666",
    backgroundColor: "#FFFFFF",
    fontFamily: "Helvetica Neue",
    '&.Mui-focused .MuiOutlinedInput-notchedOutline': {
      borderColor: theme.FieldBorderColor,
    },
  })
);

export const AccountSelectStyle = styled(Select)({
  height: "2.75rem !important",
  fontSize: "1rem !important",
  color: "#666666 !important",
  backgroundColor: "#FFFFFF !important",
  fontFamily: "Helvetica Neue !important",
});

export const RightLeftContainer = styled(Box)({
  display: "flex",
  flexDirection: "column",
  width: "44%",
});

export const ChangePasswordBtn = styled(Typography)({
  cursor: "pointer !important",
  fontFamily: "Helvetica Neue !important",
  fontSize: "1rem !important",
  fontWeight: "500 !important",
  textDecorationLine: "underline !important",
  color: `${color.themeColor} !important`,
  marginTop: "1.5rem !important",
});

export const DrawerContainer = styled(Box)({
  backgroundColor: "#fff",
  width: "32.3125rem",
  padding: "1rem 1.5rem",
  position: "relative",
});

export const ChangePassHeading = styled(Typography)({
  textAlign: "center !important",
  fontFamily: "Helvetica Neue !important",
  fontSize: "1.5rem !important",
  fontWeight: "500 !important",
  color: "#000000 !important",
});

export const ChangePassImgContainer = styled(Box)({
  position: "absolute",
  cursor: "pointer",
  top: 15,
  right: 15,
  width: "1.5rem",
  height: "24px",
});

export const ChangePwdError = styled(Typography)({
  color: (props) =>
    props.success ? "#02A93B !important" : "#FF6B6B !important",
  fontFamily: "Helvetica Neue !important",
  fontSize: "0.875rem !important",
  textAlign: "center !important",
});

export const ChangePasswrdContainer = styled(Box)({
  display: "flex",
  flexDirection: "column",
  marginTop: (props) => props.mt,
});

export const ChangePasswrdHeading = styled(Typography)({
  marginBottom: "0.5rem !important",
  fontFamily: "Helvetica Neue !important",
  fontSize: "0.75rem !important",
  fontWeight: "500 !important",
  color: "#666666 !important",
});

export const HideShowImg = styled("img")({
  width: "1.125rem",
  height: "1.125rem",
  cursor: "pointer",
});
