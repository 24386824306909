export const color = {
	buttonColor: "#3646AC",
	buttonColorOpacity: "#B57DD4",
	chevronSelectedColor: "#185BC3",
	chevronDisabledColor: "#BCBCBC",
	searchButtonColor: "#000",
	black: "#000000",
	whiteColor: "#fff",
	dashboardCardTitle: "#0C0D0F",
	sidebarItemSelected: "#538BF40D",
	sidebarItemHover: "#F9F9F9",
	themeColor: "#538BF4",
	themeGray: "#666666",
	themeGray2: "#0000004f",
	// loadingColor: "rgba(39, 39, 39, 0.99)",
	loadingColor: "#699AF5",
	themeBlack: "#122121",
	themeLightGray: "#D9D9D9",
	themeDarkGray: "#929396",
	themeRed: "#FF5A5A",
	layoutBgColor: "#FAFCFF",
	// #193241  #4E5CB6
	lightThemeColor: "rgba(54, 70, 172, 0.05)",
	tableHeaderTextColor: "#566266",
	tableHeaderColor: "#F8F9FC",
	tableCellTextColor: "#25262D",
	tableCellSecondaryTextColor: "#989CAA",
	buttonColor: "#25262D",
	comparisonChipPositiveBackgroundColor: "rgba(42, 144, 73, 0.08)",
	comparisonChipPositiveTextColor: "#2A9049",
	comparisonChipNegativeBackgroundColor: "rgba(255, 79, 79, 0.08)",
	comparisonChipNegativeTextColor: "#FF4F4F",
	barActiveColor: "#4A90E2",
	barIdleColor: "#B6D9F5",
};
