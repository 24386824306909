import {
  Box,
  Grid,
  useMediaQuery,
} from "@mui/material";
import React, { useState, useCallback, useEffect, useRef } from "react";
import { Helmet } from "react-helmet";
import { useSelector } from "react-redux";
import { useNavigate } from "react-router-dom";
import NaLayout from "../../components/NavLayout/NaLayout";
import { color } from "../../utils/Colors";
import chatBotlogo from "../../assets/ChatBot.svg";
import maillogo from "../../assets/mail.svg";
import linkDnLogo from "../../assets/linkdinWhite.svg";
import { madTechState } from "../../features/madTechSlice";
import { useAddContactMutation } from "../../services/query";
import CommonButton from "../../components/CommonButton/CommonButton";
import MailLogo from "../../components/MailLogo/MailLogo";
import useDocumentTitle from "../../hook/useDocumentTitle";
import { BASE_URL } from "../../utils/Baseurl";
import {  ConnectWithUsSubHeading, EmailUsSubHeading, Heading, 
  IsFormSubmited, 
  IsFormSubmitedButton, 
  IsFormSubmitedContainer, 
  IsFormSubmitedHeading, 
  IsFormSubmitedOuter, 
  IsFormSubmitedSubHeading, 
  MailLogoWrapper, 
  OtherContactUsWrapper, SocialMediaHeading, SocialMediaIcons, SocialMediaSubHeading, SubHeading, Wrapper } from "./ContactUs.styles";
import OthersContactUs from "./OtherContactUs";





const ContactUs = () => {
  const matchemd = useMediaQuery("(min-width:900px)");
  // const matchelg = useMediaQuery('(min-width:1400px)');

  useDocumentTitle("Contact Us");
  const navigate = useNavigate();
  const { isPlatformContact } = useSelector(madTechState);
  const captchaRef = useRef(null);

  const [name, setName] = useState("");
  const [companyName, setCompanyName] = useState("");
  const [companyWebsite, setCompanyWebsite] = useState("");
  const [email, setEmail] = useState("");
  const [phoneNumber, setPhoneNumber] = useState("");
  const [documentationUrl, setDocumentationUrl] = useState("");
  const [comment, setComment] = useState("");
  const [enquireAs, setEnquireAs] = useState("BRAND");
  const [contactMsg, setcontactMsg] = useState("");
  const [nameError, setNameError] = useState(false);
  const [emailError, setEmailError] = useState(false);
  const [phoneError, setPhoneError] = useState(false);
  const [commentError, setCommentError] = useState(false);
  const [companyError, setCompanyError] = useState(false);
  const [isFormSubmited, setIsformSubmited] = useState(false);
  const [captchaValue, setCaptchaValue] = useState(null);
  const [loaderVisible, setLoaderVisible] = useState(false);

  const [addContact, { data, isLoading, error }] = useAddContactMutation();

  const validateName = useCallback(() => {
    if (name.trim() === "") {
      setNameError(true);
      return true;
    }
    return false;
  }, [name]);

  const validateCompany = useCallback(() => {
    if (companyName.trim() === "") {
      setCompanyError(true);
      return true;
    }
    return false;
  }, [companyName]);

  const validateCommentError = useCallback(() => {
    if (comment.trim() === "") {
      setCommentError(true);
      return true;
    }
    return false;
  }, [comment]);

  const validateEmail = useCallback(() => {
    if (
      !email.match(
        /^(([^<>()[\]\\.,;:\s@]+(\.[^<>()[\]\\.,;:\s@]+)*)|(.+))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/
      )
    ) {
      setEmailError(true);
      return true;
    } else {
      setEmailError(false);
    }
    return false;
  }, [email]);

  const validatePhone = useCallback(() => {
    if (
      phoneNumber.trim() === "" ||
      6 > phoneNumber.length ||
      phoneNumber.length > 15 ||
      !/^[0-9]+$/.test(phoneNumber)
    ) {
      setPhoneError(true);
      return true;
    } else {
      setPhoneError(false);
    }
    return false;
  }, [phoneNumber]);

  const captureCaptcha = async () => {
    try {
      // console.log("Captcha value:", captchaValue);
      setLoaderVisible(true);
      setcontactMsg("");
      if (
        validateName() ||
        validateCompany() ||
        validateEmail() ||
        validateCommentError()
      ) {
        setLoaderVisible(false); 
        return;
      }
      if (phoneNumber && validatePhone()) {
        setLoaderVisible(false);
        return;

      }

      if (captchaValue) {
        const recaptchaesponse = await fetch(`${BASE_URL}/recaptcha/verify`, {
          method: "POST",
          headers: { "Content-Type": "application/json" },
          body: JSON.stringify({ token: captchaValue }),
        });
        const resData = await recaptchaesponse.json();
        // console.log('recaptcha response', resData);
        if (resData?.statusCode === 200) {
          handleContactSubmit();
        } else {
          setcontactMsg(resData?.statusMessage);
        }
      } else {
        // console.log('fill captcha');
        setcontactMsg("Please verify recaptcha");
      }
    } catch (error) {
      console.log("recaptcha error", error);
    }
    finally {
      setLoaderVisible(false); // Hide loader when response is received
    }
  };

  const handleContactSubmit = useCallback(() => {
    // if (validateName() || validateCompany() || validateEmail() || validateCommentError()) {
    //     return
    // }
    // if (phoneNumber && validatePhone()) {
    //     return
    // }

    var type = "BRAND/PUBLISHER";
    if (enquireAs === "PLATFORM") {
      type = "PLATFORM";
    }

    addContact({
      comment,
      companyName,
      companyWebsite,
      documentationUrl,
      email,
      enquireAs,
      name,
      phoneNumber,
      type,
      // isPlatformContact ? 'PLATFORM' : 'OTHER'
    });
    // setIsformSubmited(true)
  }, [
    useAddContactMutation,
    name,
    companyName,
    companyWebsite,
    email,
    enquireAs,
    phoneNumber,
    documentationUrl,
    comment,
    isPlatformContact,
    validateName,
    validateEmail,
    validateCompany,
    validateCommentError,
    validatePhone,
  ]);

  useEffect(() => {
    if (data !== undefined) {
      if (data?.statusCode === 200) {
        setIsformSubmited(true);
      }
      setcontactMsg(data?.statusMessage);
      captchaRef.current.reset();
      setCaptchaValue(null);
    }
    if (error !== undefined) {
      console.log("contact error", error);
    }
  }, [data, error]);

  useEffect(() => {
    setcontactMsg("");
  }, [isPlatformContact]);

  useEffect(() => {
    setNameError(false);
    setEmailError(false);
    setCompanyError(false);
    setCommentError(false);
    setPhoneError(false);
  }, [name, email, phoneNumber, companyName, comment]);

  return (
    <NaLayout>
      {/* <Helmet>
        <script
          dangerouslySetInnerHTML={{
            __html: (function (w, d, s, l, i) {
              var f = d.getElementsByTagName(s)[0],
                j = d.createElement(s);
              j.async = !0;
              j.src =
                "//launch.amplifyreach.com/" +
                s +
                "/" +
                l +
                "/" +
                i +
                ".js?t=" +
                new Date().getTime();
              f.parentNode.insertBefore(j, f);
            })(window, document, "script", "2005", "c97f6e41b15f6dd9"),
          }}
        ></script>
      </Helmet> */}

      {/* Get In Touch Section */}
      <Grid container sx={{ mx: "auto" }}>
        <Grid
          item
          md={6}
          lg={6}
          sm={12}
          xs={12}
          bgcolor={"#193241"}
          sx={{ display: "flex", justifyContent: "flex-end" }}
        >
          <Wrapper matchemd={matchemd} >
            <Box sx={{ width: "80%" }}>
              <Heading>Get in touch </Heading>
            </Box>
            <SubHeading > Our team would love to hear from you.</SubHeading>

            <Box sx={{ mt: 8 }}>
              {/* Chat to us section */}
              <Box sx={{ display: "flex", flexDirection: "row" }}>
                <SocialMediaIcons onClick={() => window.launchChatWidget()}>
                  <img
                    src={chatBotlogo}
                    style={{ width: "1.25rem", height: "1.25rem" }}
                    alt="chat logo"
                  />
                </SocialMediaIcons>

                <Box sx={{ ml: 3 }}>
                  <SocialMediaHeading>
                    Chat to us
                  </SocialMediaHeading>
                  <SocialMediaSubHeading > Get questions answered quickly with our live chat feature</SocialMediaSubHeading>
                  {/* <Typography sx={{
                                        width: '70%',
                                        mt: "0.5rem", textAlign: "left", color: '#fff', fontWeight: 400, fontFamily: 'Helvetica Neue',
                                        fontSize: '0.8rem', textDecorationLine: 'underline'
                                    }}>
                                        example@help.com
                                    </Typography> */}
                </Box>

              </Box>

                {/* Email Us */}
              <Box sx={{ display: "flex", flexDirection: "row", my: 10 }}>
                <SocialMediaIcons
                  onClick={() => window.open("mailto:partnerships@madtech.io")}
                >
                  <img
                    src={maillogo}
                    style={{ width: "1.25rem", height: "1.25rem" }}
                    alt="chat logo"
                  />
                </SocialMediaIcons>
                <Box sx={{ ml: "1.5rem" }}>
                  <SocialMediaHeading >EMAIL US</SocialMediaHeading>
                  <EmailUsSubHeading> Drop us a line  </EmailUsSubHeading>
                  {/* <Typography sx={{
                                        width: '70%', mt: "0.5rem", textAlign: "left", color: '#fff', fontWeight: 400, fontFamily: 'Helvetica Neue',
                                        fontSize: '1rem', textDecorationLine: 'underline'
                                    }}>
                                        <a href="mailto:partnerships@madtech.io" style={{ color: '#fff' }}>partnerships@madtech.io</a>
                                    </Typography> */}
                </Box>
              </Box>

              {/* Connect with us */}
              <Box sx={{ display: "flex", flexDirection: "row", mb: 6 }}>
                <SocialMediaIcons
                  onClick={() =>
                    window.open(
                      "https://www.linkedin.com/company/madtechadvisors/about/?viewAsMember=true"
                    )
                  }
                >
                  <img
                    src={linkDnLogo}
                    style={{ width: "1.25rem", height: "1.25rem" }}
                    alt="chat logo"
                  />
                </SocialMediaIcons>

                <Box sx={{ ml: "1.5rem" }}>
                  <SocialMediaHeading>
                    CONNECT WITH US
                  </SocialMediaHeading>
                  <ConnectWithUsSubHeading >
                    Follow us on LinkedIn
                  </ConnectWithUsSubHeading>
                  {/* <Typography sx={{
                                        width: '70%',
                                        mt: "0.5rem", textAlign: "left", color: '#fff', fontWeight: 400, fontFamily: 'Helvetica Neue',
                                        fontSize: '0.8rem', textDecorationLine: 'underline'
                                    }}>
                                        +91-9837654321
                                    </Typography> */}
                </Box>
              </Box>

            </Box>

            <Box
              sx={{
                my: "2.5rem",
                width: "80%",
                border: "1px dashed rgba(255, 255, 255, 0.2)",
              }}
            />
          </Wrapper>
        </Grid>

       {/* contact us with any quesion  */}
        {isFormSubmited ? (
          <Grid item md={6} lg={6} sm={12} xs={12}>
            <IsFormSubmited >
              <IsFormSubmitedContainer>
                <IsFormSubmitedOuter >
                  <MailLogoWrapper>
                    <MailLogo />
                  </MailLogoWrapper>
                  <IsFormSubmitedHeading>
                    Thank you for connecting with us!
                  </IsFormSubmitedHeading>
                  <IsFormSubmitedSubHeading> Our team will connect with you as soon as possible </IsFormSubmitedSubHeading>
                  <IsFormSubmitedButton>
                    <CommonButton
                      width={240}
                      height={"2.75rem"}
                      textStyle={{
                        fontSize: "1.125rem",
                        fontWeight: "500",
                        color: "#3843AC",
                      }}
                      onClick={(event) => {
                        // dispatch(setLoggedin())
                        navigate("/");
                      }}
                      title={"Back to home"}
                      color={"#FFFFFF"}
                      hasBorder={`1px solid ${"#3843AC"}`}
                    ></CommonButton>
                  </IsFormSubmitedButton>
                </IsFormSubmitedOuter>
              </IsFormSubmitedContainer>
            </IsFormSubmited>
          </Grid>
        ) : (
          <Grid item md={6} lg={6} sm={12} xs={12}>
            <OtherContactUsWrapper>
              <OthersContactUs
                name={name}
                setName={setName}
                companyName={companyName}
                setCompanyName={setCompanyName}
                enquireAs={enquireAs}
                setEnquireAs={setEnquireAs}
                email={email}
                setEmail={setEmail}
                phoneNumber={phoneNumber}
                setPhoneNumber={setPhoneNumber}
                comment={comment}
                setComment={setComment}
                isLoading={isLoading}
                handleContactSubmit={handleContactSubmit}
                contactMsg={contactMsg}
                setcontactMsg={setcontactMsg}
                nameError={nameError}
                emailError={emailError}
                phoneError={phoneError}
                commentError={commentError}
                companyError={companyError}
                setCaptchaValue={setCaptchaValue}
                captchaRef={captchaRef}
                captureCaptcha={captureCaptcha}
                loaderVisible={loaderVisible}
              />
            </OtherContactUsWrapper>
            {/* {isPlatformContact ?
                            <div className='login-signup-container' style={{ marginTop: "1rem" }}>
                                <PlatformContact
                                    name={name} setName={setName} companyName={companyName}
                                    setCompanyName={setCompanyName} companyWebsite={companyWebsite}
                                    setCompanyWebsite={setCompanyWebsite} email={email} setEmail={setEmail}
                                    phoneNumber={phoneNumber} setPhoneNumber={setPhoneNumber}
                                    documentationUrl={documentationUrl} setDocumentationUrl={setDocumentationUrl}
                                    comment={comment} setComment={setComment} isLoading={isLoading}
                                    handleContactSubmit={handleContactSubmit} contactMsg={contactMsg}
                                    setcontactMsg={setcontactMsg} nameError={nameError} emailError={emailError}
                                    phoneError={phoneError} commentError={commentError} companyError={companyError}
                                    setCaptchaValue={setCaptchaValue}
                                />
                            </div>
                            :
                            <div className='login-signup-container' style={{ marginTop: "1rem" }}>
                                <OthersContactUs
                                    name={name} setName={setName} companyName={companyName}
                                    setCompanyName={setCompanyName} enquireAs={enquireAs}
                                    setEnquireAs={setEnquireAs} email={email} setEmail={setEmail}
                                    phoneNumber={phoneNumber} setPhoneNumber={setPhoneNumber}
                                    comment={comment} setComment={setComment} isLoading={isLoading}
                                    handleContactSubmit={handleContactSubmit} contactMsg={contactMsg}
                                    setcontactMsg={setcontactMsg} nameError={nameError} emailError={emailError}
                                    phoneError={phoneError} commentError={commentError} companyError={companyError}
                                    setCaptchaValue={setCaptchaValue}
                                />
                            </div>
                        } */}
          </Grid>
        )}
      </Grid>
    </NaLayout>
  );
};

export default ContactUs;
