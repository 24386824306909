import { CircularProgress } from "@mui/material";
import React, { Suspense } from "react";
import { createTheme, ThemeProvider } from "@mui/material/styles";
import { useSelector } from "react-redux";
import { Helmet } from "react-helmet";
import NaLayout from "../../components/NavLayout/NaLayout";
import { madTechState } from "../../features/madTechSlice";
import useDocumentTitle from "../../hook/useDocumentTitle";
import { LoadingComponent } from "./Home.styles";

import Banner from "./Banner";
import AbouMadconnect from "./AbouMadconnect";
import Benifits from "./Benifits";
import HoItWorks from "./HoItWorks";
import AboutMadTech from "./AboutMadTech";
import FAQ from "./FAQ";
import LetsConnect from "./LetsConnect";
import HomeFooter from "./HomeFooter";
import { color } from "../../utils/Colors";
import DeliversEfficiency from "./DeliversEfficiency";

const theme = createTheme();

const Home = () => {
	useDocumentTitle("MadConnect");
	const { imageLoaded } = useSelector(madTechState);

	const ShowLoader = () => {
		return (
			<LoadingComponent>
				<CircularProgress
					variant="indeterminate"
					disableShrink
					sx={{ color: color.loadingColor }}
					size={45}
					thickness={8}
				></CircularProgress>
			</LoadingComponent>
		);
	};

	return (
		<ThemeProvider theme={theme}>
			{/* <Helmet>
                <script dangerouslySetInnerHTML={{
                    __html: (function (w, d, s, l, i) {
                        var f = d.getElementsByTagName(s)[0], j = d.createElement(s); j.async = !0; j.src =
                            '//launch.amplifyreach.com/' + s + '/' + l + '/' + i + '.js?t=' + new Date().getTime(); f.parentNode.insertBefore(j, f)
                    })(window, document, 'script', '2005', 'c97f6e41b15f6dd9')
                }}>
                </script>
            </Helmet> */}
			<NaLayout>
				<Banner />
				{imageLoaded ? (
					<Suspense fallback={ShowLoader()}>
						<AbouMadconnect />
						<Benifits />
						<HoItWorks />
						<DeliversEfficiency />
						<AboutMadTech />
						<FAQ />
						<LetsConnect />
						<HomeFooter />
					</Suspense>
				) : (
					ShowLoader()
				)}
			</NaLayout>
		</ThemeProvider>
	);
};

export default Home;
