import React, { useEffect, useState } from "react";
import {
	Box,
	Checkbox,
	CircularProgress,
	ListItemText,
	MenuItem,
} from "@mui/material";
import { useSelector } from "react-redux";
import BookmarkBorderOutlinedIcon from "@mui/icons-material/BookmarkBorderOutlined";
import { useForm, Controller } from "react-hook-form";

import { PANEL, getPanelType, madTechState } from "../../features/madTechSlice";
import { Strings } from "../../utils/Strings";
import CommonButton from "../../components/CommonButton/CommonButton";
import {
	useGetSRoleMutation,
	useGetUsersForSProfileMutation,
	useUpdateSUserMutation,
} from "../../services/query";
import { useGetAllSProfileAccountsMutation } from "../../services/accountsQuery";
import { useMyContext } from "../../components/CommanToasterContext/toast";

import SBreadCrumb from "../../components/Setting/SBreadCrumb";
import Loader from "../../components/Setting/Loader";
import {
	AccountSelectStyle,
	ChangePasswordBtn,
	FormNameInputWrapper,
	FormNameLabelWrapper,
	FormNameWrapper,
	PhoneInputWrapper,
	RightLeftContainer,
	SubWrapper,
	Wrapper,
} from "./SMyProfile.styles";
import ProfileDrawer from "./ProfileDrawer";
import { color } from "../../utils/Colors";

export const renderAccountNames = (selectedIds, accounts) => {
	const selectedNames = accounts
		.filter((account) => selectedIds.includes(account.id))
		.map((account) => account.name)
		.join(", ");

	return selectedNames;
};

const nameDetails = [
	{
		id: "firstName",
		label: "First name*",
	},
	{
		id: "lastName",
		label: "Last name*",
	},
];

export default function SMyProfile() {
	const { user } = useSelector(madTechState);
	const { setOpen, setOpen2, setMessage, setMessage2 } = useMyContext();
	const panelType = useSelector(getPanelType);

	const [userDetails, setUserDetails] = useState(null);
	const [roles, setRoles] = useState(null);
	const [accounts, setAccounts] = useState([]);
	const [drawerOpen, setDrawerOpen] = useState(false);

	const {
		control,
		handleSubmit,
		setValue,
		formState: { errors },
	} = useForm();

	const [getAllAccounts, { data, isLoading, error }] =
		useGetAllSProfileAccountsMutation();

	const toggleDrawer = () => {
		setDrawerOpen(!drawerOpen);
	};

	useEffect(() => {
		if (data !== undefined) {
			if (data?.statusCode === 200 && data?.response)
				setAccounts(data?.response);
			else setAccounts([]);
		}

		if (error !== undefined) {
			console.log("error", error);
			setAccounts([]);
		}
	}, [data, error]);

	const _handleUpdateUser = (data) => {
		updateSUser({ userId: user.userId, ...data });
	};

	const [
		updateSUser,
		{
			data: updateSUserData,
			isLoading: updateSUserLoading,
			error: updateSUserError,
		},
	] = useUpdateSUserMutation();

	const [
		getData,
		{ data: userData, isLoading: getUserLoading, error: getUserError, refetch },
	] = useGetUsersForSProfileMutation();

	const [
		getRoleData,
		{ data: roleData, isLoading: roleLoading, error: roleError },
	] = useGetSRoleMutation();

	useEffect(() => {
		getData();
	}, []);

	useEffect(() => {
		getRoleData();
	}, []);

	useEffect(() => {
		if (updateSUserData !== undefined) {
			if (updateSUserData?.statusCode === 200) {
				setMessage(updateSUserData?.statusMessage);
				setOpen(true);
			} else {
				setMessage2(updateSUserData?.statusMessage);
				setOpen2(true);
			}
		}
		if (updateSUserError !== undefined) {
			console.log("updateSUserError", updateSUserError);
		}
	}, [updateSUserData, updateSUserError]);

	useEffect(() => {
		if (roleData !== undefined) {
			if (roleData?.statusCode === 200 && roleData?.response) {
				setRoles(roleData?.response);
			} else {
				setRoles([]);
			}
		}

		if (roleError !== undefined) {
			setRoles([]);
		}
	}, [roleData, roleError]);

	useEffect(() => {
		if (panelType !== PANEL.PLATFORM_USER) getAllAccounts();
	}, [panelType]);

	useEffect(() => {
		if (
			userData !== undefined &&
			userData?.statusCode === 200 &&
			userData?.response
		) {
			var response = {
				...userData?.response,
				accounts: userData?.response?.accountList
					? userData.response?.accountList
					: [],
			};
			setUserDetails(response);
			// Set initial form values
			setValue("firstName", response?.firstName);
			setValue("lastName", response?.lastName);
			setValue("email", response?.email);
			setValue("phoneNumber", response?.phoneNumber);
			setValue("companyName", response?.companyName);
			setValue("title", response?.title);
			setValue("roleId", response?.roleId);
			setValue("accounts", response?.accounts);
		}

		if (getUserError !== undefined) {
			console.log("error", getUserError);
			setUserDetails({
				firstName: "",
				lastName: "",
				email: "",
				phoneNumber: "",
				companyName: user?.companyName,
				title: "",
				roleId: "",
				accounts: [],
			});
		}
	}, [userData, getUserError]);

	const showLoader = () => {
		return (
			roleLoading ||
			roles == null ||
			accounts === null ||
			isLoading ||
			getUserLoading ||
			userDetails === null
		);
	};

	return (
		<Box width="100%" px="1.5rem" pb="1.5rem">
			<SBreadCrumb mainPathName="Settings" pathName="My Profile" />
			{showLoader() ? (
				<Loader />
			) : (
				<Wrapper>
					<form onSubmit={handleSubmit(_handleUpdateUser)}>
						<SubWrapper>
							<RightLeftContainer>
								<Box display="flex" justifyContent="space-between">
									{nameDetails?.map((detail, index) => (
										<FormNameWrapper size="small" key={index}>
											<FormNameLabelWrapper id={detail.id}>
												{detail.label}
											</FormNameLabelWrapper>
											<Controller
												name={detail.id}
												control={control}
												rules={{ required: `${detail.label} is required` }}
												render={({ field }) => (
													<FormNameInputWrapper
														placeholder={detail.label}
														labelid={detail.id}
														id={detail.id}
														label={detail.label}
														{...field}
														error={!!errors[detail.id]}
														helperText={
															errors[detail.id] ? errors[detail.id].message : ""
														}
													/>
												)}
											/>
										</FormNameWrapper>
									))}
								</Box>
								<FormNameWrapper
									sx={{
										width: "100%",
										mt: 3,
									}}
									size="small"
								>
									<FormNameLabelWrapper id="workEmail">
										Work email*
									</FormNameLabelWrapper>
									<Controller
										name="email"
										control={control}
										rules={{ required: "Email is required" }}
										render={({ field }) => (
											<FormNameInputWrapper
												placeholder={"Work email*"}
												labelid="workEmail"
												id="workEmail"
												label="Work email*"
												{...field}
												error={!!errors.email}
												helperText={errors.email ? errors.email.message : ""}
											/>
										)}
									/>
								</FormNameWrapper>
								<Box mt={3} height="2.75rem">
									<Controller
										name="phoneNumber"
										control={control}
										// rules={{ required: "Phone number is required" }}
										render={({ field }) => (
											<PhoneInputWrapper
												placeholder={`${Strings.phoneNumber}*`}
												inputClass="colors"
												country={"us"}
												countryCodeEditable={false}
												enableSearch={true}
												{...field}
												error={!!errors.phoneNumber}
												helperText={
													errors.phoneNumber ? errors.phoneNumber.message : ""
												}
											/>
										)}
									/>
								</Box>
								{false && panelType !== PANEL.PLATFORM_USER && (
									<FormNameWrapper size="small" sx={{ width: "100%", mt: 3 }}>
										<FormNameLabelWrapper id="accounts">
											Accounts*
										</FormNameLabelWrapper>
										<Controller
											name="accounts"
											control={control}
											render={({ field }) => (
												<AccountSelectStyle
													labelid="accounts"
													id="accounts"
													label="Accounts*"
													multiple
													value={field.value}
													renderValue={(selected) =>
														renderAccountNames(selected, accounts)
													}
													onChange={(e) => {
														const {
															target: { value },
														} = e;
														field.onChange(
															typeof value === "string"
																? value.split(",")
																: value
														);
													}}
													MenuProps={{
														PaperProps: { style: { maxHeight: "15.625rem" } },
													}}
												>
													{accounts?.map((account) => (
														<MenuItem
															sx={{
																fontSize: "0.875rem",
																backgroundColor: "#FFFFFF",
																fontFamily: "Helvetica Neue",
															}}
															value={account?.id}
														>
															<Checkbox
																checked={field.value.indexOf(account?.id) > -1}
															/>
															<ListItemText primary={account?.name} />
														</MenuItem>
													))}
												</AccountSelectStyle>
											)}
										/>
									</FormNameWrapper>
								)}
							</RightLeftContainer>
							<RightLeftContainer>
								<FormNameWrapper sx={{ width: "100%" }} size="small">
									<FormNameLabelWrapper id="companyName">
										Company name*
									</FormNameLabelWrapper>
									<Controller
										name="companyName"
										control={control}
										rules={{ required: "Company name is required" }}
										render={({ field }) => (
											<FormNameInputWrapper
												placeholder={"Company name*"}
												labelid="companyName"
												id="companyName"
												label="Company name*"
												{...field}
												error={!!errors.companyName}
												helperText={
													errors.companyName ? errors.companyName.message : ""
												}
											/>
										)}
									/>
								</FormNameWrapper>
								<FormNameWrapper
									sx={{
										width: "100%",
										mt: 3,
									}}
									size="small"
								>
									<FormNameLabelWrapper id="title">Title</FormNameLabelWrapper>
									<Controller
										name="title"
										control={control}
										render={({ field }) => (
											<FormNameInputWrapper
												placeholder={"Title"}
												labelid="title"
												id="title"
												label="Title123"
												{...field}
											/>
										)}
									/>
								</FormNameWrapper>
								<FormNameWrapper
									sx={{
										width: "100%",
										mt: 3,
									}}
									size="small"
								>
									<FormNameLabelWrapper id="role">Role*</FormNameLabelWrapper>
									<Controller
										name="roleId"
										control={control}
										// rules={{ required: true }}
										render={({ field }) => (
											<AccountSelectStyle
												labelid="role"
												id="roleId"
												label="Role12*"
												value={field.value}
												error={!!errors.roleId}
												onChange={(e) => field.onChange(e.target.value)}
											>
												{roles?.map((role) => (
													<MenuItem
														sx={{
															fontSize: "0.875rem",
															backgroundColor: "#FFFFFF",
															fontFamily: "Helvetica Neue",
														}}
														value={role.id}
													>
														{role.name}
													</MenuItem>
												))}
											</AccountSelectStyle>
										)}
									/>
								</FormNameWrapper>
								<ChangePasswordBtn onClick={toggleDrawer}>
									Click here to change password
								</ChangePasswordBtn>
							</RightLeftContainer>
							<ProfileDrawer
								drawerOpen={drawerOpen}
								setDrawerOpen={setDrawerOpen}
								toggleDrawer={toggleDrawer}
							/>
						</SubWrapper>

						<Box display="flex" mt={2} width="100%">
							<CommonButton
								width={106}
								height={40}
								textStyle={{
									fontSize: "1rem",
									fontWeight: 600,
									fontFamily: "Helvetica",
									letterSpacing: "normal",
								}}
								disabled={updateSUserLoading}
								startIcon={
									!updateSUserLoading ? (
										<BookmarkBorderOutlinedIcon
											startIconStyle={{ height: 24, width: 24 }}
											sx={{ marginRight: "-8px", marginLeft: "4px" }}
										/>
									) : (
										<CircularProgress
											variant="indeterminate"
											disableShrink
											style={{ color: color.loadingColor }}
											size={20}
											thickness={6}
										></CircularProgress>
									)
								}
								customStyle={{
									marginRight: "20px",
									marginTop: "20px",
								}}
								type="submit"
								title={Strings.submit}
								color={color.themeColor}
							></CommonButton>
						</Box>
					</form>
				</Wrapper>
			)}
		</Box>
	);
}
