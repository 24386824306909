import React, { useEffect, useState } from 'react'
import { Box, Button, Grid, Typography, useMediaQuery } from '@mui/material';

import madConnectLogo from "../../assets/madconnectdark.png";
import CommonButton from '../../components/CommonButton/CommonButton';
import { useLocation, useNavigate } from 'react-router-dom';
import { useGetOAuthauthorizeMutation } from '../../services/query';
import Loader from '../../components/Setting/Loader';

const Authorization = () => {

  const [authorizedData, setAuthorizedData] = useState([])
  const [loading, setLoading] = useState(true)

  const location = useLocation();
  const navigate = useNavigate()
  const queryParams = new URLSearchParams(location.search);
  const clientId = queryParams.get('clientId');

  const [getAuthorizeConfiguration, { data: authorizeData, isLoading: authorizeLoading, error: authorizeError }] = useGetOAuthauthorizeMutation()

  useEffect(() => {
    if (clientId === null) {
      window.close()
    } else {
      getAuthorizeConfiguration(clientId)
    }
  }, [clientId])

  useEffect(() => {
    setLoading(true)
    if (authorizeData !== undefined) {
      setAuthorizedData(authorizeData?.response)
      setLoading(false)
    }
  }, [authorizeData])

  const logoUrl = authorizedData?.appLogo?.startsWith("http")
    ? authorizedData.appLogo
    : `/${authorizedData.appLogo}`;

  const maxHeight = useMediaQuery('(max-height:515px)');


  return (
    <Box display='flex' justifyContent='center' bgcolor="#C4C4C4" alignItems="center" height={maxHeight ? "" : "100vh"}>
      <div style={{ display: "block" }}>
        {loading ? <Loader /> :
          <>
            <div style={{ textAlign: "center", padding: "20px" }}>
              <img style={{ width: "auto", height: "4rem", justifyContent: 'center' }} alt={"MADCONNECT LOGO"} src={madConnectLogo}  ></img>
            </div>
            <Grid item md={5} lg={5} sm={12} xs={12} bgcolor="">
              <div style={{ backgroundColor: "" }}>
                <div className='signin-container signin-container-2'>
                  <img style={{ width: "50%", height: "3.5rem", marginBottom: "12px" }} alt={"CLIENT LOGO"} src={logoUrl}></img>
                  <p className='text-center' style={{ fontSize: "15px", marginBottom: "12px", fontWeight: 700 }}><span style={{ fontSize: "15px", fontWeight: 700, }}>{authorizedData?.appName}</span>  application would like to access the following</p>
                  <div style={{ marginLeft: "40px" }}>
                    <p className='text-start' style={{ fontSize: "15px", marginBottom: "12px", fontWeight: 600, color: "rgb(33 37 41 / 80%)" }}>
                      * Manage your connections - Read/Write/Delete</p>
                    <p className='text-start' style={{ fontSize: "15px", marginBottom: "12px", fontWeight: 600, color: "rgb(33 37 41 / 80%)" }}>
                      * Manage connector configuration</p>
                    <p className='text-start' style={{ fontSize: "15px", marginBottom: "12px", fontWeight: 600, color: "rgb(33 37 41 / 80%)" }}>
                      * Access connection statistics and reports</p>
                    <p className='text-start' style={{ fontSize: "12px", marginBottom: "12px", fontStyle: "italic", fontWeight: 600, color: "rgb(33 37 41 / 80%)" }}>
                      By clicking Allow, you allow <span style={{ fontSize: "12px", fontWeight: 900, }}>{authorizedData?.appName}</span>
                      application to access your information in accordance to their Terms of usage and Privacy policy.</p>

                    <Box display='flex' flexDirection='row' width='100%' my='5px' gap='0.5rem'>
                      <CommonButton style={{ display: "flex", alignItems: "center", }} onClick={() => {
                        if (authorizeData !== undefined) { window.open(authorizedData.redirectUrl, '_self'); }
                      }} width={106} height={40} textStyle={{
                        fontSize: "1rem", fontWeight: "500",
                        fontFamily: "Helvetica Neue",
                      }} title="Allow" color="#3843AC" ></CommonButton>
                      <Button onClick={() => { window.close() }} style={{
                        cursor: "pointer", backgroundColor: "white", width: "6.625rem", height: "2.5rem", color: "black",
                        textTransform: "none",
                      }} variant="contained">
                        <Box display="flex" gap="0.5rem" alignItems='center'>
                          <Typography fontSize="1rem" fontWeight={500} fontFamily="Helvetica" display="flex" alignItems="center" marginTop={2}>
                            Cancel
                          </Typography>
                        </Box>
                      </Button>
                    </Box>
                  </div>
                </div>
              </div>
            </Grid>
          </>}
      </div>
    </Box>
  )
}

export default Authorization