import React from "react";
import { useMediaQuery } from "@mui/material";

import audienceActivation from "../../assets/ben1.png";
import reportingAnalytics from "../../assets/ben2.png";
import conversionsEvents from "../../assets/ben3.png";
import ThirdPartyData from "../../assets/ben4.png";
import curation from "../../assets/ben5.png";
import retailMedia from "../../assets/ben6.png";
import {
	BenefitsBoxConatiner,
	BenefitsBoxConatinerHeading,
	BenifitLowerContainer,
	BenifitSuperContainer,
	BenifitUpperContainer,
	BenifitsHeading,
} from "./Home.styles";
import { getFontSize } from "../../utils/utils";

const upperObj = [
	{
		heading: "Audience Activation",
		description:
			"Our mapping capabilities ensure data is translatable from one platform to another",
		logo: audienceActivation,
		logoAlt: "audience activation",
	},
	{
		heading: "Reporting & Analytics",
		description:
			"As MadConnect is a universal hub, connecting once will turn on multiple connections to other platforms",
		logo: reportingAnalytics,
		logoAlt: "reporting & analytics",
	},
	{
		heading: "Conversion & Events",
		description:
			"As platforms evolve APIs, MadTech will keep connections up to date, lessening the burden on your engineering resources",
		logo: conversionsEvents,
		logoAlt: "conversions & events",
	},
];

const lowerObj = [
	{
		heading: "3rd-Party Data",
		description: "End-to-end encryption",
		logo: ThirdPartyData,
		logoAlt: "3rd party data",
	},
	{
		heading: "Curation",
		description: "Unlimited data transfer through our self-service platform",
		logo: curation,
		logoAlt: "curation",
	},
	{
		heading: "Retail Media",
		description: "Unlimited data transfer through our self-service platform",
		logo: retailMedia,
		logoAlt: "retail media",
	},
];

const Benifits = () => {
	const matches = {
		lg: useMediaQuery("(min-width:1100px)"),
		md: useMediaQuery("(min-width:980px)"),
		sm: useMediaQuery("(min-width:880px)"),
		xs: useMediaQuery("(min-width:760px)"),
		xss: useMediaQuery("(min-width:650px)"),
	};

	return (
		<BenifitSuperContainer width="100%" mb={10}>
			<BenifitSuperContainer flexDirection="column" width="91%" maxWidth={1350}>
				<BenifitsHeading
					fontSize={getFontSize(matches, {
						lg: "2.5rem",
						md: "2.2rem",
						sm: "1.9rem",
						xs: "1.7rem",
						xss: "1.7rem",
					})}
				>
					Top Use Cases
				</BenifitsHeading>
				<BenifitUpperContainer matchesxss={matches.xss}>
					{upperObj?.map((details, index) => {
						return (
							<BenefitsBoxConatiner
								matchesxss={matches.xss}
								width={matches.xss ? "25%" : "100%"}
								key={index}
							>
								<img
									style={{ width: "3rem", height: 48, objectFit: "contain" }}
									alt={details.logoAlt}
									src={details.logo}
								/>
								<BenefitsBoxConatinerHeading
									fontSize={getFontSize(matches, {
										lg: "1.5rem",
										md: "1.3rem",
										sm: "1rem",
										xs: "1rem",
										xss: "1rem",
									})}
								>
									{details.heading}
								</BenefitsBoxConatinerHeading>
							</BenefitsBoxConatiner>
						);
					})}
				</BenifitUpperContainer>
				<BenifitLowerContainer matchesxss={matches.xss}>
					{lowerObj?.map((details, index) => {
						return (
							<BenefitsBoxConatiner
								matchesxss={matches.xss}
								width={matches.xss ? "25%" : "100%"}
								key={index}
							>
								<img
									style={{ width: "3rem", height: 48, objectFit: "contain" }}
									alt={details.logoAlt}
									src={details.logo}
								/>
								<BenefitsBoxConatinerHeading
									fontSize={getFontSize(matches, {
										lg: "1.5rem",
										md: "1.3rem",
										sm: "1rem",
										xs: "1rem",
										xss: "1rem",
									})}
								>
									{details.heading}
								</BenefitsBoxConatinerHeading>
							</BenefitsBoxConatiner>
						);
					})}
				</BenifitLowerContainer>
			</BenifitSuperContainer>
		</BenifitSuperContainer>
	);
};

export default Benifits;
