import {
	Box,
	Button,
	FormControl,
	TableCell,
	TableContainer,
	TableHead,
	TableRow,
	Tabs,
	Typography,
} from "@mui/material";
import styled from "styled-components";

export const TabsWrapper = styled(Tabs)({
	border: "1px solid rgba(234, 237, 246, 1) !important",
	borderRadius: "8px !important",
});

// Connection List Left
export const ListLeftWrapper = styled(Box)({
	width: (props) =>
		(props.activeTab === "In-progress" || props.activeTab === "Offline") &&
		props.tableData?.length > 0
			? "57.8% !important"
			: "100% !important",
});

export const TableContainerStyled = styled(TableContainer)({
	height: `${"calc(100vh - 13.25rem - 3vh)"} !important`,
	overflowY: "auto !important",
});

export const TableHeadStyled = styled(TableHead)({
	position: "sticky",
	top: "0 !important",
	background: "#fff",
	zIndex: "99 !important",
});

export const TableRowStyled = styled(TableRow)({
	backgroundColor: (props) =>
		props.rowSelectIndex === props.index &&
		(props.activeTab === "In-progress" || props.activeTab === "Offline")
			? "rgba(83, 139, 244, 0.1)"
			: "inherit",
	width: "100%",
	cursor: (props) =>
		props.activeTab === "In-progress" || props.activeTab === "Offline"
			? "pointer"
			: "",
	border: "1px solid rgba(236,236,236)",
});

export const TableCellStyled = styled(TableCell)({
	width: "20%",
	border: "none !important",
	//  borderTopLeftRadius: "10px !important",
	//  borderBottomLeftRadius: "10px !important",
	color: "#25262D",
	fontFamily: "Helvetica !important",
});

export const ImageBox = styled(Box)({
	display: "flex",
	justifyContent: "start",
	alignItems: "center",
	width: "90%",
	height: "3rem",
	gap: "5px",
});

export const SpanStyled = styled("span")({
	color: "#000000c2 !important",
	fontWeight: "500 !important",
	fontFamily: "Helvetica Neue !important",
	textTransform: "capitalize",
	cursor: "default",
	// width: "80%",
	fontSize: "0.875rem !important",
	whiteSpace: "nowrap",
	overflow: "hidden",
	textOverflow: "ellipsis",
});

export const DestinationContainer = styled(Box)({
	display: "flex",
	justifyContent: "start !important",
	alignItems: "center",
	width: "90%",
	height: "3rem",
	gap: "5px",
});

export const ActionTableCell = styled(TableCell)({
	width: "15%",
	border: "none !important",
	padding: "10px !important",
	// borderTopRightRadius: "10px !important",
	// borderBottomRightRadius: "10px !important",
});

export const ActionTabBox = styled(Box)({
	display: "flex",
	flexDirection: "row",
	alignItems: "center",
	marginLeft: "auto !important",
	gap: "15px",
	justifyContent: "space-around",
});

export const NoConnectionWrapper = styled(Box)({
	display: "flex",
	flexDirection: "column",
	justifyContent: "center",
	alignItems: "center",
});

export const NoConnectionHeading = styled(Typography)({
	color: "#25262D",
	textAlign: "center",
	fontSize: "1.125rem !important",
	fontWeight: "700  !important",
	fontFamily: "Helvetica Neue  !important",
});

// Delete Model
export const WrapperTypographyImage = styled(Typography)({
	fontWeight: "700 !important",
	fontFamily: "Helvetica Neue !important",
	fontSize: "1rem !important",
	textAlign: "center",
});

export const WrapperTypographyPara = styled(Typography)({
	fontWeight: "400 !important",
	fontFamily: "Helvetica Neue !important",
	fontSize: "1rem !important",
	textAlign: "center",
	color: "#656C7B",
	width: "70%",
	marginBottom: "16px !important",
});

export const BoxImg = styled(Box)({
	display: "flex",
	flexDirection: "row",
	justifyContent: "space-between",
	marginBottom: "16px !important",
	alignItems: "center",
});

export const BoxImgContainer = styled(Box)({
	display: "flex",
	flexDirection: "row",
	alignItems: "center",
	width: "100%",
	justifyContent: "space-between",
});

export const BoxImgInner = styled(Box)({
	display: "flex",
	flexDirection: "row",
	alignItems: "center",
	padding: "8px !important",
	width: "35%",
	height: "60px !important",
	borderRadius: "4px !important",
	position: "relative",
});

export const ImgStyled = styled(Box)({
	display: "flex",
	justifyContent: "center",
	alignItems: "center",
	width: "100%",
	height: "2.5rem",
	borderRadius: "4px !imporant",
	padding: "3.2px !imporant",
});

export const DivMiddelImg = styled("div")({
	marginLeft: "5px !important",
	marginRight: "5px !important",
	height: "0px",
	width: "72px",
	borderTop: "2px solid rgba(25, 50, 65, 0.5)",
});

export const BoxMiddelImg = styled(Box)({
	display: "flex",
	justifyContent: "center",
	alignItems: "center",
	width: "56px",
	height: "56px",
	borderRadius: "32px !important",
	backgroundColor: "#EDEFF0",
});

export const BoxDeleteToConfirm = styled(Box)({
	width: "100%",
	display: "flex",
	justifyContent: "center",
	flexDirection: "column",
});

export const FormControlDeleteToConfirm = styled(FormControl)({
	width: "70%",
	height: "2.75rem",
	fontSize: "1rem !important",
	color: "#666666",
	backgroundColor: "#FFFFFF",
	fontFamily: "Helvetica Neue !important",
	display: "flex",
	justifyContent: "center",
	marginRight: "auto !important",
	marginLeft: "auto !important",
});

export const ButtonWrapper = styled(Box)({
	marginTop: "24px !important",
	display: "flex",
	flexDirection: "row",
	justifyContent: "center",
	alignItems: "center",
	gap: "16px !important",
});

export const ButtonBox = styled(Box)({
	display: "flex",
	gap: "0.5rem",
	alignItems: "center",
});

export const ButtonName = styled(Typography)({
	fontSize: "1rem !important",
	fontWeight: "500 !important",
	fontFamily: "Helvetica !important",
	display: "flex",
	alignItems: "center",
	// marginTop: 2,
});

// Delete success Model

export const Para = styled(Typography)({
	fontWeight: "400 !important",
	fontFamily: "Helvetica Neue !important",
	fontSize: "1rem !important",
	textAlign: "center",
	color: "#656C7B",
	width: "70%",
});

// Connection List Right
