import React, { useCallback, useEffect, useState } from "react";
import { Box } from "@mui/material";

import ConnectionListRight from "./ConnectionListRight";
import ConnectionListLeft from "./ConnectionListLeft";
import {
	TabsHeading,
	countConnectionsByStatus,
	shouldHideBox,
} from "../Constant";
import { useGetConditionConfirmationMutation } from "../../../../services/query";
import { useMyContext } from "../../../../components/CommanToasterContext/toast";
import {
	TabHeading,
	TabHeadingCount,
	TabStyled,
} from "../Styles/MyConnection.styles";
import { TabsWrapper } from "../Styles/ConnectionList.styles";
// import {rightContainerResponse as conditionData } from '../dumyData'

const ConnectList = ({
	connectors,
	refetch,
	connectionLoading,
	connectionLoader,
	activeTab,
	setActiveTab,
}) => {
	const { setOpen2, setMessage2 } = useMyContext();

	const [
		conditionCheck,
		{ data: conditionData, isLoading: conditionLoading, error: conditionError },
	] = useGetConditionConfirmationMutation();

	const [rowSelectIndex, setRowSelectIndex] = useState(0);
	const [selectedConnection, setSelectedConnection] = useState(null);
	const [checkBoxLists, setCheckBoxLists] = useState(null);
	const [destinationCheckbox, setDestinationCheckbox] = useState(null);
	const [sourceCheckbox, setSourceCheckbox] = useState(null);
	const [connectionsByStatus, setConnectionsByStatus] = useState(null);

	const groupConnectionsByStatus = useCallback(() => {
		return connectors.reduce((acc, connection) => {
			const status = connection.status;

			if (!acc[status]) {
				acc[status] = [];
			}

			acc[status].push(connection);
			return acc;
		}, {});
	}, [connectors]);

	useEffect(() => {
		const groupedConnections = groupConnectionsByStatus();

		setConnectionsByStatus(groupedConnections);
	}, [connectors]);

	useEffect(() => {
		if (activeTab === "In-progress" || activeTab === "Offline") {
			const item = connectors?.find((item) => item.status === activeTab);
			if (item) {
				const conId = item.conId;
				conditionCheck({ connectionId: conId });
				setSelectedConnection(item);
			} else {
				console.log(`No item found with status '${activeTab}'`);
			}
		}
	}, [activeTab, connectors]);

	useEffect(() => {
		if (conditionData !== undefined) {
			if (conditionData?.statusCode === 200) {
				setCheckBoxLists(conditionData?.response);
			} else {
				setMessage2(conditionData?.statusMessage);
				setOpen2(true);
			}
		}

		if (conditionError !== undefined) {
			console.log("add Connection Error", conditionError);
			// setMessage2(conditionError.data.error)
			// setOpen2(true)
		}
	}, [conditionData, conditionError]);

	const handleChangeTab = (event, newValue) => {
		setActiveTab(newValue);
	};

	const handleRowChange = (item, index) => {
		if (activeTab === "In-progress" || activeTab === "Offline") {
			setRowSelectIndex(index);
			setSelectedConnection(item);
			conditionCheck({ connectionId: item.conId });
			setCheckBoxLists(null);
			setSourceCheckbox(null);
			setDestinationCheckbox(null);
		}
	};

	return (
		<>
			<TabsWrapper
				value={activeTab}
				onChange={handleChangeTab}
				sx={{
					border: "1px solid rgba(234, 237, 246, 1)",
					borderRadius: "8px",
					width: "fit-content",
				}}
			>
				{TabsHeading?.map((status, index) => (
					<TabStyled
						key={status}
						value={status}
						label={
							<div style={{ display: "flex", gap: "10px" }}>
								<TabHeading>{status}</TabHeading>
								<TabHeadingCount same={activeTab === status}>
									{countConnectionsByStatus(connectors, status)}
								</TabHeadingCount>
							</div>
						}
						condition={index < TabsHeading?.length - 1}
					/>
				))}
			</TabsWrapper>

			<Box
				hidden={shouldHideBox(activeTab)}
				sx={{ width: "100%", display: "flex", flex: 1 }}
			>
				<ConnectionListLeft
					activeTab={activeTab}
					handleRowChange={handleRowChange}
					rowSelectIndex={rowSelectIndex}
					connections={connectionsByStatus}
					connectors={connectors}
					refetch={refetch}
					connectionLoader={connectionLoader}
					setActiveTab={setActiveTab}
				/>
				<ConnectionListRight
					activeTab={activeTab}
					conditionLoading={conditionLoading}
					selectedConnection={selectedConnection}
					checkBoxLists={checkBoxLists}
					connectionLoading={connectionLoading}
					refetch={refetch}
					connections={connectionsByStatus}
					setSourceCheckbox={setSourceCheckbox}
					setDestinationCheckbox={setDestinationCheckbox}
					sourceCheckbox={sourceCheckbox}
					destinationCheckbox={destinationCheckbox}
					setActiveTab={setActiveTab}
				/>
			</Box>
		</>
	);
};

export default ConnectList;
