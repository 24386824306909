import styled from "styled-components";

export const SearchBarWrapper = styled.div`
  display: flex;
  align-items: center;
  gap: 10px;
  border: 1px solid #EAEDF6;
  border-radius: 10px;
  padding: 10px;
  background-color: #ffffff;
  width: 280px;
  height: 40px;
`;

export const SearchInput = styled.input`
  border: none;
  outline: none;
  font-size: 14px;
  width: 100%;
  background: transparent;

  &::placeholder {
    color: #BCC0C2;
  }
`;
