import React, { useEffect, useRef, useState } from "react";
import {
	Box,
	CircularProgress,
	Switch,
	Table,
	TableBody,
	TableCell,
	TableRow,
	Tooltip,
	Typography,
} from "@mui/material";
import { useNavigate } from "react-router-dom";
import { useSelector } from "react-redux";
import AutorenewIcon from "@mui/icons-material/Autorenew";

import { TableHeading } from "../Constant";
import { TableCellStyle } from "../Styles/MyConnection.styles";
import { favIconMapping1 } from "../../../../components/FavIconMaping/favicon";
import editImg from "../../../../assets/edit.svg";
import trash from "../../../../assets/trash-2.svg";
import { madTechState } from "../../../../features/madTechSlice";
import DeleteModal from "../Modal/DeleteModal";
import DeleteSuccessModal from "../Modal/DeleteSuccessModal";
import {
	ActionTabBox,
	ActionTableCell,
	DestinationContainer,
	ImageBox,
	ListLeftWrapper,
	SpanStyled,
	TableCellStyled,
	TableContainerStyled,
	TableHeadStyled,
	TableRowStyled,
} from "../Styles/ConnectionList.styles";
import RetriveModel from "../Modal/RetriveModel";
import snowFlakeIcon from "../../../../assets/snowflake.png";
import snowFlakeTransferIcon from "../../../../assets/snowflaketransfer.png";
import connectorImg1 from "../../../../assets/Connector.svg";
import { useSnowflakeTransferMutation } from "../../../../services/connectionsQuery";
import { useMyContext } from "../../../../components/CommanToasterContext/toast";
import { color } from "../../../../utils/Colors";

function getFavIconImagePath(key) {
	if (favIconMapping1.hasOwnProperty(key)) {
		return favIconMapping1[key];
	} else {
		return "platforms2/favicon.png";
	}
}

const ConnectionListLeft = ({
	activeTab,
	connections,
	handleRowChange,
	rowSelectIndex,
	connectors,
	refetch,
	connectionLoader,
	setActiveTab,
}) => {
	const navigate = useNavigate();

	const { selectedAccount } = useSelector(madTechState);
	const { setOpen, setMessage, setOpen2, setMessage2 } = useMyContext();

	const [isModalOpen, setIsModalOpen] = useState(false);
	const [loadingConId, setLoadingConId] = useState(null);
	const [isRetryModalOpen, setIsRetryModalOpen] = useState(false);
	const [selectedItem, setSelectedItem] = useState(null);
	const [isForDisable, setIsForDisable] = useState(false);
	const [isDeleteSuccess, setIsDeleteSuccess] = useState(false);

	const tableContainerRef = useRef(null);

	useEffect(() => {
		if (tableContainerRef.current) {
			tableContainerRef.current.scrollTo(0, 0);
		}
	}, [activeTab]);

	const handleEdit = (editId) => {
		if (editId.length > 0) {
			navigate(
				selectedAccount !== null && selectedAccount !== undefined
					? `/create-connections?accountId=${selectedAccount.id}&connectionId=${editId}`
					: `/create-connections?connectionId=${editId}`
			);
		}
	};

	const [
		transferMutation,
		{ data: transferData, loading, error: transferError },
	] = useSnowflakeTransferMutation();

	const handleClose = () => {
		setSelectedItem(null);
		setIsModalOpen(false);
	};

	const handleOpen = (item, isenable = false) => {
		setIsForDisable(isenable);
		setSelectedItem(item);
		setIsModalOpen(true);
	};

	const handleDeleteCloseSuccess = () => {
		refetch();
		setIsDeleteSuccess(false);
	};

	const handleRetry = (item) => {
		setIsRetryModalOpen(true);
		setSelectedItem(item);
	};

	const handleRetryClose = () => {
		setSelectedItem(null);
		setIsRetryModalOpen(false);
	};

	const showLoader = () => {
		return (
			<CircularProgress
				variant="indeterminate"
				disableShrink
				sx={{ color: color.loadingColor }}
				size={45}
				thickness={8}
			/>
		);
	};

	const handleSnowFlakeTransfer = async (id) => {
		setLoadingConId(id);
		try {
			await transferMutation({ id });
		} finally {
			setLoadingConId(null);
		}
	};

	useEffect(() => {
		if (transferData !== undefined) {
			if (transferData?.statusCode === 200) {
				setOpen(true);
				setMessage(transferData.statusMessage);
			} else if (transferData?.statusCode !== 200) {
				setOpen2(true);
				setMessage2(transferData?.statusMessage || "Unknown error occurred");
			}
		}
		if (transferError !== undefined) {
			console.log("transferError", transferError);
		}
	}, [transferData, transferError]);

	return (
		<>
			<ListLeftWrapper
				activeTab={activeTab}
				tableData={connections?.[activeTab]}
				className="mainContent_scroll"
			>
				<TableContainerStyled ref={tableContainerRef}>
					<Table
						style={{
							border: "none",
						}}
					>
						<TableHeadStyled>
							<TableRow style={{ border: "none" }}>
								{TableHeading?.map((value, i) => (
									<TableCellStyle
										key={i}
										hidden={value?.hidden ? value.hidden(activeTab) : false}
										center={value?.center}
										headCenter={value?.head}
										label={value?.label}
									>
										{value?.label}
									</TableCellStyle>
								))}
							</TableRow>
						</TableHeadStyled>
						<TableBody>
							{connections?.[activeTab]?.length > 0 && !connectionLoader ? (
								connections[activeTab].map((item, index) => (
									<TableRowStyled
										key={item?.id}
										onClick={() => {
											rowSelectIndex !== index && handleRowChange(item, index);
										}}
										activeTab={activeTab}
										rowSelectIndex={rowSelectIndex}
										index={index}
									>
										<TableCellStyled>
											<Typography
												sx={{ fontSize: "0.875rem", fontWeight: 500 }}
											>
												{item?.name}
											</Typography>
											<Typography
												sx={{
													fontSize: "0.875rem",
													fontWeight: 500,
													color: "#929396",
												}}
											>
												{item?.conId}
											</Typography>
										</TableCellStyled>
										<TableCell
											style={{
												width: "23%",
												border: "none",
												padding: "10px",
											}}
										>
											<ImageBox>
												<img
													alt=""
													onClick={() => handleRowChange(item, index)}
													src={`/logos/favicons/${item?.src?.id}.svg`}
													// src="https://devk0fhuj1.madconnect.io/logos/favicons/salesforce.svg"
													style={{
														maxWidth: "100%",
														maxHeight: "100%",
														minWidth: "2.5rem",
														maxWidth: "2.5rem",
														height: "2.5rem",
														borderRadius: 2,
														p: 1,
													}}
												/>
												<Tooltip
													title={
														<span
															style={{
																textTransform: "capitalize",
																fontSize: "0.875rem",
															}}
														>
															{item?.src?.name ? item?.src?.name : ""}
														</span>
													}
													placeholder="top"
												>
													<SpanStyled>
														{item?.src?.name ? item?.src?.name : ""}
													</SpanStyled>
												</Tooltip>
											</ImageBox>
										</TableCell>

										<TableCell
											style={{
												width: "23%",
												border: "none",
												padding: "10px",
											}}
										>
											<DestinationContainer>
												<img
													alt=""
													src={`/logos/favicons/${item?.dest?.id}.svg`}
													// src='http://ec2-44-213-15-199.compute-1.amazonaws.com/logos/favicons/meta%20logo.svg'

													style={{
														maxWidth: "100%",
														maxHeight: "100%",
														minWidth: "2.5rem",
														maxWidth: "2.5rem",
														height: "2.5rem",
														borderRadius: 2,
														p: 1,
													}}
												></img>
												<Tooltip
													title={
														<span
															style={{
																textTransform: "capitalize",
																fontSize: "0.875rem",
															}}
														>
															{item?.dest?.name ? item?.dest?.name : ""}
														</span>
													}
													placeholder="top"
												>
													<SpanStyled>
														{item?.dest?.name ? item?.dest?.name : ""}
													</SpanStyled>
												</Tooltip>
											</DestinationContainer>
										</TableCell>

										<TableCell
											style={{
												width: "19%",
												border: "none",
												padding: "10px",
												color: "rgba(97, 97, 97, 1)",
												display: activeTab === "In-progress" && "none",
											}}
										>
											<Box
												sx={{
													display: "flex",
													justifyContent: "start",
													alignItems: "center",
													flexDirection: "row",
													height: "100%",
													width: "100%",
												}}
											>
												<Tooltip
													title={
														<span
															style={{
																textTransform: "capitalize",
																fontSize: "0.875rem",
															}}
														>
															{item?.src?.dataType?.trim() !== ""
																? item.src.dataType
																: item?.dest?.dataType || ""}
														</span>
													}
													placeholder="top"
												>
													<span
														style={{
															color: "#616161",
															fontWeight: 500,
															fontFamily: "Helvetica Neue",
															textTransform: "capitalize",
															cursor: "default",
															fontSize: "0.875rem",
															whiteSpace: "nowrap",
															overflow: "hidden",
															textOverflow: "ellipsis",
														}}
													>
														{item?.src?.dataType?.trim() !== ""
															? item.src.dataType
															: item?.dest?.dataType || ""}
													</span>
												</Tooltip>
											</Box>
										</TableCell>
										{/* env column value */}
										<ActionTableCell>
											{/* {(item?.src?.configured || item?.dest?.configured) ?
                        <>
                          {item?.env === 'snowflake' && (
                            <img
                              alt="not found"
                              src={snowFlakeIcon}
                              style={{ width: 24, height: 24, color: "#193241" }}
                            />)}
                          {item?.env === 'madconnect' && (
                            <img
                              alt="not found"
                              src={connectorImg1}
                              style={{ width: 24, height: 24, color: "#193241" }}
                            />)}
                        </> : null} */}
											<Tooltip
												title={
													item?.env === "madconnect"
														? "MadConnect"
														: "Snowflake"
												}
											>
												<img
													alt="not found"
													src={
														item?.env === "madconnect"
															? connectorImg1
															: snowFlakeIcon
													}
													style={{ width: 24, height: 24, color: "#193241" }}
												/>
											</Tooltip>
										</ActionTableCell>
										<ActionTableCell>
											<ActionTabBox>
												{activeTab === "Active" && (
													<Tooltip title="Initiate transfer" placement="top">
														<Box
															key={item.conId}
															className="action_Connection"
															sx={{
																cursor:
																	item?.transferButton &&
																	loadingConId !== item.conId
																		? "pointer"
																		: "",
															}}
															onClick={
																item?.transferButton &&
																loadingConId !== item.conId
																	? () => handleSnowFlakeTransfer(item.conId)
																	: () => {}
															}
														>
															<img
																alt=""
																src={snowFlakeTransferIcon}
																style={{
																	width: "19px",
																	height: "19px",
																	filter:
																		item?.transferButton &&
																		loadingConId !== item.conId
																			? "none"
																			: "grayscale(100%)",
																}}
															/>
														</Box>
													</Tooltip>
												)}
												{activeTab !== "In-progress" &&
													activeTab !== "Offline" &&
													activeTab !== "Deleted" && (
														<Tooltip
															title={!item?.enabled ? "Enable" : "Disable"}
															placement="top"
														>
															<Box
																className="action_Connection"
																sx={{
																	cursor: "pointer",
																}}
																onClick={() => handleOpen(item, true)}
															>
																<Switch size="small" checked={item?.enabled} />
															</Box>
														</Tooltip>
													)}
												{activeTab !== "Deleted" && (
													<Tooltip title="Edit" placement="top">
														<Box
															className="action_Connection2"
															onClick={() =>
																item?.editable ? handleEdit(item?.conId) : null
															}
															sx={{
																cursor: item?.editable ? "pointer" : "default",
																opacity: item?.editable ? 1 : 0.5,
															}}
														>
															<img
																alt=""
																src={editImg}
																style={{
																	width: "19px",
																	height: "19px",
																	filter: item?.editable
																		? "none"
																		: "grayscale(100%)",
																}}
															></img>
														</Box>
													</Tooltip>
												)}

												{activeTab !== "Deleted" && (
													<Tooltip title="Delete" placement="top">
														<Box
															className="action_Connection2"
															onClick={() => handleOpen(item)}
															sx={{ cursor: "pointer" }}
														>
															<img
																alt=""
																src={trash}
																style={{ width: "1.5rem", height: "1.5rem" }}
															></img>
														</Box>
													</Tooltip>
												)}
												{activeTab === "Deleted" && (
													<Tooltip title="Restore" placement="top">
														<Box
															className="action_Connection2"
															onClick={() => handleRetry(item)}
															sx={{ cursor: "pointer" }}
														>
															<AutorenewIcon />
														</Box>
													</Tooltip>
												)}
											</ActionTabBox>
										</ActionTableCell>
									</TableRowStyled>
								))
							) : (
								<TableRow>
									<TableCell
										colSpan={TableHeading?.length}
										align="center"
										sx={{ borderTop: "1px solid rgba(224, 224, 224, 1)" }}
									>
										{connectionLoader || connections === null ? (
											showLoader()
										) : (
											<>No connections are {activeTab} </>
										)}
									</TableCell>
								</TableRow>
							)}
						</TableBody>
					</Table>
				</TableContainerStyled>
			</ListLeftWrapper>

			{isModalOpen && (
				<DeleteModal
					handleClose={handleClose}
					open={isModalOpen}
					selectedItem={selectedItem}
					isForDisable={isForDisable}
					connectors={connectors}
					refetch={refetch}
					setSelectedItem={setSelectedItem}
					setIsDeleteSuccess={setIsDeleteSuccess}
					setActiveTab={setActiveTab}
				/>
			)}
			{isDeleteSuccess && (
				<DeleteSuccessModal
					handleDeleteCloseSuccess={handleDeleteCloseSuccess}
					isDeleteSuccess={isDeleteSuccess}
					setIsDeleteSuccess={setIsDeleteSuccess}
				/>
			)}
			{isRetryModalOpen && (
				<RetriveModel
					handleClose={handleRetryClose}
					open={isRetryModalOpen}
					selectedItem={selectedItem}
					refetch={refetch}
					setActiveTab={setActiveTab}
				/>
			)}
		</>
	);
};

export default ConnectionListLeft;
