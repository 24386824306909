import styled from "styled-components";
import { Box, Link, Typography } from "@mui/material";

import { color } from "../../utils/Colors";

export const LoadingComponent = styled(Box)({
	position: "absolute",
	top: 0,
	left: 0,
	width: "100%",
	backgroundColor: "#fff",
	display: "flex",
	justifyContent: "center",
	alignItems: "center",
	height: "100vh",
});

export const BenifitSuperContainer = styled(Box)({
	display: "flex",
	alignItems: "center",
	justifyContent: "center",
});

export const BenifitsHeading = styled(Typography)({
	textAlign: "center",
	marginTop: "1rem",
	color: "#0A0C0C",
	fontWeight: 400,
	fontFamily: "Phosphate !important",
	fontSize: (props) => `${props.fontSize} !important`,
	letterSpacing: "0.2px !important",
});

export const BenifitUpperContainer = styled(Box)({
	marginTop: "3rem",
	display: "flex",
	flexDirection: (props) => (props.matchesxss ? "row" : "column"),
	justifyContent: (props) => (props.matchesxss ? "space-between" : "center"),
	alignItems: "flex-start",
	width: "100%",
});

export const BenifitLowerContainer = styled(Box)({
	marginTop: "3rem",
	display: "flex",
	flexDirection: (props) => (props.matchesxss ? "row" : "column"),
	justifyContent: (props) => (props.matchesxss ? "space-between" : "center"),
	alignItems: "flex-start",
	width: "100%",
});

export const BenefitsBoxConatiner = styled(Box)({
	display: "flex",
	flexDirection: "column",
	alignItems: "center",
	padding: "24px",
	marginBottom: (props) => (props.matchesxss ? 0 : "24px"),
	borderRadius: "16px",
	backgroundColor: "rgba(25, 50, 65, 0.03)",
});

export const BenefitsBoxConatinerHeading = styled(Typography)({
	textAlign: "center",
	marginTop: "1rem !important",
	color: "#193241",
	fontWeight: "500 !important",
	fontFamily: "Helvetica Neue !important",
	fontSize: (props) => `${props.fontSize} !important`,
	letterSpacing: "0.2px !important",
});

export const DeliversEfficiencyConatinerHeading = styled(Typography)({
	textAlign: "center",
	marginTop: "1rem !important",
	fontWeight: "400 !important",
	fontFamily: "Phosphate !important",
	fontSize: (props) => `${props.fontSize} !important`,
	letterSpacing: "0.2px !important",
	color: "#193241",
});

export const BenefitsBoxConatinerSubHeading = styled(Box)({
	textAlign: "center",
	marginTop: "1rem !important",
	color: "#0A0C0C",
	fontWeight: "500 !important",
	fontFamily: "Helvetica Neue !important",
	fontSize: "1rem",
	letterSpacing: "0.2px !important",
});

export const HoItContainer = styled(Box)({
	display: "flex",
	alignItems: "center",
	flexDirection: "column",
	width: "100%",
	backgroundColor: "#193241",
	marginBottom: "80px",
});

export const HoItHeading = styled(Typography)({
	textAlign: "center",
	marginTop: "2rem !important",
	color: "#fff",
	fontWeight: "400 !important",
	fontFamily: "Phosphate !important",
	fontSize: (props) => `${props.fontSize} !important`,
	letterSpacing: "0.2px !important",
});

export const HoItSubHeading = styled(Typography)({
	margin: "0px auto 0px",
	width: "50%",
	fontFamily: "Helvetica Neue !important",
	textAlign: "center",
	color: "#fff",
	fontWeight: "400 !important",
	fontSize: "1rem !important",
	letterSpacing: "0.2px !important",
});

export const BannerSuperContainer = styled(Box)({
	display: "flex",
	flexDirection: "column",
	justifyContent: "center",
	alignItems: "center",
	position: "absolute",
	height: "100%",
	width: "100%",
	top: 0,
	left: 0,
});

export const BannerContainer = styled(Box)({
	display: "flex",
	flexDirection: "column",
	justifyContent: "center",
	height: "100%",
	width: (props) => (props.matchesxss ? "90%" : "100%"),
	maxWidth: 1360,
	paddingLeft: "16px",
	paddingBottom: "16px",
});

export const BannerHeading = styled(Typography)({
	fontFamily: "Phosphate !important",
	fontSize: (props) => `${props.fontSize} !important`,
	color: "#fff",
	whiteSpace: "nowrap",
});

export const BannerHeadingText = styled("span")({
	display: "block",
	whiteSpace: "nowrap",
});

export const BannerSubHeading = styled(Typography)({
	marginTop: "1.5rem !important",
	width: "auto",
	maxWidth: "100%",
	fontWeight: "400 !important",
	fontFamily: "Helvetica Neue !important",
	color: "#fff",
	whiteSpace: "normal",
	lineHeight: "1.5rem",
	maxHeight: "6rem",
	overflow: "visible",
});

export const BannerLetConnectBtn = styled(Link)({
	display: "flex",
	justifyContent: "center",
	alignItems: "center",
	borderRadius: "4px",
	cursor: "pointer",
	marginTop: "2rem !important",
	height: "8%",
	border: "1px solid #fff",
	textDecoration: "none",
});

// export const BannerLetConnectBtn = styled(Box)({
//   display: "flex",
//   justifyContent: "center",
//   alignItems: "center",
//   borderRadius: "4px",
//   cursor: "pointer",
//   marginTop: "2rem",
//   height: "8%",
//   border: "1px solid #fff",
// });

export const LetsConnectSuperContainer = styled(Box)({
	display: "flex",
	flexDirection: "column",
	width: "100%",
	marginBottom: "2.5rem",
	justifyContent: "center",
	alignItems: "center",
	backgroundColor: "#193241",
	height: "20%",
});

export const LetsConnectHeading = styled(Typography)({
	color: "#fff",
	fontWeight: "400 !important",
	fontFamily: "Phosphate !important",
	letterSpacing: "0.2px !important",
});

export const LetsConnectDescription = styled(Typography)({
	minWidth: 300,
	textAlign: "left",
	marginTop: "2rem !important",
	color: "#fff",
	fontWeight: "500 !important",
	fontFamily: "Helvetica Neue !important",
	fontSize: "1rem !important",
	letterSpacing: "0.2px !important",
});

export const LetsConnectBoxContainer = styled(Box)({
	display: "flex",
	justifyContent: "center",
	alignItems: "center",
	width: (props) => (props.matchesxs ? "15%" : "20%"),
	minWidth: 120,
	height: (props) => (props.matchesxss ? "44px" : "22px"),
	backgroundColor: "#fff",
	border: "1px solid #fff",
	cursor: "pointer",
	borderRadius: 1,
});

export const LetsConnectBtnText = styled(Typography)({
	color: "#193241",
	fontFamily: "Helvetica !important",
	fontWeight: "700 !important",
	fontSize: "1rem !important",
	letterSpacing: "1px !important",
});

export const HomeFooterSuperContainer = styled(Box)({
	display: "flex",
	flexDirection: "column",
	justifyContent: "center",
	alignItems: "center",
	width: "100%",
	marginBottom: "2.5rem",
	height: "6.25rem",
});

export const Divider = styled(Box)({
	maxWidth: 1350,
	marginTop: "40px",
	width: "90%",
	borderTop: "1px solid rgba(54, 70, 172, 0.25)",
});

export const CopyRightContainer = styled(Box)({
	display: "flex",
	justifyContent: "center",
	alignItems: "center",
	height: "2.5rem",
	width: "100%",
	backgroundColor: "#EDEFF0",
});

export const CopyRightText = styled(Box)({
	fontFamily: "Helvetica Neue !important",
	fontSize: "1rem !important",
	cursor: "pointer",
	color: "#193241",
	fontWeight: 400,
	lineHeight: 1.5,
	letterSpacing: "0.00938em",
});

export const NavigationLabel = styled(Typography)({
	fontFamily: "Helvetica Neue !important",
	fontSize: "1rem !important",
	cursor: "pointer",
	color: "#626262",
	fontWeight: 400,
	"&:hover": { fontWeight: "700 !important" },
});

export const LinkedinIconContainer = styled(Box)({
	display: "flex",
	justifyContent: "center",
	alignItems: "center",
	height: "2.5rem",
	width: "2.5rem",
	borderRadius: 2,
	boxShadow: "0px 0px 4px rgba(0, 0, 0, 0.04), 0px 6px 8px rgba(0, 0, 0, 0.04)",
	cursor: "pointer",
});

// FAQ

export const WrapperFAQ = styled(Box)({
	maxWidth: 1350,
	marginLeft: "auto !important",
	marginRight: "auto !important",
	display: "flex !important",
	alignItems: "center",
	flexDirection: "column",
	width: "100%",
	marginBottom: "80px !important",
});

export const HeadingFAQ = styled(Typography)({
	textAlign: "center",
	marginTop: "2rem !important",
	color: "#0A0C0C",
	fontWeight: "400 !important",
	fontFamily: "Phosphate !important",
	// fontSize: props=> (props.matcheslg) ? '2.5rem': (props=> (props.matchesmd)) ? '2.3rem' :(props=> (props.matchessm)) ? '2rem' : (props=> (props.matchesxs)) ? '1.8rem' : '1.7rem',
	fontSize: (props) => {
		if (props.matcheslg) return "2.5rem !important";
		if (props.matchesmd) return "2.3rem !important";
		if (props.matchessm) return "2rem !important";
		if (props.matchesxs) return "1.8rem !important";
		return "1.7rem";
	},
	letterSpacing: "0.2px !important",
});

export const ParaFAQ = styled(Typography)({
	marginRight: "auto !important",
	marginLeft: "auto !important",
	width: (props) => (props.matchesxss ? "45%" : "60%"),
	textAlign: "center",
	marginTop: "1rem !important",
	color: "#0A0C0C",
	fontWeight: "500 !important",
	fontFamily: "Helvetica Neue !important",
	// fontSize: matches.lg ? '1rem' : matches.md ? '1rem' : matches.sm ? '1rem' : matches.xs ? '1rem' : '1rem',
	fontSize: (props) => {
		if (props.matcheslg) return "1rem !important";
		if (props.matchesmd) return "1rem !important";
		if (props.matchessm) return "1rem !important";
		if (props.matchesxs) return "1rem !important";
		return "1rem";
	},
	letterSpacing: "0.2px !important",
});

export const FAQViewMore = styled(Box)({
	display: "flex",
	flexDirection: "row",
	cursor: "pointer",
});

export const ViewMoreHeading = styled(Typography)({
	color: "#3646AC !important",
	fontWeight: "500 !important",
	fontFamily: "Helvetica Neue !important",
	fontSize: "1.2rem !important",
});
