const ConnectionsIcon = ({ variant, color }) => {
	return variant === "solid" ? (
		<svg
			width="21"
			height="21"
			viewBox="0 0 21 21"
			fill="none"
			xmlns="http://www.w3.org/2000/svg"
		>
			<path
				d="M15.0102 4.30859H6.42152C6.09451 4.30859 5.7673 4.31792 5.44817 4.38927C4.53432 4.59358 2.64775 5.28487 2.71462 7.50495C2.80043 10.3539 5.7688 10.4323 5.92446 10.4345C5.92948 10.4345 5.93331 10.4345 5.93834 10.4345H15.1619C15.7267 10.4345 16.297 10.4609 16.8279 10.6536C17.7412 10.9851 19.093 11.7743 19.093 13.5417C19.093 15.2606 17.8144 16.0726 16.904 16.4329C16.3264 16.6615 15.6982 16.6932 15.0769 16.6932H5.9308"
				stroke={color || "#25262D"}
				stroke-width="1.5"
			/>
			<circle
				cx="16.5938"
				cy="4.30859"
				r="1.75"
				fill={color || "#25262D"}
				stroke={color || "#25262D"}
				stroke-width="1.5"
			/>
			<circle
				cx="4.4082"
				cy="16.6934"
				r="1.75"
				fill={color || "#25262D"}
				stroke={color || "#25262D"}
				stroke-width="1.5"
			/>
		</svg>
	) : (
		<svg
			width="21"
			height="21"
			viewBox="0 0 21 21"
			fill="none"
			xmlns="http://www.w3.org/2000/svg"
		>
			<path
				d="M15.0102 4.30859H6.42152C6.09451 4.30859 5.7673 4.31792 5.44817 4.38927C4.53432 4.59358 2.64775 5.28487 2.71462 7.50495C2.80043 10.3539 5.7688 10.4323 5.92446 10.4345C5.92948 10.4345 5.93331 10.4345 5.93834 10.4345H15.1619C15.7267 10.4345 16.297 10.4609 16.8279 10.6536C17.7412 10.9851 19.093 11.7743 19.093 13.5417C19.093 15.2606 17.8144 16.0726 16.904 16.4329C16.3264 16.6615 15.6982 16.6932 15.0769 16.6932H5.9308"
				stroke={color || "#25262D"}
				stroke-width="1.5"
			/>
			<circle
				cx="16.5938"
				cy="4.30859"
				r="1.75"
				stroke={color || "#25262D"}
				stroke-width="1.5"
			/>
			<circle
				cx="4.4082"
				cy="16.6934"
				r="1.75"
				stroke={color || "#25262D"}
				stroke-width="1.5"
			/>
		</svg>
	);
};

export default ConnectionsIcon;
