import React from "react";
import { Box, useMediaQuery } from "@mui/material";
import { LazyLoadImage } from "react-lazy-load-image-component";
import "react-lazy-load-image-component/src/effects/blur.css";

import aboutMadconnectImg from "../../assets/AbotMadconnect.webp";
import {
	AboutSuperContainer,
	ConnectDescription,
	ConnectSubHeading,
	ConnectContainer,
	ConnectMainContainer,
} from "./AboutMad.styles";
import { getFontSize } from "../../utils/utils";

const AbouMadconnect = () => {
	const matches = {
		lg: useMediaQuery("(min-width: 1400px)"),
		md: useMediaQuery("(min-width: 1280px)"),
		sm: useMediaQuery("(min-width: 1200px)"),
		xs: useMediaQuery("(min-width: 1060px)"),
		xss: useMediaQuery("(min-width: 810px)"),
	};

	return (
		<AboutSuperContainer isConnect={true}>
			<ConnectMainContainer matchesxss={matches.xss}>
				<Box>
					<LazyLoadImage
						alt={"ABOUT MADCONNECT"}
						// effect="blur"
						width={"100%"}
						height={"100%"}
						src={aboutMadconnectImg}
					/>
				</Box>
				<ConnectContainer matchesxss={matches.xss}>
					<ConnectSubHeading
						fontSize={getFontSize(matches, {
							lg: "2.5rem",
							md: "2.2rem",
							sm: "1.9rem",
							xs: "1.7rem",
							xss: "1.7rem",
						})}
					>
						Solving for Platform Connectivity
					</ConnectSubHeading>
					<ConnectDescription
						fontSize={getFontSize(matches, {
							lg: "1rem",
							md: "1rem",
							sm: "1rem",
							xs: "1rem",
							xss: "1rem",
						})}
					>
						The MadConnect platform provides bi-directional data connectivity
						across the advertising and marketing ecosystem, enabling seamless
						data flows between CDPs, DSPs, clean rooms, SSPs, cloud providers,
						and data platforms. By simplifying connectivity, MadConnect unlocks
						key use cases like audience activation, measurement, reporting, and
						more.
					</ConnectDescription>
					<ConnectDescription
						fontSize={getFontSize(matches, {
							lg: "1rem",
							md: "1rem",
							sm: "1rem",
							xs: "1rem",
							xss: "1rem",
						})}
					>
						MadConnect eliminates the complexity of building and maintaining
						integrations—allowing your teams to focus on execution, not
						infrastructure.
					</ConnectDescription>
					<ConnectSubHeading
						fontSize={getFontSize(matches, {
							lg: "2.5rem",
							md: "2.2rem",
							sm: "1.9rem",
							xs: "1.7rem",
							xss: "1.7rem",
						})}
						mt="1.5rem"
					>
						ABOUT MADCONNECT
					</ConnectSubHeading>
					<ConnectDescription
						fontSize={getFontSize(matches, {
							lg: "1rem",
							md: "1rem",
							sm: "1rem",
							xs: "01rem",
							xss: "1rem",
						})}
					>
						MadConnect is a purpose-built connectivity platform for AdTech and
						MarTech. It acts as a direct connection between source and
						destination platforms, reducing the operational burden of
						integrations while ensuring seamless, reliable, and privacy-safe
						data transfers.
					</ConnectDescription>
				</ConnectContainer>
			</ConnectMainContainer>
		</AboutSuperContainer>
	);
};

export default AbouMadconnect;
