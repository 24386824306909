import React, { useMemo } from "react";
import { Box, Fade, Modal, Typography, IconButton, Tooltip } from "@mui/material";
import ContentCopyIcon from "@mui/icons-material/ContentCopy";
import InfoIcon from "../../assets/infoWhite.svg";
import Cancel from "../../assets/cancelWhite.svg";

const InfoJsonModal = ({ infoOpen, setInfoOpen, infoData }) => {
    const style = useMemo(
        () => ({
            position: "absolute",
            top: "50%",
            left: "50%",
            transform: "translate(-50%, -50%)",
            borderRadius: "8px",
            bgcolor: "#fff",
            width: "26rem",
            maxWidth: "90%",
            boxShadow: 24,
            display: "flex",
            flexDirection: "column",
            "&:focus": {
                outline: "none",
            },
        }),
        []
    );

    return (
        <Modal
            open={infoOpen}
            onClose={() => setInfoOpen(false)}
            closeAfterTransition
        >
            <Fade in={infoOpen}>
                <Box sx={{ ...style }}>
                    <Box
                        sx={{
                            display: "flex",
                            justifyContent: "space-between",
                            alignItems: "center",
                            padding: "16px",
                            backgroundColor: "#1C2B47",
                            color: "white",
                            borderTopLeftRadius: "8px",
                            borderTopRightRadius: "8px",
                        }}
                    >
                        <Box sx={{ display: "flex", alignItems: "center" }}>
                            <img src={InfoIcon} alt="Info" style={{ width: 20, height: 20, marginRight: 10 }} />
                            <Typography variant="h6" sx={{ fontSize: "1rem", fontWeight: 500 }}>
                                Information
                            </Typography>
                        </Box>
                        <Box onClick={() => setInfoOpen(false)}>
                            <img
                                alt=""
                                src={Cancel}
                                style={{ width: 20, height: 20, color: "#fff", cursor: "pointer" }}
                            ></img>
                        </Box>
                    </Box>

                    <Box
                        sx={{
                            padding: "16px",
                            backgroundColor: "#F8F8FE",
                            maxHeight: "400px",
                            overflowY: "auto",
                            fontFamily: "monospace",
                            fontSize: "14px",
                            whiteSpace: "pre-wrap",
                            wordBreak: "break-word",
                            borderRadius: "0 0 8px 8px",
                            position: "relative",
                        }}
                    >
                        <Tooltip title="Copy JSON">
                            <IconButton
                                onClick={() => navigator.clipboard.writeText(JSON.stringify(infoData, null, 2))}
                                sx={{
                                    position: "absolute",
                                    top: "10px",
                                    right: "10px",
                                    backgroundColor: "rgba(61, 31, 31, 0.1)",
                                    "&:hover": { backgroundColor: "rgba(0,0,0,0.2)" },
                                }}
                                size="small"
                            >
                                <ContentCopyIcon fontSize="small" />
                            </IconButton>
                        </Tooltip>
                        <pre style={{ margin: 0 }}>{JSON.stringify(infoData, null, 2)}</pre>
                    </Box>
                </Box>
            </Fade>
        </Modal>
    );
};

export default InfoJsonModal;