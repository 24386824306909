import React, { useCallback, useEffect, useState } from "react";
import "./Support.css";
import {
	Box,
	FormControl,
	FormHelperText,
	InputLabel,
	MenuItem,
	Select,
	TextField,
	Typography,
} from "@mui/material";
import { useForm, Controller } from "react-hook-form";

import TextEditor from "./TextEditor";
import { Strings } from "../../utils/Strings";
import CommonButton from "../../components/CommonButton/CommonButton";
import { useAddContactFormDetailsMutation } from "../../services/query";
import { useMyContext } from "../../components/CommanToasterContext/toast";
import SBreadCrumb from "../../components/Setting/SBreadCrumb";

const IssueType = [
	"Account Setup",
	"Connector Issue",
	"Request Connector",
	"Data Inconsistency",
	"Reporting",
	"Billing",
	"Others",
];

const Priority = [
	"PO (Critical)",
	"P1 (High)",
	"P2 (Medium)",
	"P2 (Low)",
	"P4 (Lowest)",
];

export default function Support() {
	const {
		control,
		handleSubmit,
		formState: { errors },
	} = useForm();

	const [issueType, setIssueType] = useState("");
	const [priority, setPriority] = useState("");
	const [description, setDescription] = useState("");
	const [isSuccessMess, SetIsSuccessMess] = useState(false);
	const { setOpen, setMessage, setOpen2, setMessage2 } = useMyContext();

	const [addContactFormDetails, { data, isLoading, error }] =
		useAddContactFormDetailsMutation();
	const handleContactSubmit = useCallback(() => {
		addContactFormDetails({
			description,
			issueType,
			priority,
		});
	}, [useAddContactFormDetailsMutation, issueType, description, priority]);

	useEffect(() => {
		if (data !== undefined) {
			if (data?.statusCode === 200) {
				SetIsSuccessMess(true);
				// navigate('/dashboard')
			} else {
				setOpen2(true);
				SetIsSuccessMess(false);
				setMessage2(data?.statusMessage);
			}
		}
		if (error !== undefined) {
			console.log("contact error", error);
		}
	}, [data, error]);

	return (
		<form onSubmit={handleSubmit(handleContactSubmit)}>
			<div className="supprortForm">
				<SBreadCrumb mainPathName="MadConnect Support" />
				{isSuccessMess ? (
					<Box sx={{ marginTop: "20px" }}>
						<Typography sx={{ fontSize: "1rem" }}>
							Thank you for contacting MadConnect support.
						</Typography>
						<Typography sx={{ fontSize: "1rem" }}>
							Someone from MadConnect will get back to you soon.
						</Typography>
					</Box>
				) : (
					<>
						<Box my={3}>
							<FormControl fullWidth sx={{ my: 2 }} error={!!errors.issueType}>
								<label>Issue Type*</label>
								<Controller
									name="issueType"
									control={control}
									defaultValue=""
									rules={{ required: "Issue type is required" }}
									render={({ field }) => (
										<Select
											{...field}
											value={issueType}
											sx={{ height: "44px", width: "60%" }}
											onChange={(e) => {
												setIssueType(e.target.value);
												field.onChange(e.target.value); // Update react-hook-form state
											}}
											displayEmpty
											inputProps={{ "aria-label": "Without label" }}
										>
											{IssueType?.map((val) => (
												<MenuItem key={val} value={val}>
													{val}
												</MenuItem>
											))}
										</Select>
									)}
								/>
								{/* {errors.issueType && <FormHelperText>{errors.issueType.message}</FormHelperText>} */}
								<FormHelperText sx={{ ml: 0 }}>
									Select from the below dropdown
								</FormHelperText>
							</FormControl>

							<Box sx={{ my: 2 }}>
								<label>Description*</label>
								<Controller
									name="description"
									control={control}
									defaultValue=""
									rules={{ required: "Description is required" }}
									render={({ field }) => (
										<TextEditor
											{...field}
											setDescription={(value) => {
												setDescription(value);
												field.onChange(value); // Update react-hook-form state
											}}
										/>
									)}
								/>
								{errors.description && (
									<FormHelperText error>
										{errors.description.message}
									</FormHelperText>
								)}
							</Box>

							<FormControl fullWidth sx={{ my: 2 }} error={!!errors.priority}>
								<label>Priority*</label>
								<Controller
									name="priority"
									control={control}
									defaultValue=""
									rules={{ required: "Priority is required" }}
									render={({ field }) => (
										<Select
											{...field}
											sx={{ height: "44px", width: "60%" }}
											displayEmpty
											inputProps={{ "aria-label": "Without label" }}
											onChange={(e) => {
												setPriority(e?.target?.value);
												field.onChange(e?.target?.value); // Update react-hook-form state
											}}
										>
											{Priority?.map((val) => (
												<MenuItem key={val} value={val}>
													{val}
												</MenuItem>
											))}
										</Select>
									)}
								/>
								{/* {errors.priority && <FormHelperText>{errors.priority.message}</FormHelperText>} */}
							</FormControl>
						</Box>

						{/* Submit button */}
						<div className="mt-3 mb-4">
							<CommonButton
								width={"100px"}
								height={"2.75rem"}
								textStyle={{
									fontSize: "1.125rem",
									fontWeight: "500",
									fontFamily: "Helvetica",
								}}
								type="submit"
								title={Strings.submit}
								color={"#3843AC"}
							/>
						</div>
					</>
				)}
			</div>
		</form>
	);
}
