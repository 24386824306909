import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useLocation, useParams } from "react-router-dom";
import { Box, CircularProgress, Paper, Tab, Tabs } from "@mui/material";
import styled from "styled-components";

import {
	madTechState,
	resetPlatformConfigurations,
} from "../../features/madTechSlice";
import {
	useGetConfigurationQuery,
	useGetSelectedPlatformsQuery,
} from "../../services/platformQuery";
import { color } from "../../utils/Colors";
import OverviewPlatform from "./OverviewPlatform";
import ConfigurationTab from "./ConfigurationTab";

const Item = styled(Paper)(({ theme }) => ({
	backgroundColor: "#fff",
	borderRadius: 10,
	position: "relative",
	cursor: "pointer",
	height: 70,
	display: "flex",
	justifyContent: "center",
	alignItems: "center",
	textAlign: "center",
	color: theme.palette.text.secondary,
	boxShadow: "none !important",
	marginTop: "1rem",
	marginBottom: 16,
	paddingLeft: 20,
}));

const Configuration = ({ platformByIdDatas, connectorId }) => {
	const { id } = useParams();
	const location = useLocation();

	const { user, platformId, platformConfigurations } =
		useSelector(madTechState);

	const queryParams = new URLSearchParams(location?.search);
	const account_id = queryParams.get("accountId");
	const dataTypeId = queryParams.get("dataTypeId");

	const [isConfigure, setisConfigure] = useState("Overview");
	const [markdownContent, setMarkdownContent] = useState("");
	const [loader, setLoader] = useState(false);
	const [platfromData, setPlatformData] = useState({});
	const dispatch = useDispatch();

	const path =
		platformId !== null
			? platformId?.overviewUrl?.split("path=")[1]
			: platformByIdDatas?.overviewUrl?.split("path=")[1];

	const { data: platformsData, refetch } = useGetSelectedPlatformsQuery();

	useEffect(() => {
		if (connectorId === undefined || connectorId === null) {
			refetch();
		}
	}, [connectorId]);

	useEffect(() => {
		if (platformsData !== undefined && platformsData?.response) {
			const findPlatform = platformsData?.response?.selectedPlatforms?.find(
				(e) => e?.dataTypeId === dataTypeId
			);
			setPlatformData(findPlatform);
		}
	}, [platformsData, dataTypeId]);

	useEffect(() => {
		if (path?.length > 0) {
			setLoader(true);
			fetch(`https://madconnect-docs.s3.amazonaws.com/${path}.md`)
				.then((response) => {
					if (!response.ok) {
						setLoader(false);
						throw new Error("Network response was not ok");
					}
					setLoader(false);
					return response.text();
				})
				.then((text) => setMarkdownContent(text))
				.catch((error) => {
					setLoader(false);
					console.error("Error fetching markdown file:", error);
				});
		}
	}, [path]);

	useEffect(() => {
		dispatch(resetPlatformConfigurations());
	}, []);

	const { isLoading } = useGetConfigurationQuery(
		{
			platformId: id,
			account_id: account_id?.length > 0 ? "" : user?.accountId,
			connectorId: connectorId || platfromData?.connectorId,
		},
		{
			queryKey: [platformId, account_id, connectorId],
			refetchOnMountOrArgChange: true,
		}
	);

	const configureChange = (_, newValue) => {
		setisConfigure(newValue);
	};

	const showLoader = () => (
		<Box p={2} style={{ height: "60vh" }}>
			<Box
				sx={{
					display: "flex",
					justifyContent: "center",
					alignItems: "center",
					width: "100%",
					height: "60vh",
				}}
			>
				<CircularProgress
					variant="indeterminate"
					disableShrink
					size={45}
					thickness={8}
					sx={{ color: color.loadingColor }}
				/>
			</Box>
		</Box>
	);

	return (
		<Box sx={{ width: "100%" }}>
			<Box
				sx={{
					mr: "1.5rem",
					my: 2,
					display: "flex",
					flexDirection: "column",
					backgroundColor: "#fff",
					borderRadius: 2,
					boxShadow:
						"0px 0px 1px rgba(37, 38, 45, 0.25), 0px 2px 4px rgba(37, 38, 45, 0.15)",
				}}
			>
				<Box
					sx={{
						display: "flex",
						flexDirection: "row",
						alignItems: "center",
						position: "relative",
					}}
				>
					<Item>
						{platformByIdDatas?.id ? (
							<img
								// src={
								//   platformId !== null ? platformId?.logo : platformByIdDatas?.logo
								// }
								src={`/platforms/${platformByIdDatas.id}.png`}
								style={{ maxWidth: "100%", maxHeight: "100%" }}
							/>
						) : null}
					</Item>
					<Box
						sx={{
							position: "absolute",
							bottom: -8,
							left: 0,
							borderBottom: "1px solid #EDEFF0",
							height: 1,
							width: "100%",
						}}
					></Box>
				</Box>

				<Box
					sx={{
						pr: "1rem",
						display: "flex",
						flexDirection: "row",
						justifyContent: "space-between",
						alignItems: "center",
						backgroundColor: "#fff",
					}}
				>
					<Box sx={{ width: "100%", mt: 3 }}>
						<Box
							sx={{
								display: "flex",
								justifyContent: "space-between",
								alignItems: "center",
								borderBottom: "0.5px solid",
								borderColor: "#dee2e6",
							}}
						>
							<Tabs
								value={isConfigure}
								onChange={configureChange}
								variant="scrollable"
								allowScrollButtonsMobile
								sx={{
									width: "75%",
									overflowX: "auto",
									textTransform: "none",
									"& .Mui-selected": {
										backgroundColor: color.lightThemeColor,
										color: color.black,
										borderBottomColor: "#dee2e6",
									},
								}}
							>
								<Tab
									label="Overview"
									value="Overview"
									sx={{
										textTransform: "none",
										fontWeight: 600,
										fontSize: "1rem",
									}}
								/>
								<Tab
									label="Configuration"
									value="Configuration"
									sx={{
										textTransform: "none",
										fontWeight: 600,
										fontSize: "1rem",
									}}
								/>
							</Tabs>
							{/* <Box sx={{ display: "none", justifyContent: "end" }}>
                {isConfigure !== "Overview" && shouldShowAddButton && (
                  <CommonButton
                    height={"2.5rem"}
                    textStyle={{
                      fontSize: "1rem",
                      fontWeight: 500,
                      fontFamily: "Helvetica Neue",
                    }}
                    disabled={newConfigrationLoading}
                    onClick={addConfiguration}
                    title={"Add"}
                    color={color.buttonColor}
                    startIcon={
                      newConfigrationLoading ? (
                        <>
                          <CircularProgress variant="indeterminate" disableShrink
                            style={{ color: "white" }}
                            size={20}
                            thickness={4}
                          ></CircularProgress>
                        </>
                      ) : (
                        <AddIcon sx={{ height: "1.5rem", width: "1.5rem" }} />
                      )
                    }
                  />
                )}
              </Box> */}
						</Box>

						<Box sx={{ padding: "0.3rem 0rem 1.5rem 0rem" }}>
							{isConfigure === "Overview" && (
								<Box
									p={2}
									style={{
										height: "60vh",
										paddingRight: "0px !important",
										overflowY: "auto",
									}}
								>
									{loader ? (
										showLoader()
									) : (
										<OverviewPlatform markdownContent={markdownContent} />
									)}
								</Box>
							)}
							{isConfigure === "Configuration" && (
								<Box
									p={2}
									style={{
										height: "60vh",
										paddingRight: "0px !important",
										overflowY: "auto",
									}}
								>
									{isLoading || platformConfigurations === null ? (
										showLoader()
									) : (
										<ConfigurationTab
											platformByIdDatas={platformByIdDatas}
											accountid={account_id ? account_id : user?.accountId}
											connectorId={connectorId || platfromData?.connectorId}
											platfromData={platfromData}
											platformConfigurations={platformConfigurations}
										/>
									)}
								</Box>
							)}
						</Box>
					</Box>
				</Box>
			</Box>
		</Box>
	);
};

export default Configuration;

// import React, { useMemo, useRef, useEffect, useState } from "react";
// import {
//   Box,
//   Typography,
//   CircularProgress,
//   Paper,
//   styled,
//   Button,
//   Tabs,
//   Tab,
//   Modal,
//   Backdrop,
//   Fade,
// } from "@mui/material";
// import { useDispatch, useSelector } from "react-redux";
// import { useParams } from "react-router-dom";
// import AddIcon from "@mui/icons-material/Add";

// import {
//   addConfigurationAction,
//   madTechState,
// } from "../../features/madTechSlice";
// import { color } from "../../utils/Colors";
// // import AppLayout from '../AppLayout/AppLayout'
// import CommonButton from "../CommonButton/CommonButton";
// // import configImg from '../../assets/configImg.png';
// import {
//   useAddNewConfigrationMutation,
//   useGetPlatformConfigurationQuery,
// } from "../../services/platformQuery";
// import ConfigurationAccordion from "./ConfigurationAccordion";
// import SourceConfigurationTab from "./SourceConfigurationTab";
// import DestinationConfigurationTab from "./DestinationConfigurationTab";
// import "./Configuration.css";
// import OverviewPlatform from "./OverviewPlatform";
// import ConfigurationTab from "./ConfigurationTab";

// const Item = styled(Paper)(({ theme }) => ({
//   backgroundColor: "#fff",
//   borderRadius: 10,
//   position: "relative",
//   cursor: "pointer",
//   height: 70,
//   display: "flex",
//   justifyContent: "center",
//   alignItems: "center",
//   // paddingLeft: theme.spacing(2),
//   textAlign: "center",
//   color: theme.palette.text.secondary,
//   boxShadow: "none",
//   marginTop: "1rem",
//   marginBottom: 16,
//   paddingLeft: 20,
// }));

// const Configuration = ({ platformByIdDatas, dataTypeId, connectorId }) => {
//   const { id } = useParams()
//   const dispatch = useDispatch();
//   const { platformConfigurations, platformId } =
//     useSelector(madTechState);

//   const [intent, setIntent] = useState(false);
//   const [loader, setLoader] = useState(false);

//   const [isConfirmOpen, setIsConfirmOpen] = useState(false);
//   const [expandedSrc, setExpandedSrc] = useState(false);
//   const [expandedDest, setExpandedDest] = useState(false);

//   const srcConfigRefs = useRef({});
//   const destConfigRefs = useRef({});
//   const [isConfigure, setisConfigure] = useState("Overview");
//   const [errorArr, setErrorArr] = useState([]);

//   const { data, isLoading, error, refetch } = useGetPlatformConfigurationQuery({
//     platformId: id,
//     dataTypeId: dataTypeId
//   });

//   const style = useMemo(
//     () => ({
//       position: "absolute",
//       top: "50%",
//       left: "50%",
//       transform: "translate(-50%, -50%)",
//       borderRadius: "8px 8px 8px 8px",
//       bgcolor: "#F8F8FE",
//       width: 600,
//       boxShadow: 24,
//       p: 4,
//       display: "flex",
//       flexDirection: "column",
//       justifyContent: "center",
//       alignItems: "center",
//       "&:focus": {
//         outline: "none",
//       },
//     }),
//     []
//   );
//   const [formsSaveConfig, setformsSaveConfig] = useState({});
//   const handleChangeSrc = (panel, formsSaveConfig) => (event, newExpanded) => {
//     const oldExpanded = expandedSrc;
//     const saveEnable =
//       oldExpanded && formsSaveConfig
//         ? formsSaveConfig[oldExpanded].saveEnable
//         : false;
//     const configName =
//       oldExpanded && formsSaveConfig
//         ? formsSaveConfig[oldExpanded].configName
//         : false;
//     if (!oldExpanded) {
//       setExpandedSrc(panel);
//       return;
//     } else {
//       if (saveEnable) {
//         setIsConfirmOpen(true);
//         setIntent({
//           config: "src",
//           panel: newExpanded ? panel : false,
//           oldExpanded,
//           configName,
//         });
//       } else {
//         setExpandedSrc(newExpanded ? panel : false);
//       }
//     }
//   };

//   const onloadscript = () => {
//     var doc_iframe = document.getElementById("doc-src")
//     setLoader(true);
//     doc_iframe?.addEventListener('load', async () => {
//       var doc_iframe_body = doc_iframe.contentWindow.document.body;

//       var doc_iframe_main_content = doc_iframe_body.querySelector('main');
//       if (doc_iframe_main_content) {
//         const children = doc_iframe_main_content.children;
//         if (children && children.length >= 2) {
//           doc_iframe_body.innerHTML = children[1].outerHTML;
//           const skeleton = doc_iframe_body.querySelector("div[role]");
//           if (skeleton)
//             skeleton.remove()
//           const links = doc_iframe_body.querySelectorAll('a');
//           if (links) {
//             links.forEach(function (link) {
//               if (link.getAttribute('aria-label') === "Direct link to heading") {
//                 link.remove()
//               } else {
//                 link.setAttribute('target', '_blank');
//               }
//             });
//           }
//           const svg = doc_iframe_body?.querySelectorAll("svg[preserveAspectRatio='xMaxYMid meet']")
//           svg?.forEach(function (svgs) {
//               svgs.remove()
//           })
//         }
//       }
//       doc_iframe.style.opacity = 1
//       setLoader(false)
//     });
//   }
//   useEffect(() => {

//     onloadscript()
//   }, [])

//   const handleChangeDest = (panel, formsSaveConfig) => (event, newExpanded) => {
//     const oldExpanded = expandedDest;
//     const saveEnable =
//       oldExpanded && formsSaveConfig
//         ? formsSaveConfig[oldExpanded].saveEnable
//         : false;
//     const configName =
//       oldExpanded && formsSaveConfig
//         ? formsSaveConfig[oldExpanded].configName
//         : false;
//     if (!oldExpanded) {
//       setExpandedDest(panel);
//       return;
//     } else {
//       if (saveEnable) {
//         setIsConfirmOpen(true);
//         setIntent({
//           config: "dest",
//           panel: newExpanded ? panel : false,
//           oldExpanded,
//           configName,
//         });
//       } else {
//         setExpandedDest(newExpanded ? panel : false);
//       }
//     }
//   };

//   useEffect(() => {
//     if (isConfigure) {
//       setErrorArr([]);
//     }
//   }, [isConfigure]);

//   useEffect(() => {
//     if (id) {
//       refetch();
//     }
//   }, [id]);

//   useEffect(() => {
//     if (data !== undefined) {
//       if (data.statusCode === 200 && data.response) {
//         //
//       }
//     }

//     if (error !== undefined) {
//       console.log("configuration error", error);
//     }
//   }, [data, error]);

//   const [value, setValue] = React.useState(0);

//   const verticalTabChange = (event, newValue) => {
//     setValue(newValue);
//   };

//   const [
//     addNewConfigration,
//     {
//       data: newConfigrationData,
//       error: newConfigrationError,
//       isLoading: newConfigrationLoading,
//     },
//   ] = useAddNewConfigrationMutation();

//   useEffect(() => {
//     if (newConfigrationData !== undefined) {
//       if (
//         newConfigrationData.statusCode === 200 &&
//         newConfigrationData.response
//       ) {
//         //
//       }
//     }

//     if (newConfigrationError !== undefined) {
//       console.log("configuration error", error);
//     }
//   }, [newConfigrationData, newConfigrationError]);

//   const callSubmit = (config, panel, newPanel) => {
//     if (config === "src") {
//       srcConfigRefs.current[panel].submit();
//     } else if (config === "dest") {
//       destConfigRefs.current[panel].submit();
//     }
//   };

//   const callDiscard = (config, panel, newPanel) => {
//     if (config === "src") {
//       srcConfigRefs.current[panel].discard();
//       setExpandedSrc(newPanel);
//     } else if (config === "dest") {
//       setExpandedDest(newPanel);
//       destConfigRefs.current[panel].discard();
//     }
//   };

//   const addConfiguration = () => {
//     addNewConfigration({
//       connectorId: connectorId,
//     })
//       .unwrap()
//       .then((response) => {
//         if (response.statusCode === 200)
//           dispatch(
//             addConfigurationAction({
//               ...response.response,
//               dataType: platformConfigurations?.dataTypes[value],
//               platformType: isConfigure,
//             })
//           );
//       })
//       .catch((error) => {
//         console.error("Failed to add new configuration:", error);
//       });
//   };

//   const configureChange = (event, newValue) => {
//     setisConfigure(newValue);
//   };

//   const currentDataType = platformConfigurations?.dataTypes[value];

//   const shouldShowAddButton = useMemo(() => {
//     if (currentDataType) {
//       if (isConfigure === "SOURCE") {
//         return platformConfigurations[currentDataType]?.showSrc;
//       } else if (isConfigure === "DESTINATION") {
//         return platformConfigurations[currentDataType]?.showDest;
//       }
//     }
//     return false;
//   }, [platformConfigurations, isConfigure, currentDataType]);

//   const isSourceVisible = useMemo(() => {
//     return platformConfigurations?.dataTypes?.some(
//       (dataType) =>
//         platformConfigurations[dataType] &&
//         platformConfigurations[dataType].showSrc
//     );
//   }, [platformConfigurations]);

//   const isDestinationVisible = useMemo(() => {
//     return platformConfigurations?.dataTypes?.some(
//       (dataType) =>
//         platformConfigurations[dataType] &&
//         platformConfigurations[dataType].showDest
//     );
//   }, [platformConfigurations]);

//   const logoUrl = platformId?.logo?.startsWith("http")
//     ? platformId?.logo
//     : platformId?.logo;
//   return (
//     <Box sx={{ width: "100%" }}>
//       <Box
//         sx={{
//           mr: "1.5rem",
//           my: 2,
//           display: "flex",
//           flexDirection: "column",
//           backgroundColor: "#fff",
//           borderRadius: 2,
//           boxShadow:
//             "0px 0px 1px rgba(37, 38, 45, 0.25), 0px 2px 4px rgba(37, 38, 45, 0.15)",
//         }}
//       >
//         <Box
//           sx={{
//             display: "flex",
//             flexDirection: "row",
//             alignItems: "center",
//             position: "relative",
//           }}
//         >
//           <Item>
//             <img
//               src={platformId !== null ? logoUrl : platformByIdDatas?.logo}
//               style={{ maxWidth: "100%", maxHeight: "100%" }}
//             />
//           </Item>
//           {/* <span style={{
//             color: "#666666",
//             fontSize: "0.675rem",
//             fontFamily: "Helvetica Neue",
//             fontWeight: 500,
//             cursor: "pointer",
//             marginLeft: "3px",
//             marginTop: "14px"
//           }}>({dataType})</span> */}
//           <Box
//             sx={{
//               position: "absolute",
//               bottom: -8,
//               left: 0,
//               borderBottom: "1px solid #EDEFF0",
//               height: 1,
//               width: "100%",
//             }}
//           ></Box>
//         </Box>

//         <Box
//           sx={{
//             // px: "1rem",
//             pr: "1rem",
//             display: "flex",
//             flexDirection: "row",
//             justifyContent: "space-between",
//             alignItems: "center",
//             backgroundColor: "#fff",
//           }}
//         >
//           <Box sx={{ width: "100%", mt: 3 }}>
//             <Box
//               sx={{
//                 display: "flex",
//                 justifyContent: "space-between",
//                 alignItems: "center",
//                 borderBottom: "0.5px solid",
//                 borderColor: "#dee2e6",
//               }}
//             >
//               <Tabs
//                 value={isConfigure}
//                 onChange={configureChange}
//                 variant="scrollable"
//                 // scrollButtons
//                 allowScrollButtonsMobile
//                 sx={{
//                   width: "75%",
//                   overflowX: "auto", // Enable horizontal scrolling
//                   textTransform: "none",
//                   "& .Mui-selected": {
//                     backgroundColor: color.lightThemeColor,
//                     color: color.black,
//                     borderBottomColor: "#dee2e6",
//                   },
//                 }}
//               >
//                 <Tab
//                   label="Overview"
//                   value="Overview"
//                   sx={{
//                     textTransform: "none",
//                     fontWeight: 600,
//                     fontSize: "1rem",
//                   }}
//                 />
//                 {/* <Tab
//                   label="Source configuration"
//                   value="SOURCE"
//                   sx={{
//                     textTransform: "none",
//                     fontWeight: 600,
//                     fontSize: "1rem",
//                   }}
//                 />
//                 <Tab
//                   label="Destination configuration"
//                   value="DESTINATION"
//                   sx={{
//                     textTransform: "none",
//                     fontWeight: 600,
//                     fontSize: "1rem",
//                   }}
//                 /> */}
//                   <Tab
//                   label="Configuration"
//                   value="Configuration"
//                   sx={{
//                     textTransform: "none",
//                     fontWeight: 600,
//                     fontSize: "1rem",
//                   }}
//                 />
//               </Tabs>
//               <Box sx={{ display: "none", justifyContent: "end" }}>
//                 {isConfigure !== "Overview" && shouldShowAddButton && (
//                   <CommonButton
//                     height={"2.5rem"}
//                     textStyle={{
//                       fontSize: "1rem",
//                       fontWeight: 500,
//                       fontFamily: "Helvetica Neue",
//                     }}

//                     disabled={newConfigrationLoading}
//                     onClick={addConfiguration}
//                     title={"Add"}
//                     color={color.buttonColor}
//                     startIcon={
//                       newConfigrationLoading ? (
//                         <>
//                           <CircularProgress variant="indeterminate" disableShrink
//                             style={{ color: "white" }}
//                             size={20}
//                             thickness={4}
//                           ></CircularProgress>
//                         </>
//                       ) : (
//                         <AddIcon sx={{ height: "1.5rem", width: "1.5rem" }} />
//                       )
//                     }
//                   />
//                 )}
//               </Box>
//             </Box>

//             {/* Tabs content */}
//             <Box sx={{ padding: "0.3rem 0rem 1.5rem 0rem" }}>
//               <Box p={2} style={{ height: "60vh", display: isConfigure === "Overview" ? "" : "none", paddingRight: "0px !important", overflowY:"auto" }}>
//                 {!loader ? <Box p={2} style={{ height: "60vh" }}><Box
//                   sx={{
//                     display: "flex",
//                     justifyContent: "center",
//                     alignItems: "center",
//                     width: "100%",
//                     height: "60vh",
//                   }}
//                 >
//                   <CircularProgress variant="indeterminate" disableShrink size={45} thickness={8} sx={{ color: "#3646AC" }}></CircularProgress>
//                 </Box></Box> : ""
//                 }
//                 {/* <iframe
//                   id="doc-src"
//                   // src={"/temp.htm"}
//                   src={platformId !== null ? platformId?.overviewUrl : platformByIdDatas?.overviewUrl}
//                   title="description"
//                   width={"100%"}
//                   height={"100%"}
//                   style={{ opacity: 0 }}
//                 ></iframe> */}

//                 <OverviewPlatform name={platformId !== null ? platformId?.overviewUrl : platformByIdDatas?.overviewUrl} />
//               </Box>

//               {/* {isConfigure === "SOURCE" && (
//                 <SourceConfigurationTab
//                   formsSaveConfig={formsSaveConfig}
//                   setformsSaveConfig={setformsSaveConfig}
//                   handleChangeSrc={handleChangeSrc}
//                   isConfigure={isConfigure}
//                   platformId={platformId !== null ? platformId : platformByIdDatas}
//                   platformByIdDatas={platformByIdDatas}
//                   PlatformsIds={id}
//                   value={value}
//                   platformConfigurations={platformConfigurations}
//                   setExpandedSrc={setExpandedSrc}
//                   expandedSrc={expandedSrc}
//                   srcConfigRefs={srcConfigRefs}
//                   ConfigurationAccordion={ConfigurationAccordion}
//                   verticalTabChange={verticalTabChange}
//                   isLoading={isLoading}
//                   isSourceVisible={isSourceVisible}
//                   type={"src"}
//                   connectorId={connectorId}
//                 />
//               )}

//               {isConfigure === "DESTINATION" && (
//                 <DestinationConfigurationTab
//                   formsSaveConfig={formsSaveConfig}
//                   setformsSaveConfig={setformsSaveConfig}
//                   handleChangeDest={handleChangeDest}
//                   isConfigure={isConfigure}
//                   platformId={platformId !== null ? platformId : platformByIdDatas}
//                   platformByIdDatas={platformByIdDatas}
//                   PlatformsIds={id}
//                   value={value}
//                   platformConfigurations={platformConfigurations}
//                   setExpandedDest={setExpandedDest}
//                   expandedDest={expandedDest}
//                   destConfigRefs={destConfigRefs}
//                   ConfigurationAccordion={ConfigurationAccordion}
//                   verticalTabChange={verticalTabChange}
//                   isLoading={isLoading}
//                   isDestinationVisible={isDestinationVisible}
//                   type={"dest"}
//                   connectorId={connectorId}
//                 />
//               )} */}

//               {isConfigure === "Configuration" &&(
//                 <ConfigurationTab  />
//               )}
//             </Box>
//           </Box>
//           {/* <Box sx={{ display: 'flex', justifyContent: 'end', }}>
//                         <CommonButton
//                             height={"2.5rem"}
//                             textStyle={{
//                                 fontSize: "1rem",
//                                 fontWeight: 500,
//                                 fontFamily: 'Helvetica Neue'
//                             }}
//                             onClick={() => { addConfiguration() }}
//                             title={'Add Configuration'}
//                             color={color.buttonColor}
//                             startIcon={<AddIcon sx={{ height: "1.5rem", width: "1.5rem" }} />}
//                         ></CommonButton>
//                     </Box> */}
//         </Box>
//         {/* 'http://ec2-44-213-15-199.compute-1.amazonaws.com/e6d1adf4-c955-11ed-8cb2-f7ad6676aa63/CopyofmParticle.html' */}
//       </Box>
//       <Modal
//         aria-labelledby="transition-modal-title"
//         aria-describedby="transition-modal-description"
//         open={isConfirmOpen}
//         onClose={() => setIsConfirmOpen(false)}
//         closeAfterTransition
//         slots={{ backdrop: Backdrop }}
//         slotProps={{
//           backdrop: {
//             timeout: 500,
//           },
//         }}
//       >
//         <Fade in={isConfirmOpen}>
//           <Box sx={{ ...style, width: 520 }}>
//             <Typography
//               style={{
//                 fontWeight: 700,
//                 fontFamily: "Helvetica Neue",
//                 fontSize: "1rem",
//                 textAlign: "center",
//               }}
//             ></Typography>
//             <Typography
//               sx={{
//                 fontWeight: 500,
//                 fontFamily: "Helvetica Neue",
//                 fontSize: "1rem",
//                 textAlign: "center",
//                 color: "black",
//                 width: "100%",
//               }}
//             >
//               {intent?.configName}
//             </Typography>
//             <Typography
//               sx={{
//                 fontWeight: 400,
//                 fontFamily: "Helvetica Neue",
//                 fontSize: "1rem",
//                 textAlign: "center",
//                 color: "#656C7B",
//                 width: "100%",
//                 mt: 1,
//               }}
//             >
//               Do you want to save the configuration before continuing?
//             </Typography>
//             <Box
//               sx={{
//                 width: "80%",
//                 mt: 3,
//                 display: "flex",
//                 flexDirection: "row",
//                 justifyContent: "center",
//                 alignItems: "center",
//               }}
//             >
//               <Button
//                 disabled={false}
//                 onClick={(event) => {
//                   setIsConfirmOpen(false);
//                   callSubmit(intent?.config, intent.oldExpanded, intent.panel);
//                   // if (intent?.config == "src") {
//                   //   setExpandedSrc(intent.panel);
//                   // } else if (intent?.config == "dest") {
//                   //   setExpandedDest(intent.panel);
//                   // }
//                 }}
//                 style={{
//                   cursor: "pointer",
//                   backgroundColor: color.buttonColor,
//                   width: "5.625rem",
//                   height: "2.5rem",
//                   marginRight: "0.625rem",
//                   color: color.whiteColor,
//                   textTransform: "none",
//                   backgroundColor: color.buttonColor,
//                 }}
//                 variant="contained"
//               >
//                 <Typography
//                   style={{
//                     fontSize: "1rem",
//                     fontWeight: 500,
//                     fontFamily: "Helvetica",
//                     marginTop: 2,
//                   }}
//                 >
//                   Save
//                 </Typography>
//               </Button>
//               <Button
//                 onClick={() => {
//                   setIsConfirmOpen(false);
//                   callDiscard(intent?.config, intent.oldExpanded, intent.panel);
//                   // if (intent?.config == "src") {
//                   //   setExpandedSrc(intent.panel);
//                   // } else if (intent?.config == "dest") {
//                   //   setExpandedDest(intent.panel);
//                   // }
//                 }}
//                 style={{
//                   cursor: "pointer",
//                   border: `1px solid ${color.buttonColor}`,
//                   backgroundColor: color.whiteColor,
//                   height: "2.5rem",
//                   color: color.buttonColor,
//                   textTransform: "none",
//                   marginRight: "0.625rem",
//                 }}
//                 variant="contained"
//               >
//                 <Typography
//                   style={{
//                     fontSize: "1rem",
//                     fontWeight: 500,
//                     fontFamily: "Helvetica Neue",
//                     marginTop: 2,
//                   }}
//                 >
//                   Discard
//                 </Typography>
//               </Button>
//               <Button
//                 onClick={() => {
//                   setIsConfirmOpen(false);
//                   // if (intent?.config == "src") {
//                   //   setExpandedSrc(intent.panel);
//                   // } else if (intent?.config == "dest") {
//                   //   setExpandedDest(intent.panel);
//                   // }
//                 }}
//                 style={{
//                   cursor: "pointer",
//                   border: `1px solid ${color.buttonColor}`,
//                   backgroundColor: color.whiteColor,
//                   height: "2.5rem",
//                   color: color.buttonColor,
//                   textTransform: "none",
//                 }}
//                 variant="contained"
//               >
//                 <Typography
//                   style={{
//                     fontSize: "1rem",
//                     fontWeight: 500,
//                     fontFamily: "Helvetica Neue",
//                     marginTop: 2,
//                   }}
//                 >
//                   Go Back
//                 </Typography>
//               </Button>
//             </Box>
//           </Box>
//         </Fade>
//       </Modal>
//     </Box>
//   );
// };

// export default Configuration;
