import React from 'react';
import PropTypes from 'prop-types';
import { OutlinedInput } from '@mui/material';

import "./CommonInput.css";

function CommonInput(props) {

  return (
    <div style={{ display: 'flex', flexDirection: 'column', width: '100%', marginRight: props.marginRight, marginTop: props.marginTop }}>
      {props.floatedLabel && <label className="common-input-floated-label">{props.placeholder}</label>}
      <OutlinedInput
        {...props.inputProps}
        value={props.value}
        style={{
          backgroundColor: props.backgroundColor,
          height: props.height || "44px",
          marginTop: props.floatedLabel ? 10 : 0
        }}
        autoFocus={props.isFocused}
        error={props.isError}
        onClick={props.onClick}
        onChange={props.onChange}
        className='common-input'
        id={props.id}
        disabled={props.disabled}
        placeholder={props.floatedLabel ? '' : props.placeholder}
        type={props.inputType ? props.inputType : 'text'}
        readOnly={props.readOnly}
        inputProps={props.inputProps}
        onKeyDown={props.onKeyDown}
        endAdornment={props.endAdornment !== undefined ? props.endAdornment : <div></div>}
      ></OutlinedInput>
    </div>
  )
}

CommonInput.propTypes = {
  inputProps: PropTypes.object,
  placeholder: PropTypes.string.isRequired,
  value: PropTypes.string,
  floatedLabel: PropTypes.bool,
  disabled: PropTypes.bool,
  onClick: PropTypes.func,
  onChange: PropTypes.func,
  width: PropTypes.number,
  backgroundColor: PropTypes.string,
  height: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
  displayError: PropTypes.bool,
  errorMessage: PropTypes.string,
  readOnly: PropTypes.bool,
  onKeyDown: PropTypes.func,
  isFocused:PropTypes.bool,
}

export default CommonInput;